import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start	
import { DefaultProfile, UserImg } from "./assets"
import moment from "moment";
let rolesData = {}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  theme?:any;
  location?:any;
  history?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean,
  anchorEl: null | HTMLElement,
  mobileMoreAnchorEl: null | HTMLElement,
  screen: string,
  defaultAppBar: boolean,
  name: string,
  role: string | undefined,
  show: boolean | string,
  jobsData: string,
  activeJobsData: any[] | "",
  closedJobsData: any[] | "",
  candidateSections: string,
  candidateTablePage: number,
  dialog: any,
  allCandidateList: any,
  allManagerList: any,
  searchData: string,
  addPanelStatus: boolean,
  profileImage?: any,
  panelListData:any,
  editProfileImage: any,
  profileData: any,
  profileErrors: any,
  newRequestData?: any;
  approveRequestData?: any;
  searchPanelKey?: any;
  saveProfileResponse: string;
  addManagerStatus: boolean;
  isManagerEditMode: boolean;
  ManagerEditProf:boolean;
  JobSearchData:boolean;
  managerEditData: any
  showDisableSlotDialog: boolean;
  showManageAvailabilityDialog: boolean;
  candidateLoading: boolean;
  defaultSlotTiming: any;
  availabilityData: any;
  managerLoading: boolean;
  loading: boolean;
  selected_job_rol:any;
  openVendorDrawer: boolean;
  unassign_data:any;
  sourceList: any[];
  experienceList: any[];
  slotErrorMessage: string;
  vendorList: any[];
  vendorEditData: any;
  vendorOpeningMode: string;
  interviewSection: string;
  panelInterview:any[];
  disable_slot:any[];
  coordinatorInterview:any[];
  recruiterInterview:any[];
  cordinator_change:string;
  jobsSearch: { closed:string, active:string };
  openNotification: boolean;
  manage_availability:boolean;
  interviewSearchText: string;
  bank_details:any;
  slot_data:any;
  updatebank_detail:any;
  total_interviews:any;
  company_name:any;
  default_slot:any;
  job_data:any;
  joblistData:any;
  error_msg:string;
  isOpenPrimarySkillsDialog:boolean,
  selectedSkillIndex:number;
  skillData: any[];
  primarySkillData: any[];
  unassign_panel:any;
  select:boolean;
  assign_panel:any;
  locationList: any;
  profileKey?: string;
  slots:any;
  jobRoleList:any;
  updateSlot:any;
  recruiterJobList: any[];
  profileImageValidation: boolean;
  managerOpeningMode: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addCandidateId: string = "";
  getAllCandidateListId: string = "";
  getNotAppliedCandidateListId: string = "";
  getRejectedCandidateListId: string = "";
  getAppliedCandidateListId: string = "";
  filteredUnassignedRecruiterJobsCallId: string = "";
  getManagerListId: string = "";
  deleteManagerId: string = "";
  getClosedJobApiId: string = "";
  getActiveJobApiId: string = "";
  joblistId: string="";
  updateTimeslotId: string = "";
  paneldisableListApiCallId: string = "";
  getCoordinatorInteviewId:string="";
  getDefaultManualSlotCallId:string="";
  addBankaccountId: string="";
  getUnassignDataId: string="";
  bulkUploadId: string = "";
  getRolesId: string = "";
  addPanelId: string = "";
  panelListId:string = "";
  getProfileDetailId: string = "";
  updateProfileDetailId: string = "";
  panelNewRequestsApiCallId: string = "";
  panelApproveRequestsApiCallId: string = "";
  panelListApproveRequestsApiCallId:string = "";
  filteredActiveJobsCallId: string = "";
  filteredClosedJobsCallId: string = "";
  saveNewManagerId: string = "";
  updateManagerAPIId: string = "";
  saveDisableSlotCallId: string = "";
  saveDefaultSlotCallId: string = "";
  getDefaultSlotCallId: string = "";
  getPanelInteviewId: string = "";
  getTotalInterviewId:string="";
  getRecruiterInteviewId:string="";
  saveAvailabilityCallId: string = "";
  getAvailabilityCallId: string = "";
  sourceListCallId: string = "";
  experienceListCallId: string = "";
  saveVendorCallId: string = "";
  updateVendorCallId: string = "";
  getAllVendorsCallId: string = "";
  getBankDetailCallId:string="";
  getUnassignPanelId:string="";
  getAssignPanelId:string=""
  updateBankDetailId:string="";
  deleteVendorCallId: string = "";
  recruiterListId:string="";
  getSelectedCandidateListId: string = "";
  getPanelProfileDetailId: string = "";
  updatePanelProfileDetailId: string = "";
  dropdownApiId: string = "";
  deletePanelSkillApiCallId: string = "";
  getLocationsApiId: string = "";
  getRecruiterJobListApiId: string = "";
  //  allCandidateListData : any[] = []
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      mobileOpen: false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      screen: "",
      defaultAppBar: true,
      name: "",
      role: "",
      show: false,
      jobsData: "active",
      activeJobsData: "",
      closedJobsData: "",
      candidateSections: "",
      JobSearchData:false,
      candidateTablePage: 0,
      dialog: { show: false, msg: '' },
      allManagerList: [],
      allCandidateList: {
        NotAppliedCandidates: [],
        RejectedCandidates: [],
        AppliedCandidates: [],
        SelectedCandidates: [],
      },
      searchData: '',
      addPanelStatus: false,
      profileImage: UserImg,
      editProfileImage: {},
      profileData: {},
      profileErrors: {},
      newRequestData: [],
      slot_data:[],
      approveRequestData: [],
      panelListData:[],
      unassign_data:[],
      searchPanelKey: '',
      saveProfileResponse: "",
      addManagerStatus: false,
      isManagerEditMode: false,
      managerEditData: {},
      showDisableSlotDialog: false,
      showManageAvailabilityDialog: false,
      candidateLoading: false,
      defaultSlotTiming: [],
      availabilityData: {},
      managerLoading: false,
      loading: false,
      openVendorDrawer: false,
      sourceList: [],
      jobRoleList:[],
      experienceList: [],
      ManagerEditProf: false,
      slotErrorMessage:"",
      vendorList: [],
      vendorEditData: {},
      vendorOpeningMode: "",
      panelInterview:[],
      coordinatorInterview:[],
      recruiterInterview:[],
      disable_slot:[],
      cordinator_change:"",
      interviewSection: "",
      jobsSearch: { closed:'', active:'' },
      openNotification: false,
      interviewSearchText: "",
      select:false,
      manage_availability:false,
      bank_details:[],
      updatebank_detail:[],
      total_interviews:{},
      selected_job_rol:[],
      company_name:[],
      job_data:"",
      joblistData:[],
      error_msg:"",
      isOpenPrimarySkillsDialog: false,
      selectedSkillIndex: -1,
      skillData: [],
      primarySkillData: [],
      unassign_panel:[],
      slots:[],
      assign_panel:[],
      locationList: [],
      updateSlot:[],
      profileKey: "",
      recruiterJobList: [], 
      default_slot: [],
      profileImageValidation: false,
      managerOpeningMode: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Get All Candidate List

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log("responseJson", responseJson);

      if (apiRequestCallId === this.getManagerListId || apiRequestCallId === this.getAppliedCandidateListId ||
        apiRequestCallId === this.joblistId || apiRequestCallId === this.addBankaccountId ||
        apiRequestCallId === this.getBankDetailCallId || apiRequestCallId === this.getTotalInterviewId ||
        apiRequestCallId === this.getNotAppliedCandidateListId || apiRequestCallId === this.getRejectedCandidateListId ||
        apiRequestCallId === this.getSelectedCandidateListId || apiRequestCallId === this.panelListId ||
        apiRequestCallId === this.paneldisableListApiCallId || apiRequestCallId === this.updateManagerAPIId ||
        apiRequestCallId === this.saveNewManagerId || apiRequestCallId === this.deleteManagerId) {
          this.receiveAPIBlock1(apiRequestCallId, responseJson, errorReponse);
      }

      if (apiRequestCallId === this.addCandidateId || apiRequestCallId === this.getUnassignPanelId ||
        apiRequestCallId === this.getUnassignDataId || apiRequestCallId === this.getAssignPanelId ||
        apiRequestCallId === this.bulkUploadId || apiRequestCallId === this.getRolesId ||
        apiRequestCallId === this.getClosedJobApiId ||
        apiRequestCallId === this.getActiveJobApiId ||
        apiRequestCallId === this.addPanelId || apiRequestCallId === this.getProfileDetailId ||
        apiRequestCallId === this.getPanelProfileDetailId || apiRequestCallId === this.updateProfileDetailId) {
          this.receiveAPIBlock2(apiRequestCallId, responseJson, errorReponse);
      }

      if (apiRequestCallId === this.updatePanelProfileDetailId || apiRequestCallId === this.dropdownApiId ||
        apiRequestCallId === this.deletePanelSkillApiCallId || apiRequestCallId === this.panelNewRequestsApiCallId ||
        apiRequestCallId === this.panelApproveRequestsApiCallId || apiRequestCallId === this.panelListApproveRequestsApiCallId ||
        apiRequestCallId === this.filteredActiveJobsCallId || apiRequestCallId === this.filteredClosedJobsCallId ||
        apiRequestCallId === this.saveDisableSlotCallId ||
        apiRequestCallId === this.saveDefaultSlotCallId || apiRequestCallId === this.getDefaultSlotCallId ||
        apiRequestCallId === this.getDefaultManualSlotCallId || apiRequestCallId === this.getPanelInteviewId) {
          this.receiveAPIBlock3(apiRequestCallId, responseJson, errorReponse);
      }
      
      if (apiRequestCallId ===this.getRecruiterInteviewId || apiRequestCallId ===this.getCoordinatorInteviewId ||
        apiRequestCallId ===this.getAvailabilityCallId || apiRequestCallId ===this.updateBankDetailId ||
        apiRequestCallId ===this.saveAvailabilityCallId || apiRequestCallId ===this.sourceListCallId ||
        apiRequestCallId ===this.experienceListCallId || apiRequestCallId ===this.saveVendorCallId ||
        apiRequestCallId ===this.updateVendorCallId || apiRequestCallId ===this.getAllVendorsCallId ||
        apiRequestCallId ===this.deleteVendorCallId || apiRequestCallId ===this.getLocationsApiId || apiRequestCallId ===this.updateTimeslotId|| apiRequestCallId ===this.recruiterListId ||
        apiRequestCallId ===this.getRecruiterJobListApiId) {
          this.receiveAPIBlock4(apiRequestCallId, responseJson, errorReponse);
      }
      
      this.receiveError(responseJson);
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getAllManagers = (prams: string = '') => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getManagerListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllManagersApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveNewManage = async (values1: any, isEdit: boolean) => {
    const managerData = new FormData();
    managerData.append("manager[first_name]", this.getFormattedUserName(values1.first_name));
    managerData.append("manager[last_name]", this.getFormattedUserName(values1.last_name));
    managerData.append("manager[full_phone_number]", values1.country_code + '' + values1.phone_number);
    managerData.append("manager[email]", values1.email.toLowerCase());
    if (values1.image && values1.image !== "") {
      managerData.append("manager[image]", values1.image);
    }
    if (!isEdit) {
      this.createManagerAPI(managerData);
    } else {
      this.updateManagerAPI(managerData, values1.id);
    }

  }

  createManagerAPI = (managerData: any) => {
    this.setState({ managerLoading: true });
    const headers = {
      "Content-Type": undefined,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveNewManagerId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveNewManagerApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      managerData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateManagerAPI = (managerData: any, managerId: any) => {
    this.setState({ managerLoading: true });
    const headers = {
      "Content-Type": undefined,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateManagerAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveNewManagerApiEndPoint + '/' + managerId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      managerData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  deleteManager = (managerId: number = 0) => {
    this.setState({ managerLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteManagerId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteManagerApiEndPoint + '/' + managerId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getAllCandidates = (prams: string = '', active: string = '',init=false) => {
    if(init){
      this.getAppliedCandidates(active === "applied" ? prams : "");
      this.getNotAppliedCandidates(active === "not_applied" ? prams : "");
      this.getRejectedCandidates(active === "rejected" ? prams : "");
      this.getSelectedCandidates(active === "selected" ? prams : "");
       this.getUnassignedPanel(active ==="unassigned" ? prams:"");
    }
    else{
      switch (active) {
        case "applied":
          this.getAppliedCandidates(prams);
          break;
        case "not_applied":
          this.getNotAppliedCandidates(prams);
          break;
        case "rejected":
          this.getRejectedCandidates(prams);
          break;
        case "selected":
          this.getSelectedCandidates(prams);
          break;
        case "unassigned":
          this.getUnassignedPanel(prams);
          break;
      }
    }
  }

  getAppliedCandidates = async (prams: string = '') => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAppliedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAppliedCandidateListApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getPanelList = (token: any) => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.panelListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.panelList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }


  getJobRoll = (token: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.joblistId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobRollList}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotAppliedCandidates = async (prams: string = '') => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotAppliedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotAppliedCandidateListApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRejectedCandidates = async (prams: string = '') => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRejectedCandidateListApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
  AddBankAccount = (values:any) => {
         const headers = {
           "Content-Type": configJSON.exampleApiContentType,
           "token": localStorage.getItem("token")
         };
     
         const requestMessage = new Message(
           getName(MessageEnum.RestAPIRequestMessage)
         );
         this.addBankaccountId = requestMessage.messageId;
     
         requestMessage.addData(
           getName(MessageEnum.RestAPIResponceEndPointMessage),
           configJSON.addbankAccount
         );
     
         requestMessage.addData(
           getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(headers)
         );
         requestMessage.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
           configJSON.PostAPiMethod
         );
         requestMessage.addData(
           getName(MessageEnum.RestAPIRequestBodyMessage),
           JSON.stringify(values)
         );
         runEngine.sendMessage(requestMessage.id, requestMessage);
       }

       UpdateBankDetail(data:any,Account_id:any){
          const headers = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": localStorage.getItem("token")
          };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.updateBankDetailId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updatebankAccount + '/' + Account_id
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutApiMethodType
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        
       }

       getBankDetail = async () => {
        const headers = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBankDetailCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getbankAccount}?`+'page=1&per_page=3'
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod 
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      getTotalInterview = async () => {

        const headers = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTotalInterviewId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
           configJSON.getInterviews
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      

      }

      getUnassignedPanel = async (prams: string = '') => {

        const headers = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUnassignPanelId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
           `${configJSON.unassignData}?`+'search='+prams
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
       
       
       }

	UpdateTimeSlot(values:any,Account_id:any){

          const headers = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": localStorage.getItem("token")
          };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.updateTimeslotId = requestMessage.messageId;
      
          
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `${configJSON.UpdateSlotTime}${Account_id}/change_slot?start_time=${values.start_time.toISOString()}&end_time=${values.end_time.toISOString()}`
            );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutApiMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        
       }

      getUnassignedData= async (interview_id:any) => {

        const headers = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUnassignDataId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
           `${configJSON.unassignPanel}?`+'interview_id='+interview_id
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      
      
      }
	
	getInterviewList = async () => {

        const headers = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.recruiterListId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.recruiterList
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      
      }

      getAssignedPanel = async (values:any) => {

        const headers = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAssignPanelId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
           configJSON.assignPanel
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.PostAPiMethod
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(values)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      
      }

  getSelectedCandidates = async (prams: string = '') => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSelectedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSelectedCandidateListApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addCandidateFunc = async (values1: any) => {

    const candidateData = new FormData();

    //@ts-ignore
    candidateData.append("candidate[first_name]", this.getFormattedUserName(values1.first_name))
    candidateData.append("candidate[last_name]", this.getFormattedUserName(values1.last_name))
    if (values1.image && typeof (values1.image) === "object") {
      candidateData.append("candidate[image]", values1.image)
    }
    candidateData.append("candidate[full_phone_number]", `${values1.country_code} ${values1.phone_number}`)
    candidateData.append("candidate[email]", values1.email.toLowerCase())
    candidateData.append("candidate[role_id]", "5")
    candidateData.append("candidate[user_name]", this.getFormattedUserName(values1.first_name) + " " + this.getFormattedUserName(values1.last_name))
    candidateData.append("candidate[student_educations_attributes]", JSON.stringify(values1.education))
    candidateData.append("candidate[student_experiences_attributes]", JSON.stringify(values1.experience))
    candidateData.append("candidate[certificates][][url]", values1.certificates)
    candidateData.append("candidate[certificates][][name]", values1.certificates.name)
    candidateData.append("candidate[certificates][][file_type]", "resume")
    candidateData.append("candidate[birth_date]", values1.birth_date)
    candidateData.append("candidate[religion]", values1.religion)
    candidateData.append("candidate[birth_place]", values1.birth_place)
    candidateData.append("candidate[source]", values1.source)

    this.setState({ ...this.state, candidateLoading: true });

    const headers = {
      "Content-Type": undefined,//configJSON.FileApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCandidateId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadCandidateApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      candidateData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  bulkUpload = (data: any) => {
    if (data) {
      const multipleCsv = new FormData();

      for (let i = 0; i < data.length; i++) {
        multipleCsv.append(`file`, data[i])
      }
      this.setState({ ...this.state, candidateLoading: true });

      const headers = {
        "Content-Type": configJSON.FileApiContentType,
        "token": localStorage.getItem("token")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.bulkUploadId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.bulkUploadApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        multipleCsv
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getRoles = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  SearchData = async (searchParams = "") =>{

    if(this.state.jobsData==='active'){
    this.getActiveJob(searchParams)
    }
    if(this.state.jobsData==='closed'){
    this.getClosedJob(searchParams)
    }
  }

  getClosedJob = async (searchParams = "") => {

    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClosedJobApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllJobsApiEndPoint}?status=closed&search=${searchParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getActiveJob = async (searchParams = "") => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActiveJobApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllJobsApiEndPoint}?status=active&search=${searchParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addPanelUser = async (values: any) => {
    this.setState({ managerLoading: true, addPanelStatus: false })
    const panelData = new FormData();

    panelData.append("panel[first_name]", this.getFormattedUserName(values.first_name))
    panelData.append("panel[last_name]", this.getFormattedUserName(values.last_name))
    if (values.image && typeof(values.image) === "object" ) {
      panelData.append( "panel[image]",values.image)
    }
    panelData.append("panel[full_phone_number]", values.country_code + '' + values.phone_number)
    panelData.append("panel[email]", values.email)
    if (values.panel_document_proof && typeof(values.panel_document_proof) === "object") {
      panelData.append("panel[panel_document_proof]", values.panel_document_proof)
    }

    const headers = {
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addPanelId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPanelUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      panelData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileDetail = async (id: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDetailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailAPIEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPanelProfileDetail = async (id: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPanelProfileDetailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.panelProfileApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateProfileDetail = async (id: any, values: any) => {
    const headers = {
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfileDetailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailAPIEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updatePanelProfileDetail = async (id: any, values: any) => {
    const headers = {
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePanelProfileDetailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.panelProfileApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNewPanelsRequests = (currentPage: number, rowsPerPage: number, search: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.panelNewRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelRequestsApiEndPoint}?panel_status=new_request&page=${currentPage}&per_page=${rowsPerPage}&search=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getApprovePanelsRequests = (currentPage: number, rowsPerPage: number, search: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.panelApproveRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelRequestsApiEndPoint}?panel_status=approve&page=${currentPage}&per_page=${rowsPerPage}&search=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getApprovePanelsList = (currentPage: number, rowsPerPage: number, search: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.panelListApproveRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  getClosedFiltersJobs = async (filter: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filteredClosedJobsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFilteredJobsApiEndPoint}?` + filter
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getActiveFiltersJobs = async (filter: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filteredActiveJobsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFilteredJobsApiEndPoint}?` + filter
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveDisableSlot = async (values: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveDisableSlotCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDisableSlotApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveDefaultSlot = async (values: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveDefaultSlotCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDefaultSlotApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDefaultSlotTiming = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDefaultSlotCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDefaultSlotApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDefaultManualSlotTiming = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDefaultManualSlotCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDefaultManualSlotApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPanelDisableList = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.paneldisableListApiCallId = requestMessage.messageId;
    let user_id =localStorage.getItem("userId");
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelDisableList}/`+user_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getInterviewPanel = async (start_day: any, end_day: any) => {

    console.log('panelcalendar')


    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPanelInteviewId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      start_day === end_day ? `${configJSON.panelInterview}?day=${start_day}` :
      `${configJSON.panelInterview}?start_day=${start_day}&end_day=${end_day}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInterviewCoordinator = async (start_day: any, end_day: any, panel_id: any = "") => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCoordinatorInteviewId = requestMessage.messageId;

    const endPoint = start_day === end_day ? `${configJSON.coordinatorInterview}?day=${start_day}&panel_id=${panel_id}` : 
      `${configJSON.coordinatorInterview}?start_day=${start_day}&end_day=${end_day}&panel_id=${panel_id}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveAvailability = async (values: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveAvailabilityCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveAvailabilityApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInterviewRecruiter = async (start_day: any, end_day: any, job_id: any = "") => {

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecruiterInteviewId = requestMessage.messageId;

    const endPoint = start_day === end_day ? `${configJSON.recruiterInterview}?day=${start_day}&job_id=${job_id}` : 
    `${configJSON.recruiterInterview}?start_day=${start_day}&end_day=${end_day}&job_id=${job_id}`;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  getAvailability = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAvailabilityCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAvailabilityApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setAvailabilityData = (data: any) => {
    let availabilityArray = [
      { id: 1, day: "Monday", slots: [], isSelected: false },
      { id: 2, day: "Tuesday", slots: [], isSelected: false },
      { id: 3, day: "Wednesday", slots: [], isSelected: false },
      { id: 4, day: "Thursday", slots: [], isSelected: false },
      { id: 5, day: "Friday", slots: [], isSelected: false },
      { id: 6, day: "Saturday", slots: [], isSelected: false },
      { id: 7, day: "Sunday", slots: [], isSelected: false },
    ];
    availabilityArray.forEach((item: any) => {
      const obj = data && data.slots?.length > 0 ? data.slots.find((el: any) => el?.availability_day[0] === item.day) : null;
      if (obj) {
        const dayObj = item;
        dayObj.slots = obj.slot_timings;
        dayObj.isSelected = true;
      }
    });
    const saveObj = {
      working_availability: data.working_availability,
      default_timing: data.default_timing,
      slots: availabilityArray
    }
    this.setState({ ...this.state, availabilityData: saveObj });
  }

  getSourceList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sourceListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSourceListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getExperienceList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.experienceListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExperienceListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveVendor = (data:any, mode: string) => {
    this.setState({ managerLoading: true });
    mode === "edit" ?  this.updateVendor(data) : this.addNewVendor(data);
  }

  addNewVendor = (data: any) => {
    const newRecruiterData = new FormData();
    newRecruiterData.append("manager[first_name]", this.getFormattedUserName(data.first_name));
    newRecruiterData.append("manager[last_name]", this.getFormattedUserName(data.last_name));
    newRecruiterData.append("manager[full_phone_number]", data.country_code + '' + data.phone_number);
    newRecruiterData.append("manager[email]", data.email.toLowerCase());
    if (data.image && typeof(data.image) === "object" ) {
      newRecruiterData.append("manager[image]", data.image);
    }
    const headers = {
      "Content-Type": undefined,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveVendorCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveRecruiterApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      newRecruiterData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateVendor = (data: any) => {
    const recruiterData = new FormData();
    recruiterData.append("manager[first_name]", this.getFormattedUserName(data.first_name));
    recruiterData.append("manager[last_name]", this.getFormattedUserName(data.last_name));
    recruiterData.append("manager[full_phone_number]", data.country_code + '' + data.phone_number);
    recruiterData.append("manager[email]", data.email.toLowerCase());
    if (data.image && typeof(data.image) === "object" ) {
      recruiterData.append("manager[image]", data.image);
    }
    const headers = {
      "Content-Type": undefined,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateVendorCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveRecruiterApiEndPoint}/${data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      recruiterData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllVendors = (params: string = '') => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllVendorsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRecruitersApiEndPoint + params
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteVendor = (vendorId: number = 0) => {
    this.setState({ managerLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteVendorCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVendorApiEndPoint + '/' + vendorId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllSkills = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dropdownApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dropdownApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deletePanelSkill = (skillId: number = 0) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePanelSkillApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePanelSkillApiEndPoint + skillId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLocationList = async()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLocationsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecruiterJobList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecruiterJobListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecruiterJobListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setPanelProfileDetail = (responseJson: any) => {
    interface PanelProfileOb {
      first_name: string,
      last_name: string,
      email: string,
      country_code: string,
      phone_number: string,
      user_profile_image: string | any,
      id: string,
      company_name: string,
      experience: string,
      designation: string,
      location: string,
      timezone: string,
      timezoneLabel: string;
      primary_skill: any [],
    }
    const data = responseJson.panel;
    const otherDetails = data.other_details.sort((a:any, b: any) => b.id - a.id).find((i: any) => (i.timezone !== "" && i.timezone));
    const company: string = data.professional_details.sort((a:any, b: any) => b.id - a.id).find((i: any) => (i.company_name !== "" && i.company_name))?.company_name;
    const designation: string = data.professional_details.sort((a:any, b: any) => b.id - a.id).find((i: any) => (i.designation !== "" && i.designation))?.designation;
    const experience: string = data.professional_details.sort((a:any, b: any) => b.id - a.id).find((i: any) => (i.experience !== "" && i.experience))?.experience;
    const tText: string = (otherDetails && otherDetails.timezone) ? otherDetails.timezone : "";
    const timezone: string = tText.includes("[") ? tText.slice(tText.indexOf("[") + 1, tText.length - 1) : tText;
    const timezoneLabel: string = tText.includes("[") ? tText.slice(tText.indexOf(("(")), tText.indexOf("[")) : tText;
    const obj: PanelProfileOb = {
      first_name: this.getFormattedUserName(data.first_name),
      last_name: this.getFormattedUserName(data.last_name),
      email: data.email,
      country_code: data.country_code,
      phone_number: data.phone_number,
      user_profile_image: responseJson.panel_profile_image || "",
      id: data.id,
      company_name: company,
      experience: experience,
      designation: designation,
      location: data.location,
      timezone: timezone,
      timezoneLabel: timezoneLabel,
      primary_skill: data.primary_skill_names,
    }
    this.setState({ ...this.state, profileData: obj, editProfileImage: obj.user_profile_image ? obj.user_profile_image: DefaultProfile,
      primarySkillData: [...obj.primary_skill]});
    console.log("success");
    localStorage.setItem("fname", this.getFormattedUserName(data.first_name));
    localStorage.setItem("lname", this.getFormattedUserName(data.last_name));
    localStorage.setItem("image", responseJson.panel_profile_image);
  }

  receiveGetAllManagers = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.managers) {
      //Success Case
      this.setState({
        ...this.state,
        allManagerList:
          responseJson.managers
      })
    } else {
      //Error Case
      this.setState({ ...this.state, allManagerList: [] })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetAppliedCandidates = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.candidates) {
      //Success Case
      this.setState({
        ...this.state,
        allCandidateList:
        {
          ...this.state.allCandidateList,
          AppliedCandidates: responseJson.candidates,
          applied: responseJson.applied,
        }

      })
    } else {
      //Error Case
      console.log("Error Occurred getAppliedCandidateList");
      this.setState({
        ...this.state, allCandidateList: {
          ...this.state.allCandidateList,
          AppliedCandidates: [],
          applied: 0,
        }
      })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetJobRoll = (responseJson: any, errorReponse: any) => {
    if(responseJson && responseJson.job_roles){
      console.log('joblist', responseJson)
      this.setState({joblistData:responseJson.job_roles})
    }
    else{
      this.setState({joblistData:[]})
    }
  }

  receiveAddBankAccount = (responseJson: any, errorReponse: any) => {
    if(responseJson){
      console.log('responseJson', responseJson)
      this.getBankDetail();
     }
  }

  receiveGetBankDetail = (responseJson: any, errorReponse: any) => {
    if(responseJson && responseJson.data){
      console.log('responseJson', responseJson)
      this.setState({bank_details:responseJson.data})
    }
  }

  receiveGetTotalInterview = (responseJson: any, errorReponse: any) => {
    if(responseJson && responseJson.data){
      console.log('responseJson', responseJson);
      this.setState({total_interviews:responseJson.data})
    }
  }

  receiveGetNotAppliedCandidates = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.candidates) {
      //Success Case
      console.log('not_applied',responseJson.not_applied)
      this.setState({
        ...this.state,
        allCandidateList:
        {
          ...this.state.allCandidateList,
          NotAppliedCandidates: responseJson.candidates,
          not_applied: responseJson.not_applied,
        }

      })
    } else {
      //Error Case
      console.log("Error Occurred getNotAppliedCandidateList");
      this.setState({
        ...this.state, allCandidateList: {
          ...this.state.allCandidateList,
          NotAppliedCandidates: [],
          not_applied: 0,
        }
      })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetRejectedCandidates = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.candidates) {
      //Success Case
      console.log('rejected',responseJson.not_applied)
      this.setState({
        ...this.state,
        allCandidateList:
        {
          ...this.state.allCandidateList,
          RejectedCandidates: responseJson.candidates,
          rejected: responseJson.rejected,
        }

      })
    } else {
      //Error Case
      console.log("Error Occurred getRejectedCandidateList");
      this.setState({
        ...this.state, allCandidateList: {
          ...this.state.allCandidateList,
          RejectedCandidates: [],
          rejected: 0,
        }
      })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetSelectedCandidates = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.candidates) {
      //Success Case
      this.setState({
        ...this.state,
        allCandidateList:
        {
          ...this.state.allCandidateList,
          SelectedCandidates: responseJson.candidates,
          selected: responseJson.selected,
        }

      })
    } else {
      //Error Case
      console.log("Error Occurred getSelectedCandidateList");
      this.setState({
        ...this.state, allCandidateList: {
          ...this.state.allCandidateList,
          SelectedCandidates: [],
          selected: 0,
        }
      })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetPanelList = (responseJson: any, errorReponse: any) => {
    if(responseJson && responseJson.panels && responseJson.panels.length > 0){
      console.log('panel_user',responseJson.panels?.[0]['id'])
    
       this.setState({approveRequestData:responseJson.panels?.[0]['id']})
    
    }
  }

  receiveGetPanelDisableList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.disable_slot) {
      console.log('panel disable list data',responseJson);
      this.setState({disable_slot:responseJson?.disable_slot})
    }
  }
  
  receiveUpdateManagerAPI = (responseJson: any, errorReponse: any) => {
    this.setState({ managerLoading: false });
    if (responseJson && responseJson.candidate) {
      //Success Case
      this.setState({ addManagerStatus: false })
      window.location.reload();
    } else {
      //Error Case
      console.log("Error Occurred addManager");
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.description}` } })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  
  receiveCreateManagerAPI = (responseJson: any, errorReponse: any) => {
    this.setState({ managerLoading: false });
    if (responseJson && responseJson.message === "Manager created successfully.") {
      //Success Case
      this.setState({ addManagerStatus: false, managerEditData: {}, managerOpeningMode: "", dialog: { show: true, msg: "managerAdded" } });
      this.getAllManagers();
    } else {
      //Error Case
      console.log("Error Occurred addManager");
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.description}` } })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveDeleteManager = (responseJson: any, errorReponse: any) => {
    this.setState({ managerLoading: false });
    if (responseJson && responseJson.message === 'Manager deleted successfully.') {
      this.getAllManagers();
    } else if (responseJson && responseJson.message === "Manager Profile can't be deleted. ") {
      //Error Case
      console.log("Error Occurred deleteManager");
      this.setState({ ...this.state, dialog: { msg: `apiError^User is assigned with job. So profile can't be deleted.`, show: true } })
    } else {
      this.setState({ ...this.state, dialog: { msg: `apiError^${responseJson.description || "Something went wrong"}`, show: true } })
    }
  }

  receiveAddCandidateFunc = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message === "candidate created successfully.") {
      //Success Case
      this.setState({ ...this.state, dialog: { show: true, msg: "candidateAdded" }, candidateLoading: false })
      console.log(" this.addCandidateId", responseJson);
    } else if (responseJson && responseJson.error && responseJson.error === "User Already Exists!") {
      //Error Case
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.error}` }, candidateLoading: false })
    } else {
      console.log("Error Occurred addCandidate");
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.description}` }, candidateLoading: false })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetUnassignedPanel = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.interviews) {
      //Success Case
     this.setState({ ...this.state, unassign_panel: responseJson.interviews})
    } else {
      //Error Case
      console.log("Error Occurred GetUnassignedPanel");
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
receiveGetUnassignedData
   = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.panels) {
      //Success Case
     this.setState({ ...this.state, unassign_data: responseJson.panels })
     this.setState({ ...this.state, slot_data: responseJson.slots })

    } else {
      //Error Case
      console.log("Error Occurred GetUnassignedData")
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetAssignedPanel = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.msg) {
      //Success Case
      console.log('dialog_data',responseJson.msg)
      this.setState({ ...this.state, dialog: { show: true, msg:'slotAdded'}, candidateLoading: true})
      this.getUnassignedPanel();
    } else {
      //Error Case
      console.log("Error Occurred GetAssignedPanel")
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveBulkUpload = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message === "File Uploaded") {
      //Success Case
      console.log(" this.bulkUploadId", responseJson);
      this.setState({ ...this.state, dialog: { show: true, msg: "dataImported" }, candidateLoading: false })
       this.getAppliedCandidates();
       this.getRejectedCandidates();
       this.getNotAppliedCandidates();

    } else {
      //Error Case
      console.log("Error Occurred bulkUploadId", responseJson);

      let description = "Something Went Wrong";
      if (responseJson.message) {
        description = responseJson.message;
      } else if (responseJson.description) {
        description = "Please use sample format to upload data";
      }
      this.setState({ ...this.state,show:false, dialog: { show: true, msg: `apiError^${description}` }, candidateLoading: false })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetRoles = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      //Success Case
      rolesData = responseJson
    } else {
      //Error Case
      console.log("Error Occurred roles_Data", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetClosedJob = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.jobs) {
      //Success Case
      const jobs_list = responseJson.jobs
      //@ts-ignore
      this.setState({ ...this.state, closedJobsData: jobs_list })
      console.log("jobList", jobs_list)
    } else {
      //Error Case
      this.setState({ ...this.state, closedJobsData: [] })
      console.log("error-IN_GET_CLOSED_JOBS")
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetActiveJob = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.jobs) {
      //Success Case
      const jobs_list = responseJson.jobs
      this.setState({ ...this.state, activeJobsData: jobs_list })
    } else {
      //Error Case
      this.setState({ ...this.state, activeJobsData: [] })
      console.log("error-IN_GET_ACTIVE_JOBS")
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveAddPanelUser = (responseJson: any, errorReponse: any) => {
    this.setState({ managerLoading: false });
    if (responseJson && responseJson.message && responseJson.message === "Panel user created successfully.") {
      //Success Case
      this.getNewPanelsRequests(1, 10, "");
    } else if (responseJson && responseJson.message && responseJson.message === "Email has already been taken") {
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.message}` } })
    } else {
      //Error Case
      console.log("Error Occurred roles_Data", responseJson);
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.description || "Something went wrong"}` } })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetProfileDetail = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.user) {
      const data = responseJson.user;
      const obj = {
        first_name: this.getFormattedUserName(data.first_name),
        last_name: this.getFormattedUserName(data.last_name),
        email: data.email,
        country_code: data.country_code,
        phone_number: data.phone_number,
        user_profile_image: responseJson.user_profile_image || "",
        id: data.id,
      }
      this.setState({ ...this.state, profileData: obj, editProfileImage: obj.user_profile_image ? obj.user_profile_image: DefaultProfile})
      console.log("success");
      localStorage.setItem("fname", this.getFormattedUserName(data.first_name));
      localStorage.setItem("lname", this.getFormattedUserName(data.last_name));
      localStorage.setItem("image", responseJson.user_profile_image);
    } else {
      //Error Case
      console.log("Error Occurred getting profile details");
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetPanelProfileDetail = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.panel) {
      this.setPanelProfileDetail(responseJson);
    } else {
      //Error Case
      console.log("Error Occurred getting profile details");
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveUpdateProfileDetail = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message) {
      console.log("success", responseJson.message);
      this.getProfileDetail(localStorage.getItem("userId"));
      setTimeout(() => { this.setState({ ...this.state, saveProfileResponse: "success" }); }, 4000);
      //@ts-ignore
      this.props.updateProfile();
    } else {
      //Error Case
      console.log("Error Occurred update profile details");
      setTimeout(() => { this.setState({ ...this.state, saveProfileResponse: "error" }); }, 4000);
    }
    setTimeout(() => { this.setState({ ...this.state, loading: false }) }, 4000);
    setTimeout(() => { this.setState({ ...this.state, saveProfileResponse: "" }) }, 8000);

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveUpdatePanelProfileDetail = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message) {
      console.log("success", responseJson.message);
      this.getPanelProfileDetail(localStorage.getItem("userId"));
      setTimeout(() => {
        this.setState({ ...this.state, saveProfileResponse: "success" });
      }, 4000);
      //@ts-ignore
      this.props.updateProfile();
    } else {
      //Error Case
      console.log("Error Occurred update profile details");
      setTimeout(() => {
        this.setState({ ...this.state, saveProfileResponse: "error" });
      }, 4000);
    }
    setTimeout(() => {
      this.setState({ ...this.state, loading: false })
    }, 4000);
    setTimeout(() => {
      this.setState({ ...this.state, saveProfileResponse: ""})
    }, 8000);

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetAllSkills = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.skill && responseJson.skill.length > 0) {
      this.setState({ ...this.state, skillData: responseJson.skill})
    } else {
      //Error Case
      console.log("Error Occurred while getting skill list");
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveDeletePanelSkill = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.success) {
      console.log("Skill successfully deleted");
      this.setState({ ...this.state, saveProfileResponse: "success" });
    } else {
      //Error Case
      console.log("Error Occurred while deleting skill");
    }
    setTimeout(() => {
      this.setState({ ...this.state, saveProfileResponse: "" })
    }, 4000)

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetNewPanelsRequests = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      console.log("res newRequestData >>>>>>>", responseJson);
      this.setState({ newRequestData: responseJson })
    }
  }

  receiveGetApprovePanelsRequests = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      console.log("res approveRequestData >>>>>>>", responseJson);
      this.setState({ approveRequestData: responseJson })
    }
  }

  receiveGetApprovePanelsList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.panels && responseJson.panels.length > 0) {
      this.setState({panelListData:responseJson.panels})
      this.setState({approveRequestData:responseJson.panels?.[0]['id']})
      this.setState({cordinator_change:""})
    }
  }

  receiveGetActiveFiltersJobs = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.jobs) {
      this.setState({ ...this.state, activeJobsData: responseJson.jobs });
    }
  }

  receiveGetClosedFiltersJobs = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.jobs) {
      this.setState({ ...this.state, closedJobsData: responseJson.jobs });
    }
  }

  receiveSaveDisableSlot = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message && responseJson.message === "Slot disabled successfully") {
      //Success Case
      this.setState({ showDisableSlotDialog: false });
      this.getPanelDisableList();
      const dateFormat = "DD-MM-YYYY";
      this.getInterviewPanel(moment(new Date()).startOf("week").format(dateFormat), moment(new Date()).endOf("week").format(dateFormat));
    } else if (responseJson && responseJson.message && responseJson.message === "Slot already disabled") {
      //Error Case
      this.setState({...this.state, slotErrorMessage: "Slots already disabled"});
    } else {
      console.log("Error saving disable slot", responseJson);
      this.setState({ showDisableSlotDialog: false });
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveSaveDefaultSlot = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message) {
      this.getDefaultSlotTiming();
      //Success Case
    } else {
      //Error Case
      console.log("Error saving default slot", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetDefaultSlotTiming = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.slots) {
      this.setState({ ...this.state, defaultSlotTiming: responseJson.slots })
      this.getDefaultManualSlotTiming();
    } else {
      //Error Case
      console.log("Error getting default slot timing", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetDefaultManualSlotTiming = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.default_slot && responseJson.manual_slot) {
        this.setState({default_slot:responseJson})
    } else {
      //Error Case
      console.log("Error getting default slot timing", responseJson);
    }
    if(this.state.manage_availability){
      this.setState({manage_availability:true,showManageAvailabilityDialog:false})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetInterviewPanel = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.interviews) {
      this.setState({ ...this.state, panelInterview: responseJson.interviews })
    } else {
      //Error Case
      this.setState({ ...this.state, panelInterview:[] })
      console.log("Error getting default slot timing", responseJson);
    }
  }

  receiveGetInterviewRecruiter = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.interviews) {
      this.setState({ ...this.state, recruiterInterview:responseJson.interviews })
    } else {
      //Error Case
      this.setState({ ...this.state, recruiterInterview:[] })
      console.log("Error getting default slot timing", responseJson);
    }
  }

  receiveGetInterviewCoordinator = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.interviews) {
      this.setState({ ...this.state, coordinatorInterview: responseJson.interviews })
    } else {
      //Error Case
      this.setState({ ...this.state, coordinatorInterview: [] })
      console.log("Error getting GetInterviewCoordinator", responseJson);
    }
  }

  receiveGetAvailability = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      this.setAvailabilityData(responseJson);
    } else {
      //Error Case
      console.log("Error getting availability data", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveUpdateBankDetail = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      console.log("saving availability data", responseJson);
        this.setState({updatebank_detail:responseJson.data})
        this.getBankDetail();
    } else {
      //Error Case
      console.log("Error saving availability data", responseJson);
    }
  }

  receiveSaveAvailability = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message) {
      console.log("saving availability data", responseJson);
      this.getDefaultManualSlotTiming();
    } else {
      //Error Case
      console.log("Error saving availability data", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetSourceList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.list_of_sources) {
      this.setState({...this.state, sourceList: responseJson.list_of_sources});
      console.log("source list", responseJson);
    } else {
      //Error Case
      console.log("Error getting source list", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetExperienceList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.experience_list) {
      this.setState({...this.state, experienceList: responseJson.experience_list});
      console.log("experience list", responseJson);
    } else {
      //Error Case
      console.log("Error getting experience list", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveAddNewVendor = (responseJson: any, errorReponse: any) => {
    this.setState({ managerLoading: false });
    if (responseJson && responseJson.message) {
      this.setState({...this.state, openVendorDrawer: false, vendorEditData:{}, vendorOpeningMode:"", dialog: { show: true, msg: "vendorAdded"}});
      this.getAllVendors();
      console.log("save vendor", responseJson);
    } else {
      //Error Case
      console.log("Error saving vendor", responseJson);
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.description || "Something went wrong"}` } })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveUpdateVendor = (responseJson: any, errorReponse: any) => {
    this.setState({ managerLoading: false });
    if (responseJson && responseJson.recruiter) {
      this.setState({...this.state, openVendorDrawer: false, vendorEditData:{}, vendorOpeningMode:""});
      this.getAllVendors();
      console.log("save vendor", responseJson);
    } else {
      //Error Case
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.description || "Something went wrong"}` } })
      console.log("Error saving vendor", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetAllVendors = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.managers) {
      this.setState({...this.state, vendorList: responseJson.managers});
      console.log("get vendor list", responseJson);
    } else {
      //Error Case
      console.log("Error getting vendor list", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  receiveUpdateTimeSlot = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.slot) {
      this.setState({...this.state, updateSlot: responseJson.slot});
      console.log("Update TimeSLot", responseJson);
    } else {
      //Error Case
      console.log("Error getting Update TimeSLot", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveRecruiterList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.jobs) {
      this.setState({...this.state, jobRoleList: responseJson.jobs});
      console.log("get vendor list", responseJson);
    } else {
      //Error Case
      console.log("Error getting Recruiter list", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveDeleteVendor = (responseJson: any, errorReponse: any) => {
    this.setState({ managerLoading: false });
    if (responseJson && responseJson.message === 'Recruiter deleted successfully.') {
      this.getAllVendors();
    } else if (responseJson && responseJson.message === "Recruiter Profile can't be deleted. ") {
      //Error Case
      console.log("Error Occurred delete recruiters");
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^User is assigned with job. So profile can't be deleted.` } })
    } else {
      this.setState({ ...this.state, dialog: { show: true, msg: `apiError^${responseJson.description || "Something went wrong"}` } })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetLocationList = (responseJson: any, errorReponse: any) => {
    console.log(responseJson)
    if(responseJson && responseJson.Locations && responseJson.Locations.length > 0){
      this.setState({...this.state, locationList: responseJson.Locations});
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetRecruiterJobList = (responseJson: any, errorReponse: any) => {
    if(responseJson && responseJson.jobs && responseJson.jobs.length > 0){
      this.setState({...this.state, recruiterJobList: responseJson.jobs, selected_job_rol: responseJson.jobs[0].job_role, company_name: responseJson.jobs[0].company});
    } else {
      console.log("Error while getting job list");
      this.setState({...this.state, recruiterJobList: [], selected_job_rol: "", company_name: ""});
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveAPIBlock1 = (apiId: any, responseJson: any, errorReponse: any) => {
    if (apiId === this.getManagerListId) {
      this.receiveGetAllManagers(responseJson, errorReponse);
    }
    if (apiId === this.getAppliedCandidateListId) {
      this.receiveGetAppliedCandidates(responseJson, errorReponse);
    }
    if (apiId === this.joblistId) {
      this.receiveGetJobRoll(responseJson, errorReponse);
    }
    if (apiId === this.addBankaccountId) {
      this.receiveAddBankAccount(responseJson, errorReponse);
    }
    if (apiId === this.getBankDetailCallId) {
      this.receiveGetBankDetail(responseJson, errorReponse);
    }
    if (apiId === this.getTotalInterviewId) {
      this.receiveGetTotalInterview(responseJson, errorReponse);
    }
    if (apiId === this.getNotAppliedCandidateListId) {
      this.receiveGetNotAppliedCandidates(responseJson, errorReponse);
    }
    if (apiId === this.getRejectedCandidateListId) {
      this.receiveGetRejectedCandidates(responseJson, errorReponse);
    }
    if (apiId === this.getSelectedCandidateListId) {
      this.receiveGetSelectedCandidates(responseJson, errorReponse);
    }
    if (apiId === this.panelListId) {
      this.receiveGetPanelList(responseJson, errorReponse);
    }
    if(apiId === this.paneldisableListApiCallId){
      this.receiveGetPanelDisableList(responseJson, errorReponse);
    }
    if (apiId === this.updateManagerAPIId) {
      this.receiveUpdateManagerAPI(responseJson, errorReponse);
    }
    if (apiId === this.saveNewManagerId) {
      this.receiveCreateManagerAPI(responseJson, errorReponse);
    }
    if (apiId === this.deleteManagerId) {
      this.receiveDeleteManager(responseJson, errorReponse);
    }
  }

  receiveAPIBlock2 = (apiId: any, responseJson: any, errorReponse: any) => {
    if (apiId === this.addCandidateId) {
      this.receiveAddCandidateFunc(responseJson, errorReponse);
    } 
    if (apiId === this.getUnassignPanelId) {
      this.receiveGetUnassignedPanel(responseJson, errorReponse);
    }
    if (apiId === this.getUnassignDataId) {
      this.receiveGetUnassignedData(responseJson, errorReponse);
    }
    if (apiId === this.getAssignPanelId) {
      this.receiveGetAssignedPanel(responseJson, errorReponse);
    }
    if (apiId === this.bulkUploadId) {
      this.receiveBulkUpload(responseJson, errorReponse);
    }
    if (apiId === this.getRolesId) {
      this.receiveGetRoles(responseJson, errorReponse);
    }
    if (apiId === this.getClosedJobApiId) {
      this.receiveGetClosedJob(responseJson, errorReponse);
    }
    if (apiId === this.getActiveJobApiId) {
      this.receiveGetActiveJob(responseJson, errorReponse);
    }
    if (apiId === this.addPanelId) {
      this.receiveAddPanelUser(responseJson, errorReponse);
    }
    if (apiId === this.getProfileDetailId) {
      this.receiveGetProfileDetail(responseJson, errorReponse);
    }
    if (apiId === this.getPanelProfileDetailId) {
      this.receiveGetPanelProfileDetail(responseJson, errorReponse);
    }
    if (apiId === this.updateProfileDetailId) {
      this.receiveUpdateProfileDetail(responseJson, errorReponse);
    }
  }

  receiveAPIBlock3 = (apiId: any, responseJson: any, errorReponse: any) => {
    if (apiId === this.updatePanelProfileDetailId) {
      this.receiveUpdatePanelProfileDetail(responseJson, errorReponse);
    }
    if (apiId === this.dropdownApiId) {
      this.receiveGetAllSkills(responseJson, errorReponse);
    }
    if (apiId === this.deletePanelSkillApiCallId) {
      this.receiveDeletePanelSkill(responseJson, errorReponse);
    }
    if (apiId === this.panelNewRequestsApiCallId) {
      this.receiveGetNewPanelsRequests(responseJson, errorReponse);
    }
    if (apiId === this.panelApproveRequestsApiCallId) {
      this.receiveGetApprovePanelsRequests(responseJson, errorReponse);
    }
    if (apiId === this.panelListApproveRequestsApiCallId){
      this.receiveGetApprovePanelsList(responseJson, errorReponse);
    }
    if (apiId === this.filteredActiveJobsCallId) {
      this.receiveGetActiveFiltersJobs(responseJson, errorReponse);
    }
    if (apiId === this.filteredClosedJobsCallId) {
      this.receiveGetClosedFiltersJobs(responseJson, errorReponse);
    }
    if (apiId === this.saveDisableSlotCallId) {
      this.receiveSaveDisableSlot(responseJson, errorReponse);
    }
    if (apiId === this.saveDefaultSlotCallId) {
      this.receiveSaveDefaultSlot(responseJson, errorReponse);
    }
    if (apiId === this.getDefaultSlotCallId) {
      this.receiveGetDefaultSlotTiming(responseJson, errorReponse);
    }
    if (apiId === this.getDefaultManualSlotCallId) {
      this.receiveGetDefaultManualSlotTiming(responseJson, errorReponse);
    }
    if(apiId === this.getPanelInteviewId){
      this.receiveGetInterviewPanel(responseJson, errorReponse);
    }  
  }

  receiveAPIBlock4 = (apiId: any, responseJson: any, errorReponse: any) => {
    if(apiId === this.getRecruiterInteviewId){
      this.receiveGetInterviewRecruiter(responseJson, errorReponse);
    } 
    if(apiId === this.getCoordinatorInteviewId){
      this.receiveGetInterviewCoordinator(responseJson, errorReponse);
    }
    if (apiId === this.getAvailabilityCallId) {
      this.receiveGetAvailability(responseJson, errorReponse);
    } 
    if(apiId === this.updateTimeslotId){
      this.receiveUpdateTimeSlot(responseJson, errorReponse);
    }
    if(apiId === this.recruiterListId){
      this.receiveRecruiterList(responseJson, errorReponse);
    }
    if(apiId === this.updateBankDetailId){
      this.receiveUpdateBankDetail(responseJson, errorReponse);
    }
    
    if (apiId === this.saveAvailabilityCallId) {
      this.receiveSaveAvailability(responseJson, errorReponse);
    }
    if (apiId === this.sourceListCallId) {
      this.receiveGetSourceList(responseJson, errorReponse);
    }
    if (apiId === this.experienceListCallId) {
      this.receiveGetExperienceList(responseJson, errorReponse);
    }
    if (apiId === this.saveVendorCallId) {
      this.receiveAddNewVendor(responseJson, errorReponse);
    }
    if (apiId === this.updateVendorCallId) {
      this.receiveUpdateVendor(responseJson, errorReponse);
    }
    if (apiId === this.getAllVendorsCallId) {
      this.receiveGetAllVendors(responseJson, errorReponse);
    }
    if (apiId === this.deleteVendorCallId) {
      this.receiveDeleteVendor(responseJson, errorReponse);
    }
    if (apiId === this.getLocationsApiId) {
      this.receiveGetLocationList(responseJson, errorReponse);
    }
    if (apiId === this.getRecruiterJobListApiId) {
      this.receiveGetRecruiterJobList(responseJson, errorReponse);
    }
  }

  receiveError = (responseJson: any) => {
    if (responseJson?.errors && (responseJson.errors[0]?.token === "Token has Expired" || responseJson.errors[0]?.token === "Invalid token")) {
      //@ts-ignore
      this.props.history.push("/")
    }
  }

  getFormattedUserName = (name: any)=> {
    let nameString = "";
    if (name) {
      nameString = name.charAt(0).toUpperCase() + name.slice(1);
    }
    return nameString;
  }
  // Customizable Area End
}
