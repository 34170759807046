// Customizable Area Start
import React, { useState } from 'react'
import DocumentOpenerCommonController from "../DocumentOpenerCommonController";
import { Grid, Button, Paper, Divider, IconButton, TextField, InputAdornment,createTheme, Menu, MenuItem, Modal, CircularProgress, Tooltip } from "@material-ui/core";
import { EditIcon, DefaultProfile, AccountIcon, EmailIcon, MobileIcon, FileIcon, DownloadIcon, DeleteIcon, DegreeIcon, CalenderIcon, CompanyIcon, UploadIcon } from '../assets';
import MoreIcon from "@material-ui/icons/MoreVert";
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded'; 
import "./style.css";
import { DatePicker } from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles"
import { Formik } from "formik";
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    } ,
  },
});

export const mainConfigJson = require("../../../../framework/src/config");

const showCandidateErrorMessage = (errorMsg: any) => {
    return <> {errorMsg ? <span style={{ whiteSpace: "nowrap" }} className="error-msg">{errorMsg}</span> : null}</>
}

const getFullName = (fName: string, lName: string) => {
    return `${fName || ""} ${lName || ""}`;
}

const getEmailValue = (email: string) => {
    return email || "";
}

const EducationInfoBlock = (props:any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
      <Grid item xs={12} md={6}>
          <div style={{border:"1px solid rgba(0,0,0,0.12)", borderRadius:8, padding:"15px 0px 15px 15px"}}>
            <div className='block-header'>
                <div>{props.type === "education" ? props.data.institute_name: props.data.name}</div>
                <div><IconButton size='small' onClick={handleMenu}><MoreIcon/></IconButton></div>
            </div>
            <div className='block-row'>
                <div><img src={props.type === "education" ? DegreeIcon : CompanyIcon} alt="" height={18} width={18} /></div>
                <div>{props.type === "education" ? props.data.name : props.data.company_name}</div>
            </div>
            <div className='block-row'>
                <div><img src={CalenderIcon} alt="" height={16} width={16} /></div>
                <div>{moment(props.data.start_year).year()}-{moment(props.data.end_year).year()}</div>
            </div>
            {props.data.description && 
            <div className='description'>
                {props.data.description}
            </div>
            }
          </div>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={!!anchorEl}
            onClose={handleClose}
          >
          <MenuItem onClick={()=>{props.edit();handleClose();} }>EDIT</MenuItem>
          <MenuItem onClick={()=>{handleClose();props.remove();}}>DELETE</MenuItem>
        </Menu>
      </Grid>
    )
}
export const UploadDocumentBlock = ({data,deleteFile,handleDocumentChange,downloadFile}:any) => {
    const [isValidFile, setIsValidFile] = useState<boolean>(true);
    const handleFileChange = (e: any) => {
      if (e.target.files && e.target.files[0].size < 2097152) {
        handleDocumentChange(e, data.index);
        setIsValidFile(true);
      } else {
        setIsValidFile(false);
        const fileElement = document.getElementById(`upload-document-${data.index}`) as any;
        if (fileElement && fileElement.value) {
          fileElement.value = "";
        }
        setTimeout(() => {
          setIsValidFile(true);
        }, [3000])
      }
    }
    return( 
      <Grid item xs={12} md={6}>
          <div style={{border:"1px solid rgba(0,0,0,0.12)", borderRadius:8}}>
            <div className='upload-section'>
                <div className={data.file_name ? "file-name": "doc-name"}>{data.file_name ? data.file_name: data.label}</div>
                {data.file_name ? <div className='action-section'>
                    <span><IconButton size='small' onClick={downloadFile}><img src={DownloadIcon} alt="download-icon" height={24} width={24}/></IconButton></span>
                    <span><IconButton size='small' onClick={deleteFile}><img src={DeleteIcon} alt="delete-icon" height={24} width={24} /></IconButton></span>
                    </div>
                : <div>
                    <label htmlFor={`upload-document-${data.index}`} className="file-input-end-adornment">
                        <input data-test-id="upload-doc-input" id={`upload-document-${data.index}`} type="file" hidden accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, image/jpeg, image/png"
                            onChange={handleFileChange}/>
                            <Button component="span" className="upload-btn">
                                <img width={20} src={UploadIcon} alt="Upload" className="upload-icon" /> UPLOAD
                            </Button>
                    </label>
                  </div>
                }
            </div>
          </div>
          {!isValidFile && <div data-test-id="file-upload-error" style={{ whiteSpace: "nowrap" }} className="error-msg">The file size should be less than 2 MB</div>}
      </Grid>
    )
}

const setFirstFieldValue = (msg: string, values: any) => {
    let firstField = "";
    if (values) {
        if (msg === "personal" && values.full_name) {
            firstField = values.full_name;
        }
        else if (msg === "experience" && values.company_name) {
            firstField = values.company_name;
        }
        else if (msg === "education" && values.institute_name) {
            firstField = values.institute_name;
        }
    }
    return firstField;
}

const setSecondFieldValue = (msg: string, values: any) => {
    let secondField = "";
    if (values) {
        if (msg === "personal" && values.email) {
            secondField = values.email;
        }
        else if (msg !== "personal" && values.name) {
            secondField = values.name;
        }
    }
    return secondField;
}

const setThirdFieldValue = (msg: string, values: any) => {
    let thirdField: any = "";
    if (values) {
        if (msg === "personal") {
            thirdField = "+91";
        }
        else if (msg !== "personal" && values.start_year) {
            thirdField = new Date(values.start_year);
        }
    }
    return thirdField;
}

const setFourthFieldValue = (msg: string, values: any) => {
    let fourthField: any = "";
    if (values) {
        if (msg === "personal" && values.phone_number) {
            fourthField = values.phone_number;
        }
        else if (msg !== "personal" && values.end_year) {
            fourthField = new Date(values.end_year);
        }
    }
    return fourthField;
}

const setFifthFieldValue = (values: any) => {
    return (values && values.description) ? values.description : "";
}

const setFirstFieldPlaceholder = (msg: string) => {
    let firstPlaceHolder = "College/University";
    if (msg === "personal") {
        firstPlaceHolder = "Full Name";
    } else if (msg === "experience") {
        firstPlaceHolder = "Company Name";
    }
    return firstPlaceHolder;
}

const setSecondFieldPlaceholder = (msg: string) => {
    let secondPlaceHolder = "Degree";
    if (msg === "personal") {
        secondPlaceHolder = "Email";
    } else if (msg === "experience") {
        secondPlaceHolder = "Designation";
    }
    return secondPlaceHolder;
}

const setDialogTitle = (msg: string, isEditView: boolean) => {
    let dialogType = "Education";
    if (msg === "personal") {
        dialogType = "Personal";
    } else if (msg === "experience") {
        dialogType = "Experience";
    }
    return `${isEditView ? "Edit" : "Add"} ${dialogType} Information`;
}

const setFirstFieldValidation = (stat: boolean, state: any, msg: string) => {
    const fullNameRegex = new RegExp(/^[a-zA-Z0-9]+ [a-zA-Z0-9]+$/);
    let firstFieldErrorMsg = "";
    if (!stat) {
        if (state.first.length === 0) {
            firstFieldErrorMsg = `${setFirstFieldPlaceholder(msg)} is Required *`;
        } else if (msg === "personal" && !fullNameRegex.test(state.first)) {
            firstFieldErrorMsg = "Enter Valid Full Name i.e. John Doe";
        }
        return <span style={{ fontSize: "12px", color: "red" }}>{firstFieldErrorMsg}</span>
    }
    return null;
}

const setSecondFieldValidation = (stat: boolean, state: any, msg: string) => {
    const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
    let secondFieldErrorMsg = "";
    if (!stat) {
        if (state.second.length === 0) {
            secondFieldErrorMsg = `${setSecondFieldPlaceholder(msg)} is Required *`;
        } else if (msg === "personal" && !emailRegex.test(state.second)) {
            secondFieldErrorMsg = "Enter Valid Email";
        }
        return <span style={{ fontSize: "12px", color: "red" }}>{secondFieldErrorMsg}</span>
    }
    return null;
}

const setMobileNumberValidation = (stat: boolean, state: any) => {
    const phoneNumberRegex = new RegExp(/^\d{10}$/);
    let mobileNumberErrorMsg = "";
    if (!stat) {
        if (state.fourth.length === 0) {
            mobileNumberErrorMsg = "Mobile Number is Required *";
        } else if (!phoneNumberRegex.test(state.fourth)) {
            mobileNumberErrorMsg = "Mobile Number should be 10 digit number";
        }
        return <span style={{ fontSize: "12px", color: "red" }}>{mobileNumberErrorMsg}</span>
    }
    return null;
}

const setStartDateValidation = (stat: boolean, state: any) => {
    let startDateErrorMsg = "";
    if (!stat) {
        if (state.third.length === 0) {
            startDateErrorMsg = "This Field is Required *";
        } else if (state.third >= state.fourth) {
            startDateErrorMsg = "Year should be less than next field";
        }
        return <span style={{ fontSize: "12px", color: "red" }}>{startDateErrorMsg}</span>
    }
    return null;
}

const setEndDateValidation = (stat: boolean, state: any, msg: string) => {
    let endDateErrorMsg = "";
    const currentDate = new Date();
    if (!stat) {
        if (state.fourth.length === 0) {
            endDateErrorMsg = "This Field is Required *";
        } else if ((state.fourth > currentDate) && msg !== "education") {
            endDateErrorMsg = "Month should be less than current month";
        } else if (state.third && (state.third > state.fourth)) {
            endDateErrorMsg = "Year should be greater than previous field";
        }
        return <span style={{ fontSize: "12px", color: "red" }}>{endDateErrorMsg}</span>
    }
    return null;
}

const fileSizeValidation = (msg: boolean) => {
    return (<>
        {msg &&
            <div data-test-id="resume-validation-message" style={{ paddingTop: 5 }} className="error-msg">The file size should be less than 2 MB</div>}
    </>)
}

export const EditCandidateDialog = ({open, msg, handleClose, values, handleSave}:any) => {
    const regexForEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
    const regexForFullName = new RegExp(/^[a-zA-Z0-9]+ [a-zA-Z0-9]+$/);
    const regexForPhoneNumber = new RegExp(/^\d{10}$/);
    const first = setFirstFieldValue(msg, values);
    const second =  setSecondFieldValue(msg, values);
    const third = setThirdFieldValue(msg, values);
    const fourth = setFourthFieldValue(msg, values);
    const fifth = setFifthFieldValue(values);
    const [state,setState] = React.useState<any>({first:first,second:second,third:third,fourth:fourth,fifth:fifth})
    const [stat,setStat] = React.useState(true);

    const validate = () => {
        if ((msg === "personal" && !!state.first && !!state.second && regexForEmail.test(state.second) && regexForFullName.test(state.first) &&
            !!state.fourth && regexForPhoneNumber.test(state.fourth)) ||
            (!!state.first && !!state.second && !!state.third && !!state.fourth && state.third < state.fourth && msg !== "personal")) {
            if (msg === "personal") {
                handleSave({ first_name: state.first.split(" ")[0], last_name: state.first.indexOf(' ') > -1 ? state.first.substring(state.first.indexOf(' ') + 1) : "", email: state.second, country_code: "+91", phone_number: Number(state.fourth) })
            }
            if (msg === "education") {
                handleSave({ id: values.id ?? new Date().toString(), institute_name: state.first, name: state.second, start_year: state.third.toISOString(), end_year: state.fourth.toISOString() })
            }
            if (msg === "experience") {
                handleSave({ id: values.id ?? new Date().toString(), company_name: state.first, name: state.second, start_year: state.third.toISOString(), end_year: state.fourth.toISOString(), description: state.fifth })
            }
            handleClose()
        }
        setStat(false)
    }
    return (
        <Modal open={open} className="edit-candidate-modal">
            <div className='modal-content'>
            {msg === "" ? <><h1> Some error occurred </h1> <Button onClick={()=>handleClose()} className="hy-btn">Close</Button> </> : 
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className='dialog-header' style={{justifyContent:"space-between",display:"flex"}}>
                        <div>{setDialogTitle(msg, fourth)}</div>
                        <CloseIcon className="pointer" onClick={handleClose} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='label'>{setFirstFieldPlaceholder(msg)}</div>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        placeholder={`Enter ${setFirstFieldPlaceholder(msg)}`}
                        type="text"
                        className="text-input"
                        defaultValue={state.first} 
                        onChange={(e) => setState({...state, first:e.target.value})}
                    />
                    {setFirstFieldValidation(stat, state, msg)}
                </Grid>
                <Grid item xs={12}>
                    <div className='label'>{setSecondFieldPlaceholder(msg)}</div>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        placeholder={`Enter ${setSecondFieldPlaceholder(msg)}`}
                        type="text"
                        className="text-input"
                        defaultValue={state.second} 
                        onChange={(e) => setState({...state, second:e.target.value})}
                        disabled={msg === "personal" && role === "candidate"}
                    />
                    {setSecondFieldValidation(stat, state, msg)}
                </Grid>
                {msg === "personal" && <Grid item xs={12}>
                    <div className='label'>Mobile Number</div>
                    <div className="input-group" style={{display:"flex"}}>
                          <TextField 
                            className='text-input country-code-input' 
                            style={{flex:"1",minWidth:"60px"}}  
                            inputProps={{style: { textAlign: 'center' } }} 
                            variant='outlined' 
                            placeholder="Code"
                            value="+91"
                            onChange={(e) => setState({...state, third:e.target.value})}
                          />
                          <TextField 
                            variant="outlined"
                            fullWidth
                            placeholder="Enter Mobile Number"
                            className="text-input phone-input"
                            defaultValue={state.fourth} 
                            onChange={(e) => setState({...state, fourth:e.target.value})}
                          />
                    </div>
                    {setMobileNumberValidation(stat, state)}
                </Grid>
                }
                {msg !== "personal" && <Grid item xs={12} sm={6}>
                    <div className='sub-heading'>From</div>
                    <label className="date-label" onClick={()=>{ document.getElementById("from-date")?.click() }}>
                        <TextField
                            fullWidth
                            className='text-input ' 
                            variant='outlined'  
                            type='text'
                            disabled
                            value={state.third ? `${state.third.getMonth()+1}-${state.third.getFullYear()}` : "" }
                            placeholder="Select Date"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <img src={CalenderIcon} height={22} style={{opacity:0.5,margin: 0, cursor: "pointer"}} alt="calender" />
                                </InputAdornment>,
                            }}         
                        />
                    </label>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <DatePicker disableFuture openTo="year" className="hidden-date" label="Date of birth" views={["year", "month"]} value={new Date()} id="from-date" onChange={(e)=>{ setState({...state,third:e }) }}  />
                    </ThemeProvider>
                    {setStartDateValidation(stat, state)}
                </Grid>}
                {msg !== "personal" && <Grid item xs={12} sm={6} >
                    <div className='sub-heading'>To</div>
                    <label className="date-label" onClick={()=>{ document.getElementById("to-date")?.click() }}>
                        <TextField
                            fullWidth
                            className='text-input ' 
                            variant='outlined'  
                            type='text'
                            disabled
                            value={state.fourth ? `${state.fourth.getMonth()+1}-${state.fourth.getFullYear()}` : "" }
                            placeholder="Select Date"
                            InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <img src={CalenderIcon} height={22} style={{opacity:0.5, margin: 0, cursor: "pointer"}} alt="calender" />
                                </InputAdornment>,
                            }}         
                        />
                    </label>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <DatePicker disableFuture={msg==="experience"} openTo="year" className="hidden-date" format="dd/MM/yyyy" label="Date of birth"  views={["year", "month"]} value={new Date()} id="to-date" onChange={(e)=>{ setState({...state,fourth:e }) }}  />
                    </ThemeProvider>
                    {setEndDateValidation(stat, state, msg)}       
                </Grid>}
                {msg==="experience" && 
                    <Grid item xs={12} sm={12} >
                        <div className='sub-heading'>Description</div>
                        <TextField
                            fullWidth
                            className="text-input"
                            variant='outlined' 
                            minRows="4" 
                            type='text' 
                            placeholder="Enter Description"
                            defaultValue={state.fifth} 
                            onChange={ (e)=>setState({...state, fifth:e.target.value}) } 
                            multiline  
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={6} style={{marginTop:"8px"}} className="dialog-action-body" >
                    <Button fullWidth 
                        variant="outlined"
                        onClick={handleClose}
                        className="cancel-btn"
                    >
                        CANCEL
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} style={{marginTop:"8px"}} className="dialog-action-body" >
                    <Button className='save-btn' fullWidth onClick={validate}>
                        SAVE
                    </Button>
                </Grid>
            </Grid>
            }
            </div>
        </Modal>
    )
}

let role: string | undefined;
export default class ViewCandidateProfile extends DocumentOpenerCommonController {
    id: number = -1;
    parentComponent:any = {path:"", tab:""};
    companyId: any = "";
    async componentDidMount() {
      //@ts-ignore 
      const locationState = this.props.location.state;
      this.id = locationState.id;
      this.jobId = locationState?.jobId;
      this.parentComponent = {path:locationState.path, tab:locationState.tab};
      // @ts-ignore
      this.companyId = this.props?.location?.state?.companyId;
      this.getCandidateDetails(this.id);
      role = localStorage.getItem('role')?.toLowerCase()
    }

  render() {
    const {showEditCandidateDialog, candidateDetail, candidateProfileImage, candidateProfileErrors, candidateDocuments} = this.state;
    const currentDate = new Date();

    const openEditDialog = (isNew: boolean, type: string, obj: any = {}) => {
        if (isNew) {
            this.setState({...this.state, showEditCandidateDialog:{msg: type, show:true, data:{}}})
        } else {
            let data:any = {};
            const candidateObj = {...candidateDetail};
            if (type === "personal") {
                data.full_name = `${candidateObj.first_name} ${candidateObj.last_name}`;
                data.email = candidateObj.email;
                data.country_code = "+91";
                data.phone_number = candidateObj.phone_number;
            } else if (type === "education" && obj) {
                const educationObj = candidateObj.student_educations.find((item:any) => JSON.stringify(item) === JSON.stringify(obj));
                if (educationObj) {
                    data.institute_name = educationObj.institute_name;
                    data.name = educationObj.name;
                    data.start_year = educationObj.start_year;
                    data.end_year = educationObj.end_year;
                    data.id = educationObj.id;
                }
            } else if (type === "experience" && obj) {
                const experienceObj = candidateObj.student_experiences.find((item:any) => JSON.stringify(item) === JSON.stringify(obj));
                if(experienceObj) {
                    data.company_name = experienceObj.company_name;
                    data.name = experienceObj.name;
                    data.start_year = experienceObj.start_year;
                    data.end_year = experienceObj.end_year;
                    data.description = experienceObj.description;
                    data.id = experienceObj.id;
                }
            }
            this.setState({...this.state, showEditCandidateDialog:{msg: type, show:true, data:data}})
        }
    }

    const handleSave = (data:any) => {
        let candidateObj = {...candidateDetail};
        if (showEditCandidateDialog.msg === "personal") {
            const updatedObj = {
                ...candidateObj, ...data
            };
            setTimeout(() => {
                this.setState({...this.state, candidateDetail: updatedObj, showEditCandidateDialog: {msg:"" , data:{} , show: false}})
            }, 0);
        }
        if (showEditCandidateDialog.msg === "education") {
            const index = candidateObj.student_educations.findIndex((item:any) => data.id && item.id === data.id);
            if (index > -1) {
                const oldObj = candidateObj.student_educations[index];
                candidateObj.student_educations.splice(index, 1, {...oldObj, ...data});
            } else {
                candidateObj.student_educations.push(data);
            }
            this.setState({...this.state, candidateDetail: candidateObj, showEditCandidateDialog: {msg:"" , data:{} , show: false}})
        }
        if (showEditCandidateDialog.msg === "experience") {
            const index = candidateObj.student_experiences.findIndex((item:any) => data.id && item.id === data.id);
            if (index > -1) {
                const oldObj = candidateObj.student_experiences[index];
                candidateObj.student_experiences.splice(index, 1, {...oldObj, ...data});
            } else {
                candidateObj.student_experiences.push(data);
            }
            this.setState({...this.state, candidateDetail: candidateObj, showEditCandidateDialog: {msg:"" , data:{} , show: false}})
        }
    }
    const handleDeleteItem = (type:string, data:any) => {
        const candidateObj = {...candidateDetail};
        if (type === "education") {
            const educationArray = candidateObj.student_educations;
            let obj = candidateObj.student_educations.find((item:any) => JSON.stringify(item) === JSON.stringify(data));
            let index = candidateObj.student_educations.findIndex((item:any) => JSON.stringify(item) === JSON.stringify(data));
            if (obj && index>-1) {
                let newObj = {...obj, _destroy:1};
                educationArray.splice(index, 1, newObj);
            }
            candidateObj.student_educations = educationArray;
        } 
        else if (type === "experience") {
            const experienceArray = candidateObj.student_experiences;
            let obj = candidateObj.student_experiences.find((item:any) => JSON.stringify(item) === JSON.stringify(data));
            let index = candidateObj.student_experiences.findIndex((item:any) => JSON.stringify(item) === JSON.stringify(data));
            if (obj && index>-1) {
                let newObj = {...obj, _destroy:1};
                experienceArray.splice(index, 1, newObj);
            }
            candidateObj.student_experiences = experienceArray;
        }
        this.setState({...this.state, candidateDetail: candidateObj})
    }

    const getFormattedTimeDuration = () => {
        const date = candidateDetail.updated_at;
        let formattedTime = ""
        let count = moment(moment().format()).diff(moment(date), "seconds");
        let d = Math.floor(count / (3600*24));
        let h = Math.floor(count % (3600*24) / 3600);
        let m = Math.floor(count % 3600 / 60);
        if (d > 0) {
            formattedTime = d+` ${d > 1 ? 'days' : 'day'} ago`
        }
        else if (h > 0) {
            formattedTime = h+` ${h > 1 ? 'hours' : 'hour'} ago`
        } 
        else if (m > 1) {
            formattedTime = m+` ${m > 1 ? 'minutes' : 'minute'} ago`
        } 
        else {
            formattedTime = "just now"
        }
        return formattedTime;
    }

    const downloadFile = (url:any) => {
        if (typeof(url) === "string") {
            const newURL = url.charAt(0) === "/" ? mainConfigJson.baseURL+url : url;
            window.open(newURL, "_blank");
        }
    }

    const deleteResume = () => {
        const candidateObj = { ...candidateDetail };
        const docList = this.state.deletedDocuments;
        let index = -1;
        if (candidateObj.certificates_url && candidateObj.certificates_url.length > 0) {
            index = candidateObj.certificates_url.findIndex((item: any) => item.file_type === "resume");
        }
        if (index > -1) {
            if (typeof (candidateObj.certificates_url[index].url) === "string") {
                docList.push(candidateObj.certificates_url[index].id);
            }
            candidateObj.certificates_url.splice(index, 1);
        } else if (candidateObj.is_bulk) {
            candidateObj.bulk_resumes_url.splice(0, 1);
        }
        this.setState({ ...this.state, candidateDetail: candidateObj, deletedDocuments: docList });
    }
    
    const deleteFile = (file_type: string) => {
        const docObj = [...candidateDocuments];
        const docList = this.state.deletedDocuments;
        const index = docObj.findIndex((item:any) => item.file_type === file_type);
        if (docObj[index] && docObj[index].id) {
            docList.push(docObj[index].id);
        }
        const newObj = {
            ...docObj[index], id: null, url: ""
        }
        docObj.splice(index, 1, newObj);
        this.setState({...this.state, candidateDocuments: docObj, deletedDocuments: docList})
    }

    const uploadDocument = (e:any, index:number) => {
        const docObj = [...candidateDocuments];
        const file = e.target.files[0];
        const data = {
            name: file.name,
            url: file,
            id: docObj[index]?.id ?? null,
            label: docObj[index].label,
            file_type: docObj[index].file_type
        }
        docObj.splice(index,1,data);
        this.setState({...this.state, candidateDocuments: docObj});
    }

    const getResume = () => {
        let resume: any = { url: "", name: "" };
        const candidateObj = { ...candidateDetail };
        let files = [...candidateObj.certificates_url];
        let resumeOb: any = resume;
        if (files.length > 0) {
            resumeOb = files.find((item: any) => item.file_type === "resume");
        }
        if (resumeOb && resumeOb.url && resumeOb.name) {
            resume = { url: resumeOb.url, name: resumeOb.name };
        }
        else if (candidateObj.is_bulk && candidateObj.bulk_resumes_url.length > 0) {
            resume = { url: candidateObj.bulk_resumes_url[0], name: "" };
        }

        return resume;
    }

    const uploadResume = (e:any) => {
        if (getResume().url) {
            deleteResume();
        }
        if (e.target.files && e.target.files[0].size < 2097152) {
            const docObj = [...candidateDetail.certificates_url];
            const file = e.target.files[0];
            const data = {
                name: file.name,
                file_type: "resume",
                url: file,
            }
            docObj.push(data);
            this.setState({...this.state, resumeError: false, candidateDetail: {...candidateDetail, certificates_url: docObj}});
        } else {
            this.setState({...this.state, resumeError: true})
        }
    }

    const scrollToErrorField = (errors:any) => {
        const err = Object.keys(errors);
            if (err.length) {
            const input = document.querySelector(
                `input[name=${err[0]}]`
                );
            const dropdown = document.querySelector(
              `select[name=${err[0]}]`
              );
            const spanBtn = document.querySelector(
                `span[id=${err[0]}]`
            );
        if(input) {
            input.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
          } 
          else if(dropdown) {
              dropdown.scrollIntoView({
                  behavior: 'smooth',
              block: 'center',
            });
        } else if(spanBtn) {
            spanBtn.scrollIntoView({
                behavior: 'smooth',
            block: 'center',
          });
      }
    }
    }
    
    const ageValidation = (bd:string) => {
        let isValid = false;
        const year = bd.length > 10 ? bd.split("-")[0] : bd.split("-")[2];
        const month = bd.split("-")[1];
        const day = bd.length > 10 ? bd.split("-")[2].substr(0,2) : bd.split("-")[0];
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const currentDay = new Date().getDate();
        if (Number(year) < (currentYear - 18)) {
            isValid = true;
        } else if (Number(year) === (currentYear - 18)) {
            if (Number(month) < currentMonth) {
                isValid = true;
            } else if (Number(month) === currentMonth) {
                if (Number(day) <= currentDay) {
                    isValid = true;
                }
            }
        }
        return isValid;
    }
    
    const validateProfile = (values:any, errors:any = {}) => {
        let isInvalidResume = this.state.resumeError;
        if (values.birth_date && values.birth_date.length > 10 && !ageValidation(values.birth_date)) {
            errors.birth_date = "Enter Valid Date"
        } 
        else if (!getResume().url && !getResume().name) {
            errors.resume_file = "Please upload Resume"
            isInvalidResume = false;
        }
        if(!Object.keys(errors).length && !isInvalidResume){
            this.setState({...this.state, candidateProfileErrors:{}});
            saveProfileDetail(values);
        }
        else {
            this.setState({...this.state, candidateProfileErrors: {...errors}, resumeError: isInvalidResume});
            scrollToErrorField(errors);
        }
    }
    
    const saveProfileDetail = (values:any) => {
        const docs = [...candidateDocuments];
        values.student_educations.map((item:any) => {
            if (typeof(item.id) === "string") {
                delete item["id"];
            }
        });
        values.student_experiences.map((item:any) => {
            if (typeof(item.id) === "string") {
                delete item["id"];
            }
        });
        const isInitialData = this.initialCandidateDetail === JSON.stringify(values) && JSON.stringify(this.initialCandidateDocuments) === JSON.stringify(docs);
        if (!isInitialData) {
            this.setState({...this.state, isCandidateProfileSaved: true});
            if (this.state.deletedDocuments.length > 0) {
                this.state.deletedDocuments.forEach((item: any) => {
                    this.deleteDocument(this.id, item);
                })
            }
            this.saveCandidateProfile(values, docs);
        }
    }

    const goBack = () => {
        if(this.parentComponent.path === "/Home/Candidates") {
            //@ts-ignore
            this.props.history.push({pathname: this.parentComponent.path, tab: this.parentComponent.tab});
        }
        else if(this.parentComponent.path === "/Candidates/Home"){
            //@ts-ignore
            this.props.history.push({pathname: this.parentComponent.path, tab: this.parentComponent.tab});
        }
        else {
            //@ts-ignore
            this.props.history.replace({pathname: this.parentComponent.path, state:{tab: this.parentComponent.tab, candidateId: this.id, id: this.jobId, companyId: this.companyId}});
        }
    }

    const getEducationArray = () => {
        const candidateObj = {...candidateDetail};
        let educationArray = [];
        educationArray = candidateObj.student_educations.length > 0 ? candidateObj.student_educations.filter((item:any) => !item._destroy) : []
        return educationArray;
    }
    const getExperienceArray = () => {
        const candidateObj = {...candidateDetail};
        let experienceArray = [];
        experienceArray = candidateObj.student_experiences.length > 0 ? candidateObj.student_experiences.filter((item:any) => !item._destroy) : []
        return experienceArray;
    }

    const getResumeName = () => {
        return <>{getResume().name.length > 0 ? <div className='info-value'>{getResume().name}</div> :
            <div className='info-value link'><a href={getResume().url} target="_blank">my_resume</a></div>}</>
    }

    const Change = (e:any) =>{
     const objectUrl = URL.createObjectURL(e.target.files?e.target.files[0]:DefaultProfile)
     this.setState({...this.state, candidateProfileImage:objectUrl, candidateProfileImageError: false});
    }
    const BirthData= (values:any) =>{
         return values.birth_date && values.birth_date.length > 10 ? moment(values.birth_date).format("DD-MM-YYYY") : values?.birth_date
    }
    return (
        <Formik
            initialValues={candidateDetail}
            onSubmit={async (values) => {
            }}
            enableReinitialize={true}
        >
        {({ 
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
         }) => (
            <div>
            {this.state.isCandidateProfileSaved && <div className='loader-wrapper'><CircularProgress className="progress" size={70} /></div>}
            <form onSubmit={handleSubmit}> 
                <div className='view-candidate-profile-wrapper'>
                    <div className='top-section'>
                        <div className='top-left-header'>
                            <IconButton size='small' onClick={goBack}><KeyboardBackspaceRoundedIcon fontSize='large'/></IconButton>
                            <span style={{paddingLeft:10}}>Candidate Profile</span>
                        </div>
                        <div className='top-right-section'>
                            <div className='update-text'>Last updated {getFormattedTimeDuration()}</div>
                            <div><Button data-test-id="save-profile" className='save-btn' variant='contained' onClick={() => validateProfile(values)}>SAVE PROFILE</Button></div>
                        </div>
                    </div>
                    <Grid container spacing={3} className="pt-30">
                        <Grid item xs={12} sm={4}>
                            <Paper className='card-wrapper'>
                                <Grid container className='card-header-wrapper'>
                                    <Grid item xs={6} sm={12} md={6} className='card-header'>Personal Info</Grid>
                                    <Grid className='btn-div' item xs={6} sm={12} md={6}><Button data-test-id="edit-personal-info-btn" onClick={() => openEditDialog(false, "personal")} className='edit-btn' variant='outlined'>EDIT INFO</Button></Grid>
                                </Grid>
                                <Divider/>
                                <div className='profile-image-wrapper'>
                                    <div className="upload-profile-image">
                                    <input data-test-id="candidate-profile-image" type="file" hidden accept="image/*" id='profile' onChange={(e)=>{
                                        if (e.target.files && e.target.files[0].size < 2097152) {
                                            Change(e); 
                                            setFieldValue('image_url',e.target.files[0])
                                        } else {
                                            setFieldValue('image_url',DefaultProfile);
                                            this.setState({...this.state, candidateProfileImageError: true, candidateProfileImage: DefaultProfile});
                                        }
                                    }}
                                        />
                                        <label htmlFor='profile' className="pointer">
                                            <img id="image" src={candidateProfileImage} alt="user-image" />
                                            <div className="edit-image">
                                                <img src={EditIcon} alt="edit-icon" />
                                            </div>
                                        </label>
                                        {fileSizeValidation(this.state.candidateProfileImageError)}
                                    </div>
                                </div>
                                <div className='personal-info-wrapper'>
                                    <div>
                                        <img src={AccountIcon} alt="account-icon" height={40} width={40}/>
                                    </div>
                                    <div style={{overflow:"hidden"}}>
                                        <div className='info-label'>Full Name</div>
                                        <div className='info-value'>{getFullName(values.first_name, values.last_name)}</div>
                                    </div>
                                </div>
                                <div className='personal-info-wrapper'>
                                    <div>
                                        <img src={EmailIcon} alt="email-icon" height={40} width={40}/>
                                    </div>
                                    <div style={{overflow:"hidden"}}>
                                        <div className='info-label'>Email</div>
                                        <div className='info-value'>{getEmailValue(values.email)}</div>
                                    </div>
                                </div>
                                <div className='personal-info-wrapper'>
                                    <div>
                                        <img src={MobileIcon} alt="mobile-icon" height={40} width={40}/>
                                    </div>
                                    <div style={{overflow:"hidden"}}>
                                        <div className='info-label'>Mobile Number</div>
                                        <div className='info-value'>+91 {values.phone_number}</div>
                                    </div>
                                </div>
                            </Paper>
                            <Paper className='card-wrapper' style={{marginTop:"25px"}}>
                                <div className='card-header-wrapper'>
                                    <div className='card-header'>Resume</div>
                                    <div className='card-header'><label htmlFor="resume-file" className="file-input-end-adornment">
                                        <input data-test-id="resume-upload-input" id="resume-file" type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, image/jpeg, image/png" hidden onChange={uploadResume} />
                                        <Button id="resume_file" component="span" className="edit-btn" variant='outlined'>
                                            UPLOAD NEW RESUME
                                        </Button>
                                    </label>
                                    </div>
                                </div>
                                <Divider/>
                                {getResume().url ? 
                                <Grid container className='resume-upload-wrapper'>
                                    <Grid item xs={9} sm={12} md={9} style={{display:"flex",alignItems:"center", overflow:"hidden"}}>
                                        <div><img src={FileIcon} alt="resume-icon" height={40} width={40}/></div>
                                        {getResumeName()}
                                    </Grid>
                                    <Grid item xs={3} sm={12} md={3} className='resume-action-section'>
                                        <span><IconButton disabled={typeof(getResume().url) === "object"} size='small' onClick={() => downloadFile(getResume().url)}><img src={DownloadIcon} alt="download-icon" height={24} width={24}/></IconButton></span>
                                        <span><IconButton data-test-id="delete-resume" size='small' onClick={() => deleteResume()}><img src={DeleteIcon} alt="resume-icon" height={24} width={24}/></IconButton></span>
                                    </Grid>
                                </Grid> : 
                                <Grid container className='resume-upload-wrapper'>
                                    <Grid item xs={9} sm={12} md={9} style={{display:"flex",alignItems:"center", overflow:"hidden"}}>
                                        <div><img src={FileIcon} alt="resume-icon" height={40} width={40}/></div>
                                        <div className='info-label'>Upload Resume</div>
                                    </Grid>
                                </Grid>
                                }
                                {showCandidateErrorMessage(candidateProfileErrors.resume_file)}
                                {fileSizeValidation(this.state.resumeError)}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Paper className='card-wrapper' style={{paddingBottom:30}}>
                                <div className='card-header-wrapper'>
                                    <div className='card-header'>Education Information (Optional)</div>
                                    <div><Button className='edit-btn' variant='outlined' onClick={() => openEditDialog(true, "education")}>ADD NEW</Button></div>
                                </div>
                                <Divider/>
                                <Grid container item spacing={3} style={{paddingTop:20}}>
                                    {candidateDetail.student_educations.length > 0 && getEducationArray().map((data:any, index:number) => (
                                        <EducationInfoBlock type="education" data={data} key={index+"education"} edit={() => openEditDialog(false, "education", data)} remove={() => handleDeleteItem("education", data)} />
                                    ))}
                                </Grid>
                            </Paper>
                            <Paper className='card-wrapper' style={{marginTop:"25px", paddingBottom:30}}>
                                <div className='card-header-wrapper'>
                                    <div className='card-header'>Experience Information (Optional) </div>
                                    <div><Button className='edit-btn' variant='outlined' onClick={() => openEditDialog(true, "experience")}>ADD NEW</Button></div>
                                </div>
                                <Divider/>
                                <Grid container spacing={3} style={{paddingTop:20}}>
                                    {candidateDetail.student_experiences.length > 0 && getExperienceArray().map((data:any, index:number) => (
                                        <EducationInfoBlock type="experience" data={data} key={index+"experience"} edit={() => openEditDialog(false, "experience", data)} remove={() => handleDeleteItem("experience", data)}/>
                                    ))}
                                </Grid>
                            </Paper>
                            <Paper className='card-wrapper' style={{marginTop:"25px", paddingBottom:30}}>
                                <div className='card-header-wrapper'>
                                    <div className='card-header'>Other Information (Optional)</div>
                                </div>
                                <Divider/>
                                    <Grid container spacing={4} style={{paddingTop:20}}>
                                        <Grid item xs={12} md={6}>
                                            <div className="form-group"> 
                                                <div className="form-item">
                                                <label className="form-label">Birth Place</label>
                                                    <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Enter Birth Place"
                                                    type="text"
                                                    className="text-input"
                                                    name="birth_place"
                                                    onChange={handleChange}
                                                    value={values.birth_place}
                                                />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <div className="form-group">
                                                <div className="form-item">
                                                    <label className="date-label" onClick={()=>{ document.getElementById("trils")?.click() }}>
                                                    Birth Date
                                                    <TextField
                                                    fullWidth
                                                    className='text-input' 
                                                    variant='outlined' 
                                                    type='text'
                                                    disabled
                                                    value={BirthData(values)}
                                                    placeholder="Select Birth Date"
                                                    name="birth_date"
                                                    InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <img src={CalenderIcon} height={22} style={{opacity:0.5, cursor: "pointer"}} alt="calender" />
                                                    </InputAdornment>,
                                                    }}
                                                    />
                                                    </label>
                                                    {showCandidateErrorMessage(candidateProfileErrors.birth_date)}
                                                </div>
                                            </div>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <DatePicker className="hidden-date" disableFuture openTo="year" maxDate={new Date(`${currentDate.getFullYear()-18}-${currentDate.getMonth()+1}-${currentDate.getDate()}`)} format="dd/MM/yyyy" views={["year", "month", "date"]} value={new Date(new Date(`${currentDate.getFullYear()-18}-${currentDate.getMonth()+1}-${currentDate.getDate()}`))} id="trils" onChange={(e)=>{ setFieldValue('birth_date',`${e?.getDate()}-${e && e?.getMonth()+1 || 1}-${e?.getFullYear()}`) }}  />
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <div className="form-group"> 
                                                <div className="form-item">
                                                <label className="form-label">Religion</label>
                                                    <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Enter Religion"
                                                    type="text"
                                                    className="text-input"
                                                    name="religion"
                                                    onChange={handleChange}
                                                    value={values.religion}
                                                />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                            </Paper >
                            <Paper className='card-wrapper' style={{marginTop:"25px", paddingBottom:30}}>
                                <div className='card-header-wrapper'>
                                    <div className='card-header upload-documents'>Upload Documents (Optional)
                                        <Tooltip title="Please include all the respective files in a single document.">
                                            <InfoIcon color='action'/>
                                        </Tooltip>
                                    </div>
                                </div>
                                <Divider/>
                                <Grid container spacing={3} style={{paddingTop:20}}>
                                    {candidateDocuments.map((item:any, index:number) => (
                                        <UploadDocumentBlock
                                            key={index}
                                            data={{file_name: item.url ? item.name : "", label:item.label, index: index}}
                                            deleteFile={()=> deleteFile(item.file_type)} 
                                            downloadFile={()=> downloadFile(item.url)} 
                                            handleDocumentChange={(e:any, i:number) => uploadDocument(e, i)}
                                        />
                                    ))}
                                </Grid>
                            </Paper>
                            {showEditCandidateDialog.show && showEditCandidateDialog.msg && 
                                <EditCandidateDialog 
                                    open={showEditCandidateDialog.show} 
                                    msg={showEditCandidateDialog.msg}
                                    values={showEditCandidateDialog.data}
                                    handleSave={handleSave}
                                    handleClose={() => this.setState({...this.state, showEditCandidateDialog:{msg:"", show:false, data:{}}})} 
                                />
                            }
                        </Grid>
                    </Grid>
                </div>
            </form>
          </div>
         )}
        </Formik>
    )
  }
}

  
// Customizable Area End