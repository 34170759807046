// Customizable Area Start

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import {
  Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableContainer, IconButton, Avatar, Drawer,Select, Grid, Button, Divider,
  Dialog, DialogContent, DialogActions, DialogTitle, createTheme, Modal,Radio, Popover, Checkbox, FormControlLabel
} from "@material-ui/core"
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from "@material-ui/icons/Close";
import {
  MobileIcon, FileIcon, AccountIcon,SuccessIcon, EmailIcon, MyScheduleActiveIcon, ProfessionIcon, AudioVideoIcon, ClockIcon,
  RadioOutlinedIcon, RadioCheckedIcon,CalenderIcon, PdfIcon, WhiteStar, Notification, UnSelectedStar, RatingStar, MainLogoBlack, DropDownIcon
} from './assets';
import "./landing_page.css";
import { Formik } from 'formik';

import Rating from '@material-ui/lab/Rating';
import { InfoIcon } from "../../documentopener/src/assets"

import LinkShareController from '../../LinkShare/src/LinkShareController'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from "@material-ui/icons/AccountCircle";
import SubmitReviewDialog, { SuccessReviewDialog } from "../../LinkShare/src/ReviewDialog"
const mainConfigJson = require("../../../framework/src/config");
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ThemeProvider} from "@material-ui/styles"
interface Column {
  id:   'job_role' | 'interview_slot' | 'candidate_name' | 'confirm_slot_data' |'company'| 'first_name'|'actions'|'';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right';
  format?: (value: number) => string;
}

const defaultMaterialThemeForInterviews = createTheme({
  palette: {
    primary: {
      main: '#083247',
    } ,
  },
  
});



const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: "8px !important",
  },
  container: {
    maxHeight: "100%",
  },
});


function AssignPanelDrawer({ unassign_panel, slots, getAssignedPanel, interviewId, setOpenAssignCandidates, setModalSlot }: { unassign_panel: any, slots: [], interviewId: string, getAssignedPanel: any, setOpenAssignCandidates: any, setModalSlot: any }) {

  let filteredSlot = slots?.filter(function (val: any) {
    return (val.status == 'confirmed')
  });

   let closeDrawerMenu = () => {
    setOpenAssignCandidates(false)
  }

  let getSlotStatus = '';
  if (filteredSlot != undefined && filteredSlot.toString() != '') {
    getSlotStatus = filteredSlot[0]['status'];

  }

  let slotArray = slots?.sort(function (a: any, b: any) {
    let dateOne = new Date(a.updated_at).getTime();
    let dateTwo = new Date(b.updated_at).getTime();
    return dateOne < dateTwo ? 1 : -1; // ? -1 : 1 for ascending/increasing order
  });

  slots = slotArray;

  const assignedPanelToInterview = (ob: any) => {
    let filteredOb = slots.filter(function (ob: any) {
      return (ob.status == 'confirmed')
    });
    let panelValues = { 'panel_id': ob, 'interview_id': interviewId, 'slot_id': filteredOb[0]['id'] }

    getAssignedPanel(panelValues);
    closeDrawerMenu();
  }

  return (
    <div className="draw-cont interviews-page" >
      <div className="heading assign-heading interviews-page">Assign Panel
        <div className="closed pointer interviews-page"
          onClick={() => closeDrawerMenu()}
        >X</div>
      </div><br />
      <div style={{fontSize: '18px', color: 'red'}}>
      *Before assigning interview to panel, please check their profile status approved or not with their availability time.
      </div>
      <div className="assign-candidate-search interviews-page">
      </div>
      <div style={{ textAlign: 'center' }} >
        <div style={{ width: '70%' }}>
          <div className="timeline-content interviews-page">
            <div style={{ display: 'flex' }}>
              <p style={{ fontWeight: 'bold' }}>Confirmed Slot Details </p>
            </div>
            <div className='interview-slots2 interviews-page' style={{ cursor: 'pointer', margin: 0 }}>
              {slots?.map((slot: any) =>
                slot.status === 'confirmed' && (
                  <div className="candidate-details-normal interviews-page" style={{ borderColor: 'green' }}   >
                    {slot && slot?.start_time ? moment(slot?.start_time)?.format("DD MMMM YYYY hh:mm A") : "NA"}
                  </div>)
              ) || "NA"}
            </div>
            <Button className='change-slot-btn interviews-page' disabled={getSlotStatus != 'confirmed'} style={{ width: "100%", padding: "5px 15px", border: "1px solid", fontWeight: 600, fontSize: "14px", borderRadius: 8 }} onClick={() => setModalSlot(true)} variant="outlined">CHANGE SLOT</Button>
          </div>
        </div>
      </div>
      <p style={{ fontWeight: 'bold' }}>Panel List </p>
      <TableContainer className="assign-table-container interviews-page">
        <Table stickyHeader aria-label="sticky table" className='assign-candidate-table-wrapper interviews-page'>
          <TableHead>
            <TableRow className='assign-table-tr interviews-page'>
              <TableCell>First Name</TableCell>
              <TableCell align="left">Last Name</TableCell>
              <TableCell align="left">Phone Number</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unassign_panel?.map((up: any) => {
              return (
                <TableRow className="pointer assign-table interviews-page" key={up.first_name} >
                  <TableCell scope="row" className="block_container assign-table-th interviews-page">
                    <div className="inline">
                      {up.first_name}
                    </div>
                  </TableCell>
                  <TableCell align="left"className="inline" >{up.last_name}</TableCell>
                  <TableCell align="left">{up.phone_number}</TableCell>
                  <TableCell align="left" >
                    <Button variant="outlined" className='change-slot-btn interviews-page' disabled={getSlotStatus != 'confirmed'} onClick={() => { assignedPanelToInterview(up.id)}} >
                      Assign
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {unassign_panel.length === 0 && <p className='no_panel interviews-page'>No panel is available matching the job skill</p>}
      <div className="button assign-candidate-btn interviews-page">
        <button className="btn-2 pointer" onClick={() => closeDrawerMenu()}>CANCEL</button>
      </div>
    </div>
  )
}

const getFormattedTime = (data: any) => {
  let hourString = data.getHours();
  let minuteString = data.getMinutes();
  let ampm = hourString >= 12 ? 'PM' : 'AM';
  hourString = hourString % 12;
  hourString = hourString ? getHour(hourString)  : 12;
  minuteString = minuteString < 10 ? '0' + minuteString : minuteString;
  let stringTime = hourString + ':' + minuteString + ' ' + ampm;
  return stringTime;
}

const getHour = (hrs:any) =>{
 return hrs >= 10 ? hrs: '0'+hrs;
}

const getDateDifference = (sDate: any, eDate: any) => {
  const start_date = moment(moment(sDate, "DD-MM-YYYY"));
  const end_date = moment(moment(eDate, "DD-MM-YYYY"));
  const diffDayCount = start_date.diff(end_date, "day");
  return diffDayCount;
}

const getTimeDifference = (sTime: any, eTime: any) => {
  const start_time = moment(moment(sTime, "hh:mm A"));
  const end_time = moment(moment(eTime, "hh:mm A"));
  const diffMinuteCount = start_time.diff(end_time, "minute");
  return diffMinuteCount;
}

const setSlotValidation = (sDate: any, sTime: any, eDate: any, eTime: any) => {
  let errorMsg = "";
  if (!sTime) {
    errorMsg = "Start Time is Required";
  } else if (!sDate) {
    errorMsg = "Start Date is Required";
  } else if (!eTime) {
    errorMsg = "End Time is Required";
  } else if (!eDate) {
    errorMsg = "End Date is Required";
  } else if (getDateDifference(sDate, eDate) > 0) {
    errorMsg = "Start Date should not be greater than End Date";
  } else if (getDateDifference(sDate, eDate) === 0 &&
  getTimeDifference(sTime, eTime) > -1) {
      errorMsg = "Start Time should not be greater than or equal to End Time";
  }
  return errorMsg;
}

const showTimePlaceholder = (isDate: any) => {
  return isDate ? "": "Select Time";
}

const showDatePlaceholder = (isDate: any) => {
  return isDate ? "": "Select Date";
}

const getCompanyName = (rowData: any, activeTab: string) => {
  let companyName = "";
  companyName = activeTab === "unassigned" ? rowData?.company_detail?.user_name : rowData?.company;
  return companyName;
}

const getAvatarText = (row: any) => {
  let avatarText = "-";
  if (row.candidate_name) {
    avatarText = row.candidate_name.includes(' ') ? row.candidate_name.split(" ")[0][0] + row.candidate_name.split(" ")[1][0] : row.candidate_name[0];
  }
  return avatarText;
}

function InterviewTable({ rows, setColumns, openInterviewDrawer, activeTab, slots, getUnassignedData, unassign_panel, UpdateTimeSlot, getAssignedPanel, openCancelInterview, setData }: { rows: any[], setColumns: string, getUnassignedData: any, unassign_panel: any[], UpdateTimeSlot: any, getAssignedPanel: any, slots: any, openInterviewDrawer: any, activeTab: string, openCancelInterview: any, setData: (data: any) => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeCandidate, setActiveCandidate] = React.useState<any>({});
  const [role, setRole] = React.useState<string>("");
  const [isOpenAssignCandidates, setIsOpenAssignCandidates] = useState(false);
  const [openConfirmSlotModal, setOpenConfirmSlotModal] = useState(false);
  const [modalSlots, setModalSlot] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [interviewId, setInterviewId] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [changeSlotError, setChangeSlotError] = useState('');

  const handleTableMenu = (e: React.MouseEvent<HTMLElement>, i: any) => {
    setAnchorEl(e.currentTarget);
  };

  const interviewsColumnData: Column[] = [
    { id: activeTab==='unassigned'? 'first_name':'candidate_name', label: 'Full Name', minWidth: 100, align: 'left', },
    { id: 'company', label: 'Company', minWidth: 100, align: 'left' },
    {
      id:'job_role',
      label: 'Job Role',
      minWidth: 102,
      align: 'left',
      format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: 'confirm_slot_data',
      label: 'Confirmed Slot',
      minWidth: 100,
      align: 'left',
      format: (value: number) => value.toFixed(2),
    },
    {
      id: 'actions',
      label: '',
      minWidth: 100,
      align: 'left',
      format: (value: number) => value.toFixed(2),
    },
  ]
  const defaultMaterialUiTheme = createTheme({
    palette: {
      primary: { main: '#083247' },
    },
  });

  const handleTableClose = () => {
    setAnchorEl(null);
  };

  const backToTable = () => {
    setIsOpenAssignCandidates(false)
  }

  function menuValidation() {
    let isPassed: boolean = false;
    if (role === "panel" && (activeTab === "todays" || activeTab === "upcoming")) {
      isPassed = true;
    }
    return isPassed;
  }

  function menuValidation2() {
    let isValidValue: boolean = false;
    if (role === "coordinator" && activeTab === "unassigned") {
      isValidValue = true;
    }
    return isValidValue;
  }

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10

  useEffect(() => {
    setPage(0)
  }, [rows]);

  useEffect(() => {
    const userRole = localStorage.getItem("role")?.toLowerCase() || "";
    setRole(userRole);
  }, [])

  const getAvatarColor = (index: number) => {
    const avatarColorArray = [
      { color: "#805cbd", backgroundColor: "#e7e0f2" },
      { color: "#f45d6b", backgroundColor: "#fdebed" },
      { color: "#56c4fb", backgroundColor: "#e0f4fe" },
      { color: "#60c596", backgroundColor: "#e5f5ee" }
    ];
    const number = index < avatarColorArray.length ? index : index % avatarColorArray.length;
    return { background: avatarColorArray[number].backgroundColor, color: avatarColorArray[number].color }
  }


  function TableRowData() {
    return <TableContainer>{rows.length === 0 && <TableRow> <div style={{ padding: "18px 0 ", fontSize: "16px", fontWeight: 900 }}> No Data Found </div> </TableRow>}
    </TableContainer>
  }

  const checkInterviewId = (rows: any) => {
    if (setColumns == 'unassigned') {
      setInterviewId(rows['interview_detail']['id']);
    }
  }
  const onConfirmSlot = () => {
    const errorMsg = setSlotValidation(startDate, startTime, endDate, endTime);
    setChangeSlotError(errorMsg);
    if (!errorMsg) {
      let value = {
        start_time: moment(startDate + " " + startTime, "DD-MM-YYYY hh:mm A"),
        end_time: moment(endDate+ " " + endTime, "DD-MM-YYYY hh:mm A")
      }
      let filteredObj = slots.find((i: any) => i.status == 'confirmed');
  
      UpdateTimeSlot(value, filteredObj['id']);
      onCloseSlotModal();
      setTimeout(() => {
        getUnassignedData(interviewId);
      },[2000]);
    }
  }

  const onCloseSlotModal = () => {
    setModalSlot(false);
    setStartTime("");
    setEndTime("");
    setStartDate("");
    setEndDate("");
    setChangeSlotError("");
  }

  return (
    <Paper className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleTableClose}
      >
        {activeTab !== "unassigned" &&
          <MenuItem id="view-profile" onClick={() => { if (activeTab !== "unassigned") { openInterviewDrawer(activeCandidate) } handleTableClose(); }}>View Details</MenuItem>
        }
        {menuValidation() && <>
          <Divider />
          <MenuItem id="view-profile" onClick={() => { openCancelInterview(activeCandidate); handleTableClose(); }}>Cancel Interview</MenuItem>
        </>}
        {menuValidation2() && <>
          <MenuItem id="view-profile" onClick={() => { setIsOpenAssignCandidates(true); getUnassignedData(interviewId); handleTableClose(); }}>Assign Panel</MenuItem>
        </>}
      </Menu>
      <Drawer
        anchor={'right'}
        open={isOpenAssignCandidates}
        onClose={() => backToTable()}
        PaperProps={{ style: { width: "50%" } }}
      >
        <div className="draw-cont interviews-page" >
          <AssignPanelDrawer slots={slots} unassign_panel={unassign_panel} interviewId={interviewId} getAssignedPanel={getAssignedPanel} setOpenAssignCandidates={(obj: boolean) => setIsOpenAssignCandidates(obj)} setModalSlot={(obj: boolean) => setModalSlot(obj)} />
        </div>
      </Drawer>
      <Modal
        open={openConfirmSlotModal}
        aria-labelledby="interview-confirm-slot-modal-title"
        aria-describedby="interview-confirm-slot-modal-description"
        className="jobs-success-model interviews-page">
        <div className="infromation-block interviews-page">
          <img src={SuccessIcon} alt="relative image" style={{ height: "65px", margin: "35px 0" }} />
          <h6>Are you sure want to confirm the slot?</h6>
          <div style={{ display: "flex", height: "56px", marginTop: "24px" }}>
            <Button onClick={() => {
              setOpenConfirmSlotModal(false)
            }}
              variant='outlined'
              style={{ fontWeight: 600, marginRight: "5px", flex: 1, borderRadius: "8px" }}
            >
              CANCEL
            </Button>
            <Button
              className="hy-btn"
              style={{ fontWeight: 600, marginLeft: "5px", flex: 1, borderRadius: "8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </Modal>
      {modalSlots && <Modal
        open={modalSlots}
        aria-labelledby="interview-change-slot-modal-title"
        aria-describedby="interview-change-slot-modal-description"
        className="jobs-success-model change-slots-wrapper interviews-page"
        style={{ overflowY: 'scroll', }}
      >
        <div style={{ padding: "20px", background: "white", borderRadius: "7px", width: "450px" }}>

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 15 }}>
            <div style={{ fontSize: "20px", fontWeight: 900 }}>
              Change Slots
            </div>
            <CloseIcon className="pointer" onClick={onCloseSlotModal} />
          </div>
          <Grid container direction="row" spacing={3} >
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group interviews-page'>
                <div>
                  <label style={{ fontWeight: 500 }} className="field-label" >Start Time</label>
                  <label onClick={() => document.getElementById(`time_1_interview`)?.click()}>
                    <div style={{ cursor: 'pointer', display: 'flex' }} className="select_date">
                      <div style={{ width: '70%' }}> {startTime} <div style={{ opacity: 0.6 }}> {showTimePlaceholder(startTime)} </div> </div>
                      <div style={{ width: '30%' }} ><img src={DropDownIcon} height={6} className='dropIcon' /> </div>
                    </div>
                  </label>
                </div>
                <ThemeProvider theme={defaultMaterialThemeForInterviews}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <TimePicker className="hidden-date" label="Time" value={new Date()} id={`time_1_interview`} onChange={(e: any) => {
                      const dataToSet = getFormattedTime(e);
                      setStartTime(dataToSet)
                    }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group interviews-page' style={{ position: 'relative', cursor: 'pointer' }}>
                <label style={{ fontWeight: 500, cursor: 'pointer' }} className="field-label interviews-page" >Start Date</label>
                <label onClick={() => document.getElementById(`date_1_interview`)?.click()}>
                  <div style={{ cursor: 'pointer', display: 'flex' }} className="select_date interviews-page">
                    {startDate} 
                    <div style={{ opacity: 0.6 }}> {showDatePlaceholder(startDate)}
                      <img src={CalenderIcon} height={22} 
                        style={{ opacity: 0.5, cursor: 'pointer', marginLeft: 80, marginBottom: -4, float: 'right' }} alt="calender" />
                    </div>
                  </div>
                </label>

                <ThemeProvider theme={defaultMaterialUiTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <DatePicker className="hidden-date"
                      style={{ position: "absolute", cursor: 'pointer' }} openTo="year" format="dd/MM/yyyy"
                      views={["year", "month", "date"]} minDate={moment()}
                      maxDate={moment().add(30, "d")} value={moment()}
                      id={'date_1_interview'}
                      onChange={(e) => { setStartDate(`${e?.getDate()}-${e && e?.getMonth() + 1 || 1}-${e?.getFullYear()}`)
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ ThemeProvider>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3} >
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group interviews-page'>
                <label style={{ fontWeight: 500 }} className="field-label" >End Time</label>
                <label onClick={() => document.getElementById(`time_2_interview`)?.click()}>
                  <div style={{ cursor: 'pointer', display: 'flex' }} className="select_date">
                    <div style={{ width: '70%' }}> {endTime} <div style={{ opacity: 0.6 }}> {showTimePlaceholder(endTime)} </div> </div>
                    <div style={{ width: '30%' }} ><img src={DropDownIcon} height={6} className='dropIcon' /> </div>
                  </div>
                </label>
              </div>
              <ThemeProvider theme={defaultMaterialThemeForInterviews}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <TimePicker className="hidden-date" label="Time" value={new Date()} id={`time_2_interview`} onChange={(e: any) => {
                    const dataToSet = getFormattedTime(e);
                    setEndTime(dataToSet)
                  }} />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group interviews-page' style={{ position: 'relative', cursor: 'pointer' }}>
                <label style={{ fontWeight: 500, cursor: 'pointer' }} className="field-label interviews-page" >End Date</label>
                <label onClick={() => document.getElementById(`date_2_interview`)?.click()}>
                  <div style={{ cursor: 'pointer', display: 'flex' }} className="select_date interviews-page">
                    {endDate} 
                    <div style={{ opacity: 0.6 }}> {showDatePlaceholder(endDate)}
                      <img src={CalenderIcon} height={22} 
                        style={{ opacity: 0.5, cursor: 'pointer', marginLeft: 80, marginBottom: -4, float: 'right' }} alt="calender" />
                    </div>
                  </div>
                </label>

                <ThemeProvider theme={defaultMaterialUiTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <DatePicker className="hidden-date"
                      style={{ position: "absolute", cursor: 'pointer' }} openTo="year" format="dd/MM/yyyy"
                      views={["year", "month", "date"]} minDate={moment()}
                      maxDate={moment().add(30, "d")} value={moment()}
                      id={'date_2_interview'}
                      onChange={(e) => { setEndDate(`${e?.getDate()}-${e && e?.getMonth() + 1 || 1}-${e?.getFullYear()}`)
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ ThemeProvider>
              </div>
            </Grid>
            {changeSlotError && <div style={{paddingLeft:12, paddingBottom:6}} className='error-msg'>{changeSlotError}</div>}
          </Grid>
          <div style={{ display: "flex", height: "56px", marginTop: "24px" }}>
            <Button onClick={onConfirmSlot}
              variant='outlined' className='hy-btn interviews-page'
              style={{ fontWeight: 600, flex: 1, borderRadius: "8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </Modal>}
       <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {interviewsColumnData.map((column: any) => ((column.id !=='first_name' && activeTab !=='unassigned')||(column.id !=='candidate_name' && activeTab ==='unassigned'))&& (
                     <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="candidate-table-header-cell"
                >
                  {column.label}
                </TableCell>
              )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {TableRowData()}
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, ind: number) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={ind}>
                  {interviewsColumnData.map((column: any, i: any) =>  {
                    let value;
                    if (column.id === "actions") {
                      value = <div className=''> <IconButton onClick={(e: any) => { e.stopPropagation(); setData(row); handleTableMenu(e, i); setActiveCandidate(row); checkInterviewId(row); }} > <MoreVertIcon style={{ verticalAlign: "middle" }} /> </IconButton> </div>
                    } else if (column.id === "candidate_name") {
                      value =
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Avatar style={getAvatarColor(ind)} className={"me-2 avatar-icon"}>
                          {getAvatarText(row)}
                          </Avatar>
                          {row.candidate_name || "-"}
                        </span>
                    } 
                    else if (column.id === "first_name") {
                      value =
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Avatar style={getAvatarColor(ind)} className={"me-2 avatar-icon"}>
                              {row.first_name===''?'-':row.first_name[0]}{row.last_name===''?'-':row.last_name[0]}
                          </Avatar>
                          {row.first_name} {row.last_name}
                        </span>
                    }
                    else if (column.id === "confirm_slot_data") {
                      value = getFormattedConfirmSlot(row)
                    } else if (column.id === "company") {
                      value = getCompanyName(row, activeTab)
                    } else {
                      value = row[column.id]
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: "18px", alignItems: "center" }}>
        <div> Showing {rows.length ? page * 10 + 1 : 0} to {page * rowsPerPage + rowsPerPage > rows.length ? rows.length : page * rowsPerPage + rowsPerPage} of {rows.length} entries </div>
        {
          rows.length > 0 ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className={page == 0 ? "next-prev-btn disable" : "next-prev-btn active"} onClick={() => { page != 0 ? setPage(page - 1) : null }} >Prev</div>
              <Pagination style={{ margin: "0px 10px" }} count={Math.ceil(rows.length / 10)} shape="rounded" page={page + 1} onChange={(event, page) => setPage(page - 1)} hidePrevButton hideNextButton />
              <div className={page + 1 <= rows.length / 10 ? "next-prev-btn active" : "next-prev-btn disable"} onClick={page + 1 <= rows.length / 10 ? () => { setPage(page + 1); document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', }) } : () => { }} >Next</div>
            </div>
            : null
        }
      </div>
    </Paper>
  );
}

const CancelInterviewDialog = ({ open, handleClose, cancelInterview, candidateId, jobSlotId }: { open: boolean, handleClose: any, cancelInterview: any, candidateId: any, jobSlotId: any }) => {
  const [err_msg, setMSG] = React.useState<string>("");

  return (
    <Dialog open={open} scroll="body" fullWidth maxWidth="sm" className="default-timing-dialog-wrapper">
      <DialogTitle className="dialog-title">
        <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
          <div>Cancel Interview</div>
          <IconButton size="small" onClick={handleClose}><CloseIcon className="close-icon-btn" /></IconButton>
        </div>
      </DialogTitle>
      <Formik
        initialValues={{
          account_id: 0,
          cancel_interview_reason: "",
          i_am_not_available: false,
          candidate_not_available: false,
          other_reason: ""
        }}

        onSubmit={(values) => {
          let payload = {
            id: candidateId,
            job_slot_id: jobSlotId,
            cancelation_reason: values.cancel_interview_reason,
            description: values.other_reason,
          }

      if(values.cancel_interview_reason==''&&values.other_reason==''){
        return setMSG("Please select any option or Enter your reason") 
      }
          cancelInterview(payload)
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <div className="default-timing-dialog cancel-interview-wrapper">
                <div className={values.cancel_interview_reason === "i_am_not_available" ? 'checkdiv checked' : 'checkdiv'}>
                  <Radio disableRipple id="CancelInterview1" name="cancel_interview_reason" checked={values.cancel_interview_reason === "i_am_not_available"} value="i_am_not_available" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                  <label className='icon-target pointer' htmlFor="CancelInterview1">I am not available</label>
                </div>
                <div className={values.cancel_interview_reason === "candidate_not_available" ? 'checkdiv checked' : 'checkdiv'}>
                  <Radio disableRipple id="CancelInterview2" name="cancel_interview_reason" checked={values.cancel_interview_reason === "candidate_not_available"} value="candidate_not_available" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                  <label className='icon-target pointer' htmlFor="CancelInterview2">Candidate is not available</label>
                </div>
                <div style={{ marginTop: 30 }}>
                  <h3>Other Reason</h3>
                  <textarea className='reason' id="CancelInterview3" name="other_reason" placeholder='Please Enter Your Reason' onChange={handleChange} />
                </div>
                <p className="err_msg">{err_msg} </p>
              </div>
            </DialogContent>
            <DialogActions style={{ paddingTop: 0 }}>
              <Grid container style={{ padding: "24px 24px 12px" }} >
                <Grid item xs={12} className="default-timing-actions">
                  <Button className="save-btn" fullWidth type="submit">
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

const getFormattedConfirmSlot = (row: any) => {
  let initialDate = "-";
  if (row.confirm_job_slot && row.confirm_job_slot.start_time) {
    initialDate = row.confirm_job_slot.start_time;
  } else if (row.confirm_job_slot_id && row.confirm_job_slot_id.start_time) {
    initialDate = row.confirm_job_slot_id.start_time;
  }
  if (moment(initialDate).isValid()) {
    const time = moment(initialDate).format("hh:mm A");
    const newDate = moment(initialDate).format("DD MMMM YYYY");
    initialDate = time + " " + newDate;
  }
  return initialDate;
}

const getJobSlotId = (row: any) => {
  return row.panelinterview_rounds.find((item: any) => item.job_slot_id == row.confirm_job_slot.id).job_slot_id || 0;
}

export default class Interviews extends LinkShareController {
  role: any;
  reviewData = {}
  updatePage: any;
  constructor(props: any) {
    super(props);
    this.handleDialog = this.handleDialog.bind(this);
  }
  
  handleDialog(msg = "", payload: any) {
    if (payload && msg === "submitConfirmation") {
      payload.job_id = this.data?.confirm_job_slot?.job_id
      payload.round_id = this.state.activeInterviewDetails?.round
      payload.slot_id = this.data?.confirm_job_slot?.id
      payload.candidate_id = this.data.candidate_id
      payload.panel_id = this.data.panel_id
      payload.panel_candidate_slot_id = this.data?.panelinterview_rounds && this.data?.panelinterview_rounds[0]?.panel_candidate_slot_id
      this.reviewData = payload
      
    }
    if (msg == "submit") {
      this.sendReview(this.reviewData)
      return this.setState({ openDialog: "", joinInterview: false })
    }
    return this.setState({ openDialog: msg })
  }
  async componentDidMount() {
    this.role = localStorage.getItem("role")?.toLowerCase();
    this.getAllInterviewList(this.role, "");
    this.props?.getUnassignedPanel('');
  

    window.onpopstate = () => {
      if (this.state.joinInterview) {
        // @ts-ignore
        this.props.history.go(1);
      }
    }
  }
  
  async componentWillUnmount() {
    clearInterval(this.updatePage)
  }
  
  async componentDidUpdate(prevProps: any, prevState: any) {

    if ((prevProps.searchText !== this.props.searchText) || 
    (prevState.interviewSection !== this.state.interviewSection)) {
      this.getAllInterviewList(this.role, this.props.searchText);
    }
    const startTime = this.state.activeInterviewDetails?.job_slot?.start_time || "";
    const endTime = this.state.activeInterviewDetails?.job_slot?.end_time || "";

    if (this.state.interviewSection === "todays" && this.state.openInterviewDrawer && startTime && this.role === "panel") {
      this.updatePage = setInterval(() => {
        const currentDate = moment()
        if((currentDate.diff(new Date(startTime), "minutes") > -5 ) && (currentDate.diff(new Date(endTime), "minutes") < 0 ) ||
        currentDate.diff(new Date(endTime), "minutes") > 0){
          clearInterval(this.updatePage)
          this.forceUpdate()
        }
      }, 20000);
    }
    if(this.props.unassign_data!=prevProps.unassign_data){
    
      this.setState({unassign_paneldata:this.props?.unassign_data,slots:this.props.slots})
    }
    if (prevProps.slots !== this.props.slots) {
      this.setState({ ...this.state, slots: this.props?.slots });
    }
  }


  UpdateInterviewStatus(){
    this.updateStatus({interview: {
        round: typeof(this.data?.round) === "object" ? this.data?.round?.round : this.data?.round, round_status: "round_begin", interview_status: "in_progress"
      }
    }, this.data?.panelinterview_rounds && this.data?.panelinterview_rounds[0]?.panel_candidate_slot_id 
    ); this.setState({ ...this.state, openInterviewDrawer: false, candidateId: 0 })
  }

  InterviewButton =(interviewSection:any) =>{
    return <>
      {interviewSection !== "cancelled" && this.role === "coordinator" &&
        <div className='info-value'>{interviewSection === "completed" ? "Candidate has successfully completed the interview." : ""}</div>
      }
      {this.role === "coordinator" && interviewSection !== "cancelled" &&
        <div className='info-value'>{interviewSection === "reviews_pending" || interviewSection === "upcoming" || interviewSection === "todays" ? "Once panel submit the reviews, It will available here" : ""}</div>
      }
    </>
  }

  GridData = (interviewSection: any) => {

    return <>

      {this.InterviewButton(interviewSection)}
      {this.role === "coordinator" && interviewSection !== "cancelled" && <Button
        className="save-btn panel-review-btn"
        fullWidth
        onClick={() => this.getReview(this.data?.confirm_job_slot?.id)}
        disabled={interviewSection === "upcoming" || interviewSection === "reviews_pending" || interviewSection === "todays"}
      >
        VIEW PANEL REVIEW
      </Button>}
      {this.role === "panel" && interviewSection === "completed" && <>
        <div className='info-value'>Candidate has successfully completed the interview.</div>
        <Button
          className="save-btn panel-review-btn "
          fullWidth onClick={() => this.getReview(this.data?.confirm_job_slot?.id)}
        >
          VIEW REVIEW
        </Button>
      </>
      }
      {this.role === "panel" && interviewSection === "reviews_pending" && <Button
        className="save-btn panel-review-btn"
        fullWidth
        onClick={() => this.setState({ ...this.state, openReviews: true, candidateId: 0 })}
      >
        ADD PANEL REVIEW
      </Button>}
    </>
  }

  InteviewData = (interviewSection: any) => {

    return <>
      {this.role === "panel" && (interviewSection === "todays" || interviewSection === "upcoming") && <>

        {this.isMissedSlot() && <div className='info-value'>Join interview option will not be available as you missed this slot.</div>}
        {this.checkForCancelledInterview() && <div className='info-value'>Join interview option will not be available as candidate cancelled this slot.</div>}
        {!this.isMissedSlot() && !this.isJoinInterviewEnabled() && !this.checkForCancelledInterview() ?
          <div className='info-value'>Join interview option will be available at {this.getFormattedInterviewTime() || "-"}.</div> : ""}
        <Button
          className="save-btn panel-review-btn"
          fullWidth
          disabled={interviewSection === "upcoming" || !this.isJoinInterviewEnabled()}
          onClick={() => { this.UpdateInterviewStatus() }}
        >
          JOIN INTERVIEW
        </Button>
      </>}
    </>
  }

  ReviewComponents=(openReviews:any,interviewSection:any,openViewReviews:any,openCancelInterview:any,joinInterview:any,candidateId:any,jobSlotId:any) =>{

     return <>
    {openReviews && <ReviewsDrawer tab={interviewSection} open={openReviews} handleClose={() => { this.setState({ ...this.state, openReviews: false }) }} confirmation={this.handleDialog} />}
        {openViewReviews && <SeeReviewsDrawer tab={interviewSection} open={openViewReviews} handleClose={() => { this.setState({ ...this.state, openViewReviews: false }) }} data={this.state.reviewDetails} />}
        
        {joinInterview &&
          <JoinInterview
            tab={interviewSection}
            handleClose={() => {
              this.setState({ ...this.state, joinInterview: false })
            }
          }
          issueSubmit ={(values:any) => {
            const ob= {
              ...values,
              candidate_id: this.data.candidate_id, 
              job_slot_id: this.data?.confirm_job_slot?.id, 
              panel_id: this.data.panel_id
            }
              this.haveIssueSubmit(ob)
            }
          }
            confirmation={this.handleDialog}
            handleLogout={() => {
              localStorage.clear();
              //@ts-ignore
              this.props.history.push('/')
            }}
            reviewPending={()=>{ this.updateStatus(
                {
                  interview: { round: typeof(this.data?.round) === "object" ? this.data?.round?.round : this.data?.round,
                    round_status: "round_review_pending",
                    interview_status: "in_progress"
                  }
                }, this.data?.panelinterview_rounds && this.data?.panelinterview_rounds[0]?.panel_candidate_slot_id 
              )
            }}
            
          />
        }

        {openCancelInterview &&
          <CancelInterviewDialog
            open={openCancelInterview}
            handleClose={() => this.setState({ ...this.state, openCancelInterview: false, candidateId: 0 })}
            cancelInterview={(data: any) => this.cancelInterview(data)}
            candidateId={candidateId}
            jobSlotId={jobSlotId}
          />
        }
    </>
  }

  isMissedSlot = () => {
    let isMissed = false;
    let initialDate: any = "";
    const slotOb = this.data.confirm_job_slot;
    if (slotOb && slotOb.end_time) {
      initialDate = slotOb.end_time;
    }
    const timeDuration = moment.duration(moment(new Date()).diff(initialDate));
    const min = timeDuration.asMinutes();
    if (min > 0) {
      isMissed = true;
    }
    return isMissed;
  }

  isJoinInterviewEnabled = () => {
    let isEnabled = false;
    let dateObjI: any = "";
    let dateObjE: any = "";
    const slotObj = this.data.confirm_job_slot;
    if (slotObj && slotObj.start_time && slotObj.end_time) {
      dateObjI = slotObj.start_time;
      dateObjE = slotObj.end_time;
    }
    const durationFromStartTime = moment.duration(moment(new Date()).diff(dateObjI));
    const minutesFromStartTime = durationFromStartTime.asMinutes();
    const durationFromEndTime = moment.duration(moment(new Date()).diff(dateObjE));
    const minutesFromEndTime = durationFromEndTime.asMinutes();
    if (minutesFromStartTime > -5 && minutesFromEndTime <= 0 && !this.checkForCancelledInterview()) {
      isEnabled = true;
    }
    return isEnabled;
  }

  checkForCancelledInterview = () => {
    let isCancelled = false;
    const statusText = this.state.activeInterviewDetails.job_slot.status;
    if (statusText === "cancelled") {
      isCancelled = true;
    }
    return isCancelled;
  }

   getRoundDetails = () => {
    let description = "";
    let roundId: any = this.data?.round;
    if (roundId && typeof(roundId) === "object") {
      roundId = this.data.round?.round
    }
    if (roundId) {
      const roundDetails = this.state.activeInterviewDetails.panelinterview_round_detail.find((item: any) => roundId === item.round);
      if (roundDetails && roundDetails.round_detail && roundDetails.round_detail.description) {
        description = roundDetails.round_detail.description;
      }
    }
    return description;
  }

  RoundData(showMore:any){
    return <>
    { this.getRoundDetails() !== "" && <div className='info-value description' style={{ padding: 0 }}>
                      {showMore || this.getRoundDetails().length <= 130 ? `${this.getRoundDetails()}` : `${this.getRoundDetails().substring(0, 130)}`}
                      {this.getRoundDetails().length <= 130 ? null
                        : <span className="description-show-hide pointer" onClick={() => this.setState({ ...this.state, showMore: !showMore })}>{showMore ? "SEE LESS" : "SEE MORE"}</span>}
                    </div> }
    </>
  }
  
   getFormattedInterviewTime = () => {
    let formattedSlotData = "-";
    let iDate: any = "";
    const slotTime = this.data.confirm_job_slot;
    if (slotTime && slotTime.start_time) {
      iDate = slotTime.start_time;
    }
    if (moment(iDate).isValid()) {
      const newDate = moment(iDate).subtract(5, "minute");
      const time = moment(newDate).format("hh:mm");
      formattedSlotData = time;
    }
    return formattedSlotData;
  }
  
    getFormattedInterviewDate = () => {
    let formattedSlot = "-";
    let dateValue: any = "";
    const slotData = this.data.confirm_job_slot;
    if (slotData && slotData.start_time) {
      dateValue = slotData.start_time;
    }
    if (moment(dateValue).isValid()) {
      const day = moment(dateValue).format("dddd");
      const newDate = moment(dateValue).format("DD MMMM YYYY");
      formattedSlot = day + ", " + newDate;
    }
    return formattedSlot;
  }
  
   getFormattedSlotTime = () => {
    let formattedSlotTimings = "-";
    let date: any = "";
    const slot = this.data.confirm_job_slot;
    if (slot && slot.start_time) {
      date = slot.start_time;
    }
    if (moment(date).isValid()) {
      const time = moment(date).format("hh:mm A");
      formattedSlotTimings = time;
    }
    return formattedSlotTimings;
  }

  
   getStatus = (status: string) => {
    return <div>
    
    {status == "completed" &&
      <div className="status-completed interview-table-status">
              completed
      </div>
   }
   {status == "cancelled" &&
      <div className="status-error interview-table-status">
              cancelled
      </div>
   }
   {(status == "upcoming" || status =="todays") &&
      <div className="status-log interview-table-status">
              upcoming
      </div>
   }
   {status == "reviews_pending" &&
      <div className="status-completed interview-table-status">
              feedback pending
      </div>
   }
  </div>
}

 
  
  render() { 
    const { interviewSection, showMore, openInterviewDrawer, openCancelInterview, candidateId, openReviews,
      joinInterview, interviewList, activeInterviewDetails, openViewReviews, resumeObj, jobSlotId } = this.state;
      //@ts-ignore
      const { changeSection } = this.props;
      
      const handleOpenInterviewDrawer = (row: any) => {
        this.getInterviewDetails(interviewSection === "cancelled" ? row.panel_candidate_slot : row.panel_slot_id );
        this.setState({ ...this.state, candidateId: row.candidate_id, showMore: false });
      }
      
      const handleOpenCancelInterview = (row: any) => {
        const panelSlotId = row.panelinterview_rounds.find((item: any) => item.job_slot_id == row.confirm_job_slot.id).panel_candidate_slot_id || 0;
        const jobSlotId = getJobSlotId(row);
        this.setState({ ...this.state, openCancelInterview: true, candidateId: panelSlotId, jobSlotId: jobSlotId});
      }
      
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: -20 }}>
          <div>
            <span className="admin-jobs-heads">
              <input type="radio" name="candidate-heads" id="todays" defaultChecked={interviewSection === "todays"} />
              <label data-test-id="todays-interviews-tab" htmlFor="todays" className="" onClick={() => { this.setState({ ...this.state, interviewSection: "todays" }); changeSection("todays"); }} >
                Today's Interviews
                <div className="jobs-status-counter">{interviewList.todays.length}</div>
              </label>
            </span>
            <span className="admin-jobs-heads">
              <input type="radio" name="candidate-heads" id="upcoming" defaultChecked={interviewSection === "upcoming"} />
              <label data-test-id="upcoming-interviews-tab" htmlFor="upcoming" className="" onClick={() => { this.setState({ ...this.state, interviewSection: "upcoming" }); changeSection("upcoming"); }} >
                Upcoming Interviews
                <div className="jobs-status-counter">{interviewList.upcoming.length}</div>
              </label>
            </span>
            <span className="admin-jobs-heads">
              <input type="radio" name="candidate-heads" id="reviews_pending" defaultChecked={interviewSection === "reviews_pending"} />
              <label data-test-id="review-pending-interviews-tab" htmlFor="reviews_pending" className="" onClick={() => { this.setState({ ...this.state, interviewSection: "reviews_pending" }); changeSection("reviews_pending"); }} >
                Reviews Pending
                <div className="jobs-status-counter">{interviewList.reviewPending.length}</div>
              </label>
            </span>
            <span className="admin-jobs-heads">
              <input type="radio" name="candidate-heads" id="completed" defaultChecked={interviewSection === "completed"} />
              <label data-test-id="completed-interviews-tab" htmlFor="completed" className="" onClick={() => { this.setState({ ...this.state, interviewSection: "completed" }); changeSection("completed"); }} >
                Completed
                <div className="jobs-status-counter">{interviewList.completed.length}</div>
              </label>
            </span>
            <span className="admin-jobs-heads">
              <input type="radio" name="candidate-heads" id="cancelled" defaultChecked={interviewSection === "cancelled"} />
              <label data-test-id="cancelled-interviews-tab" htmlFor="cancelled" className="" onClick={() => { this.setState({ ...this.state, interviewSection: "cancelled" }); changeSection("cancelled"); }} >
                Cancelled
                <div className="jobs-status-counter">{interviewList.cancelled.length}</div>
              </label>
            </span>
            {this.role === "coordinator" && <span className="admin-jobs-heads">
              <input type="radio" name="candidate-heads" id="unassigned" defaultChecked={interviewSection === "unassigned"} />
              <label htmlFor="unassigned" className="" onClick={() => { this.setState({ ...this.state, interviewSection: "unassigned" }); changeSection("unassigned"); }} >
                Unassigned Interviews
                <div className="jobs-status-counter">{this.props?.unassign_panel.length}</div>
              </label>
            </span>}
          </div>
        </div>
        <div className="candidate-table-wrapper interviews-table">
          {interviewSection === "todays" ? <InterviewTable activeTab="todays" rows={interviewList.todays} setColumns={interviewSection} openInterviewDrawer={handleOpenInterviewDrawer} openCancelInterview={handleOpenCancelInterview} slots={this.state?.slots} unassign_panel={this.state?.unassign_paneldata} getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}   UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)}  getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} setData={(data:any)=>{ this.data=data; }} /> : null}
          {interviewSection === "upcoming" ? <InterviewTable activeTab="upcoming" rows={interviewList.upcoming} setColumns={interviewSection} openInterviewDrawer={handleOpenInterviewDrawer} openCancelInterview={handleOpenCancelInterview} slots={this.state?.slots} unassign_panel={this.state?.unassign_paneldata} getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}     UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)}  getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} setData={(data:any)=>{ this.data=data }} /> : null}
          {interviewSection === "reviews_pending" ? <InterviewTable activeTab="reviews_pending" rows={interviewList.reviewPending} setColumns={interviewSection} openInterviewDrawer={handleOpenInterviewDrawer} openCancelInterview={handleOpenCancelInterview} slots={this.state?.slots} unassign_panel={this.state?.unassign_paneldata} getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}    UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)}  getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} setData={(data:any)=>{ this.data=data }} /> : null}
          {interviewSection === "completed" ? <InterviewTable activeTab="completed" rows={interviewList.completed} setColumns={interviewSection} openInterviewDrawer={handleOpenInterviewDrawer} openCancelInterview={handleOpenCancelInterview} slots={this.state?.slots} unassign_panel={this.state?.unassign_paneldata} getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}  UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)} getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} setData={(data:any)=>{ this.data=data }} /> : null}
          {interviewSection === "cancelled" ? <InterviewTable activeTab="cancelled" rows={interviewList.cancelled} setColumns={interviewSection} openInterviewDrawer={handleOpenInterviewDrawer}  unassign_panel={this.state?.unassign_paneldata} slots={this.state?.slots} getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}  UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)} openCancelInterview={handleOpenCancelInterview}getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} setData={(data:any)=>{ this.data=data }} /> : null}
          {interviewSection === "unassigned" ? <InterviewTable activeTab="unassigned" rows={this.props?.unassign_panel} setColumns={interviewSection} openInterviewDrawer={handleOpenInterviewDrawer}unassign_panel={this.state?.unassign_paneldata} slots={this.state?.slots}   UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)}  getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)} openCancelInterview={handleOpenCancelInterview} getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} setData={(data:any)=>{ this.data=data }} /> : null}
        </div>
        {openInterviewDrawer && <div>
          <Drawer anchor={"right"} open={openInterviewDrawer}>
            <div className="availability-container interview-details-container">
              <div className="availability-header">
                <div>Interview Details</div>
                <IconButton onClick={() => this.setState({ ...this.state, openInterviewDrawer: false, candidateId: 0, activeInterviewDetails: {}, resumeObj: {} })} size="small">
                  <CloseIcon className="close-icon" />
                </IconButton>
              </div>
              {(activeInterviewDetails && activeInterviewDetails.id) ? <div className="availability-content interview-details-content">
                <div>
                  <div className='section-header'>Candidate Details</div>
                  <div className='personal-info-wrapper'>
                    <div>
                      <img src={AccountIcon} alt="account-icon" height={40} width={40} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className='info-label'>Candidate Name</div>
                      <div className='info-value'>{`${activeInterviewDetails.candidate.first_name} ${activeInterviewDetails.candidate.last_name}`}</div>
                    </div>
                  </div>
                  <div className='personal-info-wrapper'>
                    <div>
                      <img src={EmailIcon} alt="email-icon" height={40} width={40} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className='info-label'>Email</div>
                      <div className='info-value'>{activeInterviewDetails.candidate.email}</div>
                    </div>
                  </div>
                  <div className='personal-info-wrapper'>
                    <div>
                      <img src={MobileIcon} alt="mobile-icon" height={40} width={40} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className='info-label'>Mobile Number</div>
                      <div className='info-value'>{MobileData(activeInterviewDetails)}</div>
                    </div>
                  </div>
                  <div className='personal-info-wrapper' style={{ paddingBottom: 24 }}>
                    <div>
                      <img src={FileIcon} alt="file-icon" height={40} width={40} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className='info-label'>Resume</div>
                      {ResumeData(resumeObj)}
                    </div>
                  </div>
                  <Divider />
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", paddingTop: 22, paddingBottom: 6 }}>
                    <div className='section-header'>Interview Details</div>
                    {this.role === "coordinator" && interviewSection !== "todays" && <div>{this.getStatus(interviewSection)}</div>}
                  </div>
                  <div className='personal-info-wrapper interview-details'>
                    <div>
                      <img src={ProfessionIcon} alt="profession-icon" height={24} width={24} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      {ReviewsData(activeInterviewDetails)}
                    </div>
                  </div>
                  <div className='personal-info-wrapper interview-details'>
                    <div>
                      <img src={MyScheduleActiveIcon} alt="timing-icon" height={24} width={24} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className='info-value'>{this.getFormattedInterviewDate()}</div>
                    </div>
                  </div>
                  <div className='personal-info-wrapper interview-details'>
                    <div>
                      <img src={AudioVideoIcon} alt="audio-icon" height={24} width={24} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className='info-value'>{activeInterviewDetails?.job_detail?.interview_mode}</div>
                    </div>
                  </div>
                  <div className='personal-info-wrapper interview-details'>
                    <div>
                      <img src={ClockIcon} alt="clock-icon" height={24} width={24} />
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className='info-value'>{this.getFormattedSlotTime()}</div>
                    </div>
                  </div>
                  <div className='personal-info-wrapper' style={{ paddingTop: 20, paddingBottom: 6 }}>
                    <div className='info-label' style={{ paddingLeft: 0 }}>Round Details</div>
                  </div>
                  <div className='personal-info-wrapper' style={{ padding: 0 }}>
                    {this.RoundData(showMore)}
                  </div>
                </div>
                <Grid container spacing={2} style={{ paddingTop: 26 }}>
                  <Grid item xs={12} className="availability-action">
                    {this.GridData(interviewSection)}
                    {this.InteviewData(interviewSection)}
                  </Grid>
                  {this.role === "panel" &&
                    <Grid item xs={12} className="availability-action">
                      <Button
                        className="cancel-btn panel-review-btn"
                        fullWidth
                        variant='outlined'
                        disabled
                        style={{opacity: 0.5}}
                      >
                        <img src={PdfIcon} alt="pdf" height={27} width={36} />
                        SKILL QUESTION POOLS
                      </Button>
                    </Grid>
                  }
                </Grid>
              </div> : 
              <div style={{textAlign: "center", fontWeight: "bold"}}>Interview Details Not Found</div>}
            </div>
          </Drawer>
        </div>}
        { //@ts-ignore
          <SubmitReviewDialog show={this.state.openDialog == "submitConfirmation"} DialogClose={this.handleDialog} />}
        <SuccessReviewDialog type={this.state.openDialog} show={isShowSuccessReviewDialog(this.state.openDialog)} DialogClose={() => this.setState({...this.state, openDialog: ""})} />
        
        {this.ReviewComponents(openReviews,interviewSection,openViewReviews,openCancelInterview,joinInterview,candidateId,jobSlotId)}
        
      </>
    )
  }
}


const isShowSuccessReviewDialog = (openDialog: string) => {
  return openDialog == "submitSuccess" || openDialog == "error";
}

function ReviewsDrawer(params: any) {

  return <Drawer anchor={"right"} className="panel-review-drawrer" open={params.open}>
    <PanelReviews tab={params.tab} handleClose={params.handleClose} confirmation={params.confirmation} />
  </Drawer>
}


function ReviewsData(activeInterviewDetails: any) {

  return <div className='info-value'>{activeInterviewDetails?.job_detail?.job_role && activeInterviewDetails?.job_detail?.company ? `${activeInterviewDetails?.job_detail?.job_role}, ${activeInterviewDetails?.job_detail?.company}` : activeInterviewDetails?.job_detail?.job_role || activeInterviewDetails?.job_detail?.company || "-"}</div>
}

function ResumeData(resumeObj: any) {

 const getResumeLink = (URL: any) => {
    if (URL && typeof (URL) === "string") {
      const newUrl = URL.charAt(0) === "/" ? mainConfigJson.baseURL + URL : URL;
      return newUrl;
    }
  }

  return <div >{(resumeObj && resumeObj.name && resumeObj.url) ? <div className='info-value link'> <a href={getResumeLink(resumeObj.url)} target='_blank'>{resumeObj.name}</a></div>: <div className='info-value'>-</div>}</div>
}

function MobileData (activeInterviewDetails:any){
  return <div>
    {`${activeInterviewDetails.candidate.country_code ? '+' + activeInterviewDetails.candidate.country_code : ""} ${activeInterviewDetails.candidate.phone_number}`}
  </div>
}

function SeeReviewsDrawer(params: any) {

  return <Drawer anchor={"right"} className="panel-review-drawrer" open={params.open}>
    <PanelReviews tab={params.tab} handleClose={params.handleClose} data={params.data} />
  </Drawer>
}

function HavingIssue(params: any) {
  const [err_msg, setErrorMsg] = useState("");

  return (<>
    <Dialog open={params.show} fullWidth maxWidth="sm" scroll='body' className="default-timing-dialog-wrapper">
      <DialogTitle className="dialog-title">
        <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
          <div style={{ fontSize: 22 }}>Have an Issue?</div>
          <IconButton size="small" onClick={params.handleClose}><CloseIcon className="close-icon-btn" /></IconButton>
        </div>
      </DialogTitle>
      <Formik
        initialValues={{
          account_id: 0,
          issue_reason: "",
          comment: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          if(values.issue_reason==''&&values.comment==''){
            return  setErrorMsg("Please select any option or enter your reason")
           }


          let payload = {
              account_id: params.candidateId,
              joining_issue : values.issue_reason === "audio_issue" ? 0 : values.issue_reason === "not_joined" ? 1 : values.issue_reason === "network_issue" ? 2 : undefined,
              joining_reason: values.comment,
          }
          params.handleSubmit(payload)
          
        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          setErrors
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent className='opting-out-wrapper'>
              <div className={` border-2px  ${values.issue_reason === "audio_issue" ? 'checkdiv checked' : 'checkdiv'} `}>
                <Radio disableRipple id="issue1" name="issue_reason" checked={values.issue_reason === "audio_issue"} value="audio_issue" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                <label className='icon-target pointer' htmlFor="issue1">Audio Issue</label>
              </div>
              <div className={` border-2px  ${values.issue_reason === "not_joined" ? 'checkdiv checked' : 'checkdiv'} `}>
                <Radio disableRipple id="issue2" name="issue_reason" checked={values.issue_reason === "not_joined"} value="not_joined" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                <label className='icon-target pointer' htmlFor="issue2">Candidate has not joined</label>
              </div>
              <div className={` border-2px  ${values.issue_reason === "network_issue" ? 'checkdiv checked' : 'checkdiv'} `}>
                <Radio disableRipple id="issue3" name="issue_reason" checked={values.issue_reason === "network_issue"} value="network_issue" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                <label className='icon-target pointer' htmlFor="issue3">Network Issue</label>
              </div>
              <div style={{ marginTop: 30 }}>
                <h3>Other Reason</h3>
                <textarea className='reason' id="issue4" style={{ fontSize: "16px" }} name="comment" placeholder='Please Enter Your Reason' onChange={handleChange} />
              </div>
              <p className='err_msg2'style={{marginTop:'9px',marginLeft:'1px',color:'red',marginBottom:'2px'}}>{err_msg}</p>
            </DialogContent>
            <DialogActions className='opting-out-actions'>
              <Button fullWidth style={{ borderRadius: 8, padding: "13px 0" }} variant="contained" type="submit" className="btn-3 hy-btn" >
                SUBMIT
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  </>)
}

function JoinInterview(params: any) {
  const [state, setState] = useState(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)

  return <div className="join-interview-page" >
    <AppBar style={{ background: "#fff", color: "#000" }} position="fixed" elevation={2} >
      <Toolbar>
        <img src={MainLogoBlack} height="26" alt="Hiyamee_Logo" />
        <div style={{ flex: 1 }} />
        <Button
          variant="outlined"
          style={{ color: "#083247", borderColor: "#083247", borderRadius: "8px", marginRight: "12px" }}
          onClick={() => setState(true)}
        >
          Have an Issue?
        </Button>
        <IconButton aria-label="show 17 new notifications" color="inherit">
          <img src={Notification} alt="notification" style={{ height: "25px" }} />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          color="inherit"
        >
          {
            localStorage.getItem("image") ?
              <img
                src=
                {
                  localStorage.getItem("image") && `${mainConfigJson.baseURL}${localStorage.getItem("image")}` || ""
                }
                alt="user"
                style={{ height: "35px", width: "35px", marginRight: "6px", borderRadius: "50%" }}
              />
              :
              <AccountCircle style={{ fontSize: "36px", marginRight: "5px" }} />
          }
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}
          >
            <span style={{ fontWeight: "bold", marginBottom: "3px" }}>
              {localStorage.getItem("fname") + " " + localStorage.getItem("lname")}
            </span>
            <span style={{ color: "grey", textTransform: "capitalize" }}> {localStorage.getItem("show_role")} </span>
          </div>
        </IconButton>
      </Toolbar>
    </AppBar>
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
      className="nav-menu"
    >
      <MenuItem style={{ padding: "8px 30px" }} onClick={() => { setAnchorEl(null) }}  > Profile </MenuItem>:
      <MenuItem style={{ padding: "8px 30px" }} onClick={params.handleLogout}>Logout</MenuItem>
    </Menu>
    {state && < HavingIssue show={state} handleClose={() => setState(false) } handleSubmit={(values:any)=>{params.issueSubmit(values); params.handleClose(); setState(false); }} candidateId={0} jobId={0} />}
    <PanelReviews tab={params.tab} handleClose={()=>{params.reviewPending();params.handleClose()}} confirmation={params.confirmation} sendReview={params.sendReview} reviewPending={params.reviewPending} />
  </div>
}


function PanelReviews(params: any) {
  const [state, setState] = useState<any>(null)

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setState(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setState(null);
  };

  return <Formik
    initialValues={{
      account_id: 0,
      disabled : !!params?.data,
      leadership_skills: params?.data?.attributes?.leadership_skills || 0,
      english_language_knowledge: params?.data?.attributes?.english_language_knowledge || 0,
      communication_skills: params?.data?.attributes?.communication_skills || 0,
      problem_solving: params?.data?.attributes?.problem_solving || 0,
      programming_skills: params?.data?.attributes?.programming_skills || 0,
      overall_rating: params?.data?.attributes?.overall_rating || 0,
      comment: params?.data?.attributes?.comments || "",
      eligible_for_next_round: params?.data?.attributes?.eligibile_for_next_round || false,
      offer_to_be_made: params?.data?.attributes?.recommended_an_offer_to_be_made || false,
    }}
    onSubmit={(values, { setSubmitting }) => {
      let payload = {
        candidate_id: params.candidate_id,
        panel_candidate_slot_id: params.recruiter_id,
        leadership_skills: values.leadership_skills,
        english_language_knowledge: values.english_language_knowledge,
        communication_skills: values.communication_skills,
        problem_solving: values.problem_solving,
        programming_skills: values.programming_skills,
        overall_rating: values.overall_rating,
        comments: values.comment,
        eligibile_for_next_round: values.eligible_for_next_round || values.offer_to_be_made,
        recommended_an_offer_to_be_made: values.offer_to_be_made,

      }
      params.confirmation("submitConfirmation", payload)

    }}

  >
    {({
      values,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      resetForm,
      setFieldValue,
      setErrors
    }) => (
      <form onSubmit={handleSubmit} className='panel-review-form' >
        <div>
          <h2 className="" >
            Panel Reviews
            {params.tab !== "todays" && < CloseIcon
              style=
              {{
                float: 'right'
              }}
              onClick={params.handleClose}
            />}
          </h2>
          <RateSkill name='Leadership Skills' rating={Number(values.leadership_skills)} hChange={ handleChange } disabled={values.disabled} varName={"leadership_skills"} />
          <RateSkill name='English Language Knowledge' rating={Number(values.english_language_knowledge)} hChange={ handleChange } disabled={values.disabled} varName={"english_language_knowledge"} />
          <RateSkill name='Communication Skills' rating={Number(values.communication_skills)} hChange={ handleChange } disabled={values.disabled} varName={"communication_skills"} />
          <RateSkill name='Problem Solving' rating={Number(values.problem_solving)} hChange={ handleChange } disabled={values.disabled} varName={"problem_solving"} />
          <RateSkill name='Programming Skills' rating={Number(values.programming_skills)} hChange={ handleChange } disabled={values.disabled} varName={"programming_skills"} />
          <RateSkill name='Overall Rating' rating={Number(values.overall_rating)} hChange={ handleChange } disabled={values.disabled} varName={"overall_rating"} />
          {/* <label className='icon-target pointer' htmlFor="OptingOut3">Leadership Skills</label> */}

          <div style={{ marginTop: 30 }}>
            <h3>Comments</h3>
            <textarea className='reason' disabled={ values.disabled } style={{ width: "100%", height: "100px", resize: "none", borderRadius: "4px", fontSize: "16px" }} defaultValue={values.comment} name="comment" placeholder='Please Enter Your Comments' onChange={handleChange} />
          </div>
          <h3>
            Recommendations
            <img height="13" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} style={{ position: "relative", top: "2px", left: "10px" }} src={InfoIcon} alt="info-icon" />
          </h3>
          <FormControlLabel
            className='rating-checkbox'
            style={{pointerEvents: values.disabled ? "none": "auto"}}
            control={
              <Checkbox
              icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />}
              name="eligible_for_next_round"
              checked={values.eligible_for_next_round || values.offer_to_be_made}
              onChange={handleChange}
              />
            }
            label="Eligible for next round"
            />
          <FormControlLabel
            className='rating-checkbox' style={{pointerEvents: values.disabled ? "none": "auto"}}
            control={
              <Checkbox
              icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />}
              name="offer_to_be_made"
              checked={values.offer_to_be_made}
              onChange={handleChange}
            />
            }
            label="Recommended an offer to be made"
          />
          <Popover
            className="panel-popover"
            style={{ pointerEvents: "none" }}
            open={!!state}
            anchorEl={state}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div style={{ margin: "10px" }}>
              You have selected this if candidate is suitable for the job requirement, Based on the rating and recommendations we will proceed further.
            </div>
          </Popover>

          <div className="panel-rating-final-result" >
            <span>Final Result:</span>
            <div >
              <img src={WhiteStar} width="16px" height='16' alt="white-star" />
              <span style={{ color: "white" }}>{(
                5 - (
                  (
                    30 - (
                      Number(values.leadership_skills) +
                      Number(values.english_language_knowledge) +
                      Number(values.communication_skills) +
                      Number(values.problem_solving) +
                      Number(values.programming_skills) +
                      Number(values.overall_rating)
                    )
                  ) / 6
                )
              ).toFixed(1)
              }</span>
            </div>
          </div>
        </div>
        { ! values.disabled 
        ? <div>
          <Button fullWidth style={{ borderRadius: 8, marginTop: "8px" }} variant="contained" type="submit" className="btn-3 hy-btn" >
            SUBMIT NOW
          </Button>
          <Button fullWidth style={{ borderRadius: 8,borderColor:"#23395d", margin: "8px 0", color: "#083247" }} variant="outlined" className="btn-3 " onClick={params.handleClose} >
            I WILL DO IT LATER
          </Button>
        </div>
        :
          null
        }
      </form>
    )}
  </Formik>

}

function RateSkill(props: { name: string, rating: number, varName: string, hChange: any, disabled:boolean }) {
  return (
    <div className="rate-skill">
      <span>{props.name} </span>
      <Rating
        disabled = { props.disabled }
        name={props.varName}
        value={props.rating}
        onChange={(event, newValue) => {
          props.hChange(event)
        }}
        icon={<img src={RatingStar} style={{ marginRight: "6px" }} alt="*" height="20" />}
        emptyIcon={<img src={UnSelectedStar} style={{ marginRight: "6px" }} alt="." height="20" />}
      />
    </div>
  )
}

  // Customizable Area End 