// Customizable Area Start
import React from "react";
import { Grid, Card, CardContent, Button, Modal, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import '../src/panel.web.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CatalogueController, { Props } from "./CatalogueController";
import { ifsc_code, account_holder, Account_Number, PanelScheduledInterviews, notransactions } from "./assets";
import { getFullName } from "../../dashboard/src/Dashboard.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#083247",
      contrastText: "#fff",
    },
  },
});

export default class Payment extends CatalogueController {

  constructor(props: Props) {
    super(props);
  }

  //@ts-ignore
  componentDidMount() {
    this.props.getBankDetail();
    this.props.getTotalInterview();
  }

  componentDidUpdate(prevProps: any) {
    if ((JSON.stringify(prevProps.bank_details) !== JSON.stringify(this.props.bank_details)) ||
      (JSON.stringify(this.props.bank_details) !== JSON.stringify(this.state.bank_details))) {
      this.setState({ bank_details: this.props.bank_details })
    }
    if ((JSON.stringify(prevProps.total_interviews) !== JSON.stringify(this.props.total_interviews)) ||
      (JSON.stringify(this.state.total_reviews) !== JSON.stringify(this.props.total_interviews))) {
      const tabData = [{ 'name': 'Total Interviews', 'count': this.props.total_interviews?.total_interviews, 'Logo': PanelScheduledInterviews }];
      this.setState({ total_reviews: this.props.total_interviews, tab_data: tabData });
    }
  }

  validateAccountDetail = () => {
    const accountNumberRegex = new RegExp(/^\d{9,17}$/);
    const ifscCodeRegex = new RegExp(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/);
    const nameRegex = new RegExp(/^[a-zA-Z]+ [a-zA-Z]+$/);

    let bankDetailsError: any = {};
    if (!this.state.holder_name.trim()) {
      bankDetailsError.holder_name = "Account Holder Name is required*";
    } else if (this.state.holder_name && !nameRegex.test(this.state.holder_name)) {
      bankDetailsError.holder_name = "Enter Valid Account Holder Name i.e. John Doe";
    } else if (!this.state.account_number.trim()) {
      bankDetailsError.account_number = "Account Number is required*";
    } else if (this.state.account_number && !accountNumberRegex.test(this.state.account_number)) {
      bankDetailsError.account_number = "Enter Valid Account Number i.e. 214589652";
    } else if (!this.state.changeAccount && !this.state.conf_account_number.trim()) {
      bankDetailsError.confirm_account_number = "Account Number is required*";
    } else if (!this.state.changeAccount && this.state.conf_account_number && !accountNumberRegex.test(this.state.conf_account_number)) {
      bankDetailsError.confirm_account_number = "Enter Valid Account Number i.e. 214589652";
    } else if (!this.state.changeAccount && this.state.conf_account_number !== this.state.account_number) {
      bankDetailsError.confirm_account_number = "Confirm Account Number is not matching with Account Number*";
    } else if (!this.state.ifsc_code.trim()) {
      bankDetailsError.ifsc_code = "IFSC Code is required*";
    } else if (this.state.ifsc_code && !ifscCodeRegex.test(this.state.ifsc_code)) {
      bankDetailsError.ifsc_code = "Enter Valid IFSC Code i.e. HDBB0123456";
    }
    this.setValidationErrors(bankDetailsError);
  }

  setValidationErrors = (errors: any) => {
    if (!Object.keys(errors).length) {
      this.setState({ ...this.state, error_msg: {} });
      this.saveAccountDetails();
    } else {
      this.setState({ ...this.state, error_msg: errors });
    }
  }

  saveAccountDetails = () => {
    let values = { 'account_holder_name': getFullName(this.state.holder_name), 'account_number': this.state.account_number, 'ifsc_code': this.state.ifsc_code.toUpperCase() }
    if (this.state.changeAccount) {
      this.props.UpdateBankDetail(values, this.state.bank_details[0]['id'])
    } else {
      this.props.AddBankAccount(values);
    }
    this.setInitialState();
  }

  setInitialState = () => {
    this.setState({ ...this.state, changeAccount: false, openFormModal: false, account_number: "", holder_name: "", conf_account_number: "", ifsc_code: "", error_msg: {} })
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        {this.state.bank_details.length > 0 &&
          <Grid container direction="row" spacing={3}>
            {this.state.tab_data?.map((item: any) => {
              return <Grid item xs={12} sm={6} md={4} lg={3} key={item.name}>
                <Card className="hy-cards hy-counters-items-pay">
                  <CardContent style={{ display: "flex", alignItems: "center", padding: 0 }}>
                    <span className='hy-icons purple' style={{ padding: '8px' }}>
                      <img src={item.Logo} />
                    </span>
                    <div className='hy-info' style={{ marginLeft: 8 }}>
                      <h4 className="hy-dashboard-info-label payment_title" style={{ color: '#5d5d5d', margin: 0 }}>{item.name}</h4>
                      <h2 style={{ fontSize: 20, margin: 0 }}>{item.count}</h2>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            })}
          </Grid>
        }
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Card className="row">
              {this.state.bank_details.length > 0 &&
                <div className="payment_details">
                  <div style={{ backgroundColor: '#fff', borderRadius: 8 }}>
                    <div style={{ marginLeft: 18 }}>
                      <br></br>
                      <p style={{ fontWeight: "bold", marginTop: 4 }}>Bank Details</p>
                      {this.state.bank_details.slice(0, 1).map((item: any) =>
                      (<div key={item.id + "bank-account"}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ paddingRight: 16 }}><img src={account_holder} /></div>
                          <div>
                            <p className="bank_title">Account Holder Name</p>
                            <p className="bank_data">{getFullName(item?.attributes?.account_holder_name)}</p>
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ paddingRight: 16 }}><img src={Account_Number} /></div>
                          <div>
                            <p className="bank_title">Account Number</p>
                            <p className="bank_data">{item?.attributes?.account_number?.slice(0, 4)}{" "} {item?.attributes?.account_number?.slice(4, 8)}{" "}{item?.attributes?.account_number?.slice(8, 12)}{" "}{item?.attributes?.account_number?.slice(12, 16)}</p>
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ paddingRight: 16 }}><img src={ifsc_code} /></div>
                          <div>
                            <p className="bank_title">IFSC Code</p>
                            <p className="bank_data">{item?.attributes?.ifsc_code}</p>
                          </div>
                        </div>
                      </div>
                      ))}
                    </div>
                    <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                      <Button variant="outlined" className="btn-2 " style={{ width: '90%', marginTop: '1%', color: '#0e3647', borderColor: '#0e3647', borderRadius: '8px' }} onClick={() => this.setState({ openFormModal: true, changeAccount: true })}>
                        CHANGE ACCOUNT DETAILS
                      </Button>
                    </div>
                  </div>
                </div>}
            </Card>
          </Grid>
        </Grid>
        {!this.state.bank_details.length &&
          <div style={{ textAlign: 'center', marginTop: '5%', marginLeft: '5%', width: '80%' }}>
            <img style={{ width: '30%' }} src={notransactions} />
            <div>
              <p className="transaction">No Transactions</p>
              <p className="history">Your transaction history will appear here.</p>
              <p className="bank_account">Please add your bank account to receive payments.</p>
            </div>
            <Button variant="outlined" className="hy-btn" style={{ width: '150px', fontSize: '12px', borderRadius: '6px' }} onClick={() => this.setState({ openFormModal: true })}>
              ADD BANK DETAILS
            </Button>
          </div>
        }
        <Modal
          open={this.state.openFormModal}
          onClose={this.handleConfirmationModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="jobs-success-model"
        >
          <div className="infromation-block coordinator-panel-block">
            <div className="align-msg">
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                  <div style={{ fontWeight: 700 }}>{this.state.changeAccount ? "Change Account Details" : "Add Bank Details"}</div>
                  <IconButton onClick={this.setInitialState} size="small">
                    <CloseIcon className="close-icon" style={{ fontSize: 24, float: 'right', marginTop: '-4px', color: '#212121' }} />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={12}>
                <label className="form-l">Account Holder Name</label>
                <input
                  type="text"
                  className="panel-input"
                  name="holder_name"
                  value={this.state.holder_name}
                  onChange={(e) => {
                    this.setState({ holder_name: e.target.value })
                  }}
                  placeholder="Enter Account Holder Name"
                />
                {this.state.error_msg.holder_name && <p className="err_msg">{this.state.error_msg.holder_name}</p>}
              </Grid>
              <Grid item xs={12}>
                <label className="form-l">Account Number</label>
                <input
                  type="text"
                  className="panel-input"
                  name="account_number"
                  value={this.state.account_number}
                  onChange={(e) => {
                    this.setState({ account_number: e.target.value })
                  }}
                  placeholder="Enter Account Number"
                />
                {this.state.error_msg.account_number && <p className="err_msg">  {this.state.error_msg.account_number} </p>}
              </Grid>
              {!this.state.changeAccount &&
                <Grid item xs={12}>
                  <label className="form-l">Confirm Account Number</label>
                  <input
                    type="text"
                    className="panel-input"
                    name="conf_account_number"
                    value={this.state.conf_account_number}
                    onChange={(e) => {
                      this.setState({ conf_account_number: e.target.value })
                    }}
                    placeholder="Confirm Account Number"
                  />
                  {this.state.error_msg.confirm_account_number && <p className="err_msg"> {this.state.error_msg.confirm_account_number} </p>}
                </Grid>}
              <Grid item xs={12}>
                <label className="form-l">IFSC Code</label>
                <input
                  type="text"
                  className="panel-input"
                  name="ifsc_code"
                  value={this.state.ifsc_code}
                  onChange={(e) => {
                    this.setState({ ifsc_code: e.target.value })
                  }}
                  placeholder="Enter IFSC Code"
                />
                {this.state.error_msg.ifsc_code && <p className="err_msg"> {this.state.error_msg.ifsc_code}</p>}
              </Grid>
            </div>
            <div style={{ marginTop: 5 }}>
              <Button fullWidth variant="contained" className="btn-2 hy-btn " onClick={this.validateAccountDetail} style={{ marginTop: '16px', height: 47, borderRadius: 6 }} >
                {this.state.changeAccount ? "SAVE" : "ADD ACCOUNT"}
              </Button>
            </div>
          </div>
        </Modal>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}
// Customizable Area End
