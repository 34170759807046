//Customizable Area Start
import React from "react";



import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

//                 //
import {Link} from "react-router-dom"
import { Button , TextField, Grid, Dialog, DialogContent} from "@material-ui/core";
import {ForgotPasswordImg, HiyameeBlackLogo, MainLogo } from "./assets"
import HtmlParser from "html-react-parser";



const PrivacyPolicyDialog = (props:any) => {
  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogContent className="profile-review-dialog">
        <div className="profile-review-header policy-header">Privacy Policy</div>
        <div className="profile-review-summary">{HtmlParser (props.data)}</div>
        <div className="profile-review-action">
          <Button fullWidth className="back-btn" onClick={props.onClose}>CLOSE</Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    
  }

  render() {
    const { privacyPolicyData, openPrivacyPolicy} = this.state;

    return (
        <div>
        {openPrivacyPolicy && privacyPolicyData && <PrivacyPolicyDialog data={privacyPolicyData} open={openPrivacyPolicy} onClose={() => this.setState({...this.state, openPrivacyPolicy: false})} />}
        <div className="login-block">
          <Grid container className="inner-block">
            <Grid item xs={6} className="information-block">
              <div className="imgbox">
                <img src={ForgotPasswordImg} alt="forgetPassword banner" />
              </div>
            </Grid>
            <Grid item xs={6} className="login-item">
              <div className="items-inner">
                <div className="logintitle">
                <img src={MainLogo} style={{height:"38px"}} alt="Hiyamee logo" />
                </div>

                <h2 className="loginsubtitle">
                   Forgot Password?
                </h2>
                <p className="loginsubtitle1">
                  Enter your given email address. we will send the password on your mail 
                </p>
                <Formik
                    initialValues={{ email: "" }}
                    onSubmit={async values => { this.onSubmit({email: values.email.toLowerCase()})}}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Enter Valid Email")
                        .required("Email Required *"),
                    })}
                  >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props;
                    return (
                    <form onSubmit={handleSubmit}>
                
                  <div className="form-group"> 
                    <div className="form-item">
                      <label className="form-label fw-bold">Email</label>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="email"
                        placeholder="Enter Email"
                        type="text"
                        value={values.email}
                        onChange={(e)=>{handleChange(e);this.setState({...this.state, responseType:"",validationMessage:""})}}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email
                            ? "text-input error"
                            : "text-input"
                        }
                        />
                      {errors.email && touched.email && ( <div className="input-feedback">{errors.email}</div> )}
                      {this.state.responseType && this.state.validationMessage && 
                      (<div className={this.state.responseType === "success" ? "success-feedback" : "input-feedback"}>{this.state.validationMessage}</div>)}
                    </div>
                  </div>

                  <div className="form-group">
                    <Button className="btn btn-submit" type="submit">SUBMIT</Button>
                  </div>
                  <div className="form-group forget-group">
                      <label className="form-label">
                        <Link to={"/login"} > <span style={{ fontFamily:"Quicksand !important",fontSize:'14px',fontWeight:'bold', color: "#083247"}} >Back to login</span> </Link>
                      </label>
                  </div> 
                </form>
                );
               }}
              </Formik>
              </div>
              <ul className="navbar" style={{marginTop: 40}}>
                <li className="list-item">
                  <a className="nav-link pointer" onClick={() => this.getPrivacyPolicy()}>Privacy Policy</a>
                </li>
                <li className="list-item">
                  <label className="nav-text">and</label>
                </li>
                <li className="list-item">
                  <a href="https://hiyamee.com/" target="_blank" className="nav-link">Terms of services </a>
                </li>
              </ul>
              </Grid>
          </Grid>
        </div>
    </div>
    );
  }
}

//Customizable Area End
