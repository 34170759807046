import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { BlueClock, RoundRedCancel } from './assets'
import { SuccessIcon, WarningIcon } from "../../documentopener/src/assets";
import { UnavailableIcon } from "../../landingpage/src/assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  searchText?: any;
  unassign_panel?:any;
  getUnassignedPanel?:any;
  getTotalInterview?:any;
  unassign_data?:any;
  getUnassignedData?:any;
  getAssignedPanel?:any;
  slots?:any;
  UpdateTimeSlot?:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  interviewSection: string;
  openInterviewDrawer: any;
  showMore: Boolean;
  openCancelInterview: any;
  candidateId: Number
  openReviews: Boolean;
  openViewReviews: Boolean;
  joinInterview: Boolean;
  openDialog: string;
  interviewList: {
    todays: any,
    upcoming: any,
    reviewPending: any,
    completed: any,
    cancelled: any,
    unassigned: any,
  },
  activeInterviewDetails: any
  reviewDetails: any,
  resumeObj: any,
  jobSlotId: any,
  slots:any,
  err_msg:string,
  allnotifications:any,
  isConfirmed:any,
  isRejected:any,
  isCancelled:any,
  mobileOpen:Boolean,
  anchorEl: any,
  mobileMoreAnchorEl:any,
  screen:string;
  defaultAppBar:Boolean;
  name:string;
  role:string;
  show:Boolean;
  havingIssue: Boolean;
  havingIssuePopup: Boolean,
  listofNotificationTypes: any,
  unassign_paneldata:any,
  UpdateData:boolean,
  clearnotifications:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LinkShareController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  PanelReviewId: string = "";
  getPanelReviewId: string = "";
  updateStatusId: string = "";
  cancelInterviewApiId: string = "";
  todaysInterviewListApiId: string = "";
  upcomingInterviewListApiId: string = "";
  reviewPendingInterviewListApiId: string = "";
  completedInterviewListApiId: string = "";
  cancelledInterviewListApiId: string = "";
  data: any ={}
  getInterviewDetailsApiId: string = "";
  interviewJoiningIssueApiId: string = "";
  notificationsApiId: any;
  clearAllNotificationsApiId:any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    //@ts-ignore
    const locationState = props?.history?.location;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      interviewSection: locationState && locationState.tab ? locationState.tab : "todays",
      openInterviewDrawer: false,
      showMore: false,
      openCancelInterview: false,
      candidateId: 0,
      openReviews: false,
      openViewReviews: false,
      joinInterview: false,
      openDialog: "",
      slots:[],
      interviewList : {
        todays: [],
        upcoming: [],
        reviewPending: [],
        completed: [],
        cancelled: [],
        unassigned: []
      },
      activeInterviewDetails: {},
      reviewDetails: {},
      resumeObj: {
        name: "",
        url: ""
      },
      jobSlotId: "",
      err_msg:"",
      allnotifications:[],
      unassign_paneldata:[],
      isConfirmed:0,
      isCancelled:1,
      isRejected:2,
      mobileOpen:false,
      anchorEl: null,
      mobileMoreAnchorEl:null,
      screen:"",
      defaultAppBar:true,
      name:"",
      role:"",
      show:false,
      UpdateData:false,
      clearnotifications:false,
      havingIssue: false,
      havingIssuePopup: false,
      listofNotificationTypes:[       
        {type:'slot confirmed',key:1,icon:BlueClock},
        {type:'job cancelled',key:2,icon:WarningIcon},
        {type:'selected',key:3,icon:SuccessIcon},
        {type:'unassigned jobs',key:4,icon:UnavailableIcon},
        {type:'unassigned interviews',key:5,icon:UnavailableIcon},
        // {type:'job cancelled',key:6,icon:RoundRedCancel},

        {type:'cancelled',key:7,icon:WarningIcon},
        {type:'confirmed',key:8,icon:BlueClock},
        {type:'rejected',key:9,icon:RoundRedCancel},
        // {type:'selected',key:10,icon:SuccessIcon},
 ]    

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.PanelReviewId || apiRequestCallId === this.notificationsApiId ||
        apiRequestCallId === this.getPanelReviewId || apiRequestCallId === this.updateStatusId ||
        apiRequestCallId === this.cancelInterviewApiId || apiRequestCallId === this.todaysInterviewListApiId ||
        apiRequestCallId === this.upcomingInterviewListApiId || apiRequestCallId === this.reviewPendingInterviewListApiId ||
        apiRequestCallId === this.completedInterviewListApiId || apiRequestCallId === this.cancelledInterviewListApiId || apiRequestCallId === this.clearAllNotificationsApiId ||
        apiRequestCallId === this.getInterviewDetailsApiId || apiRequestCallId === this.interviewJoiningIssueApiId) {
          this.receiveAPIResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  sendReview = async (values: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PanelReviewId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.panelReviewsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReview = async (id: number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPanelReviewId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelReviewsAPiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateStatus = async (values: any, id:any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateStatusId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateStatusAPiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cancelInterview = async (data: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelInterviewApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelInterviewApiEndPoint + `${data.id}?job_slot_id=${data.job_slot_id}&&cancelation_reason=${data.cancelation_reason}&description=${data.description}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  convertDateFormat = (data:any)=>{
    let values = new Date(data);
    return values.toString()
  }

  getAllInterviewList = (userType: any, params: any = "") => {
    console.log('userType',this.state.interviewSection,userType)
    const section = this.state.interviewSection;
    this.getTodaysInterviewList(userType === "panel" ? true : false, section === "todays" ? params: "");
    this.getUpcomingInterviewList(userType === "panel" ? true : false, section === "upcoming" ? params: "");
    this.getReviewPendingInterviewList(userType === "panel" ? true : false, section === "reviews_pending" ? params: "");
    this.getCompletedInterviewList(userType === "panel" ? true : false, section === "completed" ? params: "");
    this.getCancelledInterviewList(userType === "panel" ? true : false, section === "cancelled" ? params: "");
  if(userType!='panel'){
    this.props.getUnassignedPanel(section === 'unassigned' ? params : "");
  }
  
  }

  getTodaysInterviewList = async (isPanelUser: boolean, params: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.todaysInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `today&search=${params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUpcomingInterviewList = async (isPanelUser: boolean, params: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upcomingInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `upcoming&search=${params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReviewPendingInterviewList = async (isPanelUser: boolean, params: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reviewPendingInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `review&search=${params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompletedInterviewList = async (isPanelUser: boolean, params: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completedInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `completed&search=${params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCancelledInterviewList = async (isPanelUser: boolean, params: any) => {

    console.log('params',params)

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelledInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `cancel&search=${params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInterviewDetails = async (id: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInterviewDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInterviewDetailsApiEndPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  haveIssueSubmit = async (value: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.interviewJoiningIssueApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interviewJoiningIssueApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(value)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  

  notificationsSubmit =  () => {
    const headers = {
      "Content-Type": configJSON.notificationsApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  clearAllnotifications =  () => {
    const headers = {
      "Content-Type": 'application/json',
      "token": localStorage.getItem("token"),
      "account_id":localStorage.getItem("userId")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.clearAllNotificationsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_notifications/notifications/clear_notification'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'delete'
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receivePanelReview = (responseJson: any) => {
    if (responseJson?.data?.type == "error") {
      this.props.getTotalInterview();
    }
    else if (responseJson && responseJson.data) {
      this.setState({ openDialog: "submitSuccess", openReviews: false, openInterviewDrawer: false });
      this.getAllInterviewList("panel", this.props.searchText);
      this.props.getTotalInterview();
    }
  }

  receiveclearAllNotifications= (responseJson: any) => {
    if (responseJson) {
      this.setState({ allnotifications:[] });
    }
  }
  

  receiveNotifications = (responseJson: any) => {
    if (responseJson?.data?.type == "error") {
      console.log("error in notifications");
    }
    else if (responseJson && responseJson.data) {
      // @ts-ignore
      const filteredItems = responseJson.data?.sort((a:any, b:any) => new Date(b.attributes.created_at) - new Date(a.attributes.created_at));
      this.setState({ allnotifications: filteredItems })
    }
  }

  receiveGetPanelReview = (responseJson: any) => {
    if (responseJson?.data?.type == "error") {
      console.log("error in panel review");
    }
    else if (responseJson && responseJson.data) {
      this.setState({ openViewReviews: true, reviewDetails: responseJson.data })
    }
  }

  receiveUpdateStatus = (responseJson: any) => {
    if (responseJson && responseJson.interview && responseJson.interview.round_status == "round_begin") {
      this.setState({ joinInterview: true })
    }
    else if (responseJson && responseJson.interview && responseJson.interview.round_status == "round_review_pending") {
      this.setState({ joinInterview: false });
      this.getAllInterviewList("panel", this.props.searchText);
    } else {
      this.setState({ ...this.state, joinInterview: false, openDialog: "error" });
    }
  }

  receiveCancelInterview = (responseJson: any) => {
    if (responseJson && responseJson.interview) {
      this.setState({ ...this.state, openCancelInterview: false });
      this.getAllInterviewList("panel", this.props.searchText);
    }
  }

  receiveTodaysInterviewList = (responseJson: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          todays: responseJson.candidate_details
        }
      })
    }
  }

  receiveUpcomingInterviewList = (responseJson: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          upcoming: responseJson.candidate_details
        }
      })
    }
  }

  receiveReviewPendingInterviewList = (responseJson: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          reviewPending: responseJson.candidate_details
        }
      })
    }
  }

  receiveCompletedInterviewList = (responseJson: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          completed: responseJson.candidate_details
        }
      })
    }
  }

  receiveCancelledInterviewList = (responseJson: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          cancelled: responseJson.candidate_details
        }
      })
    }
  }

  receiveGetInterviewDetails = (responseJson: any) => {
    if (responseJson && responseJson.interview) {
      this.setState({ ...this.state, activeInterviewDetails: responseJson.interview, openInterviewDrawer: true });
      if (responseJson.candidate_resume) {
        const resume = responseJson.candidate_resume.find((item: any) => item.file_type === "resume");
        if (resume&&resume.url) {
          this.setState({ ...this.state, resumeObj: { name: resume.name, url: resume.url } });
        }
      }else{
        this.setState({ ...this.state, resumeObj: { name:'my_resume',url: responseJson.bulk_resumes_url?.[0] } });
      }
    }else{
      this.setState({ ...this.state, resumeObj: { name:'my_resume',url: responseJson.bulk_resumes_url?.[0] } });
    }
  }

  receiveInterviewJoiningIssue = (responseJson: any) => {
    if (!responseJson.error) {
      this.setState({ ...this.state, joinInterview: false });
      this.getAllInterviewList("panel", this.props.searchText);
    }
  }

  receiveAPIResponse = (apiID: any, responseJson: any) => {
    if (apiID === this.PanelReviewId) {
      this.receivePanelReview(responseJson);
    }
    if (apiID === this.clearAllNotificationsApiId) {
      this.receiveclearAllNotifications(responseJson);
    }
    if (apiID === this.notificationsApiId) {
      this.receiveNotifications(responseJson);
    }
    if (apiID === this.getPanelReviewId) {
      this.receiveGetPanelReview(responseJson);
    }
    if (apiID === this.updateStatusId) {
      this.receiveUpdateStatus(responseJson);
    }
    if (apiID === this.cancelInterviewApiId) {
      this.receiveCancelInterview(responseJson);
    }
    if (apiID === this.todaysInterviewListApiId) {
      this.receiveTodaysInterviewList(responseJson);
    }
    if (apiID === this.upcomingInterviewListApiId) {
      this.receiveUpcomingInterviewList(responseJson);
    }
    if (apiID === this.reviewPendingInterviewListApiId) {
      this.receiveReviewPendingInterviewList(responseJson);
    }
    if (apiID === this.completedInterviewListApiId) {
      this.receiveCompletedInterviewList(responseJson);
    }
    if (apiID === this.cancelledInterviewListApiId) {
      this.receiveCancelledInterviewList(responseJson);
    }
    if (apiID === this.getInterviewDetailsApiId) {
      this.receiveGetInterviewDetails(responseJson);
    }
    if (apiID === this.interviewJoiningIssueApiId) {
      this.receiveInterviewJoiningIssue(responseJson);
    }
  }

  // Customizable Area End
}
