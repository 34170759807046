// Customizable Area Start


import React from 'react'
import "./landing_page.css"
import {Paper, Divider, Grid, TextField, IconButton, Button, Modal, Select, Menu, MenuItem, CircularProgress, createTheme} from "@material-ui/core"
import {DefaultProfile, EditIcon, DateIcon, CourseIcon, UploadIcon, Company, BackBtn
        } from "./assets"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MoreIcon from "@material-ui/icons/MoreVert";
import CloseIcon from '@material-ui/icons/Close'; 
import { Formik, FieldArray } from "formik";
import { DatePicker } from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles"
import moment from 'moment';
import { getFullName } from '../../dashboard/src/Dashboard.web';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    } ,
  },
});

export default function AddNewCandidate(props:any) {
  const [img, setImg] = React.useState<any>(DefaultProfile)
  const [show , setShow] = React.useState<boolean>(false)
  const [msg , setMsg] = React.useState<string>("");
  const [error, setError] = React.useState<any>({});
  const [data, setData] =  React.useState<any>(0);
  const [isProfileImageValid, setIsProfileImageValid] = React.useState<boolean>(true);
  const [isResumeValid, setIsResumeValid] = React.useState<boolean>(true);
  const initialValues = {
    first_name:"",last_name:"",email:"",country_code:"+91",phone_number:"",full_phone_number:"",certificates:"",resume_name:'',source:"",
    education: [],
    experience: [],
    birth_place:"",birth_date:"",religion:"",
    image:""
  };
  const currentDate = new Date()
  const scrollToErrorField = (errors:any) => {
    const err = Object.keys(errors);
    if (err.length) {
      const input = document.querySelector(
          `input[name=${err[0]}]`
      );
      const dropdown = document.querySelector(
          `select[name=${err[0]}]`
      );
      const dataBlock = document.querySelector(
        `#${err[0]}`
      ); 
      if(input) {
        input.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
      } else if(dropdown) {
        dropdown.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      }
      else if(dataBlock) {
        dataBlock.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      }
    }
  }

  function validate(val:any,func:any){

    val.country_code = "+91";
    const emailReg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
    const nameRegex = new RegExp(/^[a-zA-Z0-9]+$/);
    let error:any = {}
    if( ! val.first_name){ 
      error={first_name:"First Name is required"}
    } 
    else if (!nameRegex.test(val.first_name)) {
      error = {first_name: "Please enter valid First Name"}
    }
    else if( ! val.last_name){
      error={last_name:"Last Name is required"}
    } 
    else if (!nameRegex.test(val.last_name)) {
      error = {last_name: "Please enter valid Last Name"}
    }
    else if( ! val.email){
      error={email:"Email is required"}
    }
    else if( ! emailReg.test(val.email) ){
      error={email:"Enter valid email"}
    }
    else if( ! val.phone_number ){
      error={full_phone_number:"Phone number is required"}
    }
    else if( ! Number(val.phone_number) ){
      error={full_phone_number:"Only numbers are required"}
    }
    else if( val.phone_number.length !== 10 ){
      error={full_phone_number:"10 numbers are required"}
    }
    else if( ! val.certificates){
      error={certificates:"Resume is required"}
    }
    else if( ! val.source){
      error={source:"Source is required"}
    }
    else if(val.birth_date && (Number(val.birth_date.split("-")[0]) > (new Date().getFullYear()-18)) ){
      error={birth_date:"Enter Valid Date "}
    }
    else{
      error={}
      func()
    }
    setError(error)
    scrollToErrorField(error)
  }

  const showErrorMessage = (errorValue: any) => {
    return <>
      {errorValue && <span className="error-msg">{errorValue}</span>}
    </>
  }

  const showSourceErrorMessage = (errorValue: any) => {
    return <>
      {errorValue && <div className="error-msg" style={{paddingTop: 5}}>{errorValue}</div>}
    </>
  }

  const showLoader = (loading: any) => {
    return <>
      {loading && <div className='loader-wrapper'><CircularProgress className="progress" size={70} /></div>}
    </>
  }

  const getResumePlaceholder = (name: any) => {
    return name ? "" : "Upload Resume";
  }

  const getBirthDatePlaceholder = (birth_date: any) => {
    return birth_date ? "" : "Select Birth Date";
  }

  const showEducationDialog = () => {
    return show && msg === "education";
  }

  const showExperienceDialog = () => {
    return show && msg === "experience";
  }

  const sourceListDropdown = (sources: any) => {
    return <>
      {(sources && sources.length > 0) && sources.map((item: any) => (
        <option key={item.id + "source-item"} value={item.id}>{getFullName(item.name)}</option>
      ))}
    </>
  }

  const showProfileImageValidation = () => {
    return (<>
    {!isProfileImageValid && <div data-test-id="profile-image-validation-message" style={{ whiteSpace: "nowrap" }} className="error-msg">The file size should be less than 2 MB</div>}
    </>)
  }

  const showResumeValidation = () => {
    return (<>
    {!isResumeValid && <div data-test-id="resume-validation-message" style={{ whiteSpace: "nowrap" }} className="error-msg">The file size should be less than 2 MB</div>}
    </>)
  }

  return (
    <Formik
    initialValues={initialValues}
    onSubmit={async (values) => {
      const data = {
        ...values
      }
      props.func(data)
    }}
  >
    {({ 
      values,
      handleSubmit,
      handleChange,
      setFieldValue,
      touched
     }) => (
       <div>
      {showLoader(props.loading)}
      <form onSubmit={handleSubmit}> 
        <div className="add-new-candidate-wrapper">
          <div className="add-new-candidate">
            <div className="add-new-candidate-header">
              <Paper className="add-new-candidate-header-content" >
                <div className="add-new-candidate-head">
                  <img onClick={props.handleClose} className="pointer" src={BackBtn} alt="<-" />
                  {" Add new candidate"}
                </div>
                <Button data-test-id="save-profile" className="hy-btn add-new-candidate-save-profile" 
                  onClick={ async ()=>{ validate(values , ()=>{ handleSubmit() })
                  }} >
                    SAVE PROFILE
                </Button>
              </Paper>
            </div>
            <div className="add-new-candidate-body">
                <Paper className="add-new-candidate-personal-info add-new-candidate-body-paading" >
                  <div className="add-new-candidate-section-heading">
                    Personal Information
                  </div>
                  <Divider />
                  <div className="add-new-candidate-upload-image" id="image">
                    <input data-test-id="candidate-profile-image" type="file" hidden accept="image/*" onChange={(e)=>{
                      if (e.target.files && e.target.files[0].size < 2097152) {
                        const objectUrl = URL.createObjectURL(e.target.files[0])
                        setImg(objectUrl)
                        setFieldValue('image', e.target.files[0])
                        setIsProfileImageValid(true);
                      } else {
                        setImg(DefaultProfile);
                        setFieldValue('image', DefaultProfile);
                        setIsProfileImageValid(false);
                      }
                      }} id='profile' />
                    <label htmlFor='profile' className="pointer">
                      <img src={img} alt="user-image" />
                      <div className="add-new-candidate-edit-img">
                        <img src={EditIcon} alt="edit-icon" />
                      </div>
                    </label>
                  </div>
                  {showProfileImageValidation()}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        First Name
                        <TextField className='text-input' variant='outlined' name="first_name" onChange={handleChange} placeholder="Enter First Name" />
                        {showErrorMessage(error.first_name)}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        Last Name
                        <TextField className='text-input' variant='outlined' name="last_name" onChange={handleChange} placeholder="Enter Last Name" />
                        {showErrorMessage(error.last_name)}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        Email ID
                        <TextField className='text-input' variant='outlined' name="email" onChange={handleChange} placeholder="Enter Email ID" />
                        {showErrorMessage(error.email)}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        Phone Number
                        <div className="add-candidate-phone-number">
                        <TextField className='text-input country-code' style={{flex:"1",minWidth:"50px"}}  inputProps={{style: { textAlign: 'center' } }} variant='outlined' name="country_code" onChange={handleChange} placeholder="Code" value="+91" />
                        <TextField className='text-input phone-no' variant='outlined' name="phone_number" style={{ borderRadius:"8px 0px 0 8px !important"}} onChange={handleChange} placeholder="Enter Phone Number" />
                        </div>
                        {showErrorMessage(error.full_phone_number)}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        <input type="file" data-test-id="candidate-resume" hidden id="upload-resume" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, image/jpeg, image/png"  multiple onChange={(e)=>{
                          if(e.target.files && e.target.files[0] && e.target.files[0].size < 2097152)
                          {
                            setFieldValue('resume_name',e.target.files[0].name);
                            setFieldValue('certificates',e.target.files[0]);
                            setIsResumeValid(true);
                          } else {
                            setFieldValue('resume_name', "");
                            setFieldValue('certificates', "");
                            setIsResumeValid(false);
                          }
                       }} />
                        Resume
                        <div style={{position:"relative",cursor:'pointer'}} >
                        <label htmlFor="upload-resume" >
                        <div   style={{cursor:'pointer'}} className="birth_date">
                          <span className='selected_date' >{getResumePlaceholder(values.resume_name)}</span> 
                          <span  >{values.resume_name}</span> 
                          <img style={{width:"18px",marginRight:"8px",float:'right'}} src={UploadIcon} alt='upload icon' />
                         </div>
                        </label>
                        {showErrorMessage(error.certificates)}
                        {showResumeValidation()}
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} >              
                      <div className='form-group add-new-candidate-form-block'>
                        <label className='hy-forms-lable' >Source</label> 
                        <Select IconComponent={(sourceProps) => (<KeyboardArrowDownIcon {...sourceProps} style={{cursor:'pointer', paddingRight: 10, color: "black"}}  />)} className='textbox' native name='source' onChange={handleChange} defaultValue='' >
                          <option value="" disabled >Select Source</option>
                          {sourceListDropdown(props.sourceList)}
                        </Select>
                        {showSourceErrorMessage(error.source)}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              <FieldArray name="education">
                  {({ remove, push, replace }) => (
              <Paper className="add-new-candidate-personal-info add-new-candidate-body-paading" >
                <div className="add-new-candidate-section-heading">
                  <div id="education" >  
                    Education Information (Optional)
                    {showErrorMessage(error.education)}
                  </div>
                  { 
                    showEducationDialog() &&
                      <EnterDetailsDialog show={show} handleClose={()=>{setShow(false)}} msg={msg} push={push} values={ values.education[data] } replace={replace} index={data} /> 
                  }
                  < AddNewBtn func={()=>{ setData(values.education.length); setShow(true); setMsg("education") }}/>
                </div>
                {values.education.length> 0 && 
                <>
                  <Divider />
                  <Grid container item xs={12} sm={12} spacing={3} >
                  {values.education.map((item:any,ind:any)=>{
                   
                      return <EducationInfoBlock universityName={item.institute_name} course={item.name} minYear={item.start_year} maxYear={item.end_year} indexxx={ind} placeholder remove={remove} edit={ (inde:number)=>{ setData(inde); setShow(true); setMsg("education"); } } />
                  })}
                  </Grid>
                </>
                }
                
                </Paper>
                  )}
                </FieldArray>
                <FieldArray name="experience">
                  {({ remove, push, replace }) => (
              <Paper className="add-new-candidate-personal-info add-new-candidate-body-paading" > 
                { 
                    showExperienceDialog() &&
                      <EnterDetailsDialog show={show} handleClose={()=>{setShow(false)}} msg={msg} push={push} values={values.experience[data]} replace={replace} index={data} /> 
                  }
                <div className="add-new-candidate-section-heading">
                  <div id="experience" >  
                    Experience Information (Optional)
                    {showErrorMessage(error.experience)}
                  </div>
                  <AddNewBtn func={ ()=>{
                    setData(values.experience.length); setShow(true); setMsg("experience"); 
                  }}    
                  />
                </div>
                {values.experience.length > 0 
                &&
                <>
                  <Divider />
                  <Grid container item xs={12} sm={12} spacing={3} >
                  {values.experience.map((item,ind)=>{
                    //@ts-ignore
                      return <EducationInfoBlock universityName={item.name} course={item.company_name} minYear={item.start_year} maxYear={item.end_year} details={item.description} indexxx={ind} remove={remove} edit={ (ind:number)=>{ setData(ind); setShow(true); setMsg("experience"); } } />
                  })}
                  </Grid>
                </>}
                
                </Paper>
                  )}
                </FieldArray>
                <Paper className="add-new-candidate-personal-info add-new-candidate-body-paading" >
                  <div className="add-new-candidate-section-heading">
                    Other Information (Optional)
                  </div>
                  <Divider />
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        Birth Place
                        <TextField className='text-input' variant='outlined' name="birth_place" onChange={handleChange} placeholder="Enter Birth Place" />
                        {showErrorMessage(error.birth_place)}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        Birth Date
                        <label className="date-label" onClick={()=>{ document.getElementById("trils")?.click() }}>
                        <div   style={{cursor:'pointer'}} className="birth_date" id="birth_date">
                        {values.birth_date}  <span className='selected_date' >{getBirthDatePlaceholder(values.birth_date)}</span>   <img src={DateIcon} height={22} style={{opacity:0.5, marginBottom: -4,float:'right'}} alt="calender" />
                        </div>
                        </label>
                        {showErrorMessage(error.birth_date)}
                      </div>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <DatePicker className="hidden-date" disableFuture openTo="year" maxDate={new Date(`${currentDate.getFullYear()-18}-${currentDate.getMonth()+1}-${currentDate.getDate()}`)} format="dd/MM/yyyy" views={["year", "month", "date"]} value={new Date(new Date(`${currentDate.getFullYear()-18}-${currentDate.getMonth()+1}-${currentDate.getDate()}`))} id="trils" onChange={(e)=>{ setFieldValue('birth_date',`${e?.getDate()}-${e && e?.getMonth()+1 || 1}-${e?.getFullYear()}`) }}  />
                      </ ThemeProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                      <div className="add-new-candidate-form-block">
                        Religion
                        <TextField className='text-input' variant='outlined' name="religion" onChange={handleChange} placeholder="Enter Religion" />
                        {showErrorMessage(error.religion)}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
            </div>
          </div>
        </div>
      </form>
      </div>
    )}
    </Formik>
  )
}

interface IEducationInfoBlock{
  universityName: string;
  course: string;
  minYear: string;
  maxYear: string;
  indexxx: number;
  remove: any;
  placeholder ?: boolean;
  details?: string;
  edit:any
}

export function EducationInfoBlock({universityName,course,minYear,maxYear,indexxx,placeholder,remove,details,edit}:IEducationInfoBlock) {
  const [anchorEducationEl, setAnchorEducationEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEducationEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEducationEl(null);
  };

  return( 
    <Grid item xs={12} sm={6} key={indexxx}>
      <div style={{border: '1px solid #ddd',borderRadius:"6px",padding:"10px"}}>
        <div className="add-candidate-info-contents" >
            <span style={{ fontSize: "15px", fontWeight: 900}} >
              {universityName}
            </span> 
            <IconButton data-test-id="menu-icon" onClick={(e: any) => handleMenu(e)} > <MoreIcon /> </IconButton> 
         
        </div>
        <div className="add-candidate-info"> 
          <img src={placeholder ? CourseIcon : Company} style={{width:"15px", marginRight:"8px"}} alt="Cap logo" />
          {course}
        </div>
        <div className="add-candidate-info"> 
          <img src={DateIcon} style={{width:"15px", marginRight:"8px"}} alt="Calender logo" />
            {moment(minYear).year()}-{moment(maxYear).year()}
        </div>
        {placeholder ? null :
          <div className="add-candidate-info" style={{paddingRight:"30px"}}> 
            {details}
          </div> 
        }
        <Menu
          id="edit-education-more-options"
          anchorEl={anchorEducationEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEducationEl}
          onClose={handleClose}
        >
          <MenuItem data-test-id="edit-education" onClick={()=>{edit(indexxx);handleClose()} }>EDIT</MenuItem>
          <MenuItem onClick={()=>{handleClose();remove(indexxx) }}>DELETE</MenuItem>
        </Menu>
      </div>
    </Grid>
  )
}


function AddNewBtn({func, name="ADD NEW"}:{func:any,name?:string}) {
  return(<Button className='add-new-btn'
          variant="outlined"
          onClick={func}
        >
      {name}
    </Button>
  )  
}

const setFirstFieldData = (msg: string, values: any) => {
  let firstFieldData = "";
  if (values) {
      if (msg === "experience" && values.company_name) {
        firstFieldData = values.company_name;
      }
      else if (msg === "education" && values.institute_name) {
        firstFieldData = values.institute_name;
      }
  }
  return firstFieldData;
}

const setSecondFieldData = (values: any) => {
  let secondFieldData = "";
  if (values && values.name) {
    secondFieldData = values.name;
  }
  return secondFieldData;
}

const setThirdFieldData = (values: any) => {
  let thirdFieldData: any = "";
  if (values && values.start_year) {
    thirdFieldData = new Date(values.start_year);
  }
  return thirdFieldData;
}

const setFourthFieldData = (values: any) => {
  let fourthFieldData: any = "";
  if (values && values.end_year) {
    fourthFieldData = new Date(values.end_year);
  }
  return fourthFieldData;
}

const setFifthFieldData = (values: any) => {
  let fifthFieldData = "";
  if (values && values.description) {
    fifthFieldData = values.description;
  }
  return fifthFieldData;
}

const setDialogSaveObject = (msg: string, state: any) => {
  let obj: any = {};
  if (msg === "education") {
    obj = {institute_name: state.first, name: state.second, start_year: state.third.toISOString(), end_year: state.fourth.toISOString()};
  } else if (msg ===  "experience") {
    obj = {company_name: state.first, name: state.second, start_year: state.third.toISOString(), end_year: state.fourth.toISOString(), description:state.fifth}
  }
  return obj;
}

const setDialogTitle = (msg: any, isEditView: any) => {
  let type = "Education";
  if (msg === "experience") {
    type = "Experience";
  }
  return `${isEditView ? "Edit" : "Add"} ${type} Information`;
}

const setFirstFieldName = (msg: any) => {
  return msg === "education" ? "College/University" : "Company Name";
}

const setSecondFieldName = (msg: any) => {
  return msg === "education" ? "Degree" : "Designation";
}

const setFirstFieldValidationText = (stat: boolean, state: any, msg: string) => {
  return <>
    {stat === false && state.first.length === 0 && <span style={{ fontSize: "12px", color: "red" }}> {setFirstFieldName(msg)} is Required * </span>}
  </>
}

const setSecondFieldValidationText = (stat: boolean, state: any, msg: string) => {
  return <>
    {stat === false && state.second.length === 0 && <span style={{ fontSize: "12px", color: "red" }}> {setSecondFieldName(msg)} is Required * </span>}
  </>
}

const setThirdFieldValidationText = (stat: boolean, state: any) => {
  return <>
    {stat === false && state.third.length === 0 && <span style={{ fontSize: "12px", color: "red" }}> This Field is Required * </span>}
    {stat === false && state.third && state.fourth && (state.third >= state.fourth) && <span style={{ fontSize: "12px", color: "red" }}> Year should be less than next field </span>}
  </>
}

const setFourthFieldValidationText = (stat: boolean, state: any, msg: string) => {
  const newDate = new Date();
  return <>
    {stat === false && state.fourth.length === 0 && <span style={{ fontSize: "12px", color: "red" }}> This Field is Required * </span>}
    {stat === false && state.fourth && (state.fourth > newDate) && msg !== "education" && <span style={{ fontSize: "12px", color: "red" }}> Month should be less than current month </span>}
    {stat === false && state.fourth && state.third && (state.third > state.fourth) && <span style={{ fontSize: "12px", color: "red" }}> Year should be greater than previous field </span>}
  </>
}

const setFifthFieldValidationText = (stat: boolean, state: any) => {
  return <>
    {stat === false && state.fifth && state.fifth.length === 0 && <span style={{ fontSize: "12px", color: "red" }}> Description is Required * </span>}
  </>
}

const datePlaceholder = (value: any) => {
  return value ? "" : "Select Date"
}

const setDateField = (date: any) => {
  return date ? `${date.getMonth()+1}-${date.getFullYear()}` : "" 
}

export function EnterDetailsDialog( {show,msg,handleClose,push,values,replace,index}:any ) {
  const isOldEntry = !!values;
  const first = setFirstFieldData(msg, values)
  const second = setSecondFieldData(values);
  const third = setThirdFieldData(values);
  const fourth = setFourthFieldData(values);
  const fifth = setFifthFieldData(values);
  const [state, setState] = React.useState<any>({ first: first, second: second, third: third, fourth: fourth, fifth: fifth });
  const [stat, setStat] = React.useState(true)
  function validate() {
    if (!!state.first && !!state.second && !!state.third && !!state.fourth && state.third < state.fourth) {
      if (!isOldEntry) {
        push(setDialogSaveObject(msg, state));
      }
      else {
        replace(index, setDialogSaveObject(msg, state));
      }
      handleClose()
    }
    setStat(false)
  }
  return<Modal
  open={show}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
  className="add-candidate-model"
  >
    <div className="infromation-block">
    {msg ==="" ? <><h1 > Some error occured </h1> <Button onClick={()=>handleClose()} className="hy-btn">Close</Button> </> :
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} >
            <div className='dialog-header' style={{justifyContent:"space-between",display:"flex"}}>
              <div>{setDialogTitle(msg, isOldEntry)}</div>
              <CloseIcon data-test-id="close-icon" className="pointer" onClick={()=>handleClose()} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} >
            <div className='sub-heading'> {setFirstFieldName(msg)} </div>
            <TextField data-test-id="first_field" className="text-input" variant='outlined' placeholder={`Enter ${setFirstFieldName(msg)}`} defaultValue={state.first} onChange={ (e)=>setState({...state,first:e.target.value}) }  />
            {setFirstFieldValidationText(stat, state, msg)}
          </Grid>
          <Grid item xs={12} sm={12} >
          <div className='sub-heading'> {setSecondFieldName(msg)} </div>
            <TextField data-test-id="second_field" className="text-input" variant='outlined' placeholder={`Enter ${setSecondFieldName(msg)}`} defaultValue={state.second} onChange={ (e)=>setState({...state,second:e.target.value}) }  />
            {setSecondFieldValidationText(stat, state, msg)}
          </Grid>
          <Grid item xs={12} sm={6} >
          <div className='sub-heading'>From</div>
          <label className="date-label" onClick={()=>{ document.getElementById("from-date")?.click() }}>
          <div   style={{cursor:'pointer'}} className="select_date">
          {setDateField(state.third)}  <span className='selected_date' >{datePlaceholder(state.third)}</span>   <img src={DateIcon} height={22} style={{opacity:0.5, marginBottom: -4,float:'right'}} alt="calender" />
          </div>
          </label>
          <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker disableFuture openTo="year" className="hidden-date" label="Date of birth" views={[ "year" , "month"]} value={new Date()} id="from-date" onChange={(e)=>{ setState({...state,third:e }) }}  />
          </ ThemeProvider>
          {setThirdFieldValidationText(stat, state)}
          </Grid>
          <Grid item xs={12} sm={6} >
          <div className='sub-heading'>To</div>
          <label className="date-label" onClick={()=>{ document.getElementById("to-date")?.click() }}>
         
           <div   style={{cursor:'pointer'}} className="select_date">
          {setDateField(state.fourth)}  <span className='selected_date' >{datePlaceholder(state.fourth)}</span>   <img src={DateIcon} height={22} style={{opacity:0.5, marginBottom: -4,float:'right'}} alt="calender" />
          </div>
          </label>
          <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker disableFuture={msg==="experience"} openTo="year" className="hidden-date" label="Date of birth"  views={["year", "month"]} value={new Date()} id="to-date" onChange={(e)=>{ setState({...state,fourth:e }) }}  />
          </ ThemeProvider>
            {setFourthFieldValidationText(stat, state, msg)}          
          </Grid>
          { msg==="experience" && 
            <Grid item xs={12} sm={12} >
            <div className='sub-heading'>Description</div>
              <TextField data-test-id="fifth_field" className="text-input" variant='outlined' minRows="4" type='year' placeholder={ "Enter Description" } defaultValue={state.fifth} onChange={ (e)=>setState({...state,fifth:e.target.value}) } multiline  />
            {setFifthFieldValidationText(stat, state)}
            </Grid>
          }
          <Grid item xs={12} sm={6} style={{marginTop:"8px"}} className="dialog-action-body" >
          <Button fullWidth 
            variant="outlined"
            onClick={handleClose}
            className="cancel-btn"
          >
            CANCEL
          </Button>
          </Grid>
          <Grid item xs={12} sm={6} style={{marginTop:"8px"}} className="dialog-action-body" >
            <Button data-test-id="save-details" className='save-btn' fullWidth onClick={ validate } >
              SAVE
          </Button>
          </Grid>
             
        </Grid>
      }
       </div>
</Modal>
}



// Customizable Area End
