// Customizable Area Start
import React from "react";
import "./style.css";
import {
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { createStyles, withStyles, makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import EmailAccountLoginController, {
  Props,
  configJSON,
} from "./EmailAccountLoginController";
import {
  CheckIcon,
  MainLogoWhite,
  imgPasswordInVisible,
  OtherDetailBannerImage,
  PersonalDetailBannerImage,
  ProfessionalDetailBannerImage,
  ProfileReviewIcon,
  UploadIcon,
} from "./assets";
import { StepIconProps } from "@material-ui/core/StepIcon";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TimezoneSelect from "react-timezone-select";

const styles = (theme: any) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: "121ch",
    },
  });

const regexForEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
const regexForPhoneNumber = new RegExp(/^\d{10}$/);
const regexForDecimal = new RegExp(/^\d+(\.\d{1,2})?$/);

let steps = ["Personal Details", "Professional Details", "Other Details"];

const useStepIconStyles = makeStyles({
  root: {
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "transparent",
    borderColor: "rgba(255, 211, 39, 1)",
  },
  circle: {
    width: 56,
    height: 56,
    borderRadius: "50%",
    border: "1px solid rgba(85, 99, 60, 1)",
  },
  activeCircle: {
    width: 56,
    height: 56,
    borderRadius: "50%",
    border: "1px solid rgba(255, 211, 39, 1)",
  },
  completed: {
    color: "#000",
    zIndex: 1,
    backgroundColor: "rgba(255, 211, 39, 1)",
    borderColor: "rgba(255, 211, 39, 1)",
  },
  icon: {
    padding: "18px 12px",
  },
  textActive: {
    color: "rgba(255, 211, 39, 1) !important",
  },
  text: {
    fontSize: "28px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "13px",
    color: "rgba(85, 99, 60, 1)",
    fontFamily: "'Gilroy-Regular',sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  outerCircle: {
    borderRadius: "50%",
    border: "10px solid rgba(85, 99, 60, 1)",
  },
});

export function StepIconComponent(props: StepIconProps) {
  const classes = useStepIconStyles();
  const { active, completed } = props;
  const count: { [index: string]: string } = {
    1: "01",
    2: "02",
    3: "03",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed && (
        <div className={`${classes.circle} ${classes.completed}`}>
          <img
            src={CheckIcon}
            width={31}
            height={23}
            className={classes.icon}
          />
        </div>
      )}
      {active && (
        <div className={classes.outerCircle}>
          <div className={classes.activeCircle}>
            <span className={`${classes.text} ${classes.textActive}`}>
              {count[String(props.icon)]}
            </span>
          </div>
        </div>
      )}
      {!active && !completed && (
        <div className={classes.circle}>
          <span className={classes.text}>{count[String(props.icon)]}</span>
        </div>
      )}
    </div>
  );
}

export const ProfileReviewDialog = (props: any) => {
  return (
    <Dialog open={props.open} maxWidth="xs">
      <DialogContent className="profile-review-dialog">
        <div className="profile-review-icon">
          <img src={ProfileReviewIcon} alt="" height={74} />
        </div>
        <div className="profile-review-header">
          Your profile is under review
        </div>
        <div className="profile-review-summary">
          Your account has been submitted successfully & will be reviewed by our
          team. You will be notified for further process.
        </div>
        <div className="profile-review-action">
          <Link className="back-btn" to={"/login"} onClick={props.onClose}>
            BACK TO LOGIN
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const getFormattedName = (nameText: any) => {
  let formattedName = "";
  if (nameText) {
    formattedName = nameText.charAt(0).toUpperCase() + nameText.slice(1);
  }
  return formattedName;
};

const getStepDetailText = (step: number) => {
  let headerText = "";
  if (step === 1) {
    headerText = "Personal Details";
  } else if (step === 2) {
    headerText = "Professional Details";
  } else if (step === 3) {
    headerText = "Other Details";
  }
  return headerText;
};

const setBannerImage = (stepCount: number) => {
  let imageSource: any = "";
  if (stepCount === 1) {
    imageSource = PersonalDetailBannerImage;
  } else if (stepCount === 2) {
    imageSource = ProfessionalDetailBannerImage;
  } else {
    imageSource = OtherDetailBannerImage;
  }
  return <img src={imageSource} alt="login banner" />;
};

export const getDropdownList = (data: any) => {
  let list = [];
  if (data.skill && data.skill.length > 0) {
    list = data.skill
      .filter((i: any) => i.name && i.name !== "")
      .map((item: any) => item);
  }
  return list;
};

const newUserEmail = () => {
  return localStorage.getItem("new-user-email") || "";
};

export const setSkill = (list: any, index: number) => {
  let skillText = "";
  if (list[index]) {
    if (list[index].id) {
      skillText = list[index].id.toString();
    } else {
      skillText = list[index];
    }
  }
  return skillText;
};

export const setTimezoneText = (values: any, index: number) => {
  return index === 0 ? `${values.timezoneLabel}[${values.timezone}]` : "";
};

export const setExperienceText = (value: any, index: number) => {
  return index === 0 ? value : "";
};

export const setSkillText = (value: any) => {
  return value && value.id ? value.id.toString() : value;
};

export const setErrorField = (errorOb: any) => {
  return <>{errorOb && <span className="error-msg">{errorOb}</span>}</>;
};

export const getSkillOptionLabel = (option: any) => {
  return option.id ? option.name || option.id.toString() : option;
};

export const skillPlaceholder = (value: boolean, isPrimary: boolean) => {
  let placeHolder = "";
  if (!value) {
    placeHolder = isPrimary ? "Enter Primary Skills" : "Enter Secondary Skills";
  }
  return placeHolder;
};

export const scrollToErrorField = (errors: any) => {
  const errField = Object.keys(errors);
  if (errField.length) {
    const input = document.querySelector(`input[name=${errField[0]}]`);
    const dropdown = document.querySelector(`select[name=${errField[0]}]`);
    if (input) {
      input.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (dropdown) {
      dropdown.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
};

const showDocFileValidation = (showMessage: boolean) => {
  return (
    <>
      {!showMessage && (
        <div
          data-test-id="signup-file-message"
          style={{ whiteSpace: "nowrap" }}
          className="error-msg"
        >
          The file size should be less than 2 MB
        </div>
      )}
    </>
  );
};

const getDocumentPlaceHolder = (value: any) => {
  return (
    <>
      <span
        className="selected_date doc-placeholder"
        style={{ display: value ? "none" : "inline" }}
      >
        {value ? "" : "Aadhar card / PAN Card / Passport"}
      </span>
    </>
  );
};
export class SignUpPage extends EmailAccountLoginController {
  isSubmitted: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      open: false,
      msg: "Default Text",
      type: "info",
      isSubmitionCompleted: false,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      isMessagePop: false,
      step: 1,
      isOpenProfileReview: false,
      errors: {},
      panel_document_proof: "",
      isUserExists: false,
      dropdownData: {},
      bannerImage: "",
      openPrivacyPolicy: false,
      openTermsConditions: false,
      privacyPolicyData: "",
      termsConditionsData: "",
      userNotFoundMsg: "",
      userDeletedMsg: "",
      loader: false,
      isDocValid: true,
    };
  }

  onChangeStep = (step: number) => {
    this.setState({ step: step });
  };
  toggleProfileReviewDialog = () => {
    this.setState({ isOpenProfileReview: !this.state.isOpenProfileReview });
  };

  async componentDidMount() {
    this.getDropdownData();
  }

  setOtherDetails = (values: any) => {
    let otherDetail: any = [];

    if (
      values.primary_skill &&
      Array.isArray(values.primary_skill) &&
      values.primary_skill.length >= values.secondary_skill.length
    ) {
      values.primary_skill.filter((item: any, index: number) => {
        let otObj: any = {};
        otObj.timezone = setTimezoneText(values, index);
        otObj.primary_skill = setSkillText(item);
        otObj.primary_experience = setExperienceText(
          values.primary_experience,
          index
        );
        otObj.secondary_experience = setExperienceText(
          values.secondary_experience,
          index
        );
        if (values.secondary_skill.length > index) {
          otObj.secondary_skill = setSkill(values.secondary_skill, index);
        }
        otherDetail.push(otObj);
      });
    } else if (
      values.primary_skill &&
      Array.isArray(values.primary_skill) &&
      values.primary_skill.length < values.secondary_skill.length
    ) {
      values.secondary_skill.filter((item: any, index: number) => {
        let obj: any = {};
        obj.timezone = setTimezoneText(values, index);
        obj.secondary_skill = setSkillText(item);
        obj.primary_experience = setExperienceText(
          values.primary_experience,
          index
        );
        obj.secondary_experience = setExperienceText(
          values.secondary_experience,
          index
        );
        if (values.primary_skill.length > index) {
          obj.primary_skill = setSkill(values.primary_skill, index);
        }
        otherDetail.push(obj);
      });
    }
    return otherDetail;
  };

  savePanelUserDetail = (values: any) => {
    console.log("item",this.savePanelUserDetail)
    const formData = new FormData();

    const professionalDetail = [
      {
        company_name: values.company_name,
        experience: values.experience,
        designation: values.designation,
      },
    ];

    const otherDetail = this.setOtherDetails(values);

    formData.append("[panel[first_name]]", getFormattedName(values.first_name));
    formData.append("[panel[last_name]]", getFormattedName(values.last_name));
    if (typeof values.email === "string") {
      formData.append("[panel[email]]", values.email.toLowerCase());
    }
    formData.append(
      "[panel[full_phone_number]]",
      `+91 ${values.full_phone_number}`
    );
    formData.append(
      "[panel[panel_document_proof]]",
      this.state.panel_document_proof
    );
    formData.append(
      "[panel[professional_details_attributes]]",
      JSON.stringify(professionalDetail)
    );
    formData.append(
      "[panel[other_details_attributes]]",
      JSON.stringify(otherDetail)
    );

    this.savePanelUser(formData);
  };

  validateStep1 = (values: any, errors: any = {}) => {
    if (!values.first_name) {
      errors.first_name = "First Name is Required";
    } else if (!values.last_name) {
      errors.last_name = "Last Name is Required";
    } else if (!values.email) {
      errors.email = "Email is Required";
    } else if (!regexForEmail.test(values.email)) {
      errors.email = "Enter Valid Email";
    } else if (!values.full_phone_number) {
      errors.full_phone_number = "Phone Number is Required";
    } else if (!regexForPhoneNumber.test(values.full_phone_number)) {
      errors.full_phone_number = "Phone Number should be 10 digit number";
    } else if (!this.state.panel_document_proof) {
      errors.panel_document_proof = "ID Proof is Required";
    }
    if (!Object.keys(errors).length) {
      this.validateUserEmail({ email: values.email });
    } else {
      this.setState({ ...this.state, errors: { ...errors } });
      scrollToErrorField(errors);
    }
  };

  validateStep2 = (values: any, errors: any = {}) => {
    console.log("item",this.validateStep2)
    if (!values.company_name) {
      errors.company_name = "Current Company Name is Required";
    } else if (!values.experience) {
      errors.experience = "Experience is Required";
    } else if (!regexForDecimal.test(values.experience)) {
      errors.experience =
        "Experience Format should be in decimal i.e. 0.6, 1.8, 12";
    } else if (Number(values.experience) === 0) {
      errors.experience = "Experience should not be 0.";
    } else if (!values.designation) {
      errors.designation = "Designation is Required";
    }
    if (!Object.keys(errors).length) {
      this.setState({ step: 3, errors: {} });
    } else {
      this.setState({ ...this.state, errors: { ...errors } });
      scrollToErrorField(errors);
    }
  };

  validateStep3 = (values: any, errors: any = {}) => {
    if (!values.timezone) {
      errors.timezone = "Timezone is Required";
    } else if (values && values.primary_skill && !values.primary_skill.length) {
      errors.primary_skill = "Primary Skills are Required";
    } else if (!values.primary_experience) {
      errors.primary_experience = "Primary Experience is Required";
    } else if (!regexForDecimal.test(values.primary_experience)) {
      errors.primary_experience =
        "Experience Format should be in decimal i.e. 0.6, 1.8, 12";
    } else if (Number(values.primary_experience) === 0) {
      errors.primary_experience = "Primary Experience should not be 0.";
    } else if (
      values.secondary_skill.length > 0 &&
      !values.secondary_experience
    ) {
      errors.secondary_experience = "Secondary Experience is Required";
    } else if (
      values.secondary_experience &&
      Number(values.secondary_experience) === 0
    ) {
      errors.secondary_experience = "Secondary Experience should not be 0.";
    } else if (
      values.secondary_experience &&
      !regexForDecimal.test(values.secondary_experience)
    ) {
      errors.secondary_experience =
        "Experience Format should be in decimal i.e. 0.6, 1.8, 12";
    } else if (
      Number(values.experience) <
      Number(values.primary_experience) + Number(values.secondary_experience)
    ) {
      errors.secondary_experience =
        "Total of Primary Experience and Secondary Experience should be less than or equal to Professional Experience added";
    }

    if (!Object.keys(errors).length) {
      this.isSubmitted = true;
      this.setState({ ...this.state, errors: {}, loader: true });
      this.savePanelUserDetail(values);
    } else {
      this.setState({ ...this.state, errors: { ...errors } });
      scrollToErrorField(errors);
    }
  };

  render() {
    const initialFormValues = {
      first_name: "",
      last_name: "",
      email: newUserEmail(),
      full_phone_number: "",
      panel_document_proof: "",
      company_name: "",
      experience: "",
      designation: "",
      timezone: "",
      primary_skill: [],
      primary_experience: "",
      secondary_skill: [],
      secondary_experience: "",
      country_code: "+91",
      timezoneLabel: "",
    };

    const { errors, step } = this.state;

    const handleFileSelectionChangeHandler = (event: any) => {
      const file = event.target.files[0];
      const fileType = file.type;
      if (
        file &&
        (fileType === "application/pdf" ||
          fileType === "image/png" ||
          fileType === "image/jpg" ||
          fileType === "image/jpeg") &&
        file.size < 2097152
      ) {
        this.setState({
          ...this.state,
          panel_document_proof: file,
          isDocValid: true,
        });
      } else {
        this.setState({
          ...this.state,
          panel_document_proof: "",
          isDocValid: false,
        });
      }
    };

    return (
      <div>
        <ProfileReviewDialog
          open={this.state.isOpenProfileReview}
          onClose={this.toggleProfileReviewDialog}
        />
        {this.state.loader && (
          <div className="loader-wrapper">
            <CircularProgress className="progress" size={70} />
          </div>
        )}
        <div className="login-block">
          <Grid container className="inner-block">
            <Grid item xs={6} className="information-block signup-block">
              <div className="hy-logo">
                <img src={MainLogoWhite} alt="login banner" height={24} />
              </div>
              <div className="banner-imgbox">{setBannerImage(step)}</div>
              <div className="signup-stepper">
                <div className="step-label">{`STEP 0${step}`}</div>
                <div className="step-label-text">{getStepDetailText(step)}</div>
                <div className="stepper-container">
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={step - 1}
                    classes={{ root: "stepper-root" }}
                  >
                    {steps.map((item, index) => (
                      <Step
                        completed={step - 1 > index}
                        active={step - 1 === index}
                        key={item}
                        classes={{
                          root: "step-root",
                          completed: "step-completed",
                        }}
                      >
                        <StepLabel
                          StepIconComponent={StepIconComponent}
                          classes={{
                            active: "step-label-active",
                            completed: "step-label-completed",
                            root: "step-label-root",
                          }}
                        >
                          {item}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
              <div className="sign-in-link-container">
                Already Have an Account?
                <Link to="/login">
                  <span> SIGN IN</span>{" "}
                </Link>
              </div>
            </Grid>
            <Grid item xs={6} className="login-item">
              <div className="items-inner">
                <div className="panel-signup-title">
                  {getStepDetailText(step)}
                </div>
                <Formik
                  data-test-id="form"
                  initialValues={initialFormValues}
                  onSubmit={(values) => {
                    console.log(values);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        {step === 1 && (
                          <>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">
                                  First Name
                                </label>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Enter First Name"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="text-input"
                                  name="first_name"
                                  value={values.first_name}
                                />
                                {/* @ts-ignore */}
                                {setErrorField(errors.first_name)}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">Last Name</label>
                                <div className="input-group">
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter Last Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="text-input"
                                    name="last_name"
                                    value={values.last_name}
                                  />
                                  {/* @ts-ignore */}
                                  {setErrorField(errors.last_name)}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">Email</label>
                                <div className="input-group">
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="text-input"
                                    name="email"
                                    value={values.email}
                                  />
                                  {/* @ts-ignore */}
                                  {setErrorField(errors.email)}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">
                                  Phone Number
                                </label>
                                <div
                                  className="input-group"
                                  style={{ display: "flex" }}
                                >
                                  <TextField
                                    className="text-input country-code-input"
                                    style={{ flex: "1", minWidth: "60px" }}
                                    inputProps={{
                                      style: { textAlign: "center" },
                                    }}
                                    variant="outlined"
                                    name="country_code"
                                    onChange={handleChange}
                                    placeholder="Code"
                                    value="+91"
                                  />
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter Phone Number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="text-input phone-input"
                                    name="full_phone_number"
                                    value={values.full_phone_number}
                                  />
                                </div>
                                {/* @ts-ignore */}
                                {setErrorField(errors.full_phone_number)}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">ID Proof</label>
                                <div className="input-group">
                                  <div className="add-new-vendor-form-block add-panel-block text-input file-input">
                                    <input
                                      type="file"
                                      data-test-id="panel-signup-document"
                                      hidden
                                      id="signup-doc-file"
                                      accept=".pdf, application/pdf, image/jpeg, image/png, .jpg"
                                      onChange={(e) =>
                                        handleFileSelectionChangeHandler(e)
                                      }
                                    />
                                    <div
                                      style={{
                                        position: "relative",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <label htmlFor="signup-doc-file">
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="birth_date doc-container"
                                        >
                                          {getDocumentPlaceHolder(
                                            this.state.panel_document_proof.name
                                          )}
                                          <div className="doc-name">
                                            {
                                              this.state.panel_document_proof
                                                .name
                                            }
                                          </div>
                                          <Button
                                            component="span"
                                            className="input-group-addon-upload panel-upload-btn pointer"
                                          >
                                            <img
                                              width={20}
                                              src={UploadIcon}
                                              alt="Upload"
                                              className="upload-icon"
                                              style={{ paddingRight: 5 }}
                                            />
                                            UPLOAD
                                          </Button>
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  {/* @ts-ignore */}
                                  {setErrorField(errors.panel_document_proof)}
                                  {showDocFileValidation(this.state.isDocValid)}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <Button
                                data-test-id="next"
                                className="btn btn-next"
                                onClick={() => this.validateStep1(values)}
                              >
                                Next
                              </Button>
                            </div>
                          </>
                        )}
                        {step === 2 && (
                          <>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">
                                  Current Company Name
                                </label>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Enter Current Company Name"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="text-input"
                                  name="company_name"
                                  value={values.company_name}
                                />
                                {/* @ts-ignore */}
                                {setErrorField(errors.company_name)}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">
                                  Experience
                                </label>
                                <div className="input-group">
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter Experience"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="text-input"
                                    name="experience"
                                    value={values.experience}
                                  />
                                  {/* @ts-ignore */}
                                  {setErrorField(errors.experience)}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">
                                  Designation
                                </label>
                                <div className="input-group">
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter Designation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="text-input"
                                    name="designation"
                                    value={values.designation}
                                  />
                                  {/* @ts-ignore */}
                                  {setErrorField(errors.designation)}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <Button
                                className="btn btn-next"
                                onClick={() => this.validateStep2(values)}
                              >
                                Next
                              </Button>
                            </div>
                            <div className="form-group">
                              <Button
                                className="btn btn-back"
                                onClick={() => this.onChangeStep(1)}
                              >
                                Back
                              </Button>
                            </div>
                          </>
                        )}
                        {step === 3 && (
                          <>
                            <div className="form-group">
                              <label className="form-label ">
                                Select Timezone
                              </label>
                              <TimezoneSelect
                                data-test-id="data"
                                classNamePrefix="timezone-select"
                                placeholder="Select Timezone"
                                className="timezone-select-container"
                                value={values.timezone}
                                labelStyle="abbrev"
                                onChange={(tz) => {
                                  setFieldValue("timezone", tz.value);
                                  setFieldValue("timezoneLabel", tz.label);
                                }}
                              />
                              {/* @ts-ignore */}
                              {setErrorField(errors.timezone)}
                            </div>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">
                                  Primary Skills & Experience
                                </label>
                                <Autocomplete
                                  data-test-id="data"
                                  fullWidth
                                  multiple
                                  value={values.primary_skill}
                                  selectOnFocus
                                  handleHomeEndKeys
                                  options={getDropdownList(
                                    this.state.dropdownData
                                  )}
                                  onChange={(e, value: any) => {
                                    setFieldValue(
                                      "primary_skill",
                                      value && value.id ? value.id : value
                                    );
                                  }}
                                  onBlur={(e: any) => {
                                    if (e.target.value) {
                                      setFieldValue("primary_skill", [
                                        ...values.primary_skill,
                                        e.target.value,
                                      ]);
                                    }
                                  }}
                                  getOptionLabel={(option: any) =>
                                    getSkillOptionLabel(option)
                                  }
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField
                                      name="primary_skill"
                                      placeholder={skillPlaceholder(
                                        values.primary_skill.length > 0,
                                        true
                                      )}
                                      {...params}
                                      variant="outlined"
                                      className="text-input"
                                    />
                                  )}
                                />
                                {/* @ts-ignore */}
                                {setErrorField(errors.primary_skill)}
                              </div>
                              <div className="second-input">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Enter Experience"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="text-input"
                                  name="primary_experience"
                                  value={values.primary_experience}
                                />
                                {/* @ts-ignore */}
                                {setErrorField(errors.primary_experience)}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="form-item">
                                <label className="form-label ">
                                  Secondary Skills & Experience
                                </label>
                                <Autocomplete
                                  fullWidth
                                  multiple
                                  value={values.secondary_skill}
                                  selectOnFocus
                                  handleHomeEndKeys
                                  options={getDropdownList(
                                    this.state.dropdownData
                                  )}
                                  onChange={(e, value: any) => {
                                    setFieldValue(
                                      "secondary_skill",
                                      value && value.id ? value.id : value
                                    );
                                  }}
                                  onBlur={(e: any) => {
                                    if (e.target.value) {
                                      setFieldValue("secondary_skill", [
                                        ...values.secondary_skill,
                                        e.target.value,
                                      ]);
                                    }
                                  }}
                                  getOptionLabel={(option: any) =>
                                    getSkillOptionLabel(option)
                                  }
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField
                                      name="secondary_skill"
                                      placeholder={skillPlaceholder(
                                        values.secondary_skill.length > 0,
                                        false
                                      )}
                                      {...params}
                                      variant="outlined"
                                      className="text-input"
                                    />
                                  )}
                                />
                              </div>
                              <div className="second-input">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Enter Experience"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="text-input"
                                  name="secondary_experience"
                                  value={values.secondary_experience}
                                />
                                {/* @ts-ignore */}
                                {setErrorField(errors.secondary_experience)}
                              </div>
                            </div>
                            <div className="form-group">
                              <Button
                                disabled={this.isSubmitted}
                                className="btn btn-next"
                                type="submit"
                                onClick={() => this.validateStep3(values)}
                              >
                                Submit Profile
                              </Button>
                            </div>
                            <div className="form-group">
                              <Button
                                data-test-id="back"
                                className="btn btn-back"
                                onClick={() => this.onChangeStep(2)}
                              >
                                Back
                              </Button>
                            </div>
                          </>
                        )}
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SignUpPage);
// Customizable Area End
