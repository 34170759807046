// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Drawer, IconButton, Grid, Button, FormControlLabel, Divider, Switch, Tooltip, createTheme, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CheckBoxRounded, DeleteButtonIcon, DisableCheckedIcon, DisableDeleteIcon, InfoIcon, PencilIcon, UnavailableIcon } from "./assets";
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from "@material-ui/styles";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import "./landing_page.css";

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    },
  },
});

const availabilityArray = [
  { id: 1, day: "Monday", slots: [], isSelected: false },
  { id: 2, day: "Tuesday", slots: [], isSelected: false },
  { id: 3, day: "Wednesday", slots: [], isSelected: false },
  { id: 4, day: "Thursday", slots: [], isSelected: false },
  { id: 5, day: "Friday", slots: [], isSelected: false },
  { id: 6, day: "Saturday", slots: [], isSelected: false },
  { id: 7, day: "Sunday", slots: [], isSelected: false },
];

const dayList = [
  { day: "All_Days", id: 1 },
  { day: "Thursday", id: 2 },
  { day: "Monday", id: 3 },
  { day: "Friday", id: 4 },
  { day: "Tuesday", id: 5 },
  { day: "Saturday", id: 6 },
  { day: "Wednesday", id: 7 },
  { day: "Sunday", id: 8 },
];

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const getFormattedTime = (value: any) => {
  let hours = value.getHours();
  let minutes = value.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? (hours >= 10 ? hours: '0'+hours) : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const dateMinMaxValidation = (from:any, to:any) => {
  let valid = true;
  const fromPeriod = from.substr(6,2);
  const toPeriod = to.substr(6,2);
  const fromHourInNumber = Number(from.substr(0,2));
  const toHourInNumber = Number(to.substr(0,2));
  const fromHour = fromPeriod ==="PM" ? (fromHourInNumber < 12 ? fromHourInNumber+12 : fromHourInNumber) : (fromHourInNumber === 12 ? 0: fromHourInNumber)
  const toHour = fromPeriod ==="PM" ? (toHourInNumber < 12 ? toHourInNumber+12 : toHourInNumber) : (toHourInNumber === 12 ? 0: toHourInNumber)
  const fromMinute = from.substr(3,2);
  const toMinute = to.substr(3,2);
  if (fromPeriod === "PM" && toPeriod === "AM") {
    valid = false;
  } else if (fromPeriod === toPeriod) {
    if (Number(fromHour) > Number(toHour)) {
      valid = false;
    } else if (Number(fromHour) === Number(toHour)) {
      if (Number(fromMinute) >= Number(toMinute)) {
        valid = false;
      }
    }
  }
  return valid;
}

export default function ManageAvailability({ show, defaultSlotTiming, availabilityData, hide,manage_availability, saveDefaultSlot,getDefaultManualSlotTiming, saveAvailability }: any) {
  const [availability, setAvailability] = useState<any[]>(availabilityArray);
  const [checkedAvailability, setCheckedAvailability] = useState<boolean>(false);
  const [checkedDefaultTiming, setCheckedDefaultTiming] = useState<boolean>(false);
  const [showDefaultTimingDialog, setShowDefaultTimingDialog] = useState<boolean>(false);
  const [showAvailabilityConfirmation, setShowAvailabilityConfirmation] = useState<boolean>(false);
  const [defaultSlots, setDefaultSlots] = useState<any[]>([]);
  const [defaultDays, setDefaultDays] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [daysErrorObj, setDaysErrorObj] = useState<any[]>([]);

  useEffect(() => {
    resetError();
  }, []);

  useEffect(()=>{
    if (defaultSlotTiming && defaultSlotTiming[0]) {
      const slots = defaultSlotTiming[0].slot_timings;
      const days = defaultSlotTiming[0].default_day;
      setDefaultDays(days);
      setDefaultSlots(slots);
      
    }
    if (availabilityData) {
      if (availabilityData.slots) {
        setAvailability(availabilityData.slots);
      }
    }
  }, [defaultSlotTiming, availabilityData])

  useEffect(() => {
    if (availabilityData) {
      setCheckedAvailability(availabilityData.working_availability || false);
      setCheckedDefaultTiming(availabilityData.default_timing || false);
    }
  },[availabilityData.working_availability, availabilityData.default_timing])

  const resetError = () => {
    let errorObj: any[] = [];
    availability.filter((item: any) => {
      let obj: any = {};
      const slotArray = item.slots && item.slots.length > 0 ? item.slots.map((el: any) => "") : [];
      obj.dayId = item.id;
      obj.slots = slotArray;
      obj.dayError = "";
      errorObj.push(obj);
    });
    setDaysErrorObj(errorObj);
  }

  const toggleSwitch = (e: any) => {
    if (e.target.name === "availability") {
      e.target.checked ? setCheckedAvailability(true) : setShowAvailabilityConfirmation(true);
    } else {
      setCheckedDefaultTiming((prev) => !prev);
      if (e.target.checked) {
        resetError();
      }
    }
  }

  const toggleDaySelection = (id: any) => {
    const data = [...availability];
    const errorObj = { ...errors };
    const daysError = [...daysErrorObj];
    const index = data.findIndex((item: any) => item.id === id);
    if (index > -1) {
      const obj = data[index];
      obj.isSelected = !obj.isSelected;
      obj.slots = !obj.isSelected ? [] : obj.slots;
      data.splice(index, 1, obj);
      if (errorObj && errorObj.availableDay) {
        const selectedDays = data.filter((item: any) => item.isSelected);
        if (selectedDays.length > 0) {
          delete errorObj.availableDay;
          setErrors(errorObj);
        }
      }
      if (!obj.isSelected && index > -1) {
        const errorOb = daysError[index];
        errorOb.slots = [];
        errorOb.dayError = "";
        setDaysErrorObj(daysError);
      }
    }
    setAvailability(data);
  }

  const addSlot = (id: any) => {
    const slotObj = { id: new Date().toISOString(), start_time: "", end_time: "" };
    const data = [...availability];
    const daysError = [...daysErrorObj];
    const index = data.findIndex((item: any) => item.id === id);
    if (index > -1) {
      const obj = data[index];
      obj.slots.push(slotObj)
      data.splice(index, 1, obj);
      const errorOb = daysError[index];
      errorOb.dayError = "";
      errorOb.slots.push("");
      setDaysErrorObj(daysError);
    }
    setAvailability(data);
  }

  const onDeleteSlot = (dayId: any, slotIndex: number) => {
    const data = [...availability];
    const daysError = [...daysErrorObj];
    const index = data.findIndex((item: any) => item.id === dayId);
    if (index > -1) {
      const obj = data[index];
      obj.slots.splice(slotIndex, 1);
      data.splice(index, 1, obj);
      const errorOb = daysError[index];
      errorOb.slots.splice(slotIndex, 1);
      setDaysErrorObj(daysError);
    }
    setAvailability(data);
  }

  const saveDefaultTiming = (days: any = [], slots: any = []) => {
    const errorObj = { ...errors };
    const slotData = slots && slots.length > 0 ? slots.map((item: any) => ({start_time: item.start_time, end_time: item.end_time}) ):[];
    let saveObj = {
      schedule : {
        slot_timings_attributes: slotData,
        default_day: days,
      }
    };
    saveDefaultSlot(saveObj);
    setShowDefaultTimingDialog(false);
    if (errorObj && errorObj.defaultTiming) {
      delete errorObj.defaultTiming;
      setErrors(errorObj);
    }
  }

  const getDefaultDays = () => {
    const dayItems = [...defaultDays];
    dayItems.sort((a, b) => weekDays.indexOf(a) - weekDays.indexOf(b));
    let dayString = "";
    dayString = dayItems.includes("All_Days") ? "All Days" : dayItems.map((item) => item.substr(0, 3)).join(", ");
    if (!dayItems.includes("All_Days") && dayItems.length === 7) {
      dayString = "All Days";
    }
    return dayString
  }

  const getDefaultSlots = () => {
    const slotList = [...defaultSlots];
    let slotArray = [];
    let slotString = "";
    slotArray = slotList && slotList.length > 0 ? slotList.map((item: any) => `${item.start_time} - ${item.end_time}`) : [];
    slotString = slotArray.map((item: any) => item).join(", ");
    return slotString
  }

  const setFromTime = (value: any, slotIndex: number, dayId: any) => {
    const data = [...availability];
    const index = data.findIndex((item: any) => item.id === dayId);
    if (index > -1) {
      const obj = data[index];
      const slotObj = obj.slots[slotIndex];
      slotObj.start_time = getFormattedTime(value);
      obj.slots.splice(slotIndex, 1, slotObj)
      data.splice(index, 1, obj);
    }
    setAvailability(data);
  }

  const setToTime = (value: any, slotIndex: number, dayId: any) => {
    const data = [...availability];
    const index = data.findIndex((item: any) => item.id === dayId);
    if (index > -1) {
      const obj = data[index];
      const slotObj = obj.slots[slotIndex];
      slotObj.end_time = getFormattedTime(value);
      obj.slots.splice(slotIndex, 1, slotObj)
      data.splice(index, 1, obj);
    }
    setAvailability(data);
  }

  const validate = () => {
    let errorObj: any = {};
    if (checkedDefaultTiming) {
      if (!defaultDays.length || !defaultSlots.length) {
        errorObj.defaultTiming = "Please select default days and slots."
        const fileInput = document.querySelector(
          `button[id=default-timing]`);
        if (fileInput) {
          fileInput.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }
    if (checkedAvailability && !checkedDefaultTiming) {
      const selectedDays = availability.filter((item: any) => item.isSelected);
      if (!selectedDays.length) {
        errorObj.availableDay = "Please select at least 1 day";
        const fileInput = document.querySelector(
          `div[id=available-day]`);
        if (fileInput) {
          fileInput.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
      if (selectedDays.length > 0) {
        const daysError = [...daysErrorObj];
        availability.filter((item: any, index: number) => {
          const dayError = daysError[index];
          if (item.isSelected) {
            if (!item.slots.length) {
              dayError.dayError = "Please add at least 1 slot";
            }
            else {
              dayError.dayError = "";
              const slotList = item.slots;
              slotList.filter((el: any, i: number) => {
                let errorState = "";
                if (!el.start_time || !el.end_time) {
                  errorState = "Time is Required";
                } else if (el.start_time && el.end_time && !dateMinMaxValidation(el.start_time, el.end_time)) {
                  errorState = "To time should be greater then the From time";
                }
                dayError.slots[i] = errorState;
              })
            }
          }
          daysError.splice(index, 1, dayError);
        });
        setDaysErrorObj(daysError);
      }
    }
    let errorIndexes: any[] = [];
    daysErrorObj.filter((el: any, index:number) => {
      if (el.dayError) {
        errorIndexes.push(index);
      }
      el.slots.filter((a: any) => {
        if (a && !el.dayError) {
          errorIndexes.push(index);
        }
      })
    });
    if (errorIndexes.length > 0) {
      const fileInput = document.querySelector(
        `button[id=day-error-${errorIndexes[0]}]`);
      if (fileInput) {
        fileInput.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
    if (!Object.keys(errorObj).length && !errorIndexes.length) {
      setErrors({});
      saveSlotsAvailability();
    } else {
      setErrors(errorObj);
    }
  }
  
  const saveSlotsAvailability = () => {
    let availabilityArray = [...availability];
    availabilityArray = availabilityArray.map((item:any) => ({...item, slots: item.slots.map((a:any)=> ({start_time: a.start_time, end_time: a.end_time}))}));
    const slotObj = availabilityArray.filter((ob:any) => ob.isSelected).map((item:any) => ({slot_timings_attributes: item.slots, availability_day: [item.day]}));
    const saveObj = {
      account:{
        slots_attributes: slotObj,
        working_availability: checkedAvailability,
        default_timing: checkedDefaultTiming,
      }
    }
    saveAvailability(saveObj);
    //getDefaultManualSlotTiming();
    manage_availability();
  }

  return (
    <div>
      <Drawer anchor={"right"} open={show}>
        <div className="availability-container">
          <div className="availability-header">
            <div>Manage Availability</div>
            <IconButton onClick={hide} size="small">
              <CloseIcon className="close-icon" />
            </IconButton>
          </div>
          <div className="availability-content">
            <div className="availability-default-section">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 20 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="default-section-header">Working Availability</span>
                  <span style={{ paddingLeft: 5, position: "relative", top: 3 }}>
                    <Tooltip classes={{ tooltip: "custom-tooltip", arrow: "tooltip-arrow" }} arrow title="You will be deactivated from the system if you turn off the switch, you will be no longer available until you turn it on.">
                      <img src={InfoIcon} alt="info" height={14} width={14} />
                    </Tooltip>
                  </span>
                </div>
                <div style={{ marginRight: -15 }}>
                  <FormControlLabel
                    control={<Switch name="availability"
                      classes={{
                        root: "switch-root",
                        switchBase: "switch-base",
                        thumb: "switch-thumb",
                        track: "switch-track",
                        checked: "switch-checked",
                      }}
                      focusVisibleClassName="switch-focus-visible"
                      disableRipple
                    />}
                    label=""
                    checked={checkedAvailability}
                    onChange={toggleSwitch}
                  />
                </div>
              </div>
              <Divider />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: 20 }}>
                <div>
                  <span className="default-section-header">Default Timing</span>
                  <span style={{ paddingLeft: 5, position: "relative", top: 3 }}>
                    <Tooltip classes={{ tooltip: "custom-tooltip", arrow: "tooltip-arrow" }} arrow title="If you want to set the default time slot for the days. You can turn off the switch anytime if you want to use manual slots.">
                      <img src={InfoIcon} alt="info" height={14} width={14} />
                    </Tooltip>
                  </span>
                </div>
                <div style={{ marginRight: -15 }}>
                  <FormControlLabel
                    control={<Switch name="default-timing"
                      classes={{
                        root: "switch-root",
                        switchBase: "switch-base",
                        thumb: "switch-thumb",
                        track: "switch-track",
                        checked: "switch-checked",
                      }}
                      focusVisibleClassName="switch-focus-visible"
                      disableRipple
                    />}
                    label=""
                    checked={checkedDefaultTiming}
                    onChange={toggleSwitch}
                    disabled={!checkedAvailability}
                  />
                </div>
              </div>
              <Grid container style={{ padding: "16px 0px 4px" }}>
                <Grid item xs={9} className={`default-day ${!checkedAvailability || !checkedDefaultTiming? " disabled": ""}`}>{getDefaultDays() || "-"}</Grid>
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  <IconButton disabled={!checkedAvailability || !checkedDefaultTiming} id='default-timing' size="small" style={{ padding: 6 }} onClick={() => setShowDefaultTimingDialog(true)}><img src={PencilIcon} height={14} width={14} /></IconButton>
                </Grid>
              </Grid>
              <div className={`default-time${!checkedAvailability || !checkedDefaultTiming ? " disabled": ""}`} >{getDefaultSlots() || "-"}</div>
              {errors && errors.defaultTiming ? <span className="error-msg">{errors.defaultTiming}</span> : null}
            </div>
            {availability.map((item: any, ind: number) => (
              <div key={item.day} id={ind === 6 ? "available-day" : ""}>
                <div className={item.isSelected && !checkedDefaultTiming && checkedAvailability ? "day-item active" : "day-item"}>
                  <div className="day-label">{item.day}</div>
                  {item.isSelected ? <img src={checkedDefaultTiming || !checkedAvailability ? DisableCheckedIcon : CheckBoxRounded} height={22} width={23} className={checkedDefaultTiming || !checkedAvailability ? "disable-section" : "pointer"} onClick={() => toggleDaySelection(item.id)} /> : <div className={checkedDefaultTiming || !checkedAvailability ? "circle disable-section" : "circle pointer"} onClick={() => toggleDaySelection(item.id)}></div>}
                </div>
                {item.isSelected && item.slots && item.slots.length > 0 ? item.slots.map((slot: any, index: number) => (
                  <TimeSelectionRow key={index + "slot" + item.id} dayId={item.id} slotId={slot.id} index={index} end_time={slot.end_time} start_time={slot.start_time} onDelete={onDeleteSlot} disable={checkedDefaultTiming || !checkedAvailability} setFromTime={setFromTime} setToTime={setToTime} error={daysErrorObj[ind]?.slots[index] || ""} />
                )) : ""}
                {daysErrorObj && daysErrorObj[ind]?.dayError && <div className="error-msg" style={{paddingBottom:5}}>{daysErrorObj[ind]?.dayError}</div>}
                {item.isSelected ? <Button id={`day-error-${ind}`} disabled={checkedDefaultTiming || !checkedAvailability} className={checkedDefaultTiming || !checkedAvailability ? "add-slot-btn disabled" : "add-slot-btn"} variant="text" onClick={() => addSlot(item.id)}>+ ADD SLOT</Button> : ""}
              </div>
            ))}
            {errors && errors.availableDay ? <span className="error-msg">{errors.availableDay}</span> : null}
          </div>
          <Grid container spacing={2} style={{ padding: "20px 26px" }}>
            <Grid item xs={6} className="availability-action">
              <Button
                className="cancel-btn"
                fullWidth
                variant="outlined"
                onClick={hide}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6} className="availability-action">
              <Button className="save-btn" fullWidth onClick={validate}>
                SAVE
              </Button>
            </Grid>
          </Grid>
        </div>
      </Drawer>
      {showDefaultTimingDialog && <DefaultTimingDialog defaultSlots={defaultSlots} defaultDays={defaultDays} show={showDefaultTimingDialog} hide={() => setShowDefaultTimingDialog(false)} saveDefaultTiming={saveDefaultTiming} />}
      {showAvailabilityConfirmation && <AvailabilityConfirmationDialog show={showAvailabilityConfirmation} onConfirm={() => { setShowAvailabilityConfirmation(false); setCheckedAvailability(false); setCheckedDefaultTiming(false); resetError();}} onCancel={() => setShowAvailabilityConfirmation(false)} />}
    </div>
  );
}

const TimeSelectionRow = ({ dayId = -1, index, start_time, end_time, onDelete, disable = false, setFromTime, setToTime, error, slotId }: any) => {
  const [fromTimeState, setFromTimeState] = useState<any>(start_time);
  const [toTimeState, setToTimeState] = useState<any>(end_time);

  useEffect(() => {
    if (start_time) {
      setFromTimeState(start_time);
    } else {
      setFromTimeState("");
    }
    if (end_time) {
      setToTimeState(end_time);
    } else {
      setToTimeState("");
    }
  }, [start_time, end_time]);

  const handleTimeChange = (value: any, type: string) => {
    const strTime = getFormattedTime(value);
    if (type === "start_time") {
      setFromTimeState(strTime)
      setFromTime(value, index, dayId);
    } else if (type === "end_time") {
      setToTimeState(strTime)
      setToTime(value, index, dayId);
    }
  }

  let Fontcolor = disable === true ? 'lightgrey' : '';

  return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
    <div>
      <Grid container spacing={2} style={{paddingBottom: "6px"}}>
        <Grid item xs={5}>
          <label className="date-label" onClick={() => { document.getElementById(`from-time-${dayId > -1 ? "availability" : "default"}-${slotId}`)?.click() }}>
            <div   style={{cursor:'pointer',color:Fontcolor}} className="select_date availability">
             {fromTimeState} <span className='selected_date' > {fromTimeState? "" : "Select From" }</span>     {disable ? <ExpandMoreRoundedIcon color="disabled" style={{ fontSize: 28, marginRight: -8,float:'right',marginTop:-5 }} /> :
                    <ExpandMoreRoundedIcon style={{ fontSize: 28, marginRight: -8, color: "#083247",float:'right',marginTop:-5 }} />}
          </div>
          </label>
          <ThemeProvider theme={defaultMaterialTheme}>
            <TimePicker style={{display: "none"}} disabled={disable} className="hidden-date" label="Time" value={start_time || new Date()} id={`from-time-${dayId > -1 ? "availability" : "default"}-${slotId}`} onChange={(e: any) => handleTimeChange(e, "start_time")} />
          </ThemeProvider>
          </Grid>
        <Grid item xs={5}>
          <label className="date-label" onClick={() => { document.getElementById(`to-time-${dayId > -1 ? "availability" : "default"}-${slotId}`)?.click() }}>
            <div   style={{cursor:'pointer',color:Fontcolor}} className="select_date availability"  >
             {toTimeState} <span className='selected_date' > {toTimeState? "" : "Select To" }</span>     {disable ? <ExpandMoreRoundedIcon color="disabled" style={{ fontSize: 28, marginRight: -8,float:'right',marginTop:-5 }} /> :
                    <ExpandMoreRoundedIcon style={{ fontSize: 28, marginRight: -8, color: "#083247",float:'right',marginTop:-5 }} />}
          </div>
          </label>
          <ThemeProvider theme={defaultMaterialTheme}>
            <TimePicker style={{display: "none"}} disabled={disable} className="hidden-date" label="Time" value={end_time || new Date()} id={`to-time-${dayId > -1 ? "availability" : "default"}-${slotId}`} onChange={(e: any) => handleTimeChange(e, "end_time")} />
          </ThemeProvider>
        </Grid>
        <Grid item xs={2}>
          <img style={{cursor: "pointer"}} src={disable ? DisableDeleteIcon : DeleteButtonIcon} alt="delete" height={56} onClick={() => onDelete(dayId, index)} />
        </Grid>
        </Grid>
      {error ? <div className="error-msg" style={{ paddingBottom: 5 }}>{error}</div> : null}
    </div>
  </MuiPickersUtilsProvider>)
}

const DefaultTimingDialog = ({ show, hide, defaultSlots, defaultDays, saveDefaultTiming }: any) => {
  const [days, setDays] = useState<any[]>([]);
  const [slots, setSlots] = useState<any[]>(defaultSlots);
  const [errors, setErrors] = useState<any>({});
  const [slotErrors, setSlotErrors] = useState<any[]>([]);

  useEffect(() => {
    const slotErrorList = defaultSlots && defaultSlots.length > 0 ? defaultSlots.map((item: any) => "") : [];
    setSlotErrors(slotErrorList);
    if (defaultDays && defaultDays.length > 0) {
      const isAllDayPresent: string = defaultDays.find((item: any) => item === "All_Days");
      const allDayArray = dayList.map((item: any) => item.day);
      if (isAllDayPresent || (!defaultDays.includes("All_Days") && defaultDays.length === 7)) {
        setDays(allDayArray);
      } else {
        setDays(defaultDays);
      }
    }
  }, [])

  const onDeleteSlot = (dayId: any, index: any) => {
    const slotArray = [...slots];
    const slotErrorList = [...slotErrors];
    slotArray.splice(index, 1);
    setSlots(slotArray);
    slotErrorList.splice(index, 1);
    setSlotErrors(slotErrorList);
  }

  const addSlot = () => {
    const slotArray = [...slots];
    const slotErrorList = [...slotErrors];
    const obj = { id: new Date().toISOString(), start_time: "", end_time: "" };
    slotArray.push(obj);
    setSlots(slotArray);
    if (errors && errors.slot) {
      const errorObj = errors;
      delete errorObj.slot;
      setErrors(errorObj);
    }
    slotErrorList.push("");
    setSlotErrors(slotErrorList);
  }

  const toggleDaySelection = (day: any) => {
    let all_day = dayList.map((item: any) => item.day);
    let updatedList = [];
    const daysArray = [...days];
    let dayIndex = daysArray.findIndex((item: any) => item === day);
    const allDayIndex = daysArray.findIndex((item: any) => item === "All_Days");

    if ((!daysArray.includes('All_Days') && day === "All_Days") || (dayIndex === -1 && daysArray.length === 6 && !daysArray.includes("All_Days"))) {
      updatedList = all_day;
      setDays(all_day);
    } else if (daysArray.includes('All_Days') && day === "All_Days") {
      updatedList = [];
      setDays([]);
    } else if (dayIndex > -1) {
      if (allDayIndex > -1) {
        daysArray.splice(allDayIndex, 1);
      }
      dayIndex = daysArray.findIndex((item: any) => item === day);
      daysArray.splice(dayIndex, 1);
      updatedList = daysArray;
      setDays(daysArray);
    } else {
      daysArray.includes(day) ? daysArray.splice(dayIndex, 1) : daysArray.push(day);
      updatedList = daysArray;
      setDays(daysArray);
    }

    if (errors && errors.day && updatedList.length > 0) {
      const errorObj = errors;
      delete errorObj.day;
      setErrors(errorObj);
    }
  }

  const setFromTimeState = (value: any, index: number) => {
    const time = getFormattedTime(value);
    const slotArray = [...slots];
    const obj = slotArray[index];
    obj.start_time = time;
    slotArray.splice(index, 1, obj);
    setSlots(slotArray);
  }

  const setToTimeState = (value: any, index: number) => {
    const time = getFormattedTime(value);
    const slotArray = [...slots];
    const obj = slotArray[index];
    obj.end_time = time;
    slotArray.splice(index, 1, obj);
    setSlots(slotArray);
  }

  const validate = () => {
    let errorObj: any = {};
    let isValid = false;
    if (!slots.length && days.length > 0) {
      errorObj.slot = "Please add at least 1 slot";
    }
    if (!days.length && slots.length > 0) {
      errorObj.day = "Please select at least 1 day";
      const fileInput = document.querySelector(
        `div[id=default-day-selection]`);
      if (fileInput) {
        fileInput.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
    if (!days.length && !slots.length) {
      isValid = true;
    }
    if (slots && slots.length > 0) {
      let slotErrorList = [...slotErrors];
      slots.filter((item: any, index: number) => {
        let errorState = "";
        if (!item.start_time || !item.end_time) {
          errorState = "Time is Required";
        } else if (item.start_time && item.end_time && !dateMinMaxValidation(item.start_time, item.end_time)) {
          errorState = "To time should be greater then the From time";
        }
        slotErrorList.splice(index, 1, errorState);
      })
      setSlotErrors(slotErrorList);
      isValid = slotErrorList.filter((item) => item === "").length === slots.length;
    }
    if (!Object.keys(errorObj).length && isValid) {
      saveDefaultTiming(days, slots);
      setErrors({})
    } else {
      setErrors(errorObj);
    }
  }

  return (
    <Dialog open={show} fullWidth maxWidth="sm" className="default-timing-dialog-wrapper">
      <DialogTitle className="dialog-title">
        <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
          <div>Select Default Timing</div>
          <IconButton size="small" onClick={hide}><CloseIcon className="close-icon-btn" /></IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="default-timing-dialog">
          {errors && errors.slot ? <span className="error-msg">{errors.slot}</span> : null}
          <div>
            {slots && slots.length > 0 && slots.map((item: any, index: number) => (
              <TimeSelectionRow key={"default-timing-slot" + index} index={index} start_time={item.start_time} end_time={item.end_time} error={slotErrors[index]} slotId={item.id} onDelete={onDeleteSlot} setFromTime={setFromTimeState} setToTime={setToTimeState} />
            ))}
            <Button className="add-slot-btn" variant="text" onClick={addSlot}>+ ADD SLOT</Button>
          </div>
          <div>
            <Grid container spacing={2}>
              {dayList.map((item: any, index: number) => (
                <Grid item xs={6} key={item.day + index}>
                  <div className={days.includes(item.day) ? "day-item active" : "day-item"}>
                    <div className="day-label">{item.day=== "All_Days" ? "All Days" : item.day}</div>
                    {days.includes(item.day) ? <img src={CheckBoxRounded} height={25} width={25} className="pointer" onClick={() => toggleDaySelection(item.day)} /> : <div id={index === 7 ? "default-day-selection" : ""} className="circle pointer" onClick={() => toggleDaySelection(item.day)}></div>}
                  </div>
                </Grid>
              ))}
            </Grid>
            {errors && errors.day ? <div className="error-msg" style={{paddingTop:5}}>{errors.day}</div> : null}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} style={{ padding: 20 }} >
          <Grid item xs={6} className="default-timing-actions">
            <Button
              className="cancel-btn"
              fullWidth
              variant="outlined"
              onClick={hide}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6} className="default-timing-actions">
            <Button className="save-btn" fullWidth onClick={validate}>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const AvailabilityConfirmationDialog = ({ show, onConfirm, onCancel }: any) => {
  return (
    <Dialog open={show} fullWidth maxWidth="xs" className="default-timing-dialog-wrapper">
      <DialogContent>
        <div className="default-timing-dialog">
          <div style={{ textAlign: "center", paddingTop: 15 }}>
            <img src={UnavailableIcon} alt="unavailable-icon" width={84} height={84} />
            <div className="confirmation-header">Turn off Working Availability?</div>
            <div className="confirmation-description">You will be deactivated from the system if you turn off working availability , you will be no longer available until you turn it on.</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ paddingTop: 0 }}>
        <Grid container spacing={2} style={{ padding: 20 }} >
          <Grid item xs={6} className="default-timing-actions">
            <Button
              className="cancel-btn"
              fullWidth
              variant="outlined"
              onClick={onCancel}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6} className="default-timing-actions">
            <Button className="save-btn" fullWidth onClick={onConfirm}>
              CONFIRM
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
// Customizable Area End
