export const Notification = require('../assets/notification.png')
export const HiyameeLogo = require('../assets/hiyameLogo.png')
export const DashboardLogo = require('../assets/Dashboard.png')
export const Candidates = require('../assets/Candidates.png')
export const Jobs = require('../assets/Jobs.png')
export const ManagerLogo = require('../assets/Manager.png')
export const Vendor = require('../assets/Vendor.png')
export const Interviews = require('../assets/Interviews.png')
export const MySchedule = require('../assets/MySchedule.png')
export const ScheduledInterviews = require('../assets/ScheduledInterviews.png')
export const PaymentsLogo = require('../assets/paymentsLogo.png')
export const SuccessIcon = require('../assets/successIcon.png')


export const CompanyLogo = require('../assets/companyLogo.png')
export const NotificationWhiteIcon = require('../assets/notificationWhiteLogo.png')

// addCandidate
export const DefaultProfile = require('../assets/defaultProfile.png')
export const EditIcon = require('../assets/edit.png')
export const DateIcon = require('../assets/date.png')
export const CourseIcon = require('../assets/course.png')
export const UploadIcon = require('../assets/upload.png')
export const Company = require('../assets/company.png')
export const BackBtn = require('../assets/backBtn.png')
export const SingleCandidateIcon = require("../assets/singleCandidateIcon.png");
export const BulkUploadIcon = require("../assets/bulkUploadIcon.png");
export const UserImg = require('../assets/defaultProfile.png')
export const DropDownIcon = require('../assets/dropDownIcon.png')
export const MainLogoWhite = require('../assets/mainLogoWhite.png')
export const FilterLogo = require("../assets/FilterLogo.png");
export const CheckBoxOutline = require("../assets/checkBox.png");
export const CheckboxSelected = require("../assets/checkBoxSelected.png");
export const CalenderIcon = require('../assets/calender.png')
export const CheckBoxRounded = require("../assets/checkBoxRounded.png");
export const InfoIcon = require("../assets/infoIcon.png");
export const PencilIcon = require("../assets/pencilIcon.png");
export const DeleteButtonIcon = require("../assets/deleteButtonIcon.png");
export const UnavailableIcon = require("../assets/unavailableIcon.png");
export const DisableCheckedIcon = require("../assets/disableCheckedIcon.png");
export const DisableDeleteIcon = require("../assets/disableDeleteIcon.png");
export const MobileIcon = require("../assets/mobile.png");
export const FileIcon = require("../assets/file.png");
export const EmailIcon = require("../assets/email.png");
export const AccountIcon = require("../assets/account.png");
export const ClockIcon = require("../assets/clockIcon.png");
export const ProfessionIcon = require("../assets/professionIcon.png");
export const AudioVideoIcon = require("../assets/audioVideoIcon.png");
export const MyScheduleActiveIcon = require("../assets/MyScheduleActive.png");
export const RadioOutlinedIcon = require("../assets/radioOutlinedIcon.png");
export const RadioCheckedIcon = require("../assets/radioCheckedIcon.png");
export const PdfIcon = require("../assets/pdfIcon.png");


export const ArrowDown = require("../assets/arrowDown.png");
export const WhiteStar = require("../assets/whiteStar.png");
export const UnSelectedStar = require("../assets/unSelectedStar.png");
export const RatingStar = require("../assets/ratingStar.png");
export const MainLogoBlack = require('../assets/mainLogoBlack.png')
