// Customizable Area Start
import React, { useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Chip, Menu, MenuItem, IconButton, Divider, FormControl, FormControlLabel, Checkbox, FormGroup } from "@material-ui/core"
import "./landing_page.css";

export const configJSON = require("../../../framework/src/config");

interface Column {
  id: 'full_name' | 'phone_number' | 'user_type' | 'email' | 'recruiter_jobs' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right';
  format?: (value: number) => string;
}

const vendorColumns: Column[] = [
  { id: 'full_name', label: 'Full Name', minWidth: 100, align: 'left', },
  {
    id: 'phone_number',
    label: 'Phone Number',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'user_type',
    label: 'User Type',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email ID',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'recruiter_jobs',
    label: 'Assigned Jobs',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'actions',
    label: '',
    minWidth: 100,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: "100%",
  },
  vendorJobChip: {
    borderRadius: "8px",
    marginLeft: "6px",
    background: "#08324719",
    color: "#083247"
  },
});

const showUserType = (isHiyameeUser: any) => {
  return isHiyameeUser ? "Hiyamee" : "Company";
}

const getTableColumnClass = (columnId: any) => {
  return columnId === "email" ? "email-column" : "";
}

function VendorTable({ rows, editVendor, deleteVendor, viewVendor }: { rows: any[], editVendor: any, deleteVendor: any, viewVendor: any }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  let [vendorId, setVendorId] = React.useState<number>(0);
  const [extend_data, SetExtendData] = React.useState([]);
  let [vendorData, setVendorData] = React.useState<any>({});
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    console.log('event.currentTarget', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const Check = (event: React.MouseEvent<HTMLElement>, jobs: any) => {
    setAnchorE2(event.currentTarget);
    SetExtendData(jobs)
  }

  const classes = useStyles();
  let col: any = []
  col = vendorColumns

  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10

  useEffect(() => {
    setPage(0)
  }, [rows])

  const getFullName = (fName: string, lName: string) => {
    const firstNameLower = fName.toLowerCase();
    const lastNameLower = lName.toLowerCase();
    const firstName = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
    const lastName = lastNameLower[0].toUpperCase() + lastNameLower.slice(1);
    return `${firstName} ${lastName}`;
  }

  const isHiyameeUser = () => {
    const loginAsHiyameeUser = localStorage.getItem("is_hiyamee") === "true" ? true : false;
    let isHiyameeUser: boolean = false;
    if (!loginAsHiyameeUser) {
      isHiyameeUser = rows.find((item: any) => item.id === vendorId)?.is_hiyamee;
    }
    return isHiyameeUser;
  }

  return (
    <Paper className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem id="edit-manager" onClick={() => { viewVendor(vendorData); handleClose(); }}>View Profile</MenuItem>
        {!isHiyameeUser() && <>
        <Divider />
        <MenuItem id="edit-manager" onClick={() => { editVendor(vendorData); handleClose(); }}>Edit Profile</MenuItem>
        <Divider />
        <MenuItem id="delete-manager" onClick={() => { deleteVendor(vendorId); handleClose(); }}>Delete</MenuItem>
        </>}
      </Menu>
      <Menu
        anchorEl={anchorE2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginLeft: -40, height: 350 }}
        open={!!anchorE2}
        onClose={handleClose2}
      >
        {extend_data.slice(2, extend_data.length).map((item: any, index) => (
          <div>
            <Chip style={{ margin: 5 }} className={classes.vendorJobChip} label={item.job_role} />
          </div>
        )
        )
        }
      </Menu>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {col.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="candidate-table-header-cell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? <TableRow> <div style={{ padding: "18px 0 ", fontSize: "16px", fontWeight: 900 }}> No Data Found </div> </TableRow> : null}
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, ind: number) => {
              return (
                <>
                  <TableRow hover role="checkbox" tabIndex={-1} key={ind}>
                    {col.map((column: any) => {
                      let value
                      if ("actions" === column.id)
                        value = <div className=''> <IconButton onClick={(e: any) => { e.stopPropagation(); handleMenu(e); setVendorId(row.id); setVendorData(row) }} > <MoreVertIcon style={{ verticalAlign: "middle" }} /> </IconButton> </div>
                      else if ("recruiter_jobs" === column.id) {
                        value = <div>
                          {
                            row.recruiter_jobs.map((jobData: any, index: number) => {
                              return (
                                index < 2 ? <Chip className={classes.vendorJobChip} label={jobData.job_role} /> : null)
                            })
                          }
                          {
                            row.recruiter_jobs.length > 2 ? <Chip onClick={(e: any) => Check(e, row.recruiter_jobs)} style={{ backgroundColor: '#083247', color: '#fff', borderRadius: 8, marginLeft: 6 }} label={"+" + (row.recruiter_jobs.length - 2).toString()} /> : null
                          }
                        </div>
                      }
                      else if ("job_role" === column.id)
                        value = row.candidate_job.job_role
                      else if ("full_name" === column.id)
                        value = getFullName(row.first_name, row.last_name)
                      else if ("user_type" === column.id)
                        value = showUserType(row.is_hiyamee)
                      else
                        value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align} className={getTableColumnClass(column.id)}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: "18px", alignItems: "center" }}>
        <div> 
          Showing {rows.length > 0 ? page * 10 + 1 : 0} to {page * rowsPerPage + rowsPerPage > rows.length ? rows.length : page * rowsPerPage + rowsPerPage} of {rows.length} entries </div>
        {rows.length > 0 ?
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <div className={page == 0 ? "next-prev-btn disable" : "next-prev-btn active"} onClick={() => { setPage(page - 1) }} >Prev</div>
            <Pagination shape="rounded" style={{ margin: "0px 10px" }} count={Math.ceil(rows.length / 10)} page={page + 1} onChange={(event, page) => setPage(page - 1)} hidePrevButton hideNextButton />
            <div className={page + 1 < rows.length / 10 ? "next-prev-btn active" : "next-prev-btn disable"} onClick={page + 1 <= rows.length / 10 ? () => { setPage(page + 1); document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ block: 'center', behavior: 'smooth'}) } : () => { }} >Next</div>
          </div>
          : null
        }
      </div>
    </Paper>
  );
}

export default class Vendors extends React.Component<any, any>  {
  //@ts-ignore
  componentDidMount() {
    this.props.getAllVendors("");
  }
  render() {
    const deleteVendor = (vendorId: any) => {
      this.props.deleteVendor(vendorId);
    }
    
    const editVendor = (vendorData: any) => {
      this.props.editVendor(vendorData);
    }

    const viewVendor = (vendorData: any) => {
      this.props.viewVendor(vendorData);
    }
    return (
      <>
        <div className="manager-table-wrapper vendor-table-wrapper" style={{ marginTop: -20 }}>
          <VendorTable rows={this.props?.vendorList || []} editVendor={editVendor} viewVendor={viewVendor} deleteVendor={deleteVendor} />
        </div>
      </>
    )
  }
}

// Customizable Area End