// Customizable Area Start
import React, { PureComponent } from 'react'
import { createStyles, withStyles, Theme, useTheme, makeStyles } from '@material-ui/core/styles';
import { Popover } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import CloseIcon from '@material-ui/icons/Close';
import { InfoIcon, WarningIcon, Selected, ScheduleIcon, RejectedIcon } from '../assets';

import { Grid, TextField, Button, Divider, Modal, NativeSelect, Avatar } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import moment from "moment";
import { Link } from "react-router-dom"

// import {DeleteIcon} from "./assets"


const useStyles = (theme: Theme) => createStyles({
  mainDiv: {
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      width: "100vw"
    }
  },
  hidden: {
    display: "none"
  },
  cancelBtn: {
    position: "absolute",
    right: "16px",
    top: "16px",
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: 5,
    maxWidth: "300px",
  },
}
)

interface props {
  classes: any;
  show: boolean;
  hide: any;
  id: number;   // Candidate's ID
  scheduleInterview: any;
  parentComponent:any;
  candidateInterviewDetails: any;
  candidateSlotsTiming: any[];
  getScheduleSlots: any;
  getCandidateRoundDetails: any;
  jobId:number | undefined;
  openPanelReview: any;
}

interface state {
  // dashboardData:any[];
  // errorMsg: string;
  // token: string;
  // loading: boolean;
  // step: number;
  // showDialog:boolean;
  // active_closed_jobs:string;
  // createJobsAddRounds:any[];
  // allJobsList:string;
  // errors:any;
  // categories: any;
  // editJobSection : string;
  element: HTMLElement | null;
  scheduleNext: boolean;
  dialog: boolean;
}

const getSlotClassName = (status: any) => {
  let statusClass: string = "candidate-details-normal";
  if (status=="confirmed") {
    statusClass = "candidate-details-confirmed";
  } else if (status=="missed_out" || status === "cancelled") {
    statusClass = "candidate-details-missed";
  }
  return statusClass;
}

class CandidateDetails extends PureComponent<props, state> {
  constructor(props: props) {
    super(props)
    this.state = {
      // dashboardData: [],
      // errorMsg: "",
      // token: "",
      // loading: false,
      // step: 1,
      // showDialog:false,
      // active_closed_jobs:"active",
      // createJobsAddRounds:[1],
      // allJobsList:"",
      // errors:{},
      // categories: {mandatory_skill:[],optional_skill:[],job_category:[]},
      // editJobSection:"jobDetails",
      element: null,
      scheduleNext: false,
      dialog:false,
    }
    this.getStatus = this.getStatus.bind(this);
    this.ShowSlots = this.ShowSlots.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
    this.TimelineUnit = this.TimelineUnit.bind(this);
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    // this.props.getCandidateRoundDetails();
    this.props.getScheduleSlots();
  }
  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  getStatus(status: string) {
    return <div
      className={`${status == "processing"
          ? "status-warning"
          : status == "selected"
            ? "status-completed"
            : status == "rejected"
              ? "status-error"
              : (status == "opted_out" || status == "job_closed") ? "status-opted-out"
              : status == "applied"
                ? "status-log"
                : "status-log"
        } dashboard-table-status`}
    >
      {status === "opted_out" ? "Opted Out" : status === "job_closed" ? "Job Closed" : status}
    </div>
  }

  ShowSlots(props: any) {
    const slots = this.props.candidateSlotsTiming.filter((item: any) => item.round_id === props.round?.id);
    return <>
      Interview Slots
      <div style={{ position: "relative", display: "inline" }} >
        <img height="13" onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} style={{ position: "relative", top: "2px", left: "10px" }} src={InfoIcon} alt="info-icon" />
        <Popover
          classes={{
            paper: this.props.classes.paper,
          }}
          style={{ pointerEvents: "none" }}
          open={!!this.state.element}
          anchorEl={this.state.element}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <div style={{ margin: "10px" }}>Assigned {slots.length} slots for this candidate and needs to confirm their slot before 30 mins, if Candidate failed to attend interview then they can give interview in remaining slots.</div>
        </Popover>
      </div>
      <div className="interview-slots">
        {slots?.map((slot)=>
        <div className={getSlotClassName(slot.status)} key={slot.id+"slot-for-jobs"}>
          { slot && slot?.interview_start_time ? moment(slot?.interview_start_time)?.format("DD MMMM YYYY hh:mm A") : "NA"}
          { slot && slot.status == "missed_out" && <span>Missed Slot</span> }
          { slot && slot.status == "cancelled" && <span>Cancelled Slot</span> }
          { slot && slot.status == "confirmed" && <span>Confirmed Slot</span> }
        </div>
        ) || "NA" } 
      </div>
      <Button className='change-slot-btn' disabled={props.disabled} style={{ width: "100%", padding: "5px 15px", border: "1px solid", fontWeight: 600, fontSize: "14px", borderRadius: 8 }} onClick={props.changeSlots} variant="outlined">CHANGE SLOT</Button>
    </>
  }

  TimelineUnit(props: any) {

    return <>
      <span className={`candidate-details-round-heading ${props.active ? "" : "disbled-round-heading"}`} > 
        {/* <input type="radio" defaultChecked={props.active} disabled />  */}
        <TimelineStep active={props.active} /> Round data {props.ind + 1} : {props.name} </span>
      <div className={`timeline-content-wrapper ${props.status === "opted_out"? "disabled-section":""}`}>
      {props.lastSlot ? null :<div className="dashed-image" ></div>}

      { 
        (((props.round.round_status === "round_in_progress" || props.round.round_status === "round_begin") && props.round.interview_status === "in_progress" && props.round?.all_slots?.length > 0)
        || ( props.ind==0 && ( this.props?.candidateInterviewDetails?.job_slots?.length > 0 && !props.round?.round_status ) ))
        && 
        <div className="timeline-content"> 
          <this.ShowSlots changeSlots={() => this.props.scheduleInterview(false, props?.round?.id)} disabled={props.status === "opted_out"} round={props.round} />
        </div> 
      }
      { ((props.ind>0 && (props.round.round_status=="round_begin" || props.round.round_status=="round_in_progress" ) && ( !props.round.all_slots?.length ) )  || ( props.ind==0 && ( !this.props?.candidateInterviewDetails?.job_slots?.length )) ) &&
        <div className="timeline-content">
          <ScheduleNow scheduleInterview={() => this.props.scheduleInterview(true, props?.round?.id, props.round?.panel_slot_id)} disabled={props.status === "opted_out"}/>
        </div>
      }
      { (props.round.round_status === "round_completed" || props.round.round_status === "round_selected") &&
        <div className="timeline-content">
          <InterviewCleared round={props.ind +1} viewPanelReview={() => props.openPanelReview(props.round?.job_slot?.id)}/>
        </div>
      }
      { props.round.round_status === "round_review_pending" &&
          <div className="timeline-content">
            <InterviewCompleted />
          </div>
        } 
        { props.round.round_status === "round_rejected" &&
        <div className="timeline-content">
          <InterviewRejected round={props.ind +1} viewPanelReview={() => props.openPanelReview(props.round?.job_slot?.id)}/>
        </div>
        }
        {/* {props.ind === 0 ? <div className="timeline-content"> {this.props.candidateSlotsTiming.length ? 
          <this.ShowSlots changeSlots={() => this.props.scheduleInterview(false)} disabled={props.status === "opted_out"}/> 
          : <ScheduleNow scheduleInterview={() => this.props.scheduleInterview(true)} disabled={props.status === "opted_out"}/>} </div> : null} */}
      </div>
    </>
  }

  handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ element: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ element: null });
  };

  render() {
    const { classes, show, hide, id, scheduleInterview, parentComponent, candidateInterviewDetails: candidateData, candidateSlotsTiming } = this.props

    // const scrollToErrorField = (errors:any) => {
    //   const err = Object.keys(errors);
    //   if (err.length) {
    //     const input = document.querySelector(
    //         `input[name=${err[0]}]`
    //     );
    //     const dropdown = document.querySelector(
    //         `select[name=${err[0]}]`
    //     );
    //     if(input) {
    //       input.scrollIntoView({
    //           behavior: 'smooth',
    //           block: 'center',
    //       });
    //     } else if(dropdown) {
    //       dropdown.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'center',
    //     });
    //     }
    //   }
    // }
    return (
      <div style={{ top: 0, right: 0 }} className={show ? 'wrapper-dialog fullscreen-dialog' : classes.hidden}>
        {/* <div style={{ top: 0,right: 0 }} className={true ? 'wrapper-dialog fullscreen-dialog' : classes.hidden}> */}
        <div className={'inner-space-jobscreation ' + classes.mainDiv}>
          {/* <Header step={this.state.step} hide={hide} /> */}
          <div className="candidate-details-wrapper" style={{ position: 'static' }}>
            <div className="header-item" style={{ marginBottom: "18px" }} >
              <span className='fw-bold' >Candidate Details</span>
              <span className="pointer" onClick={hide}>
                <CloseIcon />
              </span>
            </div>
            {candidateData === "" ? <h3> loading... </h3> :
              <div >
                <div style={{ display: 'flex' }}>
                  <Avatar className="candidate-details-avatar">
                    {candidateData.candidate_first_name ? candidateData.candidate_first_name[0] : ""}{candidateData.candidate_last_name ? candidateData.candidate_last_name[0] : ""}
                  </Avatar>
                  <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly" }}>
                    <span style={{ fontSize: "18px" }}> {candidateData.candidate_first_name + " " + candidateData.candidate_last_name} </span>
                    <Link to={{ pathname: '/Home/CandidateProfile', state: {id:id, path: parentComponent.path, tab:parentComponent.tab, jobId:this.props.jobId} }}><span style={{ fontWeight: 900, fontSize: "15px" }}>VIEW PROFILE </span></Link>
                  </div>
                </div>
                <Divider style={{ margin: "20px 0px" }} />
                <div className="header-item" >
                  <span className='fw-bold' style={{ fontSize: "18px", whiteSpace: "nowrap" }} >Interview Process</span>
                  {/* <div className="job-card-label candidate-details-chip" >{"Applied"}</div> */}
                  { this.getStatus(candidateData.status) }
                </div>
                {/* <div>
              <span className='candidate-details-round-heading' > <input type="radio" defaultChecked={true} disabled /> Round 1 : Technical Interview </span>
              <div className="timeline-content-wrapper ">
              <div className="dashed-image" ></div> */}
                {/* <div className="timeline-content">
                  Interview Slots   
                  <div style={{position: "relative",display: "inline"}} >
                    <img height="13" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} style={{position: "relative", top: "2px",left:"10px"}} src={InfoIcon} alt="info-icon" /> 
                    <Popover
                      classes={{
                        paper: classes.paper,
                      }}
                      style={{ pointerEvents: "none" }}
                      open={!!this.state.element}
                      anchorEl={this.state.element}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <div style={{margin:"10px"}}>We have assigned 3 slots for your interview. kindly confirm your slot before 30 mins. Also make sure your are available on your confirmed slot if you failed to attend interview then you can give interview in remaining slots.</div>
                    </Popover>
                  </div> 
                  <div className="interview-slots">
                    <div className="candidate-details-missed">
                      12 July 2021 5:00 PM <span>Missed Slot</span> 
                    </div>
                    <div className="candidate-details-confirmed">
                      12 July 2021 5:00 PM <span>Confirmed Slot</span> 
                    </div>
                    <div className="candidate-details-normal">
                      12 July 2021 5:00 PM  
                    </div>
                  </div>
                  <Button style={{ width: "100%", padding: "4px 15px", border: "1px solid", fontWeight: 500, fontSize: "13px" }} variant="outlined">CHANGE SLOT</Button>
                </div> */}
                {/* <div className="timeline-content">
                  <div className="schedule-interview-content">
                    <img src={WarningIcon} height={40} width={40} alt="warning" />
                    <div>Interview is not scheduled yet.</div>
                  </div>
                  <Button fullWidth className='schedule-now-btn' variant="contained" onClick={scheduleInterview}>SCHEDULE NOW</Button>
                </div>
              </div>

              <span className='candidate-details-round-heading disbled-round-heading' > <input type="radio" disabled /> Round 2 : Management Interview </span>
              <div className="timeline-content-wrapper ">
              <div className="dashed-image" ></div>
              <div className="timeline-content">
              </div>
              </div>

              <span className='candidate-details-round-heading disbled-round-heading' > <input type="radio"  disabled /> Round 3 : HR Interview </span>
            </div> */}

                { candidateData?.interview_rounds?.map((round: any, ind: number) => 
                  <this.TimelineUnit key={"slot-round "+ind} active={ round?.round_status || (ind==0 && candidateData?.job_slots.length > 0) } ind={ind} name={round.name} lastSlot={ind + 1 == candidateData.interview_rounds.length}  status={candidateData.status} round={round} openPanelReview={this.props.openPanelReview}/>
                )
                }

                <SuccessDialogBox show={this.state.dialog} handleClose={()=>this.setState({...this.state, dialog:false })} schedule={()=>this.setState({...this.state, scheduleNext:true, dialog:false })} />

              </div>
            }
          </div>
        </div>
      </div>

    )
  }
}

function ScheduleNow(props: any) {
  return <>
    <div className="schedule-interview-content" style={{paddingBottom: 16}}>
      <img src={WarningIcon} height={40} width={40} alt="warning" />
      <div>Interview is not scheduled yet.</div>
    </div>
    <Button fullWidth className='schedule-now-btn' variant="contained" onClick={props.scheduleInterview} disabled={props.disabled}>SCHEDULE NOW</Button>
  </>
}

function ScheduleNext(props:any){
  return <>
  <span className={`candidate-details-round-heading`} > 
      <input type="radio" defaultChecked={true} disabled /> Round {1} : {"Technical Interview"} 
    </span>
    <div className={`timeline-content-wrapper `}>
    {<div className="dashed-image" ></div>}
      <div className="timeline-content">
        <div className="schedule-interview-content">
        <img src={Selected} height={40} width={40} alt="warning" />
        <div>Candidate has successfully cleared round 1 Interview.</div>
      </div>
      <Button style={{borderRadius: 8, fontSize:12}} fullWidth variant="outlined" onClick={()=>props.viewPanelReview()}>VIEW PANEL REVIEW</Button>
      </div>
    </div>
    <span className={`candidate-details-round-heading`} > 
      <input type="radio" defaultChecked={true} disabled /> Round {2} : {"Management Interview"} 
    </span>
    <div className={`timeline-content-wrapper `}>
    {<div className="dashed-image" ></div>}
      <div className="timeline-content">
        { props.scheduleNext ?
            <>
              {props.ShowSlots}
            </> 
          :
          <>
          <div className="schedule-interview-content">
            <img src={WarningIcon} height={40} width={40} alt="warning" />
            <div>Interview is not scheduled yet.</div>
          </div>
          <Button fullWidth className='schedule-now-btn' variant="contained" onClick={props.scheduleInterview} >SCHEDULE NOW</Button>
          </>
        }

      </div>
    </div>
  </>
}

function SuccessDialogBox(params: any) {

  return <Modal
    open={params.show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="jobs-success-model"
  >
    <div className="infromation-block"> 
        <>
          <img src={ScheduleIcon} alt="relative image" style={{ height: "65px", margin: "35px 0" }} /> 
          <h4>
            Schedule Interview
          </h4>
          <h6 >Are you sure want to schedule the interview ? <br/>
          Slots will be auto generated</h6>
          <div style={{ display: "flex",height: "56px", marginTop: "24px"}}>
            <Button onClick={() => {
              params.handleClose();
            }}
              variant='outlined'
              style={{fontWeight: 600,marginRight:"5px", flex:1, borderRadius:"8px" }}
            >
              CANCEL
            </Button>
            <Button onClick={() => { params.schedule() }}
              className="hy-btn"
              style={{fontWeight: 600,marginLeft:"5px",flex:1, borderRadius:"8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </>
    </div>
  </Modal>
}
  
function TimelineStep({active}:{active:boolean}) {
  return<div className='timeline-step'>
      { 
        active 
        ?
          <div className="timeline-step-active" />
        :
          <div className="timeline-step-inactive" />
      }
    </div>
}

function InterviewCleared( { round, viewPanelReview } : { round:number, viewPanelReview: any } ) {
  return <div>
    <div style={{display: "flex", alignItems: "center", paddingBottom: 16}}>
      <img src={Selected} height={42} alt="" style={{marginRight:"10px"}} />
      <span>
        Candidate has successfully cleared round {round} interview.
      </span>
    </div>
    <Button className='view-panel-review-btn' fullWidth variant='outlined' style={{borderRadius: 8, borderColor: "#000"}} onClick={viewPanelReview}>
      VIEW PANEL REVIEW
    </Button>
  </div>
}

function InterviewCompleted() {
  return <div style={{ display: "flex", alignItems: "center" }} >
    <img src={Selected} height={42} alt="" style={{marginRight:"10px"}} />
    <span>
      Interview has been successfully completed. Once panel submit the reviews, it will available here.
    </span>
  </div>
}

function InterviewRejected( { round, viewPanelReview } : { round:number, viewPanelReview: any } ) {
  return <div><div style={{ display: "flex", alignItems: "center", paddingBottom: 16 }} >
    <img src={RejectedIcon} height={42} alt="" style={{marginRight:"10px"}} />
    <span>
      Candidate has not cleared round {round} interview.
    </span>
  </div>
      <Button className='view-panel-review-btn' fullWidth variant='outlined' style={{borderRadius: 8, borderColor: "#000"}} onClick={viewPanelReview}>
      VIEW PANEL REVIEW
    </Button>
    </div>
}

export default withStyles(useStyles, { withTheme: true })(CandidateDetails)

// Customizable Area End
