Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.createJobsApiEndPoint = "job";
exports.createJobsApiMethod = "POST";
exports.jobCategoryApiEndPoint = "jobs_category";
exports.optionalSkillApiEndPoint = "optional_skill";
exports.mandatorySkillApiEndPoint = "mandatory_skill";
exports.commonSkillApiEndPoint = "bx_block_panel/panels/show_skills"
exports.recruiterListApiEndPoint = "get_recruiters";
exports.managerListApiEndPoint = "get_managers";
exports.getAllJobsApiEndPoint = "account_block/jobs";
exports.jobTypesApiEndPoint = "account_block/jobs/job_types";
exports.getApiMethod = "GET";
exports.getCompanyListApiEndPoint = "company_list";
exports.panelRequestsApiEndPoint = "bx_block_coordinator/coordinator/panels";
exports.getNotAppliedCandidateListApiEndPoint = "account_block/candidates?type=not_applied&search="
exports.getAppliedCandidateListApiEndPoint = "account_block/candidates?type=applied&search="
exports.getSelectedCandidateListApiEndPoint="account_block/candidates?type=selected&search=";
exports.getInterviewListApiEndPoint = "bx_block_coordinator/coordinator/coordinator_interviews/?tab=";
exports.getInterviewListForPanelApiEndPoint = "bx_block_panel/panel_interviews/?tab=";
exports.getRecentInterviewsForCoordinatorApiEndPoint = "bx_block_coordinator/coordinator/recent_interviews?page=1&per_page=4";
exports.getInterviewSummaryForCoordinatorApiEndPoint = "bx_block_coordinator/coordinator/interviews_summary";
exports.getJobSummaryForManagerApiEndPoint = "account_block/managers/manager_job_summary";
exports.getJobSummaryForRecruiterApiEndPoint = "account_block/recruiters/recruiter_job_summary";
exports.getCandidateSummaryForRecruiterAPIEndpoint = "account_block/recruiters/recruiter_candidate_summary";
exports.getCandidateSummaryForManagerAPIEndpoint = "account_block/managers/manager_candidate_summary";
exports.getCandidateSummaryForClientAdminAPIEndpoint = "account_block/jobs/client_candidate_summary";
exports.getJobSummaryForClientAdminApiEndPoint = "account_block/jobs/client_job_summary";
exports.getCompletedInterviewsApiEndPoint = "bx_block_coordinator/coordinator/completed_interviews";
exports.getRecentInterviewsForPanelApiEndPoint = "bx_block_panel/panels/recent_interviews";
exports.getRecruitingStatisticsForRecruiterApiEndPoint = "account_block/recruiters/recruiting_statistics";
exports.getRecruitingStatisticsForManagerApiEndPoint = "account_block/managers/recruiting_statistics";
exports.getRecruitingStatisticsForClientAdminApiEndPoint = "account_block/jobs/client_recruiting_statistics";
exports.getRecruiterClosed = "account_block/jobs";
exports.getLocationsApiEndPoint = "account_block/jobs/locations_list";


// Customizable Area End