import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const mainConfigJson = require("../../../framework/src/config");
import { DefaultProfile } from "./assets";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location?:any;
  history?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectFile: boolean;
  view_job_section: string;
  anchorEl: null | HTMLElement;
  showDialog: { msg: string, show: boolean };
  appliedCandidateList: any[] | "";
  jobDetails: any;
  job_types: any[];
  categories: any;
  showEditCandidateDialog: { msg: string, show: boolean, data: any };
  candidateDetail: any,
  candidateProfileImage: any,
  candidateProfileErrors: any,
  candidateDocuments: any[]
  assignCandidateListData: any;
  candidateId: number ;
  isCandidateProfileSaved: boolean;
  candidateInterviewDetails: any;
  candidateSlotsTiming:any[];
  loader:boolean;
  showCandidateDetails: boolean;
  showPanelReview: boolean;
  panelReviewsObj: any;
  scheduleNextRoundParams: any;
  deletedDocuments: any[];
  locationList: any[];
  role:any;
  candidateProfileImageError: boolean;
  resumeError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DocumentOpenerCommonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  id: number = 0
  getJobDetailsId: string = "";
  appliedCandidateListId: string = "";
  updateJobDetailsId: string = "";
  optoutId: string = "";
  reAddCandidateId: string = "";
  getjobCategoryId: string = "";
  getCandidateInterviewDetailId: string = "";
  scheduleInterviewId: string = "";
  jobTypeId: string = "";
  commonSkillId: string = "";
  managerListId: string = "";
  recruiterListId: string = "";
  closeJobId: string = "";
  getCandidateDetailsId: string = "";
  saveCandidateDetailsId: string = "";
  deleteDocumentId: string = "";
  assignCandidateListId: string = "";
  deleteJobId: string = "";
  activateJobId:string="";
  assignCandidateId: string = "";
  savedCandidateId: string = "";
  changeSlotId: string = "";
  activeJobFilter: any = {};
  closedJobFilter: any = {};
  currentTabName: string = "";
  jobId: number | undefined = undefined;
  appliedSearchCandidateListId:string = "";
  initialCandidateDetail: any = {};
  initialCandidateDocuments: any[] = [];
  type: string = "";
  getPanelReviewsApiId:string = "";
  scheduleNextRoundApiId:string = "";
  getLocationListApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      // getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectFile: false,
      view_job_section: "jobDetails",
      appliedCandidateList: "",
      anchorEl: null,
      showDialog: { msg: "", show: false },
      jobDetails: "",
      job_types: [],
      categories: { mandatory_skill: [], optional_skill: [], job_category: [], commonSkills: [{ id: 0, name: "loading..." }] },
      showEditCandidateDialog: { msg: "", show: false, data: {} },
      candidateDetail: {
        id: -1,
        first_name: "",
        last_name: "",
        country_code: "",
        phone_number: "",
        full_phone_number: "",
        email: "",
        updated_at: "",
        birth_place: "",
        birth_date: "",
        religion: "",
        image_url: DefaultProfile,
        student_educations: [],
        student_experiences: [],
        certificates_url: [],
        bulk_resumes_url: [],
        is_bulk: false,
      },
      candidateProfileImage: DefaultProfile,
      candidateProfileErrors: {},
      candidateDocuments: [],
      assignCandidateListData: [],
      candidateId:0,
      isCandidateProfileSaved: false,
      candidateInterviewDetails: "",
      candidateSlotsTiming:[],
      loader:false,
      showCandidateDetails:false,
      showPanelReview: false,
      panelReviewsObj: {},
      scheduleNextRoundParams: {
        roundId: "",
        slotId: ""
      },
      deletedDocuments: [],
      locationList: [],
      role:[],
      candidateProfileImageError: false,
      resumeError: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getJobDetailsId || apiRequestCallId === this.appliedCandidateListId ||
        apiRequestCallId === this.getjobCategoryId || apiRequestCallId === this.getCandidateInterviewDetailId ||
        apiRequestCallId === this.scheduleInterviewId || apiRequestCallId === this.jobTypeId ||
        apiRequestCallId === this.commonSkillId || apiRequestCallId === this.getLocationListApiId ||
        apiRequestCallId === this.managerListId || apiRequestCallId === this.recruiterListId ||
        apiRequestCallId === this.getCandidateDetailsId || apiRequestCallId === this.assignCandidateListId ||
        apiRequestCallId === this.appliedSearchCandidateListId || apiRequestCallId === this.getPanelReviewsApiId) {

        this.receiveGetAPI(apiRequestCallId, responseJson, errorReponse);
      }

      if (apiRequestCallId === this.closeJobId || apiRequestCallId === this.activateJobId ||
        apiRequestCallId === this.updateJobDetailsId || apiRequestCallId === this.optoutId ||
        apiRequestCallId === this.reAddCandidateId || apiRequestCallId === this.changeSlotId ||
        apiRequestCallId === this.saveCandidateDetailsId) {
          
        this.receivePutAPI(apiRequestCallId, responseJson, errorReponse);
      }

      if (apiRequestCallId === this.deleteDocumentId || apiRequestCallId === this.deleteJobId) {
        this.receiveDeleteAPI(apiRequestCallId, responseJson, errorReponse);
      }

      if (apiRequestCallId === this.assignCandidateId || apiRequestCallId === this.scheduleNextRoundApiId) {
        this.receivePostAPI(apiRequestCallId, responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getJobDetails = async (id: number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAppliedCandidateList = async (id: number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.appliedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}/applied_job_candidate_list?search=`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closeJob = async (id: number) => {
    this.setState({ ...this.state, loader: true})
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.closeJobId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}/closed_job`

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteJob = async (id: number) => {
    this.setState({ ...this.state, loader: true})
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteJobId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  activateJob = async (id: number) => {

    console.log('ActivateJob',id)

  
let  requestData ={
  "job": {
  "status": "active"
  }
  }

 const headers = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activateJobId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  updateJobDetails = async (id: number, values: any) => {
    this.setState({...this.state, loader: true});
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateJobDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  optoutCandidate = async(values:any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.optoutId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${this.id}/opted_out_assign_candidate`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  reAddCandidate = async(candidateId:any) => {
    const payload = {
      job:{
        account_id: candidateId,
        not_interested: false,
        get_offer_from_other_company: false,
        not_looking_for_opprtunities: false,
        other_reason: ""
      }
    }
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reAddCandidateId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${this.id}/readd_opted_out_candidate`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeSlots = async(values:any) => {
    const starTime = values.start_time;
    const endTime = values.end_time;

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeSlotId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${values?.id}/change_slot?start_time=${starTime.toISOString()}&end_time=${endTime.toISOString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobCategory = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getjobCategoryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobCategoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCandidateInterviewDetails = async (id:number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCandidateInterviewDetailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${this.id}/interview_slot?candidate_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  scheduleSlots = async (id:number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.scheduleInterviewId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${this.id}/get_interview_slot?candidate_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobTypes = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobTypeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobTypesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCommonSkills = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commonSkillId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commonSkillApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLocationList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLocationListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getManagersList = async()=>{
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.recruiterListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recruiterListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecruiterList = async()=>{
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.managerListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.managerListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCandidateDetails = async (id: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCandidateDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateDetailsApiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteDocument = async (candidateId: any, docId: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateDetailsApiEndPoint + candidateId + `/delete_attachment?attachment_id=${docId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveCandidateProfile = async (updatedValues: any, docs: any) => {
    this.setState({...this.state, resumeError: false, candidateProfileImageError: false});
    const candidateData = new FormData();
    if (typeof (updatedValues.image_url) === "object") {
      candidateData.append("candidate[image]", updatedValues.image_url)
    }
    docs.forEach((item: any) => {
      if (typeof (item.url) === "object") {
        candidateData.append("candidate[certificates][][name]", item.name);
        candidateData.append("candidate[certificates][][url]", item.url);
        candidateData.append("candidate[certificates][][file_type]", item.file_type);
      }
    });
    if (updatedValues.certificates_url.length > 0) {
      const resume = updatedValues.certificates_url.find((item:any) => item.file_type === "resume" && typeof(item.url) === "object");
      if (resume) {
        candidateData.append("candidate[certificates][][name]", resume.name);
        candidateData.append("candidate[certificates][][url]", resume.url);
        candidateData.append("candidate[certificates][][file_type]", resume.file_type);
      }
    }
    candidateData.append("candidate[first_name]", this.formateUserName(updatedValues.first_name))
    candidateData.append("candidate[last_name]", this.formateUserName(updatedValues.last_name))
    candidateData.append("candidate[full_phone_number]", `+91 ${updatedValues.phone_number}`)
    candidateData.append("candidate[email]", updatedValues.email.toLowerCase())
    candidateData.append("candidate[role_id]", "5")
    candidateData.append("candidate[user_name]", this.formateUserName(updatedValues.first_name) + " " + this.formateUserName(updatedValues.last_name))
    candidateData.append("candidate[student_educations_attributes]", JSON.stringify(updatedValues.student_educations))
    candidateData.append("candidate[student_experiences_attributes]", JSON.stringify(updatedValues.student_experiences))
    candidateData.append("candidate[birth_date]", updatedValues.birth_date)
    candidateData.append("candidate[religion]", updatedValues.religion)
    candidateData.append("candidate[birth_place]", updatedValues.birth_place)

    const headers = {
      "Content-Type": undefined,
      "token": localStorage.getItem("token")
    };

    const requestMessageForSaveCandidate = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveCandidateDetailsId = requestMessageForSaveCandidate.messageId;
    this.savedCandidateId = updatedValues.id;

    requestMessageForSaveCandidate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateDetailsApiEndPoint + updatedValues.id
    );

    requestMessageForSaveCandidate.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageForSaveCandidate.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessageForSaveCandidate.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      candidateData
    );
    runEngine.sendMessage(requestMessageForSaveCandidate.id, requestMessageForSaveCandidate);
  }

  setCandidateDocument = (list: any[]) => {
    let docList: any = [];
    const documentList = [
      { label: "Aadhar Card", file_type: "aadhar_card" },
      { label: "Pan Card", file_type: "pan_card" },
      { label: "ID Proof", file_type: "id_proof" },
      { label: "Graduation Document", file_type: "graduation_document" },
      { label: "PF", file_type: "pf" },
      { label: "Counter Offer Letter", file_type: "counter_offer_letter" },
      { label: "Current organization offer letter", file_type: "current_org_offer_letter" },
      { label: "Last 3 Months Salary Slips", file_type: "salary_slip" },
    ];

    documentList.forEach((item) => {
      const record = list.length > 0 ? list.find((obj) => obj.file_type === item.file_type) : null;
      const obj = {
        id: record ? record.id : null,
        name: record ? record.name : "",
        file_type: item.file_type,
        url: record ? record.url : "",
        label: item.label
      }
      docList.push(obj);
    })
    this.setState({ ...this.state, candidateDocuments: docList })
    this.initialCandidateDocuments = docList;
  }

    getAssignCandidateList = async()=>{
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token":localStorage.getItem("token")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.assignCandidateListId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.jobDetailAPiEndPoint}${this.id}/assign_candidate_list`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAssignCandidatesData = async (search: string) => {
    let searchKey = await search;
    console.log("searchKey >>",searchKey)
    if (searchKey.length >= 1) {
      this.getSearchAssignCandidateList(searchKey)
    } else {
      this.getAssignCandidateList()
    }
  }

    getSearchAssignCandidateList = async(searchAssignCandidate: string)=>{
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token":localStorage.getItem("token")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.assignCandidateListId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.searchAssignCandidateListEndPoint}${this.id}/search_assign_candidate?search=${searchAssignCandidate}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    assignCandidate = async(candidate_id: number[])=>{
      console.log(JSON.stringify({candidate_id:candidate_id}));
      let payload = { candidate_id : candidate_id }
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token":localStorage.getItem("token")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.assignCandidateId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.jobDetailAPiEndPoint}${this.id}/add_assign_candidate`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(payload)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSearchAppliedCandidateList = async (id: number, searchKey:string = "") => {
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": localStorage.getItem("token")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.appliedSearchCandidateListId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.jobDetailAPiEndPoint}${id}/applied_job_candidate_list?search=${searchKey}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPanelReviews = async (id: any) => {
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": localStorage.getItem("token")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getPanelReviewsApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPanelReviewsEndPoint + id
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    scheduleNextRound = async (slotId: any, roundId: any) => {
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": localStorage.getItem("token")
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.scheduleNextRoundApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.scheduleNextRoundEndPoint + slotId
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({round_id: roundId})
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getImageUrl = (url: any = "") => {
      let newUrl = DefaultProfile;
      if (url) {
        newUrl = url.charAt(0) === "/" ? mainConfigJson.baseURL + url : url;
      }
      return newUrl;
    }

    receiveGetJobDetails = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.job && Object.keys(responseJson.job).length) {
        //Success Case
        this.setState({ ...this.state, jobDetails: responseJson.job })
      } else {
        //Error Case
        this.setState({ ...this.state, jobDetails: "" })
        //@ts-ignore
        this.props.history.push("/Home/Jobs")
        console.log("error-IN_VIEW_JOB_API")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetAppliedCandidateList = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.applied_jobs_candidate) {
        //Success Case
        this.setState({ ...this.state, appliedCandidateList: responseJson.applied_jobs_candidate })
      } else {
        //Error Case
        this.setState({ ...this.state, appliedCandidateList: [] })
        console.log("error-IN_APPLIED_CANDIDATE_LIST")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetJobCategory = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.category) {
        //Success Case
        this.setState({ ...this.state, categories: { ...this.state.categories, job_category: responseJson.category } })

      } else {
        //Error Case
        console.log("error-IN_GET_JOB_CATEGORY")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetCandidateInterviewDetails = (responseJson: any, errorReponse: any) => {
      if( responseJson?.message === "No Applied Job Found" ){
        this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^No Applied Job Found`}})
      }
      else if ( responseJson.errors ) {
        //Success Case
        this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^${responseJson.errors}`}})
        
      } else {
        //Error Case
        console.log("error-IN_GET_Candidate_Details_for_interview")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveScheduleSlots = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.job_slots ) {
        //Success Case
        if(responseJson?.job_interviews_round_details){
          responseJson?.job_interviews_round_details?.forEach((round:any)=>{
            responseJson.interview_rounds?.forEach((Round:any,index:number)=> {
              if(Round.id == round.id){
                responseJson.interview_rounds[index] = round
              } 
            })
        })
        }
          this.setState({ ...this.state, candidateSlotsTiming: responseJson.job_slots, candidateInterviewDetails:responseJson })

      } else {
        //Error Case
        console.log("error-IN_GET_JOB_CATEGORY")
        this.setState({ ...this.state, candidateSlotsTiming: [] })
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetSearchAppliedCandidateList = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.applied_jobs_candidate) {
        //Success Case
        this.setState({ ...this.state, appliedCandidateList: responseJson.applied_jobs_candidate })
      } else {
        //Error Case
        this.setState({ ...this.state, appliedCandidateList: [] })
        console.log("error-IN_APPLIED_CANDIDATE_LIST")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    
    receiveGetJobTypes = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.job_types) {
        //Success Case
        this.setState({ ...this.state, job_types: responseJson.job_types })

      } else {
        //Error Case
        console.log("error-IN_GET_JOB_CATEGORY")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetCommonSkills = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.skill) {
        //Success Case
        this.setState({ ...this.state, categories: { ...this.state.categories, commonSkills: responseJson.skill } })
      } else {
        //Error Case
        console.log("error-IN_Common_SKILLS")
        this.setState({ ...this.state, categories: { ...this.state.categories, commonSkills: [] } })
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetLocationList = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.Locations && responseJson.Locations.length > 0) {
        //Success Case
        this.setState({ ...this.state, locationList: responseJson.Locations })
      } else {
        //Error Case
        console.log("error-location list")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetRecruiterList = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.managers) {
        //Success Case
        
        this.setState({ ...this.state, categories: { ...this.state.categories, managers: responseJson.managers}  })

      } else {
       //Error Case
       this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^Error Occured While Fetching Manager's List`}})
      console.log("error-IN_Manager_List")
      this.setState({ ...this.state, categories: { ...this.state.categories, managers:[] }  })
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetManagersList = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.recruiters) {
        //Success Case
        
        this.setState({ ...this.state, categories: { ...this.state.categories, recruiters: responseJson.recruiters}  })

      } else {
       //Error Case
       
       this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^Error Occured While Recruiter's List`}})
      console.log("error-IN_Recruiter_List")
      this.setState({ ...this.state, categories: { ...this.state.categories, recruiters:[] }  })
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveUpdateJobDetails = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.message ) {
        //Success Case
        if(responseJson.message === "Job updated successfully"){
          this.getJobDetails(this.id)
          this.getAppliedCandidateList(this.id)
          this.setState({ ...this.state, loader: false, showDialog: { show: false, msg:""}})
        }
        else {
          //Error Case
          this.setState({ ...this.state, loader: false, showDialog: { show: true, msg:`apiError^${responseJson.message}`}})
        }
      } else {
        //Error Case
        this.setState({ ...this.state, loader: false, showDialog: { show: true, msg:`apiError^Some error occured while updating jobs try again later`}})
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveOptOutCandidate = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.message ) {
        //Success Case
        if(responseJson.message === "Candidate opted out to job successfully"){
          this.getAppliedCandidateList(this.id)
        }
        else {
          //Error Case
          this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^${responseJson.message}`}})
        }
      } else {
        //Error Case
        this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^Some error occured while optingout candidate try again later`}})
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveReAddCandidate = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.message ) {
        //Success Case
        if(responseJson.message === "Candidate readded to job successfully"){
          this.getAppliedCandidateList(this.id)
        }
        else {
          //Error Case
          this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^${responseJson.message}`}})
        }
      } else {
        //Error Case
        this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^Some error occured while re adding candidate try again later`}})
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveChangeSlots = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.message ) {
        //Success Case
        if(responseJson.message === "Interview Slots updated successfully"){
          this.scheduleSlots(this.state.candidateId)

          this.setState({ ...this.state, scheduleNextRoundParams: {}})
        }
        else {
          //Error Case
          this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^${responseJson.message}`}})
        }
      } else {
        //Error Case
        this.setState({ ...this.state, showDialog: { show: true, msg:`apiError^Some error occured while changing slots try again later`}})
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveCloseJob = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.message) {
        //Success Case
        if (responseJson.message === "Job closed successfully") {
          this.setState({ ...this.state, showDialog: { show: false, msg: "" }, anchorEl: null, loader:false })
          //@ts-ignore
          this.props.history?.replace({pathname:"/Home/Jobs", state: { currentTabName: this.currentTabName, 
            activeJobFilter: this.activeJobFilter, closedJobFilter: this.closedJobFilter, parent: "Jobs"}})
        }
        else{
          this.setState({ ...this.state, showDialog: { show: true, msg: `apiError^${responseJson.message}` }, anchorEl:null })
        }

      } else {
       //Error Case
       this.setState({ ...this.state, showDialog: { show: true, msg: "apiError^Error While closing the job please try later" }, anchorEl:null })
      console.log("error-IN_Close_Jobs")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveDeleteDocument = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.message) {
        //Success Case
        console.log(" this.deleteDocument", responseJson);
      } else {
        //Error Case
        console.log("Error Occured deleteDocument");
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveDeleteJob = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.success) {
        //Success Case
        if(responseJson.success===true){
          this.setState({ ...this.state, showDialog: { show: false, msg: "" }, anchorEl:null, loader:false })
          //@ts-ignore
          this.props.history?.replace({pathname:"/Home/Jobs", state: { currentTabName: this.currentTabName, 
            activeJobFilter: this.activeJobFilter, closedJobFilter: this.closedJobFilter, parent: "Jobs"}})            }
        else{
          this.setState({ ...this.state, showDialog: { show: true, msg: `apiError^${responseJson.message||"Some Error has Occured while Deleting Job"}` }, anchorEl:null })
        }

      } else {
        //Error Case
        this.setState({ ...this.state, showDialog: { show: true, msg: `apiError^Some Error has Occured while Deleting Job` }, anchorEl:null })
        console.log("error-IN_Delete_Jobs")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetCandidateDetails = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.candidate) {
        //Success Case
        const data = responseJson.candidate;
        const obj = {
          id: data.id,
          first_name: this.formateUserName(data.first_name),
          last_name: this.formateUserName(data.last_name),
          country_code: data.country_code,
          phone_number: data.phone_number,
          full_phone_number: data.full_phone_number,
          email: data.email,
          updated_at: data.updated_at,
          birth_place: data.birth_place,
          birth_date: data.birth_date,
          religion: data.religion,
          image_url: this.getImageUrl(data.image_url || ""),
          student_educations: data.student_educations,
          student_experiences: data.student_experiences,
          certificates_url: data.certificates_url,
          is_bulk: data.is_bulk,
          bulk_resumes_url: data.bulk_resumes_url
        }
        this.setCandidateDocument(data.certificates_url)
        this.setState({ ...this.state, candidateDetail: { ...obj }, candidateProfileImage: this.getImageUrl(data.image_url || "")})
        this.initialCandidateDetail = JSON.stringify(obj);
        if (localStorage.getItem("role") === "candidate") {
          localStorage.setItem("fname", this.formateUserName(obj.first_name));
          localStorage.setItem("lname", this.formateUserName(obj.last_name));
          localStorage.setItem("image", obj.image_url === DefaultProfile ? "" : obj.image_url);
          //@ts-ignore
          this.props.updateProfile();
        }
      } else {
        //Error Case
        console.log("error-IN_candidate_details")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveSaveCandidateProfile = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.message) {
        //Success Case
        console.log(" this.updateCandidate", responseJson);
        this.getCandidateDetails(this.savedCandidateId);
        this.savedCandidateId = "";
        this.setState({...this.state, isCandidateProfileSaved: false, candidateProfileErrors:{}, deletedDocuments: []});
      } else {
        //Error Case
        this.setState({...this.state, isCandidateProfileSaved: false, candidateProfileErrors:{}, deletedDocuments: []});
        console.log("Error Occured updateCandidate");
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetAssignCandidateList = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.candidate_details ) {
        //Success Case
        //@ts-ignore
        if(responseJson.candidate_details instanceof Array ){
          this.setState({ assignCandidateListData: responseJson.candidate_details })
        }
        else{
          this.setState({ assignCandidateListData: [] })
        }
      } else {
        //Error Case
        console.log("error-IN_candidate_details")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveAssignCandidate = (responseJson: any, errorReponse: any) => {
      if (responseJson) {
        //Success Case
        this.getAppliedCandidateList(this.id)
        console.log("Job Assign successfully!")
      } else {
        //Error Case
        this.setState({ ...this.state, showDialog: { show: true, msg: `apiError^${responseJson.message||"Some Error has occured while Assigning Candidate"}` }})
        console.log("error-IN_candidate_details")
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetPanelReviews = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.data) {
        this.setState({...this.state, showCandidateDetails: false, showPanelReview: true, panelReviewsObj: responseJson.data?.attributes})
        //Success Case
      } else {
        //Error Case
        console.log("Error while getting panel reviews");
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveScheduleNextRound = (responseJson: any, errorReponse: any) => {
      if (responseJson && responseJson.slot && responseJson.slot.length > 0) {
        this.setState({ ...this.state, showDialog: { show: false, msg: "" }, scheduleNextRoundParams: {} })
        this.scheduleSlots(this.state.candidateId);            
      } else {
        //Error Case
        console.log("Error while schedule next round");
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

    receiveGetAPI = (apiId: string, responseJson: any, errorReponse: any) => {
      if (apiId === this.getJobDetailsId) {
        this.receiveGetJobDetails(responseJson, errorReponse);
      }
      if (apiId === this.appliedCandidateListId) {
        this.receiveGetAppliedCandidateList(responseJson, errorReponse);
      }
      if (apiId === this.getjobCategoryId) {
        this.receiveGetJobCategory(responseJson, errorReponse);
      }
      if (apiId === this.getCandidateInterviewDetailId) {
        this.receiveGetCandidateInterviewDetails(responseJson, errorReponse);
      }
      if (apiId === this.scheduleInterviewId) {
        this.receiveScheduleSlots(responseJson, errorReponse);
      }
      if (apiId === this.jobTypeId) {
        this.receiveGetJobTypes(responseJson, errorReponse);
      }
      if (apiId === this.commonSkillId) {
        this.receiveGetCommonSkills(responseJson, errorReponse);
      }
      if (apiId === this.getLocationListApiId) {
        this.receiveGetLocationList(responseJson, errorReponse);
      }
      if (apiId === this.recruiterListId) {
        this.receiveGetManagersList(responseJson, errorReponse);
      }
      if (apiId === this.managerListId) {
        this.receiveGetRecruiterList(responseJson, errorReponse);
      }
      if (apiId === this.getCandidateDetailsId) {
        this.receiveGetCandidateDetails(responseJson, errorReponse);
      }
      if (apiId === this.assignCandidateListId) {
        this.receiveGetAssignCandidateList(responseJson, errorReponse);
      }
      if (apiId === this.appliedSearchCandidateListId) {
        this.receiveGetSearchAppliedCandidateList(responseJson, errorReponse);
      }
      if (apiId === this.getPanelReviewsApiId) {
        this.receiveGetPanelReviews(responseJson, errorReponse);
      }
    }

    receivePutAPI = (apiId: string, responseJson: any, errorReponse: any) => {
      if (apiId === this.closeJobId) {
        this.receiveCloseJob(responseJson, errorReponse);
      }
      if (apiId === this.activateJobId) {
        this.setState({ ...this.state, showDialog: { show: false, msg: "" }, anchorEl:null, loader:false })
        //@ts-ignore
        this.props.history?.push("/Home/Jobs")      
      }
      if (apiId === this.updateJobDetailsId) {
        this.receiveUpdateJobDetails(responseJson, errorReponse);
      }
      if (apiId === this.optoutId) {
        this.receiveOptOutCandidate(responseJson, errorReponse);
      }
      if (apiId === this.reAddCandidateId) {
        this.receiveReAddCandidate(responseJson, errorReponse);
      }
      if (apiId === this.changeSlotId) {
        this.receiveChangeSlots(responseJson, errorReponse);
      }
      if (apiId === this.saveCandidateDetailsId) {
        this.receiveSaveCandidateProfile(responseJson, errorReponse);
      }
    }

    receiveDeleteAPI = (apiId: string, responseJson: any, errorReponse: any) => {
      if (apiId === this.deleteDocumentId) {
        this.receiveDeleteDocument(responseJson, errorReponse);
      }

      if (apiId === this.deleteJobId) {
        this.receiveDeleteJob(responseJson, errorReponse);
      }
    }

    receivePostAPI = (apiId: string, responseJson: any, errorReponse: any) => {
      if (apiId === this.assignCandidateId) {
        this.receiveAssignCandidate(responseJson, errorReponse);
      }

      if (apiId === this.scheduleNextRoundApiId) {
        this.receiveScheduleNextRound(responseJson, errorReponse);
      }
    }

    formateUserName = (uName: any)=> {
      let userName = "";
      if (uName) {
        userName = uName.charAt(0).toUpperCase() + uName.slice(1);
      }
      return userName;
    }

  // Customizable Area End
}
