// Customizable Area Start

import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import "./landing_page.css";
import { FilterLogo,CalenderIcon, CheckBoxOutline,SuccessIcon, DropDownIcon,CheckboxSelected } from "./assets";
import {Button, Menu, MenuItem, Grid, Typography,Drawer, IconButton, Modal, AccordionDetails, AccordionSummary, Accordion, Divider, FormControl, FormControlLabel, Checkbox, FormGroup, createTheme, Tooltip} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker,TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ThemeProvider} from "@material-ui/styles";

export const configJSON = require("../../../framework/src/config");
interface Column {
  id: 'first_name' | 'last_name' | 'phone_number' | 'job_role' |  'applied_company' | 'resume';
  label: string;
  minWidth?: number;
  align?: 'left';
  format?: (value: number) => string;
}


const notAppliedColumns:Column[] = [
  { id: 'first_name', label: 'First Name', minWidth: 100, align: 'left',},
  { id: 'last_name', label: 'Last Name', minWidth: 100, align: 'left', },
  {
    id: 'phone_number',
    label: 'Phone Number',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'resume',
    label: '',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
]

const appliedColumns: Column[] = [
  { id: 'first_name', label: 'First Name', minWidth: 100, align: 'left',},
  { id: 'last_name', label: 'Last Name', minWidth: 100, align: 'left', },
  {
    id: 'phone_number',
    label: 'Phone Number',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'job_role',
    label: 'Job Role',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'resume',
    label: '',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
];

const recruiterAppliedColumns: Column[] = [
  { id: 'first_name', label: 'First Name', minWidth: 100, align: 'left',},
  { id: 'last_name', label: 'Last Name', minWidth: 100, align: 'left', },
  {
    id: 'phone_number',
    label: 'Phone Number',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
   { id: 'applied_company', label: 'Applied Company', minWidth: 100, align: 'left', },
  {
    id: 'job_role',
    label: 'Job Role',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'resume',
    label: '',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
];

interface Data {
  firstname: string;
  lastname: string;
  phoneno: number;
  jobrole: string;
  resume: JSX.Element;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: { // to get scroll bar in table 
    maxHeight: "100%"
  },
});


const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    } ,
  },
  
});
interface CandidateTableProps{
  pageNo:number
  changePage: (value: number)=> any
}


function PanelDrawer({ unassign_panel, slots, getAssignedPanel, InterviewId, setOpenAssignCandidates, setModalSlot }: { unassign_panel: any, slots: [], InterviewId: string, getAssignedPanel: any, setOpenAssignCandidates: any, setModalSlot: any }) {

  let filterSlot = slots?.filter(function (item: any) {
    return (item.status == 'confirmed')
  });

  const back = () => {
    setOpenAssignCandidates(false)
  }

  let slotGetStatus = '';
  if (filterSlot != undefined && filterSlot.toString() != '') {
    slotGetStatus = filterSlot[0]['status'];
  }

  let arr = slots?.sort(function (a: any, b: any) {
    let dateA = new Date(a.updated_at).getTime();
    let dateB = new Date(b.updated_at).getTime();
    return dateA < dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
  });

  slots = arr;

  const AssignedPanel = (item: any) => {
    let filteredData = slots.filter(function (item: any) {
      return (item.status == 'confirmed')
    });
    let values = { 'panel_id': item, 'interview_id': InterviewId, 'slot_id': filteredData[0]['id'] }

    getAssignedPanel(values);
    back();
  }

  return (
    <div className="draw-cont candidate-page" >
      <div className="heading assign-heading candidate-page">Assign Panel
        <div className="closed pointer candidate-page"
          onClick={() => back()}>X</div>
      </div><br />
      <div style={{fontSize: '18px', color: 'red'}}>
      *Before assigning interview to panel, please check their profile status approved or not with their availability time.
      </div>
      <div className="assign-candidate-search candidate-page">
      </div>
      <div style={{ textAlign: 'center' }} >
        <div style={{ width: '70%' }}>

          <div className="timeline-content candidate-page">
            <div style={{ display: 'flex' }}>
              <p style={{ fontWeight: 'bold' }}>Confirmed Slot Details </p>
            </div>
            <div className='interview-slots2 candidate-page' style={{ cursor: 'pointer', margin: 0 }}>
              {slots?.map((slot: any) =>
                slot.status === 'confirmed' && (
                  <div className="candidate-details-normal candidate-page" style={{ borderColor: 'green' }}   >
                    {slot && slot?.start_time ? moment(slot?.start_time)?.format("DD MMMM YYYY hh:mm A") : "NA"}
                  </div>)
              ) || "NA"}
            </div>

            <Button className='change-slot-btn candidate-page' disabled={slotGetStatus != 'confirmed'} style={{ width: "100%", padding: "5px 15px", border: "1px solid", fontWeight: 600, fontSize: "14px", borderRadius: 8 }} onClick={() => setModalSlot(true)} variant="outlined">CHANGE SLOT</Button>
          </div>
        </div>
      </div>
      <p style={{ fontWeight: 'bold' }}>Panel List </p>
      <TableContainer className="assign-table-container candidate-page">
        <Table stickyHeader aria-label="sticky table" className='assign-candidate-table-wrapper candidate-page'>
          <TableHead>
            <TableRow className='assign-table-tr candidate-page'>
              <TableCell>First Name</TableCell>
              <TableCell align="left">Last Name</TableCell>
              <TableCell align="left">Phone Number</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unassign_panel?.map((ob: any) => {
              return (
                <TableRow className="pointer assign-table" key={ob.first_name} >
                  <TableCell scope="row" className="block_container assign-table-th candidate-page">
                    <div className="inline">
                      {ob.first_name}
                    </div>
                  </TableCell>
                  <TableCell className="inline" align="left">{ob.last_name}</TableCell>
                  <TableCell align="left">{ob.phone_number}</TableCell>
                  <TableCell align="left" >
                    <Button variant="outlined" className='change-slot-btn candidate-page' disabled={slotGetStatus != 'confirmed'} onClick={() => { AssignedPanel(ob.id) }} >
                      Assign
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {unassign_panel.length === 0 &&
        <p className='no_panel'>No panel is available matching the job skill</p>
      }
      <div className="button assign-candidate-btn candidate-page">
        <button className="btn-2 pointer rowsPerPage" onClick={() => back()}>CANCEL</button>
      </div>
    </div>
    
  )
}

const dateComparison = (sD: any, eD: any) => {
  const start_date = moment(moment(sD, "DD-MM-YYYY"));
  const end_date = moment(moment(eD, "DD-MM-YYYY"));
  const diffDaysCount = start_date.diff(end_date, "day");
  return diffDaysCount;
}

const timeComparison = (sT: any, eT: any) => {
  const start_time = moment(moment(sT, "hh:mm A"));
  const end_time = moment(moment(eT, "hh:mm A"));
  const diffMinutesCount = start_time.diff(end_time, "minute");
  return diffMinutesCount;
}

const slotValidation = (sd: any, st: any, ed: any, et: any) => {
  let error = "";
  if (!st) {
    error = "Start Time is Required";
  } else if (!sd) {
    error = "Start Date is Required";
  } else if (!et) {
    error = "End Time is Required";
  } else if (!ed) {
    error = "End Date is Required";
  } else if (dateComparison(sd, ed) > 0) {
    error = "Start Date should not be greater than End Date";
  } else if (dateComparison(sd, ed) === 0 &&
    timeComparison(st, et) > -1) {
    error = "Start Time should not be greater than or equal to End Time";
  }
  return error;
}

const getResumeUrl = (rowData: any) => {
  const baseUrl = configJSON.baseURL;
  let resumeUrl = "";
  if (rowData && rowData.is_bulk && rowData.bulk_resumes_url && rowData.bulk_resumes_url.length > 0) {
    resumeUrl = rowData.bulk_resumes_url[0].charAt(0) === "/" ? baseUrl + rowData.bulk_resumes_url[0] : rowData.bulk_resumes_url[0]
  } else if (rowData && rowData.certificates_url && rowData.certificates_url.length > 0) {
    const resume = rowData.certificates_url.find((item: any) => item.file_type === "resume");
    if (resume) {
      resumeUrl = resume.url.charAt(0) === "/" ? baseUrl + resume.url : resume.url
    }
  }
  return resumeUrl;
}

const getViewResumeClass = (rowData: any) => {
  const resumeUrl = getResumeUrl(rowData);
  return resumeUrl ? "" : "disable-view-resume";
}

const getViewResumeTooltipTitle = (rowData: any) => {
  const resumeObj = getResumeUrl(rowData);
  return resumeObj ? "" : "No Resume Found";
}

const timeSelectionPlaceholder = (value: any) => {
  return value === "" ? "Select Time" : "";
}

const dateSelectionPlaceholder = (value: any) => {
  return value === "" ? "Select Date" : "";
}

export function CandidateTable({ rows, setColumns, viewCandidateProfile, unassign_panel, UpdateTimeSlot, slots, getUnassignedData, getAssignedPanel }: { rows: any[], setColumns: string, UpdateTimeSlot: any, viewCandidateProfile: any, getUnassignedData: any, slots: any, unassign_panel: any[], getAssignedPanel: any }) {

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  let [candidateId, setCandidateId] = useState<number>(0);
  const [openAssignCandidatesDrawer, setOpenAssignCandidatesDrawer] = useState(false);
  const [InterviewId, SetInterviewId] = useState('');
  const [openConfirmSlot, setConfirmSlot] = useState(false);
  const [ModalSlot, setModalSlot] = useState(false);
  const [slotStartDate, setSlotStartDate] = useState('');
  const [slotStartTime, setSlotStartTime] = useState('');
  const [slotEndDate, setSlotEndDate] = useState('');
  const [slotEndTime, setSlotEndTime] = useState('');
  const [slotError, setSlotError] = useState<string>("");

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const classes = useStyles();
  let col: any = appliedColumns;
  if (setColumns === "notApplied") {
    col = notAppliedColumns
  }
  else if (localStorage.getItem("role")?.toLowerCase() === "recruiter") {
    col = recruiterAppliedColumns
  }
  const [pageNumber, setPageNumber] = React.useState(0);
  const rowsInPage = 10;
  useEffect(() => {
    setPageNumber(0)
  }, [rows])

  const back = () => {
    setOpenAssignCandidatesDrawer(false)
  }

  const checkId = (rows: any) => {
    if (setColumns == 'unassigned') {
      SetInterviewId(rows['interview_detail']['id']);
    }
  }

  const getFormattedTime = (value: any) => {
    let hours = value.getHours();
    let minutes = value.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? Hour(hours)  : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const Hour = (hours:any) =>{
   return hours >= 10 ? hours: '0'+hours;
  }

  const confirmSlots = () => {
    const error = slotValidation(slotStartDate, slotStartTime, slotEndDate, slotEndTime);
    setSlotError(error);
    if (!error) {
      let value = {
        start_time: moment(slotStartDate + " " + slotStartTime, "DD-MM-YYYY hh:mm A"),
        end_time: moment(slotEndDate+ " " + slotEndTime, "DD-MM-YYYY hh:mm A")
      }
      let filteredData = slots.find((i: any) => i.status == 'confirmed');
  
      UpdateTimeSlot(value, filteredData['id']);
      onCloseChangeSlotModal();
      setTimeout(() => {
        getUnassignedData(InterviewId);
      },[2000]);
    }
  }

  const viewResume = (rowData: any) => {
    const url = getResumeUrl(rowData);
    if (url) {
      window.open(url, "_blank");
    }
  }

  const getFormattedName = (fName: string) => {
    const firstNameLowerCase = fName.toLowerCase();
    const firstNameString = firstNameLowerCase[0].toUpperCase() + firstNameLowerCase.slice(1);
    return firstNameString;
  }
  const columns = ()=>{
    return(
    setColumns === "unassigned" ?
    <MenuItem id="view-profile" onClick={() => { setOpenAssignCandidatesDrawer(true); handleClose(); getUnassignedData(InterviewId) }}>Assign Panel</MenuItem> :
    <MenuItem id="view-profile" onClick={() => { viewCandidateProfile(candidateId, false); handleClose(); }}>View Profile</MenuItem>
    )
  
  }

  const resultPageNumber =(row:any,pageNumber:any)=>{
    if(row){
      return pageNumber * 10 + 1
    }else{
      return 0
    }
  }

  const pageRowNum =(data:any,dataLength:any) =>{
    if(data > dataLength){
      return dataLength
    }else{
      return data
    }
  }

  const onCloseChangeSlotModal = () => {
    setModalSlot(false);
    setSlotStartTime("");
    setSlotEndTime("");
    setSlotStartDate("");
    setSlotEndDate("");
    setSlotError("");
  }

  return (
    <Paper className={classes.root}>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchor}
        onClose={handleClose}
      >
        {columns()}
        
      </Menu>
      <Drawer
        anchor={'right'}
        open={openAssignCandidatesDrawer}
        onClose={() => back()}
        PaperProps={{ style: { width: +window?.innerWidth > 864 ? "50%":'300px' } }}
      >
    
        <div className="draw-cont" >
          <PanelDrawer slots={slots} unassign_panel={unassign_panel} InterviewId={InterviewId} getAssignedPanel={getAssignedPanel} setOpenAssignCandidates={(obj: boolean) => setOpenAssignCandidatesDrawer(obj)} setModalSlot={(obj: boolean) => setModalSlot(obj)} />
        </div>
      </Drawer>
      <Modal
        open={openConfirmSlot}
        aria-labelledby="confirm-slot-modal-title"
        aria-describedby="confirm-slot-modal-description"
        className="jobs-success-model candidate-page"
      >
        <div className="infromation-block candidate-page">
          < img src={SuccessIcon} alt="relative image" style={{ height: "65px", margin: "35px 0" }} />
          <h6>Are you sure want to confirm the slot ?</h6>
          <div style={{ display: "flex", height: "56px", marginTop: "24px" }}>
            <Button onClick={() => {
              setConfirmSlot(false)
            }}
              variant='outlined'
              style={{ fontWeight: 600, marginRight: "5px", flex: 1, borderRadius: "8px" }}
            >
              CANCEL
            </Button>
            <Button onClick={() => { }}
              className="hy-btn"
              style={{ fontWeight: 600, marginLeft: "5px", flex: 1, borderRadius: "8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </Modal>
      {ModalSlot && <Modal
        open={ModalSlot}
        aria-labelledby="change-slot-modal-title"
        aria-describedby="change-slot-modal-description"
        className="jobs-success-model change-slots-wrapper candidate-page"
        style={{ overflowY: 'scroll', }}
      >
        <div style={{ padding: "20px", background: "white", borderRadius: "7px", width: "450px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 15 }}>
            <div style={{ fontSize: "20px", fontWeight: 900 }}>
              Change Slots
            </div>
            <CloseIcon className="pointer" onClick={onCloseChangeSlotModal} />
          </div>
          <Grid key={"a"} container direction="row" spacing={3} >
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group'>
                     <div>
                    <label style={{fontWeight: 500}} className="field-label" >Start Time</label>
                    <label onClick={()=>document.getElementById(`time_1`)?.click()}>
                      <div   style={{cursor:'pointer',display:'flex'}} className="select_date">
                      <div style={{width:'70%'}}> {slotStartTime} <div style={{opacity: 0.6}}> {timeSelectionPlaceholder(slotStartTime)} </div> </div>
                      <div style={{width:'30%'}} ><img src={DropDownIcon} height={6} className='dropIcon' /> </div> 
                    </div>
                    </label>
                  </div>
                  <ThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <TimePicker  className="hidden-date" label="Time"  value={new Date()} id={`time_1`}  onChange={(e: any) =>{
                  const  dataToSet = getFormattedTime(e);
                  setSlotStartTime(dataToSet) }} 
                  />
                  </MuiPickersUtilsProvider>
               </ThemeProvider>
              </div>

            </Grid>
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group' style={{ position: 'relative', cursor: 'pointer' }}>
                <label style={{ fontWeight: 500, cursor: 'pointer' }} className="field-label" >Start Date</label>
                <label onClick={() => document.getElementById(`date_1`)?.click()}>
                  <div style={{ cursor: 'pointer', display: 'flex' }} className="select_date">
                    {slotStartDate} <div style={{ opacity: 0.6 }}> {dateSelectionPlaceholder(slotStartDate)}
                      <img src={CalenderIcon} height={22} style={{ opacity: 0.5, cursor: 'pointer', marginLeft: 80, marginBottom: -4, float: 'right' }} alt="calender" />
                    </div>
                  </div>
                </label>
                < ThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <DatePicker
                      className="hidden-date"
                      style={{ position: "absolute", cursor: 'pointer' }}
                      openTo="year"
                      format="dd/MM/yyyy"
                      views={["year", "month", "date"]}
                      minDate={moment()}
                      maxDate={moment().add(30, "d")}
                      value={moment()}
                      id={'date_1'}
                      onChange={(e) => {
                        setSlotStartDate(`${e?.getDate()}-${e && e?.getMonth() + 1 || 1}-${e?.getFullYear()}`)
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ ThemeProvider>
              </div>
            </Grid>
          </Grid>
          <Grid key={"a"} container direction="row" spacing={3} >
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group'>
              <label style={{ fontWeight: 500 }} className="field-label" >End Time</label>
                <label onClick={()=>document.getElementById(`end_time`)?.click()}>
                      <div   style={{cursor:'pointer',display:'flex'}} className="select_date">
                      <div style={{width:'70%'}}> {slotEndTime} <div style={{opacity: 0.6}}> {timeSelectionPlaceholder(slotEndTime)} </div> </div>
                      <div style={{width:'30%'}} ><img src={DropDownIcon} height={6} className='dropIcon' /> </div> 
                    </div>
                    </label>

              </div>
              <ThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <TimePicker  className="hidden-date" label="Time"  value={new Date()} id={`end_time`}  onChange={(e: any) =>{
                  const  dataToSet = getFormattedTime(e);
                  setSlotEndTime(dataToSet) }} />
                  </MuiPickersUtilsProvider>
               </ThemeProvider>
            </Grid>
            <Grid item xs={6} sm={6} spacing={4}>
              <div className='form-group' style={{ position: 'relative', cursor: 'pointer' }}>
                <label style={{ fontWeight: 500, cursor: 'pointer' }} className="field-label" >End Date</label>
                <label onClick={() => document.getElementById(`date_2`)?.click()}>
                  <div style={{ cursor: 'pointer', display: 'flex' }} className="select_date">
                    {slotEndDate} <div style={{ opacity: 0.6 }}> {dateSelectionPlaceholder(slotEndDate)}
                      <img src={CalenderIcon} height={22} style={{ opacity: 0.5, cursor: 'pointer', marginLeft: 80, marginBottom: -4, float: 'right' }} alt="calender" />
                    </div>
                  </div>
                </label>
                < ThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <DatePicker
                      className="hidden-date"
                      style={{ position: "absolute", cursor: 'pointer' }}
                      openTo="year"
                      format="dd/MM/yyyy"
                      views={["year", "month", "date"]}
                      minDate={moment()}
                      maxDate={moment().add(30, "d")}
                      value={moment()}
                      id={'date_2'}
                      onChange={(e) => {
                        setSlotEndDate(`${e?.getDate()}-${e && e?.getMonth() + 1 || 1}-${e?.getFullYear()}`)
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ ThemeProvider>
              </div>
            </Grid>
            {slotError && <div style={{paddingLeft:12, paddingBottom:6}} className='error-msg'>{slotError}</div>}
          </Grid>
          <div style={{ display: "flex", height: "56px", marginTop: "24px" }}>
            <Button onClick={confirmSlots}
              variant='outlined'
              className='hy-btn candidate-page'
              style={{ fontWeight: 600, flex: 1, borderRadius: "8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </Modal>}
      <TableContainer className={classes.container + " candidate-table-container"}>
        <Table stickyHeader aria-label="sticky table"  >
          <TableHead>
            <TableRow>
              {col.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, textTransform: 'capitalize' }}
                  className="candidate-table-header-cell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? <TableRow key="no-data-for-candidates"> <div style={{ padding: "18px 0 ", fontSize: "16px", fontWeight: 900 }}> No Data Found </div> </TableRow> : null}
            {rows.slice(pageNumber * rowsInPage, pageNumber * rowsInPage + rowsInPage).map((row: any, ind: number) => {
              return (
                <TableRow data-test-id="candidate-table-row" hover role="checkbox" tabIndex={-1} style={{ textTransform: 'capitalize' }} key={row.job_id+"candidate-row"+row.id} onClick={() => viewCandidateProfile(row.id, false)}>  
                {col.map((column: any) => {
                      let value
                      if ("resume" === column.id)
                        value = <div className='candidate-table-btn' >{setColumns !== "unassigned" && 
                        <Tooltip title={getViewResumeTooltipTitle(row)}>
                          <Button className={getViewResumeClass(row)} variant="outlined" onClick={(e: any) => { viewResume(row); e.stopPropagation(); }}>VIEW RESUME</Button> 
                          </Tooltip>
                          } <IconButton style={{ padding: 8 }} onClick={(e: any) => { e.stopPropagation(); handleMenu(e); setCandidateId(row.id); checkId(row) }} > <MoreVertIcon style={{ verticalAlign: "middle" }} /> </IconButton> </div>
                      else if ("job_role" === column.id)
                        value = row.candidate_job?.job_role
                      else if ("applied_company" === column.id)
                        value = row.candidate_job?.company || row.candidate_job?.company_name
                      else if ("first_name" === column.id || "last_name" === column.id)
                        value = getFormattedName(row[column.id])
                      else
                        value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align} style={{ textTransform: 'capitalize' }} >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="candidate-table-footer" >
        <div> Showing {resultPageNumber(rows.length,pageNumber)} to {pageRowNum(pageNumber * rowsInPage + rowsInPage,rows.length)} of {rows.length} entries </div>
        {
          rows.length > 0 ?
            <div className="candidate-table-pagination-container" >
              <div className={`${pageNumber == 0 && "next-prev-btn disable"}  ${pageNumber != 0 &&  "next-prev-btn active"}`} onClick={() => { setPageNumber(pageNumber !== 0 ? pageNumber-1 : pageNumber)}} >Prev</div>
              <Pagination style={{ margin: "0px 10px" }} count={Math.ceil(rows.length / 10)} shape="rounded" page={pageNumber + 1} onChange={(event, pageNumber) => setPageNumber(pageNumber - 1)} hidePrevButton hideNextButton />
              <div className={`${pageNumber + 1 <= (rows.length / 10) && "next-prev-btn active"}  ${pageNumber + 1 > (rows.length / 10) && "next-prev-btn disable"}`} onClick={pageNumber + 1 <= rows.length / 10 ? () => { setPageNumber(pageNumber + 1); document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', }) } : () => { }} >Next</div>
            </div>
            : null
        }
      </div>
    </Paper>
  );

}
export default class Candidates extends React.Component < any ,any > {
    constructor(props:any){
      super(props);
      const locationState = props.history.location;
      this.state = {
        // allCandidateList:"",
        candidateSections: locationState && locationState.tab ? locationState.tab: "applied",
        isOpenCandidateFilter: false,
        candidateList:[],
        RejectedCandidates:[],
        SelectedCandidates:[],
        NotAppliedCandidates:[],
        AppliedCandidates:[],
        selectedRoll:[],
        unassign_data:[],
        UpdateData:false,
        selectedData:false,
        showCandidateDetail:{show:false, candidateId:-1},
      }
    }
    //@ts-ignore
    componentDidMount(){
      const locationProps = this.props.history.location;
      let prevTab = "applied";
      if (locationProps && locationProps.tab) {
        prevTab = locationProps.tab;
        this.setState({...this.state, candidateSections: prevTab});
      }
      this.props.getAllCandidates("", prevTab, true);
      this.props.changeSection(prevTab);
      this.props.getJobRoll();
      this.props.getUnassignedPanel('');
      this.setState({candidateList:this.props?.allCandidateList})
      this.setState({NotAppliedCandidates:this.props?.allCandidateList?.NotAppliedCandidates})
      this.setState({RejectedCandidates:this.props?.allCandidateList?.RejectedCandidates})
      this.setState({AppliedCandidates:this.props?.allCandidateList?.AppliedCandidates})
      this.setState({SelectedCandidates:this.props?.allCandidateList?.SelectedCandidates})
    this.setState({unassign_data:this.props?.unassign_data})
    }

    componentDidUpdate(prevProps: any, prevState: any){
      if(this.props?.allCandidateList!=prevProps.allCandidateList){

        this.setState({NotAppliedCandidates:this.props?.allCandidateList?.NotAppliedCandidates})
        this.setState({RejectedCandidates:this.props?.allCandidateList?.RejectedCandidates})
        this.setState({AppliedCandidates:this.props?.allCandidateList?.AppliedCandidates})
        this.setState({SelectedCandidates:this.props?.allCandidateList?.SelectedCandidates})
       

      }

        if(this.props.unassign_data!==prevProps.unassign_data){
          this.setState({unassign_data:this.props?.unassign_data})
          this.setState({slots:this.props?.slots})
          this.setState({UpdateData:true})
        }
        if(this.props.slots!==prevProps.slots){
          this.setState({slots:this.props?.slots})
        }
    }



    CandidateFilter = (candidateSections:any,JobRoll:any) => {

      const jobRoles = [{name:"UX Designer", id:1}, {name:"Project Manager", id:2}, {name:"Software Engineer", id:3}, {name:"Dot net Developer", id:4}];
          
            const [expanded, setExpanded] = useState<string[]>(['panel1']);
            const [jobRoleFilter, setJobRoleFilter] = useState<any[]>(this.state.selectedRoll);
          
            const toggleAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
              const list = [...expanded];
              if (isExpanded) {
                list.push(panel);
              } else {
                const index = list.findIndex((value) => value === panel);
                if (index > -1) {
                  list.splice(index, 1);
                }
              }
              setExpanded(list);
            };
          
            const handleFilterChange = (e:any) => {
              let data = [...jobRoleFilter];
                if (e.target.checked) {
                    data.push(Number(e.target.name))
                } else {
                    const index = data.findIndex((value:any) => value.toString() === e.target.name.toString());
                    if (index > -1) {
                      data.splice(index, 1);
                    }
                }
                setJobRoleFilter(data); 
            }
          
            const resetFilter = () => {
              setJobRoleFilter([])
              this.setState({...this.state, isOpenCandidateFilter:false,selectedRoll:[]})
              if(candidateSections=='applied'){
                this.setState({AppliedCandidates:this.props?.allCandidateList?.AppliedCandidates})
              }
              if(candidateSections=='rejected'){
                this.setState({RejectedCandidates:this.props?.allCandidateList?.RejectedCandidates})
              }
              if(candidateSections=='selected'){
                this.setState({SelectedCandidates:this.props?.allCandidateList?.SelectedCandidates})
              }
            }
    
            const applyFilter = () => {
              this.setState({...this.state, isOpenCandidateFilter:false})
    
             // candidate_job
    
             if(candidateSections=='applied'){
             let d:any=[];
              jobRoleFilter.forEach((element)=>{
              var filteredData = this.props?.allCandidateList?.AppliedCandidates.filter(function(item:any){
                 return (item?.candidate_job?.job_id) == element;
                 })
                  d=d.concat(filteredData)   
    
               })
               this.setState({AppliedCandidates:d})
              }
    
              if(candidateSections=='rejected'){
    
                 let d:any=[];
                 jobRoleFilter.forEach((element)=>{
                   var filteredData = this.props?.allCandidateList?.RejectedCandidates.filter(function(item:any){
                      return (item?.candidate_job?.job_id) == element;
                    })
                     d=d.concat(filteredData)   
       
                  })
       
                  this.setState({RejectedCandidates:d})
                }
                if(candidateSections=='selected'){
                 let d:any=[];
                  jobRoleFilter.forEach((element)=>{
        
                    var filteredData = this.props?.allCandidateList?.SelectedCandidates.filter(function(item:any){
                     return (item?.candidate_job?.job_id) == element;
                     })
                    d=d.concat(filteredData)   
        
                   })
                  this.setState({SelectedCandidates:d})
                  }
                  if(candidateSections=='unassigned'){
                    var filteredData = this.props?.allCandidateList?.AppliedCandidates.filter(function(item:any){
                     return (item?.candidate_job?.job_id) == jobRoleFilter;
                     })
                this.setState({AppliedCandidates:filteredData})
      }
    
                    let d = jobRoleFilter.filter(item => item);
    
    
                    this.setState({selectedRoll:jobRoleFilter})
              }
            return (
              <div className='candidate-filter-container'>
                <div className='candidate-filter-header'>
                  <div>Filter</div>
                  <IconButton onClick={() =>this.setState({...this.state, isOpenCandidateFilter: false})} size='small'><CloseIcon className="close-icon"/></IconButton>
                </div>
                <div className='candidate-filter-content'>
                  <div className='accordion-container'>
                    <Accordion expanded={expanded.includes("panel1")} onChange={toggleAccordion("panel1")}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordion-icon' />}
                      >
                        <Typography className='accordion-title'>Job Role</Typography>
                      </AccordionSummary>
                      <Divider style={{margin:"0px 16px"}}/>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {JobRoll.map((item:any, index: any) => (
                              <FormControlLabel
                                control={<Checkbox className='checkbox' 
                                checked={jobRoleFilter.includes(item.id)}
                                icon={<img src={CheckBoxOutline} height={20} width={20} />}
                                checkedIcon={<img src={CheckboxSelected} height={20} width={20} />} 
                                name={item.id.toString()} 
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e)}/>}
                                label={item.job_role}
                                className="label"
                                key={index+item.id}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <Grid container spacing={2} style={{padding:26}}>
                  <Grid item xs={6} className='candidate-filter-action'>
                    <Button className='reset-btn' fullWidth variant='outlined' onClick={resetFilter}>RESET</Button>
                  </Grid>
                  <Grid item xs={6} className='candidate-filter-action'>
                    <Button className='apply-btn' fullWidth onClick={applyFilter}>APPLY</Button>
                  </Grid>
                </Grid>
              </div>
            )
    
    }


     viewCandidateProfile = (candidateId:any, showSidebar:boolean) => {
      if (showSidebar && this.state.candidateSections) {
        this.setState({...this.state, showCandidateDetail:{show:true, candidateId:candidateId}})
      } else {
        //@ts-ignore
        this.props.history.push("/Home/CandidateProfile", {id:candidateId, path: "/Home/Candidates", tab: this.state.candidateSections})
      }
    }

    render() {
      const {candidateSections, isOpenCandidateFilter} = this.state;
      const JobRoll=this.props?.joblistData;
                            
      return (
        <>
          <div className="candidate-head-wrapper" >
          <div>
            <span className="admin-jobs-heads"  >
              <input type="radio" name="candidate-heads" id="not-applied" defaultChecked={candidateSections==="notApplied"} />
              <label data-test-id="not-applied-tab" htmlFor="not-applied" className="" onClick={()=>{this.setState({ ...this.state , candidateSections:"notApplied"}); this.props.changeSection("not_applied"); }} >
                Not Applied
                <div className="jobs-status-counter">{this.props?.allCandidateList?.not_applied}</div>
              </label>
            </span>
            <span className="admin-jobs-heads"  >
              <input type="radio" name="candidate-heads" id="applied" defaultChecked={candidateSections==="applied"} />
              <label data-test-id="applied-tab" htmlFor="applied" className="" onClick={()=>{this.setState({ ...this.state , candidateSections:"applied"}); this.props.changeSection("applied"); }} >
                Applied
                <div className="jobs-status-counter">{this.props?.allCandidateList?.applied}</div>
              </label>
            </span> 
            <span className="admin-jobs-heads"  >
              <input type="radio" name="candidate-heads" id="selected" defaultChecked={candidateSections==="selected"} />
              <label data-test-id="selected-tab" htmlFor="selected" className="" onClick={()=>{this.setState({ ...this.state , candidateSections:"selected"}); this.props.changeSection("selected"); }} >
                Selected
                <div className="jobs-status-counter">{this.props?.allCandidateList?.selected}</div>
              </label>
            </span> 
            <span className="admin-jobs-heads"  >
              <input type="radio" name="candidate-heads" id="rejected" defaultChecked={candidateSections==="rejected"} />
              <label data-test-id="rejected-tab" htmlFor="rejected" className="" onClick={()=>{this.setState({ ...this.state , candidateSections:"rejected"}); this.props.changeSection("rejected"); }} >
                Rejected
                <div className="jobs-status-counter">{this.props?.allCandidateList?.rejected}</div>
              </label>
            </span>
            <span className="admin-jobs-heads"  >
              <input type="radio" name="candidate-heads" id="unassigned" defaultChecked={candidateSections==="unassigned"} />
              <label data-test-id="unassigned-tab" htmlFor="unassigned" className="" onClick={()=>{this.setState({ ...this.state , candidateSections:"unassigned"}); this.props.changeSection("unassigned");}} >
                Unassigned Interviews
                <div className="jobs-status-counter">{this.props?.unassign_panel?.length}</div>
                </label>
            </span>
          </div>
          {candidateSections !== "notApplied" && this.props.role=='recruiter' && <div className='candidate-filter-btn' style={{paddingRight:10, paddingTop:24}}>
          </div>}
          </div>
          {isOpenCandidateFilter && <div className='candidate-filter-shadow'>{this.CandidateFilter(candidateSections,JobRoll)}</div>}
          <div className="candidate-table-wrapper">
          { candidateSections==="notApplied" ? <CandidateTable rows={this.state.NotAppliedCandidates} setColumns={candidateSections} slots={this.state?.slots} getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}  UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)} viewCandidateProfile={this.viewCandidateProfile} getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} unassign_panel={this.props.unassign_data}  /> : null}
          { candidateSections==="rejected" ? <CandidateTable rows={ this.state?.RejectedCandidates} setColumns={candidateSections} slots={this.state?.slots}  getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)} UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)} viewCandidateProfile={this.viewCandidateProfile} getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} unassign_panel={this.props.unassign_data} /> : null }
          { candidateSections==="applied" ? <CandidateTable rows={ this.state?.AppliedCandidates} setColumns={candidateSections} slots={this.state?.slots}  getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}  UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)}  viewCandidateProfile={this.viewCandidateProfile} getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} unassign_panel={this.props.unassign_data} /> : null }
          { candidateSections==="selected" ? <CandidateTable rows={ this.state?.SelectedCandidates} setColumns={candidateSections}  slots={this.state?.slots}  getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)} UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)} viewCandidateProfile={this.viewCandidateProfile} getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} unassign_panel={this.props.unassign_data} /> : null }
          { candidateSections==="unassigned" ? <CandidateTable rows={this.props?.unassign_panel} setColumns={candidateSections}   slots={this.state?.slots}  getUnassignedData={(obj:any) => this.props.getUnassignedData(obj)}  UpdateTimeSlot={(obj:any,id:any) => this.props.UpdateTimeSlot(obj,id)} getAssignedPanel={(obj:any) => this.props.getAssignedPanel(obj)} unassign_panel={this.state?.unassign_data} viewCandidateProfile={()=>{}} /> : null } 
  
          </div>
        </>
      )
    }
  }




// Customizable Area End