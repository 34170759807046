import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { parseInt } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showMore: boolean;
  element: any;
  showDialog:{show:string, msg:string}
  jobsList: any[] | ""
  jobDetails: any | ""
  slotsDetails :any | ""
  comfirmSlot: boolean;
  experienceList: any[];
  loader: boolean;
  openNotification: boolean;
  openMeeting: boolean;
  havingAnIssue: boolean;
  InterviewDetails: any;
  toggleBot:any;
  locationList: any;
  selectedClientAdmin: any;
  clientAdminList: any[] | "";
  isGoButtonClicked: boolean;
  InterviewRateMsg:any;
  profileKey?: string;
  open_review_model:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  selected:any=-1
  confirmed:any=this.selected
  id: number = 0;
  optoutId: string = "";
  optoutStatusId: string = "";
  getCandidateInterviewSlotsId: string = "";
  getJobDetailsId: string = "";
  getJobsId: string = "";
  confirmSlotId: string = "";
  changeSlotId: string = "";
  jobId: any = 0;
  experienceListCallId: string = "";
  CandidateReviewId: string = "";
  joinInterviewId: string = "";
  interviewStatusId: string = "";
  panelCandidateSlotId : number = 0;
  currentRoundId: number = 0;
  interviewJoiningIssueApiId: string = "";
  getLocationListApiId: string = "";
  getClientAdminListApiId: string = "";
  updateClientAdminApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showMore:false,
      element: null,
      showDialog:{show:"", msg:""},
      jobsList:"",
      jobDetails:"",
      slotsDetails:"",
      comfirmSlot:false,
      experienceList:[],
      loader: false,
      openNotification: false,
      openMeeting: false,
      havingAnIssue: false,
      InterviewDetails: undefined,
      toggleBot:false,
      locationList: [],
      selectedClientAdmin: "",
      clientAdminList: "",
      isGoButtonClicked: false,
      InterviewRateMsg:[],
      profileKey: "",
      open_review_model:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId === this.interviewJoiningIssueApiId) {
        this.getCandidateInterviewSlots(this.jobId);
        //@ts-ignore
        this.props.havingIssuePopup(false);
        //@ts-ignore
        this.props.havingIssue(false);
        this.setState({...this.state, openMeeting: false, havingAnIssue: false});
        console.log("api called for Interview Issue response submit ")
      }
      
      if (apiRequestCallId === this.experienceListCallId || apiRequestCallId === this.getLocationListApiId ||
        apiRequestCallId === this.getClientAdminListApiId || apiRequestCallId === this.getJobsId ||
        apiRequestCallId === this.getCandidateInterviewSlotsId || apiRequestCallId === this.getJobDetailsId ||
        apiRequestCallId === this.confirmSlotId || apiRequestCallId === this.joinInterviewId ||
        apiRequestCallId === this.interviewStatusId) {
          
        this.receiveGetAPI(apiRequestCallId, responseJson, errorReponse);
      }

      if (apiRequestCallId === this.optoutId || apiRequestCallId === this.optoutStatusId ||
        apiRequestCallId === this.updateClientAdminApiId || apiRequestCallId === this.changeSlotId
        || apiRequestCallId === this.CandidateReviewId) {

        this.receivePutAPI(apiRequestCallId, responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    this.id = Number(localStorage.getItem("userId"))
    if( Number.isNaN(this.id) || this.id === 0 ){
      //@ts-ignore
      this.props.history.push("/")
    }
  }
  
  optoutCandidate = async(values:any,id:number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.optoutId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}/opted_out_assign_candidate`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  optoutStatus = async(values:any,id:number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.optoutStatusId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.optOutApiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCandidateInterviewSlots = async (id:number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCandidateInterviewSlotsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}/get_interview_slot?candidate_id=${this.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobsList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidateDetailAPiEndPoint}${this.id}/job_list_of_candidate`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateClientAdmin = async (id: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    //@ts-ignore
    this.props?.companyId(id);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateClientAdminApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidateDetailAPiEndPoint}${this.id}/update_client_admin`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({client_admin_id: id})
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobDetails = async (id:any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeSlots = async(values:any) => {
    const dateVal = values.date.substring(0, values.date.indexOf("-"));
    const monthVal = values.date.substring(values.date.indexOf("-") + 1, values.date.lastIndexOf("-"));
    const yearVal = values.date.substring(values.date.lastIndexOf("-") + 1, values.date.length);
    const newDate = `${dateVal}-${Number(monthVal) + 1}-${yearVal}`;
    const param = moment(newDate + " " + values.time, "DD-MM-YYYY hh:mm A");
    const end_param = moment(param).add(30, 'minute');

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeSlotId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${values?.id}/change_slot?start_time=${param.toISOString()}&end_time=${end_param.toISOString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  confirmSlot = async (id:any) => {
    this.setState({ loader: true })
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.confirmSlotId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobDetailAPiEndPoint}${id}/confirm_slot`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getExperienceList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.experienceListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExperienceListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLocationList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLocationListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getClientAdminList = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientAdminListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientAdminListApiEndPoint + this.id + "/get_client_admins"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  rateInterviewer = async ( values: any) => {
    
    if (this.state?.slotsDetails && this.state?.slotsDetails.panel) {
      values.panel_id = this.state.slotsDetails.panel.id;
    } else {
      const roundObj = this.state?.slotsDetails.job_interviews_round_details.find((item: any) => (item.id === this.currentRoundId));
      if (roundObj && roundObj.panel) {
          values.panel_id = roundObj.panel.id;
      }
    }
    values.round_id = this.currentRoundId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CandidateReviewId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidateReviewsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  joinInterview = async ( id: any) => {

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.joinInterviewId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.joinInterviewApiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(values)
    // );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  haveIssueSubmit = async ( value: any) => {

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.interviewJoiningIssueApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interviewJoiningIssueApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(value)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInterviewStatus = async ( id: any) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.interviewStatusId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.InterviewStatusApiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveOptOutCandidate = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message ) {
      //Success Case
      if(responseJson.message === "Candidate opted out to job successfully"){

        if(this.state.jobDetails && this.state.jobDetails?.id){
          this.getCandidateInterviewSlots(this.state.jobDetails.id)
          this.optoutStatus({
            interview: {
                round: this.currentRoundId,
                round_status: "round_opted_out",
                interview_status: "opted_out"
            }
        },this.state.slotsDetails.panel_candidate_slot_id)
        }
        
      }
      else {
        //Error Case
        this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^${responseJson.message}`}})
      }
    } else {
      //Error Case
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^Some error occured while optingout candidate try again later`}})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveOptOutStatus = (responseJson: any, errorReponse: any) => {
    if( responseJson?.message === "No Applied Job Found" ){
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^No Applied Job Found`}})
    }
    else if ( responseJson ) {
      //Success Case
      

    } else {
      //Error Case
      console.log("error-IN_GET_Candidate_Details_for_interview")
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetCandidateInterviewSlots = (responseJson: any, errorReponse: any) => {

    console.log('receiveInterviewSlots',)
    if( responseJson?.message === "No Applied Job Found" ){
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^No Applied Job Found`}})
    }
    else if ( responseJson && responseJson?.job_interviews_round_details) {
      //Success Case
        responseJson?.job_interviews_round_details?.forEach((round:any)=>{
          responseJson.interview_rounds?.forEach((Round:any,index:number)=> {
            if(Round.id == round.id){
              responseJson.interview_rounds[index] = round
            } 
          })
      })
        this.setState({ ...this.state, slotsDetails: responseJson })
        const currentRound = responseJson?.job_interviews_round_details.find((item: any) => 
        ((item.round_status === "round_in_progress" || item.round_status === "round_begin") && (item.interview_status === "in_progress" || item.interview_status === "cancelled") && item.all_slots.length > 0))
        this.currentRoundId = currentRound?.id || responseJson.interview_rounds[0].id;
    } else {
      //Error Case
      console.log("error-IN_GET_Candidate_Details_for_interview")
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  
  receiveGetJobsList = (responseJson: any, errorReponse: any) => {
    if ( responseJson && responseJson.applied_jobs && responseJson.applied_jobs.length > 0 ) {
      //Success Case
      this.setState({ ...this.state, jobsList:responseJson.applied_jobs})

    } else {
      //Error Case
      console.log("error-IN_GET_Candidate_Applied_Jobs")
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^Error in fetching jobs`}, jobsList:[] })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveUpdateClientAdmin = (responseJson: any, errorReponse: any) => {
    if(responseJson && responseJson.message === "candidate updated successfully." ){
      console.log("candidate updated successfully.");
      this.getJobsList();
    } else {
      //Error Case
      console.log("error-IN_updating candidate");
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetJobDetails = (responseJson: any, errorReponse: any) => {
    if ( responseJson && responseJson.job ) {
      //Success Case
      this.setState({ ...this.state, jobDetails:responseJson.job})

    } else {
      //Error Case
      console.log("error-IN_GET_Jobs_Details")
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^Error in fetching job details`}, jobDetails:{} })
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveConfirmSlot = (responseJson: any, errorReponse: any) => {
    if ( responseJson && responseJson.message === "This Slot Is Confirmed" ) {
      //Success Case
      this.confirmed = this.selected
      this.setState({ ...this.state, comfirmSlot:false, loader:false })
      this.getCandidateInterviewSlots(this.jobId);
    } else {
      //Error Case
      console.log("error-IN_CONFIRMING_SLOT")
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^Error in confirming slot`}})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveChangeSlots = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.message ) {
      //Success Case
      if(responseJson.message === "Interview Slots updated successfully"){
        this.getCandidateInterviewSlots(this.jobId);
      }
      else {
        //Error Case
        this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^${responseJson.message}`}})
      }
    } else {
      //Error Case
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^Some error occured while changing slots try again later`}})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetExperienceList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.experience_list) {
      this.setState({...this.state, experienceList: responseJson.experience_list});
      console.log("experience list", responseJson);
    } else {
      //Error Case
      console.log("Error getting experience list", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetLocationList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.Locations && responseJson.Locations.length > 0) {
      this.setState({...this.state, locationList: responseJson.Locations});
      console.log("location list", responseJson);
    } else {
      //Error Case
      console.log("Error getting location list", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetClientAdminList = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.client_admins && responseJson.client_admins.length > 0) {
      this.setState({...this.state, clientAdminList: responseJson.client_admins});
      console.log("client admin list", responseJson);
    } else {
      //Error Case
      this.setState({...this.state, clientAdminList: []});
      console.log("Error getting client admin list", responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetInterviewStatus = (responseJson: any, errorReponse: any) => {
    if ( responseJson && responseJson.interview ) {
      this.setState({ ...this.state, InterviewDetails: responseJson.interview });
      this.panelCandidateSlotId = responseJson?.interview?.id
    } 
    else if (responseJson && responseJson.errors) {
      //Error Case
      console.log("Error getting experience list", responseJson);
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^${responseJson.errors}`}})
    }
    else {
      //Error Case
      console.log("Error getting experience list", responseJson);
      this.setState({ ...this.state, showDialog: { show: "true", msg:`apiError^${responseJson.message}`}})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  receiveGetAPI = (apiId: string, responseJson: any, errorReponse: any) => {
    if (apiId === this.experienceListCallId) {
      this.receiveGetExperienceList(responseJson, errorReponse);
    }
    if (apiId === this.getLocationListApiId) {
      this.receiveGetLocationList(responseJson, errorReponse);
    }
    if (apiId === this.getClientAdminListApiId) {
      this.receiveGetClientAdminList(responseJson, errorReponse);
    }
    if (apiId === this.getJobsId) {
      this.receiveGetJobsList(responseJson, errorReponse);
    }
    if (apiId === this.getCandidateInterviewSlotsId) {
      this.receiveGetCandidateInterviewSlots(responseJson, errorReponse);
    }
    if (apiId === this.getJobDetailsId) {
      this.receiveGetJobDetails(responseJson, errorReponse);
    }
    if (apiId === this.confirmSlotId) {
      this.receiveConfirmSlot(responseJson, errorReponse);
    }
    if (apiId === this.joinInterviewId) {
      if (responseJson && responseJson.message === "You have Joined The Interview") {
        console.log("Join interview successfully");
        this.setState({ ...this.state, openMeeting:true })
        //@ts-ignore
        this.props.havingIssue(true);
      } else {
        this.setState({...this.state, showDialog: { show: "true", msg:`apiError^Error while joining interview`}})
      }
    }
    if (apiId === this.interviewStatusId) {
      this.receiveGetInterviewStatus(responseJson, errorReponse);
    }
  }

  receivePutAPI = (apiId: string, responseJson: any, errorReponse: any) => {
    if (apiId === this.optoutId) {
      this.receiveOptOutCandidate(responseJson, errorReponse);
    }

    if (apiId === this.optoutStatusId) {
      this.receiveOptOutStatus(responseJson, errorReponse);
    }

    if (apiId === this.updateClientAdminApiId) {
      this.receiveUpdateClientAdmin(responseJson, errorReponse)
    }

    if (apiId === this.changeSlotId) {
      this.receiveChangeSlots(responseJson, errorReponse);
    }

    if (apiId === this.CandidateReviewId) {
      let msg: string = "";
      if (responseJson && responseJson.data && responseJson.data.type) {
        if (responseJson.data.type === "candidate_reviews") {
          msg = "Your review has been saved successfully";
        }
        else if (responseJson.data.type === "error") {
          msg = "Something went wrong";
        }
      } else if (responseJson && responseJson.message) {
        msg = responseJson.message;
      }
      this.setState({ InterviewRateMsg: msg, open_review_model: true })
      this.getJobDetails(this.jobId);
      this.getCandidateInterviewSlots(this.jobId);
    }
  }

  // Customizable Area End
}