// Customizable Area Start
import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Modal, Grid, CircularProgress, Select, createTheme, Button } from '@material-ui/core';
import { alpha, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link, Switch, Route } from 'react-router-dom';
import {
  Notification, MainLogoWhite, DashboardLogo, Candidates, Jobs, PaymentsLogo, ArrowDown,
  ManagerLogo, Vendor, Interviews, MySchedule, ScheduledInterviews, Company, NotificationWhiteIcon, SuccessIcon, SingleCandidateIcon, BulkUploadIcon, UploadIcon, UserImg, EditIcon, CalenderIcon, DropDownIcon
} from "./assets"
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Loader from "../../../components/src/Loader";
import NotificationBar from "../../LinkShare/src/NotificationBar.web"
import moment from 'moment';

const mainConfigJson = require("../../../framework/src/config");

const getAppendString = (url: any) => {
  const newUrl = url.charAt(0) === "/" ? mainConfigJson.baseURL+url : url;
  return newUrl;
}

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    },
  },
});

import LandingPageController, { Props } from "./LandingPageController";
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import SearchIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';

///////// SideBar's Candidates Page /////////

import CandidatesPage from "./Candidates.web"
import ManagersPage from './Managers.web';
import AddNewCandidate from "./AddNewCandidate.web"

/////////  Create Jobs  /////////

import JobsPage from "../../dashboard/src/Jobs.web";
import CreateJob from "../../dashboard/src/CreateJob.web";
import ViewJobsPage from '../../documentopener/src/JobsSection/ViewJobsPage.web';
/////////  Dashboard  /////////

import Dashboard from "../../dashboard/src/Dashboard.web";

/////////  Candidate Page  /////////

import CandidatePage from '../../CustomisableUserProfiles/src/CandidatePage.web';

/////////  Panels Page  /////////

import PanelPage from '../../catalogue/src/Panels.web';
import Scheduled_Interviews from '../../catalogue/src/Scheduled_Interviews.web';
import Scheduled_PanelInterviews from '../../catalogue/src/Scheduled_PanelInterviews.web';
import Scheduled_CoordinateCalendar from '../../catalogue/src/Scheduled_CoordinateCalendar.web';
import Payment from '../../catalogue/src/Payment.web';


/////////  Profile Page  /////////

import MyProfileWeb from './MyProfile.web';
import ViewCandidateProfile from '../../documentopener/src/JobsSection/ViewCandidateProfile.web';
import AddEditManager from './AddEditManager.web';

/////////  My Schedule Page  ////////

import ManageAvailability from "./ManageAvailability.web";

////////  Add Vendor Page  //////////

import AddEditVendor from './AddEditVendor.web';
import VendorsPage from './Vendors.web';

/////// Interviews Page /////////

import InterviewsPage from "./Interviews.web";

////// Add Panel Page ////////

import AddPanelPage from './AddPanel.web';


/////////   DATA   /////////


///////// Css for main page mui  ///////////

const drawerWidth = 250;

export const useStyles = (theme: Theme) => createStyles({
  grow: {
    flexGrow: 1
  },
  root: {
    display: 'flex',
  },
  title: {
    minWidth: "fit-content",
    marginRight: "16px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "0px",
      marginRight: "0px"
    },
    fontFamily: "'Poppins-SemiBold', sans-serif !important"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: 12,
    maxWidth: "500px",
    minWidth: "120px",
    border: "solid 1px #e7e7e7",
  },
  searchMobile: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    margin: "0px 12px",
    maxWidth: "500px",
    minWidth: "120px",
    border: "solid 1px #e7e7e7",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    height: "100%"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      height: "100%"
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up(768)]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up(768)]: {
      display: "none"
    }
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#083247",
    fontSize: "18px",
    color: "white",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#0832470a",
    minHeight: "100vh",
    boxSizing: "border-box",
    width:"100vw",
    
    [theme.breakpoints.down(768)]: {
      padding: "6px",

    }
  },
  candidateAppBar: {
    background: "#083247",
  }
}
)

const getScreenName = (screen: string) => {
  let screenName = "";
  switch(screen) {
    case "View_Jobs":
      screenName = "Jobs";
      break;
    case "MyProfile":
      screenName = "My Profile";
      break;
    case "Scheduled_PanelInterviews":
      screenName = "My Schedule";
      break;
    case "Vendors":
      screenName = "Recruiters / Vendors";
      break;
    default:
      screenName = screen;
      break;
  }
  return screenName;
}

const getCheckedResult = (screen: any, name: any) => {
  return screen === name || (screen === "View_Jobs" && name === "Jobs") ||
  (screen === "Scheduled_Interviews" && name === "Scheduled Interviews")||(screen === "Scheduled_CoordinateCalendar" && name === "Calendar");
}

const getContainer = (el: any) => {
  return el !== undefined ? () => el?.document?.body : undefined;
}

const getTabName = (img: any, displayName: any, icon: any) => {
  return img ? <img style={{ width: "25px" }} src={img} alt={displayName + "Icon"} /> : icon;
}

const getAppBarClassName = (isDefault: boolean) => {
  return isDefault ? "" : " background "
}

const getFormattedFullName = (iName: string) => {
  let formattedName = "";
  if (iName) {
    const trimmedName = iName.trim();
    if (trimmedName.indexOf(' ') >= 0) {
      const firstNameLower = trimmedName.split(" ")[0].toLowerCase();
      const lastNameLower = trimmedName.slice(trimmedName.indexOf(" ") + 1, trimmedName.length).toLowerCase();
      const firstNameStr = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
      const lastNameStr = lastNameLower[0].toUpperCase() + lastNameLower.slice(1);
      formattedName = `${firstNameStr} ${lastNameStr}`;
    } else {
      const firstNameLower = trimmedName.toLowerCase();
      const firstNameString = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
      formattedName = firstNameString;
    }
  }
  return formattedName
}

const getPathName = (fullPath: string) => {
  let pathName: string | string[] = [];
  if (fullPath && fullPath.includes("/")) {
    pathName = fullPath.split("/")
    pathName = pathName[pathName.length - 1];
    pathName = pathName[0].toUpperCase() + pathName.slice(1);
  }
  return pathName;
}

export class LandingPage extends LandingPageController {
  role: any
  data: any[] = []
  constructor(props: Props) {
    super(props);
    

  }

  //@ts-ignore
  componentDidMount() {

    this.role = localStorage.getItem("role")?.toLowerCase()
    if(!this.role ){
      //@ts-ignore
      this.props.history.push("/")
    }
    if (this.role === "client admin") {
      this.data = [
        { name: "Dashboard", path: "/Home/Dashboard", icon: <DashboardOutlinedIcon />, img: DashboardLogo },
        { name: "Jobs", path: "/Home/Jobs", icon: <WorkOutlineOutlinedIcon />, img: Jobs },
        { name: "Managers", path: "/Home/Managers", icon: <AccountBoxOutlinedIcon />, img: ManagerLogo },
        { name: "Recruiters / Vendors", path: "/Home/Vendors", icon: <GroupAddOutlinedIcon />, img: Vendor },
        { name: "Candidates", path: "/Home/Candidates", icon: <PeopleAltOutlinedIcon />, img: Candidates }
      ]
    }
    else if (this.role === "manager") {
      this.data = [
        { name: "Dashboard", path: "/Home/Dashboard", icon: <DashboardOutlinedIcon />, img: DashboardLogo },
        { name: "Jobs", path: "/Home/Jobs", icon: <WorkOutlineOutlinedIcon />, img: Jobs },
        { name: "Recruiters / Vendors", path: "/Home/Vendors", icon: <GroupAddOutlinedIcon />, img: Vendor },
        { name: "Candidates", path: "/Home/Candidates", icon: <PeopleAltOutlinedIcon />, img: Candidates }
      ]
    }
    else if (this.role === "recruiter") {
      this.data = [
        { name: "Dashboard", path: "/Home/Dashboard", icon: <DashboardOutlinedIcon />, img: DashboardLogo },
        { name: "Scheduled Interviews", path: "/Home/Scheduled_Interviews", icon: <DateRangeOutlinedIcon />, img: ScheduledInterviews }, // logo problem
        { name: "Jobs", path: "/Home/Jobs", icon: <WorkOutlineOutlinedIcon />, img: Jobs },
        { name: "Candidates", path: "/Home/Candidates", icon: <PeopleAltOutlinedIcon />, img: Candidates }
      ];
      this.getRecruiterJobList();
    }
    else if (this.role === "candidate") {
      //@ts-ignore
      this.props.history.push("/Home/Candidate");
    }
    else if (this.role === "coordinator") {
      this.data = [
        { name: "Dashboard", path: "/Home/Dashboard", icon: <DashboardOutlinedIcon />, img: DashboardLogo },
        { name: "Calendar", path: "/Home/Scheduled_CoordinateCalendar", icon: <DateRangeOutlinedIcon />, img: MySchedule },     // logo problem
        { name: "Interviews", path: "/Home/Interviews", icon: <AccountBoxOutlinedIcon />, img: Interviews },  // logo problem
        { name: "Panels", path: "/Home/Panels", icon: <PeopleAltOutlinedIcon />, img: ManagerLogo }            // logo problem
      ]
       //@ts-ignore
      let path = this.props.location.pathname.split("/")

      path = path[path.length - 1]
      path = path[0].toUpperCase() + path.slice(1)

      this.setState({ ...this.state, role: this.role, screen: getScreenName(path) });
    }
    else if (this.role === "panel") {
      this.data = [
        { name: "Dashboard", path: "/Home/Dashboard", icon: <DashboardOutlinedIcon />, img: DashboardLogo },
        { name: "My Schedule", path: "/Home/Scheduled_PanelInterviews", icon: <DateRangeOutlinedIcon />, img: MySchedule }, // logo problem
        { name: "Interviews", path: "/Home/Interviews", icon: <AccountBoxOutlinedIcon />, img: Interviews },    // logo problem
        { name: "Payments", path: "/Home/Payments", icon: <PaymentOutlinedIcon />, img: PaymentsLogo }          // logo problem
      ]

    this.getDefaultManualSlotTiming();
    this.getTotalInterview();
    this.getBankDetail();
    }

    //@ts-ignore
    let path = this.props?.location.pathname.split("/")

    path = path[path.length - 1]
    path = path[0].toUpperCase() + path.slice(1)
    this.setState({ ...this.state, screen: getScreenName(path), role: this.role }) 
    this.getSourceList();
    this.getExperienceList();
    this.getLocationList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    //@ts-ignore
    if (prevProps.location.pathname !== this.props.location.pathname) {
      //@ts-ignore
      let path = this.props.location.pathname.split("/")

      path = path[path.length - 1]
      path = path[0].toUpperCase() + path.slice(1)
      this.setState({ ...this.state, screen: getScreenName(path), role: this.role, searchData: "", jobsSearch: { closed: "", active: ""}, job_data: "", cordinator_change: "", candidateSections: "applied" });  
    }
    if ((prevState.searchData !== this.state.searchData) || (prevState.candidateSections !== this.state.candidateSections)) {
      this.getAllCandidates(this.state.searchData, !this.state.candidateSections ? "applied": this.state.candidateSections);
    }
  }


  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state?.mobileOpen });
  };

  ///////////////   SideBar Btn & its onClick Function  ///////////////

  handleSidebarBtn(screenName: string) {
    this.setState({ ...this.state, screen: screenName, show: "" })
  }
  
  handleChangeRecruiterEvent = (event:any) => {   
    if (event.target.value !== '') {
      let d = event.target.value;
      const index = this.state.recruiterJobList.findIndex((i: any) => i.id == d);
      if (index > -1) {
        const jobData = this.state.recruiterJobList[index];
        this.setState({...this.state, selected_job_rol: jobData.job_role, company_name: jobData.company, job_data: d, select: true});
      }          
    }
  } 

  handleSearchJob = (e: any) => {
    if ( this.state.jobsData === "active" ) {
      this.setState({jobsSearch: { ...this.state.jobsSearch, active: e.target.value }});
    } 
    else if ( this.state.jobsData === "closed" ) {
      this.setState({jobsSearch: { ...this.state.jobsSearch, closed: e.target.value }});
    } 

    this.SearchData(e.target.value);
  }

  getJobSearchText = () => {
    let value = "";
    if (this.state.jobsData === "active") {
      value = this.state.jobsSearch.active;
    } else if (this.state.jobsData === "closed") {
      value = this.state.jobsSearch.closed;
    }
    return value;
  }

  showActiveScreenName = () => {
    return <>
      {this.role !== "candidate" && this.state.screen !== "CandidateProfile" &&
        getPathName(this.props.location.pathname) !== "Scheduled_CoordinateCalendar" && getPathName(this.props.location.pathname) !== "Scheduled_Interviews" &&
        //@ts-ignore
        <Typography data-test-id="screen-name" className={this.props.classes.title} variant="h6" noWrap style={{ fontWeight: 600 }} >
          {getScreenName(this.state.screen)}
        </Typography>}
    </>
  }
  
  showMainWhiteLogo = () => {
    return <>
      {this.role === "candidate" || this.state.screen === "CandidateProfile" &&
        <img src={MainLogoWhite} alt="" style={{ height: 20 }} />
      }
    </>
  }

  showJobDropDownForCalendar = () => {
    return <>
      {getPathName(this.props.location.pathname) === "Scheduled_Interviews" &&
        <div className='view_scheduled_int_dropdown_wrapper'>
          <Select data-test-id="job-dropdown" native className="panel-selection-dropdown" style={{ fontWeight: 'bold' }} IconComponent={(jobDropdownProps) => (<KeyboardArrowDownIcon {...jobDropdownProps} style={{marginLeft: -20, color: "black"}}/>)} name='job-selection' onChange={(e: any) => this.handleChangeRecruiterEvent(e)} value={this.state.job_data} >
            <option value="" disabled>Select a job</option>
            {this.state.recruiterJobList.map((e: any, ind: any) => {
              return (<option key={ind + e.job_role} value={e.id}>{getFormattedFullName(e.job_role)}</option>)
            })
            }
          </Select>
          {localStorage.getItem('is_hiyamee') == 'true' &&
            <div className='comp'><span><img className='comp_icon' src={Company} alt="" /></span>{this.state.company_name}</div>
          }
          {localStorage.getItem('is_hiyamee') == 'false' &&
            <div className='comp'><span><img className='comp_icon1' src={mainConfigJson.baseURL + localStorage.getItem('company_logo')} alt="" /></span>{this.state.company_name}</div>
          }
        </div>
      }
    </>
  }

  showPanelDropDownForCoordinator = () => {
    return <>
      {getPathName(this.props.location.pathname) === "Scheduled_CoordinateCalendar" &&
      <div>
        <Grid item xs={12} sm={12} >
          <div>
            <Select className="panel-selection-dropdown" style={{fontWeight:'bold'}} IconComponent={(panelDropdownProps) => (<KeyboardArrowDownIcon {...panelDropdownProps} style={{marginLeft: -20, color: "black"}}/>)} native name='source' onChange={(e: any) => this.handleChangePanel(e)} value={this.state.cordinator_change} >
              <option value="" disabled>Select a panel</option>
              {this.state.panelListData.map((e: any, ind: any) => {
                return (<option key={e.first_name + "source" + ind} value={e.id} > {getFormattedFullName(`${e.first_name} ${e.last_name}`)}</option>)
              })
            }
            </Select>
          </div>
        </Grid> </div>
      }
    </>
  }

  handleChangePanel = (e:any) => {
    this.setState({cordinator_change:e.target.value})
    this.setState({approveRequestData: e.target.value});  
    this.setState({select:true})
  }

  openManageAvailability = () => {
    this.getDefaultSlotTiming();
    this.getAvailability();
    this.setState({...this.state, showManageAvailabilityDialog: true,manage_availability:false, mobileMoreAnchorEl:null});
  }

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ ...this.state, anchorEl: event?.currentTarget });
  }

  handleMobileMenuClose = () => {
    this.setState({ ...this.state, mobileMoreAnchorEl: null });
  }

  handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ ...this.state, mobileMoreAnchorEl: event.currentTarget });
  }

  jobTopSectionForMobile = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Jobs" && getPathName(this.props.location.pathname) === "Jobs" && (this.role == "manager" || this.role == "client admin" || this.role == "recruiter") && <div>
        <div className={classes.searchMobile} style={{ maxHeight: "42px", borderRadius: 8 }}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={"Search Jobs"}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            value={this.getJobSearchText()}
            inputProps={{ "aria-label": "search" }}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => this.handleSearchJob(e)}
          />
        </div>
        {this.role !== "recruiter" && <div style={{ padding: "12px" }}>
          <Button className='hy-btn'
            fullWidth
            variant="contained"
            style={{ maxHeight: "42px", color: "white", background: "#083247", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={() => { this.setState({ ...this.state, show: true, mobileMoreAnchorEl: null }) }}
          >
            CREATE NEW JOB
          </Button>
        </div>}
        <Divider />
      </div>
      }
    </>
  }

  jobTopSection = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Jobs" && getPathName(this.props.location.pathname) === "Jobs" &&
        (this.role == "manager" || this.role == "client admin" || this.role == "recruiter") &&
        <>
          {this.role !== "recruiter" && <Button className='hy-btn'
            variant="contained"
            style={{ marginRight: "15px", maxHeight: "42px", color: "white", background: "#083247", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={() => { this.setState({ ...this.state, show: true }) }}
          >
            CREATE NEW JOB
          </Button>}
          <div className={classes.search} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Jobs"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              value={this.getJobSearchText()}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => { this.handleSearchJob(e) }}
              data-test-id="job-search-input"
            />
          </div>
        </>
      }
    </>
  }

  candidateTopSectionForMobile = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Candidates" && (this.role == "manager" || this.role == "client admin" || this.role == "recruiter") &&
        <div>
          <div className={classes.searchMobile} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Candidates"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={(e) => this.setState({...this.state, searchData: e.target.value})}
              onKeyDown={(e) => e.stopPropagation()}
              inputProps={{ "aria-label": "search" }}
              value={this.state.searchData}
            />
          </div>
          <div style={{ padding: "12px" }}>
            <Button className='hy-btn'
              fullWidth
              variant="contained"
              style={{ maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
              onClick={() => { this.setState({ ...this.state, show: "createJobDialog", mobileMoreAnchorEl: null }) }}
            >
              ADD CANDIDATES
            </Button>
          </div>
          <Divider />
        </div>}
    </>
  }

  candidateTopSection = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Candidates" &&
        (this.role == "manager" || this.role == "client admin" || this.role == "recruiter") &&
        <>
          <Button className='hy-btn'
            variant="contained"
            style={{ marginRight: "15px", maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={() => { this.setState({ ...this.state, show: "createJobDialog" }) }}
          >
            ADD CANDIDATES
          </Button>
          <div className={classes.search} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Candidates"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={(e) => this.setState({...this.state, searchData: e.target.value})}
              inputProps={{ "aria-label": "search" }}
              value={this.state.searchData}
              data-test-id="candidate-search-input"
            />
          </div>
        </>
      }
    </>
  }

  managerTopSectionForMobile = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Managers" &&
        <div>
          <div className={classes.searchMobile} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Managers"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => this.getAllManagers(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <div style={{ padding: "12px" }}>
            <Button className='hy-btn'
              fullWidth
              variant="contained"
              style={{ maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
              onClick={() => { this.setState({ ...this.state, addManagerStatus: true, managerEditData: {}, managerOpeningMode: "add" , mobileMoreAnchorEl: null }) }}
            >
              ADD NEW MANAGER
            </Button>
          </div>
          <Divider />
        </div>
      }
    </>
  }

  managerTopSection = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Managers" &&
        <>
          <Button className='hy-btn'
            data-test-id="add-manager-btn"
            variant="contained"
            style={{ marginRight: "15px", maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={() => { this.setState({ addManagerStatus: true, managerEditData: {}, managerOpeningMode: "add" }) }}
          >
            ADD NEW MANAGER
          </Button>
          <div className={classes.search} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Managers"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => this.getAllManagers(e.target.value)}
            />
          </div>
        </>
      }
    </>
  }

  recruiterTopSectionForMobile = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Recruiters / Vendors" &&
        (this.role == "manager" || this.role == "client admin") &&
        <div>
          <div className={classes.searchMobile} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Vendors"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => this.getAllVendors(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <div style={{ padding: "12px" }}>
            <Button className='hy-btn'
              fullWidth
              variant="contained"
              style={{ maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
              onClick={() => { this.setState({ ...this.state, openVendorDrawer: true, mobileMoreAnchorEl: null, vendorEditData: {}, vendorOpeningMode: "add" }) }}
            >
              ADD NEW VENDOR
            </Button>
          </div>
          <Divider />
        </div>}
    </>
  }

  recruiterTopSection = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Recruiters / Vendors" &&
        (this.role == "manager" || this.role == "client admin") &&
        <>
          <Button className='hy-btn'
            data-test-id="add-recruiter-btn"
            variant="contained"
            style={{ marginRight: "15px", maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={() => this.setState({ ...this.state, openVendorDrawer: true, vendorEditData: {}, vendorOpeningMode: "add" })}
          >
            ADD NEW VENDOR
          </Button>
          <div className={classes.search} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Vendors"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => this.getAllVendors(e.target.value)}
            />
          </div>
        </>
      }
    </>
  }

  panelTopSectionForMobile = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Panels" && this.role === "coordinator" &&
        <div>
          <div className={classes.searchMobile} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Panels"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => this.setState({ searchPanelKey: e.target.value })}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <div style={{ padding: 12 }}>
            <Button className='hy-btn'
              variant="contained"
              fullWidth
              style={{ maxHeight: "42px", color: "white", background: "#083247", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
              onClick={() => this.setState({ addPanelStatus: true, mobileMoreAnchorEl: null })}
            >
              ADD NEW PANEL
            </Button>
          </div>
          <Divider />
        </div>
      }
    </>
  }

  panelTopSection = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Panels" && this.role === "coordinator" &&
        <>
          <Button className='hy-btn'
            variant="contained"
            style={{ marginRight: "15px", maxHeight: "42px", color: "white", background: "#083247", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={() => this.setState({ addPanelStatus: true })}
          >
            ADD NEW PANEL
          </Button>
          <div className={classes.search} style={{ maxHeight: "42px", borderRadius: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={"Search Panels"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => this.setState({ searchPanelKey: e.target.value })}
            />
          </div>
        </>
      }
    </>
  }

  interviewTopSectionForMobile = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Interviews" &&
        (this.role == "coordinator" || this.role == "panel") &&
        <div>
          <div className={classes.searchMobile} style={{ maxHeight: "42px", borderRadius: 8, marginBottom: 8 }}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Candidate"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => this.setState({ ...this.state, interviewSearchText: e.target.value })}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <Divider />
        </div>
      }
    </>
  }

  interviewTopSection = () => {
    //@ts-ignore
    const { classes } = this.props;
    return <>
      {this.state.screen === "Interviews" &&
        (this.role == "coordinator" || this.role == "panel") &&
        <div className={classes.search} style={{ maxHeight: "42px", borderRadius: 8 }}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search Candidate"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => this.setState({ ...this.state, interviewSearchText: e.target.value })}
            data-test-id="interview-candidate-search"
          />
        </div>
      }
    </>
  }

  myScheduleTopSectionForMobile = () => {
    return <>
      {this.state.screen === "My Schedule" &&
        this.role == "panel" &&
        <div>
          <div style={{ padding: 12 }}>
            <Button
              variant="outlined"
              fullWidth
              className='disable-slot-btn'
              style={{ maxHeight: "42px", whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
              onClick={() => this.setState({ ...this.state, showDisableSlotDialog: true, mobileMoreAnchorEl: null })}
            >
              DISABLE SLOT
            </Button>
          </div>
          <div style={{ padding: "0px 12px 12px" }}>
            <Button className='hy-btn'
              variant="contained"
              style={{ maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
              onClick={this.openManageAvailability}
            >
              MANAGE AVAILABILITY
            </Button>
          </div>
          <Divider />
        </div>
      }
    </>
  }

  myScheduleTopSection = () => {
    return <>
      {this.state.screen === "My Schedule" &&
        this.role == "panel" &&
        <>
          <Button
            variant="outlined"
            className='disable-slot-btn'
            style={{ marginRight: "15px", maxHeight: "42px", whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={() => this.setState({ ...this.state, showDisableSlotDialog: true, slotErrorMessage: "" })}
          >
            DISABLE SLOT
          </Button>
          <Button className='hy-btn'
            variant="contained"
            style={{ marginRight: "15px", maxHeight: "42px", color: "white", background: "#083247", fontWeight: 600, whiteSpace: "nowrap", minWidth: "fit-content", borderRadius: 8 }}
            onClick={this.openManageAvailability}
          >
            MANAGE AVAILABILITY
          </Button>
        </>
      }
    </>
  }

  createJobComponent = () => {
    return <>
      {this.state.screen === "Jobs" && this.state.show === true &&  //@ts-ignore 
        <CreateJob show={this.state.show} hide={() => { this.setState({ ...this.state, show: false }) }} experienceList={this.state.experienceList}
          updateJob={() => this.getActiveJob()} />}
    </>
  }

  managerLoaderComponent = () => {
    return <>
      {this.state.managerLoading && <div className='loader-wrapper'><CircularProgress className="progress" size={70} /></div>}
    </>
  }

  addCandidateComponent = () => {
    return <>
      {this.state.screen === "Candidates" &&
        <AddCandidate
          show={this.state.show} msg={this.state.show === "createJobDialog" ? "AddNewCandidate" : null}
          handleClose={(str: string) => { this.setState({ ...this.state, show: str }) }}
          //@ts-ignore
          bulkUpload={(data: any[]) => { this.bulkUpload(data); }}
          loading={this.state.candidateLoading}
        />
      }
    </>
  }

  disableSlotDialogComponent = () => {
    return <>
      {this.state.showDisableSlotDialog &&
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DisableSlotDialog
            open={this.state.showDisableSlotDialog}
            saveDisableSlot={this.saveDisableSlot}
            handleDialogClose={() => this.setState({ ...this.state, showDisableSlotDialog: false })}
            errorMessage={this.state.slotErrorMessage}
          />
        </MuiPickersUtilsProvider>
      }
    </>
  }

  manageAvailabilityDialogComponent = () => {
    return <>
      {this.state.showManageAvailabilityDialog &&
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ManageAvailability
            show={this.state.showManageAvailabilityDialog}
            manage_availability={()=> this.setState({ ...this.state,manage_availability:true })}
            defaultSlotTiming={this.state.defaultSlotTiming}
            availabilityData={this.state.availabilityData}
            saveDefaultSlot={(values: any) => this.saveDefaultSlot(values)}
            getDefaultManualSlotTiming={(values: any) => this.getDefaultManualSlotTiming()}
            saveAvailability={(values: any) => this.saveAvailability(values)}
            hide={() => this.setState({ ...this.state, showManageAvailabilityDialog: false})} />
        </MuiPickersUtilsProvider>
      }
    </>
  }

  addEditVendorComponent = () => {
    return <>
      {this.state.openVendorDrawer &&
        <AddEditVendor
          show={this.state.openVendorDrawer}
          vendorObject={this.state.vendorEditData}
          hide={() => this.setState({ ...this.state, openVendorDrawer: false, vendorEditData: {}, vendorOpeningMode: "" })}
          saveVendor={(data: any, mode: string) => this.saveVendor(data, mode)}
          vendorOpeningMode={this.state.vendorOpeningMode}
        />
      }
    </>
  }

  addEditManagerComponent = () => {
    return <>
      {this.state.addManagerStatus &&
        <AddEditManager
          open={this.state.addManagerStatus}
          managerObject={this.state.managerEditData}
          close={() => this.setState({ ...this.state, addManagerStatus: false, managerEditData: {}, managerOpeningMode: ""})}
          saveManager={(data: any, mode: boolean) => this.saveNewManage(data, mode)}
          managerOpeningMode={this.state.managerOpeningMode}
        />
      }
    </>
  }

  showNotificationIcon = () => {
    return (<IconButton aria-label="show 17 new notifications" color="inherit" onClick={() => this.setState({ openNotification: true })} >
      <img src={this.role === "candidate" || this.state.screen === "CandidateProfile" ? NotificationWhiteIcon : Notification} alt="notification" style={{ height: "25px" }} />
    </IconButton>)
  }

  showUserProfileIconButton = () => {
    return (<IconButton
      edge="end"
      aria-label="account of current user"
      aria-controls="primary-search-account-menu"
      aria-haspopup="true"
      onClick={(e: any) => this.handleProfileMenuOpen(e)}
      color="inherit"
      data-test-id="profile-icon"
    >
      {
        localStorage.getItem("image") ?
          <img src={localStorage.getItem("image") ? getAppendString(localStorage.getItem("image")) : ""} alt="user" style={{ height: "35px", width: "35px", marginRight: "6px", borderRadius: "50%" }} />
          :
          <AccountCircle key={this.state.profileKey + "account"} style={{ fontSize: "36px", marginRight: "5px", color: this.role === "candidate" || this.state.screen === "CandidateProfile" ? "#fff" : "" }} />
      }
      <div
        style={{
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginRight:18
        }}
        key={this.state.profileKey + "name"}
      >
        <span style={{ fontWeight: "bold", textTransform: "capitalize", marginBottom: "3px", color: this.role === "candidate" || this.state.screen === "CandidateProfile" ? 'white' : "" }}>
          {localStorage.getItem("fname") + " " + localStorage.getItem("lname")}
        </span>
        <span style={{ color: "grey", textTransform: "capitalize" }}> {localStorage.getItem("show_role") || this.state.role} </span>
      </div>
    </IconButton>)
  }

  showUserProfileIconForMobile = () => {
    return (
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        onClick={(e: any) => this.handleProfileMenuOpen(e)}
        color="inherit"
      >
        {
          localStorage.getItem("image") ?
            <img src={localStorage.getItem("image") ? getAppendString(localStorage.getItem("image")) : ""} alt="user" style={{ height: "30px", width: "30px", borderRadius: "50%" }} />
            : <AccountCircle key={this.state.profileKey + "account-1"} style={{ color: this.role === "candidate" || this.state.screen === "CandidateProfile" ? "#fff" : "" }} />
        }
      </IconButton>
    )
  }

  showOtherMenuIconForMobile = () => {
    return (
      <IconButton
        aria-label="show more"
        aria-controls="primary-search-account-menu-mobile"
        aria-haspopup="true"
        onClick={(e: any) => this.handleMobileMenuOpen(e)}
        color="inherit"
      >
        <MoreIcon style={{ color: this.role === "candidate" || this.state.screen === "CandidateProfile" ? "#fff" : "" }} />
      </IconButton>
    )
  }

  showMobileMenuButton = () => {
    //@ts-ignore
    const { classes } = this.props;
    return (
      this.state.screen !== "CandidateProfile" && <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
    )
  }
  
  showNotificationDrawer = () => {
    return (
      //@ts-ignore
      this.state.openNotification && < NotificationBar handleClose={() => { this.setState({ openNotification: false }) }} />
    )
  }

  showCompanyDetailsInDrawer = () => {
    return <>
      {(this.role === "client admin" || this.role === "manager") &&
        <div style={{ position: "absolute", bottom: "0", width: "100%", padding: "10px", boxSizing: "border-box" }} >
          <div style={{ display: "flex", justifyContent: "space-evenly", borderRadius: "6px", background: "#194d66", padding: "10px 0" }}>
            <Grid container direction="row" >
              <Grid item xs={4} sm={4} style={{ textAlign: 'center' }}>
                <img src={localStorage.getItem("company_logo") ? getAppendString(localStorage.getItem("company_logo")) : Company} alt="" style={{ height: "40px", width: "40px", alignSelf: "center", borderRadius: "50%", marginLeft: 5 }} />
              </Grid>
              <Grid item xs={8} sm={8} style={{ textAlign: 'left' }}>
                <div key={this.state.profileKey + "name-1"} style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 5 }} >
                  <span title={`${localStorage.getItem("user_name")}`} style={{ fontSize: "14px", fontWeight: 500, maxWidth: "170px" }} > {localStorage.getItem("user_name")} </span>
                  <span style={{ fontSize: "12px", color: "#aaa" }} > {localStorage.getItem("company_type")} </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      }
    </>
  }

  showAddNewCandidateComponent = () => {
    return <>
      {this.state.show === "AddNewCandidatePage" && this.state.screen === "Candidates" && <MuiPickersUtilsProvider utils={DateFnsUtils} ><AddNewCandidate handleClose={() => this.setState({ ...this.state, show: "" })}
        saveDialog={(show: boolean, msg: string) => this.setState({ ...this.state, dialog: { show: show, msg: msg } })}
        candidateAdded={this.state.name}
        func={(values: any) => this.addCandidateFunc(values)}
        loading={this.state.candidateLoading}
        sourceList={this.state.sourceList}
      /> </MuiPickersUtilsProvider>
      }
    </>
  }

  addNewPanelComponent = () => {
    return <>
      {this.state.addPanelStatus &&
        <AddPanelPage
          show={this.state.addPanelStatus}
          hide={() => this.setState({ ...this.state, addPanelStatus: false })}
          savePanel={(data: any) => this.addPanelUser(data)}
        />
      }
    </>
  }

  render() {
    //@ts-ignore
    const { classes } = this.props
    const SideBarBtn = (props: { path: string, displayName: string, name: string, icon: JSX.Element, checked: boolean, img: any }) => {
      return (
        <Link to={props.path} onClick={() => this.handleSidebarBtn(props.displayName)}>
          <input type="radio" id={props.displayName} name={props.name} defaultChecked={props.checked} />
          <label htmlFor={props.displayName} style={{ alignItems: "flex-end" }}>
            <span className='me-3'>
              {getTabName(props.img, props.displayName, props.icon)}
            </span>
            {props.displayName}
          </label>
        </Link>
      )
    }

    const drawer = (
      <div style={{ position: "relative" }} >
        <div style={{ padding: "20px 25px 10px" }}>
          <img src={MainLogoWhite} alt="" style={{ height: 24 }} />
        </div>
        <form className="sidebar-btn" >
          {this.data.map((item, ind) => {
            return <SideBarBtn name='home-sidebar-btn' key={item.name} displayName={item.name}
              checked={getCheckedResult(this.state.screen, item.name)} path={item.path} icon={item.icon} img={item.img} />
          })}
        </form>
      </div>
    );

    const handleLogout = () => {
      localStorage.clear();
      //@ts-ignore
      this.props.history.push("/")
    }

    let isMenuOpen = Boolean(this.state.anchorEl);

    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

    const viewVendor = (data:any) => {
      this.setState({...this.state, openVendorDrawer: true, vendorEditData: data, vendorOpeningMode: "view"})
    }

    const editVendor = (data:any) => {
      this.setState({...this.state, openVendorDrawer: true, vendorEditData: data, vendorOpeningMode: "edit"})
    }

    const viewManager = (data:any) => {
      this.setState({...this.state, addManagerStatus: true, managerEditData: data, managerOpeningMode: "view"})
    }

    const editManager = (data:any) => {
      this.setState({...this.state, addManagerStatus: true, managerEditData: data, managerOpeningMode: "edit"})
    }

    const updateUserProfile = () => {
      setTimeout(() => {
        this.setState({...this.state, profileKey: new Date().toISOString()});
      }, 1000);
      setTimeout(() => {
        this.setState({...this.state, profileKey: ""});
      }, 2000);
    }

    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={() => this.setState({ ...this.state, anchorEl: null })}
        className="nav-menu"
      >
        <MenuItem style={{ padding: "8px 30px" }}>
        <Link data-test-id="my-profile-btn" to="/Home/MyProfile" onClick={() => { this.setState({ ...this.state, anchorEl: null, screen: `My Profile` }) }}>Profile</Link></MenuItem>
        <MenuItem style={{ padding: "8px 30px" }} onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="primary-search-account-menu-mobile"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={()=> this.handleMobileMenuClose()}
      >
        {this.jobTopSectionForMobile()}
        {this.myScheduleTopSectionForMobile()}
        {this.candidateTopSectionForMobile()}
        {this.managerTopSectionForMobile()}
        {this.recruiterTopSectionForMobile()}
        {this.interviewTopSectionForMobile()}
        {this.panelTopSectionForMobile()}
        <MenuItem onClick={() => {this.setState({...this.state, mobileMoreAnchorEl:null, openNotification: true})}}>
          <IconButton aria-label="show new notifications" color="inherit">
            <img src={Notification} alt="notification" style={{ height: "25px" }} />
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div style={{ display: "flex" }}>
        <Loader loading={this.state.loading} />
        {this.createJobComponent()}
        {
          //@ts-ignore
          <AppBar position="fixed" className={this.role === "candidate" || this.state.screen === "CandidateProfile" ? classes.candidateAppBar : classes.appBar + getAppBarClassName(this.state.defaultAppBar)} color={this.state.defaultAppBar ? "#000" : "#fff"} >
            <Toolbar>
              {this.showMobileMenuButton()}
              {this.showActiveScreenName()}
              {this.showJobDropDownForCalendar()}
              {this.showPanelDropDownForCoordinator()}
              {this.showMainWhiteLogo()}
              <div className={classes.grow} />
              <div className={classes.sectionDesktop} style={{ alignItems: "center" }}>
                {this.jobTopSection()}
                {this.managerTopSection()}
                {this.managerLoaderComponent()}
                {this.panelTopSection()}
                {this.addNewPanelComponent()}
                <SuccessDialogBox show={this.state.dialog.show} msg={this.state.dialog.msg} handleClose={() => this.setState({ ...this.state, dialog: { show: false, msg: "" }, show: false })}
                  getAllCandidates={this.getAllCandidates} onlyDialogClose={() => this.setState({ ...this.state, dialog: { show: false, msg: "" } })}
                  handleDialogClose={(isReset: any) => this.setState({ ...this.state, dialog: { show: false, msg: "" }, show: isReset ? "AddNewCandidatePage" : false })}
                />
                {this.addCandidateComponent()}
                {this.candidateTopSection()}
                {this.myScheduleTopSection()}
                {this.disableSlotDialogComponent()}
                {this.manageAvailabilityDialogComponent()}
                {this.recruiterTopSection()}
                {this.addEditVendorComponent()}
                {this.addEditManagerComponent()}
                {this.interviewTopSection()}
                {this.showNotificationIcon()}
                {this.showUserProfileIconButton()}
              </div>

              <div className={classes.sectionMobile}>
                {this.showUserProfileIconForMobile()}
                {this.showOtherMenuIconForMobile()}
              </div>
            </Toolbar>
          </AppBar>
        }
        {this.showNotificationDrawer()}
        {renderMobileMenu}
        {renderMenu}
        {/*@ts-ignore*/}
        {this.role !== "candidate" && this.state.screen !== "CandidateProfile" ? <>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {/* @ts-ignore */}
            <Hidden mdUp implementation="css">
              <Drawer
                container={getContainer(window)}
                variant="temporary"
                anchor={"left"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {drawer}
                {this.showCompanyDetailsInDrawer()}
              </Drawer>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {drawer}
                {this.showCompanyDetailsInDrawer()}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content +" main-content-wrapper"}>
            <div className={classes.toolbar} />
            {this.showAddNewCandidateComponent()}
              {/* @ts-ignore */}
            <Switch>
              {/* @ts-ignore */}
              <Route path={"/Home/Dashboard"} component={Dashboard} />
              {/* @ts-ignore */}
              <Route path={"/Home/Jobs"} render={() => (<JobsPage activeData={this.state.activeJobsData} getActiveJob={() => this.getActiveJob()} closedData={this.state.closedJobsData} getClosedJob={() => this.getClosedJob()} updateSection={(obj: string) => { this.setState({jobsData:obj},()=>console.log("seted",this.state.jobsData)) }} getActiveFiltersJobs={(obj:any) => this.getActiveFiltersJobs(obj)} getClosedFiltersJobs={(obj:any) => this.getClosedFiltersJobs(obj)} history={this.props.history} location={this.props.location}/>)} />
              {
                /* @ts-ignore */
                <Route path={"/Home/Managers"} render={() => <ManagersPage getAllManagers={this.getAllManagers} editManager={editManager} viewManager={viewManager} deleteManager={this.deleteManager} allManagerList={this.state.allManagerList} history={this.props.history} />} />
              }
              {
                /* @ts-ignore */
                <Route path={"/Home/Vendors"} render={() => <VendorsPage getAllVendors={this.getAllVendors} editVendor={editVendor} viewVendor={viewVendor} deleteVendor={this.deleteVendor} vendorList={this.state.vendorList} />} />
              }
              {/* @ts-ignore */}
              {this.state.show !== "AddNewCandidatePage" && <Route path={"/Home/Candidates"} render={() => <CandidatesPage getAllCandidates={this.getAllCandidates}   getAssignedPanel={(obj:any) => this.getAssignedPanel(obj)}  getUnassignedData={(obj:any) => this.getUnassignedData(obj)}   UpdateTimeSlot ={(obj:any,id:any) =>this.UpdateTimeSlot(obj,id)} slots={this.state.slot_data} getUnassignedPanel={(obj:any) =>this.getUnassignedPanel(obj)}  unassign_panel={this.state.unassign_panel} assign_panel={this.state.assign_panel} unassign_data={this.state.unassign_data} getJobRoll={this.getJobRoll} joblistData={this.state.joblistData}  role={this.role} allCandidateList={this.state.allCandidateList} changeSection={(section: string) => this.setState({...this.state ,candidateSections: section}) } history={this.props.history} />} />}
              {/* <Route path={"/Home/Candidates"} component={CandidatesPage} /> */}
              {/* @ts-ignore */}
              <Route path={"/Home/Panels"} render={() => <PanelPage searchPanelKey={this.state.searchPanelKey} getNewPanelsRequests={this.getNewPanelsRequests} getApprovePanelsRequests={this.getApprovePanelsRequests} newRequestData={this.state.newRequestData} approveRequestData={this.state.approveRequestData}   getDefaultSlotTiming={this.getDefaultSlotTiming} navigation={undefined} id={''} />} />
              {/* @ts-ignore */}
              <Route path={"/Home/MyProfile"} render={() => <MyProfileWeb updateProfile={updateUserProfile}/>} />
              {/* @ts-ignore */}
              <Route path={"/Home/Payments"}    render={() => <Payment searchPanelKey={this.state.searchPanelKey} getNewPanelsRequests={this.getNewPanelsRequests} getApprovePanelsRequests={this.getApprovePanelsRequests} newRequestData={this.state.newRequestData} approveRequestData={this.state.approveRequestData} total_interviews={this.state.total_interviews}  getTotalInterview={this.getTotalInterview} UpdateBankDetail={(data:any,account_id:any) =>this.UpdateBankDetail(data,account_id)} updatebank_detail={this.state.updatebank_detail}  AddBankAccount={(data:any) => this.AddBankAccount(data)} getBankDetail={this.getBankDetail}  bank_details={this.state.bank_details}  getDefaultSlotTiming={this.getDefaultSlotTiming} navigation={undefined} id={''}/>} />
              {/* @ts-ignore */}
              <Route path={"/Home/Scheduled_Interviews"} render={() => <Scheduled_Interviews selectedJob={this.state.job_data} getInterviewRecruiter={this.getInterviewRecruiter}  recruiterInterview={this.state.recruiterInterview}  selected_job_rol={this.state.selected_job_rol} />} />
              {/* @ts-ignore */}
              <Route path={"/Home/Scheduled_PanelInterviews"} render={() => <Scheduled_PanelInterviews default_slot={this.state.default_slot} getDefaultManualSlotTiming={this.getDefaultManualSlotTiming} manage_availability={this.state.manage_availability}  getPanelDisableList={this.getPanelDisableList} getDefaultSlotTiming={this.getDefaultSlotTiming}  panelInterview={this.state.panelInterview}   defaultSlotTiming={this.state.defaultSlotTiming}  getInterviewPanel={this.getInterviewPanel} disable_slot={this.state.disable_slot} navigation={undefined} id={''}  />} />
              {/* @ts-ignore */}
              <Route path={"/Home/Scheduled_CoordinateCalendar"} render={() => <Scheduled_CoordinateCalendar selectedPanel={this.state.cordinator_change} getApprovePanelsList={this.getApprovePanelsList} coordinatorInterview={this.state.coordinatorInterview}   getInterviewCoordinator={this.getInterviewCoordinator} navigation={undefined} id={''}  />} />
              {/* @ts-ignore */}
              <Route path={"/Home/Interviews"} render={() => <InterviewsPage searchText={this.state.interviewSearchText}  unassign_panel={this.state.unassign_panel}   UpdateTimeSlot ={(obj:any,id:any) =>this.UpdateTimeSlot(obj,id)}    slots={this.state.slot_data} unassign_data={this.state.unassign_data} getUnassignedPanel={(obj:any) =>this.getUnassignedPanel(obj)}  getAssignedPanel={(obj:any) => this.getAssignedPanel(obj)} getUnassignedData={(obj:any) => this.getUnassignedData(obj)}  getTotalInterview={this.getTotalInterview} changeSection={(section: string) => { this.setState({ ...this.state, interviewSection: section }); }} history={this.props.history} />} />
              {/* @ts-ignore */}
              <Route path={`/Home/View_Jobs`} render={() => <ViewJobsPage locationList={this.state.locationList} sourceList={this.state.sourceList} experienceList={this.state.experienceList} screen={(str: string) => this.setState({ ...this.state, screen: str })} location={this.props.location} history={this.props.history} />} />
              {/* @ts-ignore */}
              <Route path={"/Home/*"} component={NoPage} />

            </Switch>
          </main>
        </> :
          <main className={classes.content}>
            <div className={classes.toolbar} />
              {/* @ts-ignore */}
            <Switch>
              {/* @ts-ignore */}
              <Route path={"/Home/Candidate"} component={CandidatePage} />
              {/* @ts-ignore */}
              <Route path={"/Home/CandidateProfile"} render={() => <MuiPickersUtilsProvider utils={DateFnsUtils} ><ViewCandidateProfile location={this.props.location} history={this.props.history} updateProfile={()=>{}}/></MuiPickersUtilsProvider>} />
              {/* @ts-ignore */}
              <Route path={"/Home/*"} component={NoPage} />
            </Switch>
          </main>
        }
      </div>
    )
  }
}


function NoPage() {
  return <h1> Work In Progress... </h1>
}

function AddCandidate(params: any) {
{/* @ts-ignore */}
  return <Modal
    open={params.show === "createJobDialog"}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="candidate-success-model"
  >
    <div className="infromation-block">
      {params.loading && <div className='dialog-loading-wrapper'><CircularProgress className="progress" size={40} /></div>}
      {params.msg === "" ? <><h1 > Some error occured </h1> <button onClick={params.handleClose("")} className="hy-btn">Close</button> </> : null}
      {params.msg === "AddNewCandidate" ? <>


        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} >
            <div className="candidate-heading">Add New Candidate <span className="pointer" onClick={() => { params.handleClose("") }}>
              <CloseIcon />
            </span> </div>
          </Grid>

          <Grid item xs={6} sm={6}>
            <div className="candidate-midsection" onClick={() => { params.handleClose("AddNewCandidatePage") }}>
              <img src={SingleCandidateIcon} alt="CandidateLogo" height={66} width={66} />
              Single candidate
            </div>
          </Grid>
          <Grid item xs={6} sm={6} >
            < input type="file" hidden id='blk_upload' multiple accept=".csv"
              onChange={(e) => {
                params.bulkUpload(e.target.files)
              }}
            />
            <label htmlFor="blk_upload">
              <div className="candidate-midsection" >
                <img src={BulkUploadIcon} alt="CandidateLogo" height={66} width={66} />
                Bulk Upload
              </div>
            </label>
          </Grid>
          <div className = "bulk-section"  >
          <Grid item xs={12} sm={12}  >
          <a href= {`${mainConfigJson.baseURL}/candidate.csv`} download  style={{textDecorationLine:'underline'}} >
            <span style={{textDecorationLine:'underline'}}> DOWNLOAD</span> BULK UPLOAD FORMAT 
            </a>
          </Grid>
          </div>
        </Grid>
      </>
        : null}

    </div>
  </Modal>
}
const DisableSlotDialog = ({ open, saveDisableSlot, handleDialogClose, errorMessage }: any) => {
  const [date, setDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [dateObj, setDateObj] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const handleTimeChange = (value: any, state: string) => {
    if (state === "date") {
      setDate(`${value.getDate()}-${value.getMonth() + 1 || 1}-${value.getFullYear()}`)
    } else {
      let hours = value.getHours();
      let minutes = value.getMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      if (hours) {
        hours = hours >= 10 ? hours: '0'+hours;
      } else {
        hours = 12;
      }
      minutes = minutes < 10 ? '0' + minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      state === "fromTime" ? setFromTime(strTime) : setToTime(strTime);
    }
    setDateObj({ ...dateObj, [state]: value });
  }

  const saveSlotDetail = () => {
    const currentDate = new Date();
    const isSameDate = dateObj && dateObj.date && currentDate.getFullYear() === dateObj.date.getFullYear() &&
      currentDate.getMonth() === dateObj.date.getMonth() &&
      currentDate.getDate() === dateObj.date.getDate();

    let errorObj: any = {};
    if (!date) {
      errorObj.date = "Date is Required"
    }
    else if (!fromTime) {
      errorObj.fromTime = "Time is Required"
    }
    else if (fromTime && isSameDate && currentDate.getTime() > dateObj.fromTime.getTime()) {
      errorObj.fromTime = "Time should be greater then the current time"
    }
    else if (!toTime) {
      errorObj.toTime = "Time is Required"
    }
    else if (toTime && isSameDate && currentDate.getTime() > dateObj.toTime.getTime()) {
      errorObj.toTime = "Time should be greater then the current time"
    }
    else if (toTime && dateObj.toTime.getTime() < dateObj.fromTime.getTime()) {
      errorObj.toTime = "To time should be greater then the From time"
    }
    if (!Object.keys(errorObj).length) {
      setErrors({});
      saveDisableSlot({slot:{slot_date:date, slot_to:toTime, slot_from:fromTime}});
    } else {
      setErrors(errorObj);
    }
  }
{/* @ts-ignore */}
  return <Modal
    open={open}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="disable-slot-model"
  >
    <div className="information-block">
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <div className="dialog-heading">Disable Slot<span className="pointer">
            <CloseIcon onClick={handleDialogClose} />
          </span> </div>
        </Grid>
        <Grid item xs={12}>
          <div className='sub-heading' style={{ paddingBottom: 4 }}>Date</div>
          <label className="date-label" onClick={() => { document.getElementById("disable-slot-date-id")?.click() }}>
          
          <div   style={{cursor:'pointer'}} className="select_date">
             {date} <span className='selected_date' > {date? "" : "Select Date" }</span>   <img src={CalenderIcon} height={22} style={{opacity:0.5, marginBottom: -4,float:'right'}} alt="calender" />
          </div>
          </label>
          {errors.date ? <span className="error-msg">{errors.date}</span> : null}
          <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker disablePast minDate={moment().add(1, "days")} className="hidden-date" format="dd/MM/yyyy" label="Date" value={dateObj && dateObj.date || new Date()} id="disable-slot-date-id" onChange={(e: any) => { handleTimeChange(e, "date") }} />
          </ThemeProvider>
        </Grid>
        <Grid style={{ paddingBottom: 4 }} item xs={12} className='sub-heading'>Time</Grid>
        <Grid item xs={6}>
          <label className="date-label" onClick={() => { document.getElementById("from-time")?.click() }}>
            
          <div   style={{cursor:'pointer'}} className="select_date">
             {fromTime} <span className='selected_date' > {fromTime? "" : "Select From" }</span>   <img src={DropDownIcon} width={14} style={{marginBottom: -4,float:'right',marginTop:7}} alt="dropdown" />
          </div>
          </label>
          {errors.fromTime ? <span className="error-msg">{errors.fromTime}</span> : null}
          <ThemeProvider theme={defaultMaterialTheme}>
            <TimePicker className="hidden-date" label="Time" value={dateObj && dateObj.fromTime || new Date()} id="from-time" onChange={(e: any) => handleTimeChange(e, "fromTime")} />
          </ThemeProvider>
        </Grid>
        <Grid item xs={6}>
          <label className="date-label" onClick={() => { document.getElementById("to-time")?.click() }}>
         
             <div   style={{cursor:'pointer'}} className="select_date">
             {toTime} <span className='selected_date' > {toTime? "" : "Select To" }</span>   <img src={DropDownIcon} width={14} style={{marginBottom: -4,float:'right',marginTop:7}} alt="dropdown" />
          </div>
          </label>
          {errors.toTime ? <span className="error-msg">{errors.toTime}</span> : null}
          <ThemeProvider theme={defaultMaterialTheme}>
            <TimePicker className="hidden-date" label="Time" value={dateObj && dateObj.toTime || new Date()} id="to-time" onChange={(e: any) => { handleTimeChange(e, "toTime") }} />
          </ThemeProvider>
        </Grid>
        {errorMessage ? <Grid item xs={12}><span className="error-msg">{errorMessage}</span></Grid> : null}
        <Grid item xs={6} style={{ marginTop: "8px" }} className="dialog-action-body" >
          <Button fullWidth
            variant="outlined"
            className="cancel-btn"
            onClick={handleDialogClose}
          >
            CANCEL
          </Button>
        </Grid>
        <Grid item xs={6} style={{ marginTop: "8px" }} className="dialog-action-body" >
          <Button className='save-btn' fullWidth onClick={saveSlotDetail}>
            SAVE
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
}



function StatusMsg(params:any){
  return <div>
     {params.msg === "dataImported" ? "Data Imported" : null}
         
          {params.msg === "managerAdded" ? "Manager Added" : null}
          {params.msg === "slotAdded" ? "Panel Assign successfully" : null}
          {params.msg === "vendorAdded" ? "Vendor Added" : null}
          {params.msg === "candidateAdded" && params.msg !== "slotAdded" &&
         <div> {"Candidate Added"}</div>
          }
  </div>
}


function StatusMsg2(params:any){
  return <div>
    {params.msg === "managerAdded"&&  <span>
      {'Manager'}
      </span>}
      {params.msg === "vendorAdded" && <span> 
        {"Vendor"} 
        </span>
       }
       {params.msg !== "vendorAdded" && params.msg !== "managerAdded" &&
        <span> 
        {"Candidate"} 
        </span>
       }
    {params.msg === "dataImported" ? "s" : null} data {params.msg === "dataImported" ? "from your file" : null} has been {params.msg === "dataImported" ? "imported successfully." : "added successfully"}
  </div>
}


function SuccessDialogBox(params: any) {

  const onSuccess = () => {
    if (params.msg === "candidateAdded" || "dataImported") {
      params.getAllCandidates();
    } else if (params.msg === "managerAdded") {
      params.getAllManagers();
    }
    params.handleClose("");
  }
{/* @ts-ignore */}
  return <Modal
    open={params.show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="jobs-success-model"
  >
    <div className="infromation-block">
      {params.msg === "" ? <><h1 > Some error occured </h1> <button className="hy-btn">Close</button> </> : null}
      {params.msg == "dataImported" || params.msg == "candidateAdded" || params.msg == "managerAdded"|| params.msg=="slotAdded"|| params.msg == "vendorAdded"? <>
        < img src={SuccessIcon} alt="success-icon" style={{ height: "65px", margin: "35px 0" }} />
        <h4>
           {StatusMsg(params)}
        </h4>
        <h6>{StatusMsg2(params)}</h6>
        <Button onClick={onSuccess}
          className="btn btn-submit hy-btn"
          style={{ fontWeight: 600, width: "96%" }}
        >
          OKAY
        </Button>
      </>
        : null}
      {params.msg.split("^") && params.msg.split("^")[0] && params.msg.split("^")[0] === "apiError" ? <>
        <CloseIcon style={{ color: "red" }} />
        <h4>Error Occured</h4>
        {params.msg.split("^") && params.msg.split("^")[1] ?
          <h6> {params.msg.split("^")[1]} </h6>
          : <h6 >Some error is Occured !<br />
            Please try Again
          </h6>}
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button onClick={() => {
            return params.onlyDialogClose()
          }} variant="outlined" className="hy-btn" fullWidth style={{ borderRadius: 8, height: 56 }}>
            OKAY
          </Button>
        </div>
      </>
        : null}
    </div>
  </Modal>
}


export default withStyles(useStyles, { withTheme: true })(LandingPage)



// Customizable Area End