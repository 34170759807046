// Customizable Area Start
import React, { useEffect } from 'react'
import { createStyles, withStyles, Theme, useTheme, makeStyles } from '@material-ui/core/styles';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import CloseIcon from '@material-ui/icons/Close'; 
import CheckIcon from '@material-ui/icons/Check'; 
import DashboardController from "./DashboardController"
import { Formik, FieldArray } from 'formik';
import {Grid, TextField, Button, Divider, Modal, CircularProgress, Input, Radio} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {DeleteIcon, DeleteChipIcon, RadioIcon, RadioSelectedIcon} from "./assets"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getFullName } from './Dashboard.web';


export const useStyles = (themeOb:Theme) => createStyles({
    mainDiv:{
        [themeOb.breakpoints.down("sm")]:{
        height:"100vh",
        width:"100vw"}
    },
    hidden:{
      display:"none"
    },
    cancelBtn:{
      position: "absolute",
      right: "16px",
      top: "16px",
    }
  }
)

const getSelectedLocation = (ids: any, locationList: any[]) => {
  let locationString = "";
  let locationArray: any[] = [];
  if (ids && ids.length > 0) {
    ids.filter((id: any) => {
      const location = locationList.find((i: any) => i.id == id)?.name || "";
      if (location) {
        locationArray.push(location);
      }
    })
  }
  locationString = locationArray.join(", ");
  return locationString;
}

const scrollToErrorInputField = (errors:any) => {
  const errObject = Object.keys(errors);
  if (errObject.length) {
    const inputObject = document.querySelector(
        `input[name=${errObject[0]}]`
    );
    const dropdownObject = document.querySelector(
        `select[name=${errObject[0]}]`
    );
    if(inputObject) {
      inputObject.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
    } else if(dropdownObject) {
      dropdownObject.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
    }
  }
}

const getCreateJobClass = (show: boolean, hiddenClass: any) => {
  return show ? 'wrapper-dialog fullscreen-dialog' : hiddenClass
}

const showCreateJobLoader = (loading: boolean, mainDivClass: any) => {
  return <>
    {loading &&
      <div className={"circular-loader " + mainDivClass}>
        <CircularProgress style={{ height: "30px", width: "30px", color: '#083247' }} />
      </div>
    }
  </>
}

const showErrorMessageForField = (err: any) => {
  return <span className={err ? 'error-msg' : ""}>{err}</span>
}

const getJobDropdownClass = (val: any) => {
  return `textbox create-job-select-icon ${val ==="" ? "defaultOption":""}`
}

const getRoundNameErrors = (errors: any, index: number) => {
  if (errors && errors.round_name && errors.round_name.length > 0) {
    return errors.round_name[index];
  } else {
    return ""
  }
}

const getRoundDescriptionErrors = (errors: any, index: number) => {
  if (errors && errors.round_description && errors.round_description.length > 0) {
    return errors.round_description[index];
  } else {
    return ""
  }
}

const checkRoundValidation = (values: any, errors: any) => {
  let roundNameErrors = values.interview_rounds.map((i: any) => "");
  let roundDescriptionErrors = values.interview_rounds.map((i: any) => "");
  values.interview_rounds.forEach((round: any, ind: number) => {
    if (!round.name) {
      roundNameErrors[ind] = "Round Name is Required";
    }
    if (!round.description) {
      roundDescriptionErrors[ind] = "Round Details are Required";
    }
  });
  errors.round_name = roundNameErrors;
  errors.round_description = roundDescriptionErrors;
  const isRoundNameError: any = errors.round_name.find((i: any) => (i !== ""));
  const isRoundDescriptionError: any = errors.round_description.find((i: any) => (i !== ""));
  
  if (!isRoundNameError) {
    delete errors.round_name;
  }
  if (!isRoundDescriptionError) {
    delete errors.round_description;
  }
  return errors;
}
export class CreateJob extends DashboardController {
  constructor(props: any) {
    super(props)
      //@ts-ignore
      this.getData = this.getData.bind(this)
    }

    async componentDidMount(){
      this.getJobCategory()
      this.getJobTypes()
      this.getCommonSkills()
      this.getManagersList()
      this.getRecruiterList()
      this.getLocationList()
    }

    data: any = {jobs:{}}

  getData() {
    //@ts-ignore
    console.log("Form DATA", document.getElementById("jobCategory")?.options[document.getElementById("jobCategory")?.selectedIndex].value)
  }
    
  render() {
      //@ts-ignore
    const {classes,show,hide,updateJob} = this.props
    const {errorMsg,showDialog} = this.state
    const setStep = (stepnumber:any) => {
      this.setState({step:stepnumber})
    }
    const validateStep1 = (values: any, errors: any = {}) => {

      if (!values.job_role) {
        errors.job_role = 'Job Role is Required';
      }
      else if (!values.category) {
        errors.category = 'Category is Required';
      }
      else if (!values.company) {
        errors.company = 'Company is Required';
      }
      else if (!values.salaryRange  ) {
        errors.salaryRange = 'Salary Range is Required';
      }
      else if ( !values.salaryRange.includes("-") ) {
        errors.salaryRange = 'Enter Correct Format i.e. 100-1000 ';
      }
      else if (  !( Number(values.salaryRange.split("-")[0]) && Number(values.salaryRange.split("-")[1]) )  ) 
      {
          errors.salaryRange = 'Please Enter in Number format i.e. 100-1000 ';         
      }
      else if (  ( Number(values.salaryRange.split("-")[0]) >= Number(values.salaryRange.split("-")[1]) )  ) 
      {
          errors.salaryRange = 'Number Format should be Val 1(Min)< Val 2(Max ) i.e.100-1000'; 
      }
      else if (!values.location.length ) {
        errors.location = 'Location is Required';
      }
      else if ( ! values.job_type ) {
        errors.job_type = 'Job Type is Required';
      }
      else if ( !values.experience ) {
        errors.experience = 'Experience is Required';
      }
      else if ( !values.description ) {
        errors.description = 'Description is Required';
      }
      else if (values.mandatory_skills.length ==  0 ) {
        errors.mandatory_skills = 'Select atleast one Mandatory Skill';
      }
      else if (values.optional_skills.length ==  0 ) {
        errors.optional_skills = 'Select atleast one Optional Skill';
      }
      checkStep1Validation(errors);
    }

    const checkStep1Validation = (errors: any) => {
      if( ! Object.keys(errors).length ){
        return setStep(2)
      }
      else {
        this.setState({...this.state, errors:{...errors} })
        scrollToErrorInputField(errors);                               
      }
    }

    const companyName = localStorage.getItem("user_name") || "" 
    const initValuesStep1 = { job_role: '', job_id:'', category:'', company:companyName.trim(), salaryRange:'', location: [], job_type:'', experience:'', description:'', mandatory_skills:[], mandatory_skills_id:[], optional_skills:[], optional_skills_id:[],
                              no_of_positions:'', manager:'', recruiter:'', round:"", round_details:"", interview_rounds:[{name:"",description:""}],
                              interviewer_type:'', interviewer_media_mode:'', interviewer_video:'', min_schedules_slot:'', photoshop_ques:'', adobe_ques:'', illustrator_ques:'',  }

    const validateStep2 = (values: any, errors: any = {}) => {
      let errOb: any = errors;
      if ( values.no_of_positions && ! Number(values.no_of_positions) ) {
        errOb.no_of_positions = "No of Position accepts only Numbers";
      }
      else if (!values.manager) {
        errOb.manager = "Manager's field is Required";
      }
      else if (!values.recruiter) {
        errOb.recruiter = "Recruiter's field is Required";
      } else {
        errOb = checkRoundValidation(values, errOb);
      }
      if( ! Object.keys(errOb).length ){
        return setStep(3)
      }
      else {
        this.setState({...this.state, errors: {...errOb} })
        scrollToErrorInputField(errOb);
      }
    }

    const validateStep3 = (values: any,errors: any = {}) => {
      if (! values.interviewer_type) {
        errors.interviewer_type = 'This Field is Required';
      }
      else if (! values.interviewer_media_mode) {
        errors.interviewer_media_mode = 'This Field is Required';
      }
      else if (! values.interviewer_video) {
        errors.interviewer_video = 'This Field is Required';
      }
      else if (! values.min_schedules_slot) {
        errors.min_schedules_slot = 'Minimum Scheduled Slots are Required';
      }
      if( ! Object.keys(errors).length ){
        createJobSubmit(values)
      }
      else {
        this.setState({...this.state, errors: {...errors} })
        scrollToErrorInputField(errors);
      }
      
    }

    const createJobSubmit = (values:any) =>{

      const [min_salary,max_salary] = values.salaryRange.split("-")
      const interview_types_attributes = [ {name:"photoshop", questions:values.photoshop_ques} , 
                                           {name:"adobe", questions:values.adobe_ques} ,
                                           {name:"illustrator", questions:values.illustrator_ques} 
                                         ]
      let job_skill_attribute =  [ ...values.mandatory_skills_id.map((skill: any)=>({skill_id:skill.id ? skill.id : skill.name,optional:false})), ...values.optional_skills_id.map( (skill:any)=>({skill_id:skill.id ? skill.id : skill.name,optional:true})) ]
      let reqPayload ={
        job:{
          job_role:values.job_role ,
          job_type_id: values.job_type ,
          company: values.company ,
          max_salary:max_salary.trim() ,
          min_salary:min_salary.trim() ,
          location: values.location.map((id: any) => id.toString()),
          description: values.description.trim() ,
          no_of_positions:values.no_of_positions ,
          job_category_id:values.category ,
          interviewer:values.interviewer_type ,
          interview_mode:values.interviewer_media_mode ,
          show_interview_video:values.interviewer_video ,
          min_shedule_slots:values.min_schedules_slot ,
          job_skills_attributes:job_skill_attribute,
          interview_rounds_attributes:values.interview_rounds ,
          interview_types_attributes:interview_types_attributes,
          experience: `${values.experience}` ,
          manager_id:values.manager, 
          recruiter_id:values.recruiter, 
      }
      }
      this.createJob(reqPayload)
    }





   const {errors, locationList} = this.state
   const fontColor = {
    style: { color: 'black' }
        }
    return (
      <div className={getCreateJobClass(show, classes.hidden)}>
      {showCreateJobLoader(this.state.loader, classes.mainDiv)}
        <Formik
          initialValues={ initValuesStep1 }
          onSubmit={(values, { setSubmitting }) => {
            this.data.jobs = {...values}
            console.log("values",values)
            
          }}
        >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        setErrors
      }) => (
        <form onSubmit={handleSubmit}>
          <SuccessDialogBox show={showDialog} msg={errorMsg} handleClose={this.toogleDialog} hideJobs={hide} 
          resetPage={()=>{ resetForm() ; setStep(1);}} getJobs={updateJob} update={this.forceUpdate}  />

          <div className={'inner-space-jobscreation '+ classes.mainDiv}>
            <Header step={this.state.step} hide={hide} resetPage={()=>{resetForm();setStep(1);}} />
            {
              this.state.step === 1 && 
              <div className="jobs-forms-body"><div className='hy-forms-block' > 
              <Grid container direction="row" spacing={3} >
                <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job Role<span className="require-field">*</span></label>
                    <TextField className='textbox' placeholder="Enter Job Role" name='job_role' value={values.job_role} onChange={handleChange}/>
                </div>
                {showErrorMessageForField(errors.job_role)}
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job ID</label>
                    <TextField className='textbox' placeholder="Job ID" disabled inputProps={fontColor} />
                    </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable cj'>Category<span className="require-field">*</span></label> 
                    <Select IconComponent={(categoryProps) => (<KeyboardArrowDownIcon  {...categoryProps} />)} className={getJobDropdownClass(values.category)} native name='category' id='category-id' defaultValue={values.category} onChange={handleChange} >
                        <option aria-label="" className='cj-category' value="" disabled>Select Category</option>
                        { this.state?.categories?.job_category?.map((item:any,ind:number)=>
                            <option className='cj-category' value={item.id} key={ind+`${item.name}`} >{getFullName(item.name)}</option>
                          )
                        }
                    </Select>
                    {showErrorMessageForField(errors.category)}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Company</label>
                    <TextField disabled inputProps={fontColor} className='textbox' placeholder="Enter Company Name" name='company' value={values.company} onChange={handleChange}/>
                    {showErrorMessageForField(errors.company)}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Salary Range <span>(LPA)</span><span className="require-field">*</span></label>
                    <TextField className='textbox' placeholder="Enter Salary Range" name='salaryRange' value={values.salaryRange} onChange={handleChange}/>
                    {showErrorMessageForField(errors.salaryRange)}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Location<span className="require-field">*</span></label>
                    <Select
                      renderValue={(selected: any) => {
                        if ((selected as string[]).length === 0) {
                          return "Select Location";
                        }
                        return getSelectedLocation(selected, locationList);
                      }}
                      displayEmpty
                      input={<Input />} multiple IconComponent={(locationProps) => (<KeyboardArrowDownIcon {...locationProps}  />)} className={`textbox create-job-select-icon location ${(values.location && values.location.length > 0) ? "":"defaultOption"}`} name='location' value={values.location} onChange={handleChange} >
                      <MenuItem disabled value="">
                        Select Location
                      </MenuItem>
                        {(locationList && locationList.length > 0) && locationList.map((item: any) => (
                          <MenuItem key={"location-cj"+item.id} value={item.id.toString()}>{getFullName(item.name)}
                        </MenuItem>
                        ))}
                    </Select>
                    {showErrorMessageForField(errors.location)}
                </div>
            </Grid>

            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job Type<span className="require-field">*</span></label>
                    <Select IconComponent={(jobTypeProps) => (<KeyboardArrowDownIcon {...jobTypeProps} />)} className={getJobDropdownClass(values.job_type)} native name='job_type' defaultValue={values.job_type} onChange={handleChange}>
                        <option aria-label="None" value="" disabled>Select Job Type</option>
                        { this.state?.job_types?.map((item:any,ind:number)=>
                            <option className='cj-job-type' value={item.id} key={ind+`${item.name}`} >{getFullName(item.name)}</option>
                          )
                        }                       

                    </Select>
                    {showErrorMessageForField(errors.job_type)}
                </div>
            </Grid>

            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Experience<span className="require-field">*</span></label>
                    <Select IconComponent={(experienceProps) => (<KeyboardArrowDownIcon {...experienceProps}/>)} className={getJobDropdownClass(values.experience)} native defaultValue={values.experience} name='experience' onChange={handleChange} >
                    <option aria-label="None" value="" disabled>Select Experience</option>
                    {/*@ts-ignore*/}
                    {this.props.experienceList.length > 0 ? this.props.experienceList.map((item:any) => (
                      <option key={item.id+"experience"} value={item.id}>{item.years} {`${item.years === "1" ? " Year": " Years"}`}</option>
                      )): null}
                    </Select>
                    {showErrorMessageForField(errors.experience)}

                </div>
            </Grid>

            <Grid item xs={12} sm={12} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job Description<span className="require-field">*</span> </label> 
                    <TextField className='textbox textbox1' rows="4" placeholder="Enter Job Description" multiline name='description' value={values.description} onChange={handleChange} /> 
                    {showErrorMessageForField(errors.description)}
                </div>
            </Grid>
            <Grid item xs={12} sm={12} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Mandatory Skills<span className="require-field">*</span> </label> 
                    <MultipleSelect setFieldValue={setFieldValue} placeholder="Select Mandatory Skills" data={this.state?.categories?.commonSkills.filter((i: any) => (i.name !== "" && i.name))} tagName="mandatory_skills" hChange={handleChange} vals={values.mandatory_skills} />
                    {showErrorMessageForField(errors.mandatory_skills)}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Optional Skills<span className="require-field">*</span></label> 
                    <MultipleSelect setFieldValue={setFieldValue} placeholder="Select Optional Skills" data={this.state?.categories?.commonSkills.filter((i: any) => (i.name !== "" && i.name))} tagName="optional_skills" hChange={handleChange} vals={values.optional_skills} />
                    {showErrorMessageForField(errors.optional_skills)}
                </div>
              </Grid>
        
          </Grid>
        </div>

        <div className="jobs-footer-block">
            <Button variant='outlined' className='btn-100px fw-bold' style={{paddingTop:"8px"}} color='inherit' onClick={()=>{ setErrors({}); hide();    }} >
              CANCEL
            </Button>
            <div className='hy-btn MuiButton-root btn-100px fw-bold' style={{textAlign: "center",paddingTop:"12px"}}  onClick={()=>{ validateStep1(values); }}  >
              NEXT
            </div> 
        </div>

        </div>
   } { this.state.step === 2 && 
            <>
            <div className="jobs-forms-body">
            <div className='hy-forms-block' >  
            <Grid container direction="row" spacing={3} style={{margin:"0 -12"}}  >
                <Grid item xs={12} sm={4} spacing={4}>              
                    <div className='form-group'>
                        <label className='hy-forms-lable'>No of Positions</label>
                        <TextField className='textbox' placeholder="Enter No of Positions" name='no_of_positions' value={values.no_of_positions} onChange={handleChange}/>
                    {showErrorMessageForField(errors.no_of_positions)}
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} spacing={4}>  
                <div className='form-group'>
                        <label className='hy-forms-lable'>Manager<span className="require-field">*</span></label> 
                        <Select IconComponent={(managerProps) => (<KeyboardArrowDownIcon {...managerProps} />)} className={getJobDropdownClass(values.manager)} native name='manager' defaultValue={values.manager} onChange={handleChange}>
                        <option aria-label="None" disabled value="">Select Manager</option>
                        { this.state?.categories?.managers?.map((item:any,ind:number)=>
                            <option className='cj-manager' value={item.id} key={ind+`${item.first_name}`} >{getFullName(`${item.first_name} ${item.last_name}`)}</option>
                          )
                        }
                    </Select>
                    {showErrorMessageForField(errors.manager)}
                    </div>
                    </Grid>

                <Grid item xs={12} sm={4} spacing={4}>              
                    <div className='form-group'>
                        <label className='hy-forms-lable'>Recruiter<span className="require-field">*</span></label> {console.log("values.recruiter",values)}
                        <Select IconComponent={(recruiterProps) => (<KeyboardArrowDownIcon {...recruiterProps} />)} className={getJobDropdownClass(values.recruiter)} native name='recruiter' defaultValue={values.recruiter} onChange={handleChange}>
                        <option aria-label="" disabled value="">Select Recruiter</option>
                        { this.state?.categories?.recruiters?.map((item:any,ind:number)=>
                            <option className='cj-recruiter' value={item.id} key={ind+`${item.first_name}`} >{getFullName(`${item.first_name} ${item.last_name}`)}</option>
                          )
                        }
                    </Select>
                    {showErrorMessageForField(errors.recruiter)}
                    </div>
                </Grid>
                <FieldArray name="interview_rounds">
                    {({ insert, remove, push, replace }) => (
                <Grid item xs={12} sm={12} md={12} lg={12} spacing={4} >
                <span style={{fontSize:"16px", fontWeight:"bold"}}>Interview Rounds</span>
                      {values.interview_rounds.map(( item, index, array ) => { 
                        return <AddRounds roundNo={index+1} hChange={handleChange} delBtn={ array.length != 1 } delF={(ind:number)=>{
                          remove(ind); 
                        }} 
                        insert={replace}
                        value1={values.interview_rounds[index].name } 
                        value2={values.interview_rounds[index].description }
                        error1={getRoundNameErrors(errors, index)}
                        error2={getRoundDescriptionErrors(errors, index)}
                        />
                        })
                      }
                      <div style={{cursor:"pointer",fontWeight:"bold", color: "#083247", fontSize: 14,marginTop:8}} 
                    onClick={()=>{ 
                      push({name:"",description:""})
                    }} 
                    >
                      + ADD ROUND
                    </div>
                </Grid>
                )
              }
            </FieldArray>
              </Grid>
            </div></div> 
            <div className="jobs-footer-block">
            <Button variant='outlined' color='inherit' style={{paddingTop:"8px"}} className='btn-100px fw-bold'
             onClick={()=>{setStep(1)}}
            >
              PREVIOUS
             
            </Button>
            <div className='hy-btn MuiButton-root btn-100px fw-bold' style={{textAlign: "center",paddingTop:"12px"}}  onClick={()=>{ validateStep2(values) }}  >
              NEXT
            </div> 
        </div>
            </>
  } 
           {this.state.step === 3 && <>
            <div className="jobs-forms-body"> <div className='hy-forms-block' >  
            <Grid container direction="row" spacing={3} style={{margin:"0 -12"}}>
                <Grid style={{paddingBottom: 8}} item xs={12} sm={12} spacing={4}>              
                  <div className='form-group'>
                    <div className='fw-bold'>Interviewer<span className="require-field">*</span></div>
                    <div className='hy-radio-group'>
                      <span>
                        <Radio disableRipple id="hiyameeExpert" name="interviewer_type" checked={values.interviewer_type === "Hiyamee Expert"} value="Hiyamee Expert" icon={<img src={RadioIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioSelectedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                        <label htmlFor="hiyameeExpert" className='icon-target pointer'>Hiyamee Expert</label>
                      </span>
                      <span style={{ marginLeft: 30 }}>
                        <Radio disableRipple id="InHouseExpert" name="interviewer_type" checked={values.interviewer_type === "In-House Expert"} value="In-House Expert" icon={<img src={RadioIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioSelectedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                        <label htmlFor="InHouseExpert" className='icon-target pointer'>In-House Expert</label>
                      </span>
                    </div>
                    {showErrorMessageForField(errors.interviewer_type)}
                  </div>
                </Grid>
            
                <Grid style={{paddingBottom: 8}} item xs={12} sm={12} spacing={4}>              
                    <div className='form-group'>
                    <div className='fw-bold'>Interview Media Mode<span className="require-field">*</span></div>
                    <div className="hy-radio-group">
                      <span>
                        <Radio disableRipple id="AudioOnly" name="interviewer_media_mode" checked={values.interviewer_media_mode === "Audio Only"} value="Audio Only" icon={<img src={RadioIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioSelectedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                        <label htmlFor="AudioOnly" className='icon-target pointer'>Audio Only</label>
                      </span>
                      <span style={{marginLeft: 68}}>
                        <Radio disableRipple id="AudioVideo" name="interviewer_media_mode" checked={values.interviewer_media_mode === "Audio-Video"} value="Audio-Video" icon={<img src={RadioIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioSelectedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                        <label htmlFor="AudioVideo" className='icon-target pointer'>Audio-Video</label>
                      </span>
                    </div>
                      {showErrorMessageForField(errors.interviewer_media_mode)}
                    </div>
                </Grid>
            
                <Grid style={{paddingBottom: 8}} item xs={12} sm={12} spacing={4}>              
                    <div className='form-group'>
                    <div className='fw-bold'>Show Interviewer Video<span className="require-field">*</span></div>
                    <div className="hy-radio-group">
                      <span>
                        <Radio disableRipple id="ShowSelfVideo" name="interviewer_video" checked={values.interviewer_video === "Yes"} value="Yes" icon={<img src={RadioIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioSelectedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                        <label htmlFor="ShowSelfVideo" className='icon-target pointer'>Yes</label>
                      </span>
                      <span style={{ marginLeft: 124 }}>
                        <Radio disableRipple id="HideSelfVideo" name="interviewer_video" checked={values.interviewer_video === "No"} value="No" icon={<img src={RadioIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioSelectedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                        <label htmlFor="HideSelfVideo" className='icon-target pointer'>No</label>
                      </span>
                    </div>
                    {showErrorMessageForField(errors.interviewer_video)}
                    </div>
                </Grid>
                                      
                <Grid item xs={12} sm={12} spacing={4}>              
                    <div className='form-group'>
                        <label className='hy-forms-lable'>Minimum Schedules Slots<span className="require-field">*</span></label> 
                        <Select IconComponent={(slotsProps) => (<KeyboardArrowDownIcon {...slotsProps} />)} className={getJobDropdownClass(values.min_schedules_slot)} native name='min_schedules_slot' defaultValue={values.min_schedules_slot}  onChange={handleChange}>
                            <option value="" disabled >Select Minimum Schedules Slots</option>
                            <option value={'1 slot'}> 1 Slot </option>
                            <option value={'2 slots'}> 2 Slot </option>
                            <option value={'3 slots'}> 3 Slot </option>
                        </Select>
                        {showErrorMessageForField(errors.min_schedules_slot)}
                    </div>
                </Grid>
                
                <Grid item xs={12} sm={12} spacing={4}>
                    <div className='form-group'> <Divider/> </div>
                </Grid>
            
                <Grid item xs={12} sm={12} md={12} lg={12} spacing={4}>              
                  <div className='form-group'>
                    <label className='hy-forms-lable'>Interview Type</label> 
                    <AddQuestions text="Photoshop" tagName="photoshop_ques"  hChange={handleChange} /> 
                    <AddQuestions text="Illustrator" tagName="illustrator_ques" hChange={handleChange} /> 
                    <AddQuestions text="Adobe XD" tagName="adobe_ques" hChange={handleChange} /> 
                  </div>
                </Grid>
              </Grid>
            </div>
            </div>

            <div className="jobs-footer-block">
            <Button variant='outlined' color='inherit' style={{paddingTop:"8px"}} className='btn-100px fw-bold' onClick={()=>{setStep(2)}} >
              PREVIOUS
            </Button>
            
            <Button className='hy-btn btn-100px fw-bold' variant='contained' style={{paddingTop:"8px"}} onClick={()=>validateStep3(values)}> SAVE </Button> 
        </div>

            </>
            }
          </div>
      </form>
      )}
    </Formik>
    </div>
    )
  }
}

export default withStyles(useStyles, { withTheme: true })(CreateJob)

function Header(prams:{step:number,hide:any,resetPage:any}) {
  return (<>
    <div className="jobs-header-block">
      <div className="header-item mb-3">
        <span className='fw-bold' >Create New Job</span>
        <span className="pointer" onClick={()=> { 
          prams.resetPage();
          prams?.hide(); }}>  
          <CloseIcon/>
        </span>
      </div>
    <div className='step-counter mb-3'> Step {prams.step} of 3 : { prams.step === 1 ? "Job Details" : prams.step===2 ? "Interview Details" : prams.step===3 ? "Configure Interview Panels" : "" } </div>
    </div>
  </>)
}

function AddRounds({roundNo, hChange, delBtn, delF, insert, value1, value2, error1, error2}:{roundNo:number, hChange:any, delBtn:boolean, delF:any,insert:any , value1:string, value2:string, error1?: string, error2?: string }) {
  return <>
    { roundNo > 1 ? <Divider/> : null }
    <Grid item xs={12} sm={12} md={12} lg={12} spacing={4}>              
      <div className='form-group'>
        <label className='hy-forms-lable' style={{position: 'relative'}}>
          {"Round "+roundNo}
          <span className="require-field">*</span>
          { roundNo > 1 ?
            <span style={{color:"#cf1d1de0",position:"absolute",right:8,fontSize:"12px"}} className="pointer" onClick={()=>{delF(roundNo-1); }}> 
              <img src={DeleteIcon} alt="DeleteIcon" style={{height:"13px",marginRight:"4px"}}  />
              DELETE ROUND
            </span>
            : null
          }
        </label> 
        <TextField name={`interview_rounds.${roundNo-1}.name`} className='textbox' placeholder={`Enter Round ${roundNo} Name`} multiline value={value1} 
        onChange={
          (e)=>{
            insert(roundNo-1,{name:e.target.value,description:value2})
            }} /> 
      </div>
      {showErrorMessageForField(error1)}
    </Grid>

    <Grid item xs={12} sm={12} spacing={4}>              
      <div className='form-group'>
        <label className='hy-forms-lable'> Details<span className="require-field">*</span></label> 
        <TextField className='textbox textbox1' rows="4" value={value2} placeholder="Enter Details" multiline defaultValue={value2} name={`interview_rounds.${roundNo-1}.description`} 
          onChange={(e)=>{
            insert(roundNo-1,{name:value1,description:e.target.value})
          }}/> 
      </div>
      {showErrorMessageForField(error2)}
    </Grid>
  </>
}

function AddQuestions({text,tagName,hChange}:{text: string, tagName: string, hChange:any}){
  const [state,setState] = React.useState<boolean>(false)
  return     <Grid item xs={12} sm={12} spacing={4} style={{marginTop:10}} >              
            <div style={{position: 'relative',height:"33px",padding:"8 10"}} className="add-content-input">
              {text}
              <span className='add-btn' onClick={()=> setState(!state) } >{state ? "-" : "+" }</span>
            </div>
            {state ? <TextField className='textbox textbox1 mt-3' rows="4" placeholder="Add Questions" name={tagName} onChange={hChange} multiline/> : null }
          </Grid>
}

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0
    },
    formControl: {
      margin : "0px",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop:10
    },
    chip: {
      margin: theme.spacing(0.5),
      color: "#083247",
      fontWeight:"bold",
      borderRadius: 4,
      marginLeft:0,
    },
    btn:{
        color : "#083247",
    },
    fullWidth:{
        minWidth:"100%"
    },
    itemList:{
      maxHeight:"110px ",
    }
  })
);

function MultipleSelect({setFieldValue,placeholder,data,tagName,hChange,vals}:{setFieldValue:any,placeholder:string,data:any[],tagName:string,hChange:any,vals:any[]}) {
  const handleDeleteMy = (chipToDelete: string) => async() => { 
    const restChips = skillName.filter((chip) => chip !== chipToDelete)
    setSkillName(restChips);
    setFieldValue(`${tagName}`,restChips);
  };

  const handleChange = (event: any) => {
    if (!skillName.includes(event.target.value[0])) {
      setFieldValue(`${tagName}`,[...vals, event.target.value[0]]);
      setSkillName([...skillName, event.target.value[0]]);
      setTempValue([]);
    }
  };

  const handleOnBlur = (value: any) => {
    if (!skillName.includes(value)) {
      setFieldValue(`${tagName}`,[...value, value]);
      setSkillName([...skillName, value]);
    }
  };
const classes = Styles();
const [skillName, setSkillName] = React.useState<string[]>( [ ...vals ]);
const [tempValue, setTempValue] = React.useState<string[]>([]);

useEffect(()=>{
  console.log("aaaa1",skillName)
  let ids = skillName.map((name)=>{
    let filteredData = data.filter((d)=> d.name === name );
    if (name && !filteredData.length) {
      filteredData.push({name: name, id: ""});
    }
    console.log("filteredData",filteredData)
    return filteredData[0] ? filteredData[0] : skillName.slice(-1)[0]
  })
  console.log("aaaa",ids)
  setFieldValue(`${tagName}_id`,ids)
},[skillName])

return (
  <> 
      <FormControl
        variant="outlined"
        className={`${classes.formControl} ${classes.fullWidth}`}
      >
      <Autocomplete
        forcePopupIcon={true}
        popupIcon={<KeyboardArrowDownIcon style={{color: "#083247"}}/>}
        fullWidth
        multiple
        value={tempValue}
        selectOnFocus
        handleHomeEndKeys
        options={data.map((val, key) => getFullName(val.name))}
        onChange={(e,value) => { handleChange({target:{value}}) }}
        onBlur={(event:any) => {setTempValue([]);
          if (event.target.value) {
            handleOnBlur(event.target.value)
          }
        }}
        renderOption={(option) => option}
        freeSolo
        getOptionDisabled={(option) => skillName.includes(option)}
        renderInput={(params) => (
          <TextField {...params} InputProps={{
            ...params.InputProps
          }} name={tagName} placeholder={!tempValue.length ? placeholder : ""} onChange={()=>console.log("options",params)} variant="outlined" className="text-input multi-select-input"/>
        )}
      />
      <div className={classes.chips}>
        {skillName.map((value, key) => (
          <Chip
            key={value + key}
            label={value}
            onDelete={ handleDeleteMy(value) }
            className={`skills-chip ${classes.chip}`}
            deleteIcon={<img src={DeleteChipIcon} alt="delete"/>}
          />
        ))}
      </div>
    </FormControl>
  </>
);
}

function SuccessDialogBox(params:any) {

  return <Modal
    open={params.show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="jobs-success-model"
    >
      <div className="infromation-block">
      {params.msg ==="" ? <><h1 > Some error occured </h1> <button className="hy-btn">Close</button> </> : null }
         {params.msg ==="success" ? <>
          <CheckIcon style={{color: "green"}}/> 
          <h4>Job Created</h4>
        <h6 >Job has been Created successfully</h6>
        <Button onClick={()=>{
          params.getJobs()
          setTimeout(()=>{
            params.handleClose(""); 
            params.resetPage();
            params.hideJobs(); 
          },1000)
        }} className="btn btn-submit hy-btn">
          OKAY
        </Button>
        </>
          : null }
         {params.msg ==="error" ? <>
         <CloseIcon style={{ color: "red" }}/> 
         <h4>Close Job?</h4>
        <h6 >Are you sure want to close the job ?<br/>
          All the running interview process will be closed
        </h6>
        <div style={{ display:"flex",justifyContent:"space-evenly" }}>
        <Button onClick={()=>params.handleClose("")} variant="outlined" className="hy-btn">
          CANCEL
        </Button>
        <Button onClick={()=>{params.handleClose(""); params.resetPage(); params.hideJobs() }} variant="contained" className=" hy-btn">
          CONFIRM
        </Button>
        </div>
         </>
         : null }
         {params.msg && params.msg.split("^")&& params.msg.split("^")[0] === "apiError" ? <>
         <CloseIcon style={{ color: "red" }}/> 
         <h4>Error Occured</h4>
        <h6 >{params.msg.split("^") && params.msg.split("^")[1] ? params.msg.split("^")[1]  : `Some error is Occured !<br/>
          Please try Again`}
        </h6>
        <div style={{ display:"flex",justifyContent:"space-evenly" }}>
        <Button onClick={()=>params.handleClose("")} variant="outlined" className="hy-btn">
          CANCEL
        </Button>
        <Button onClick={()=>{params.handleClose(""); }} variant="contained" className=" hy-btn">
          CONFIRM
        </Button>
        </div>
         </>
         : null }
        
      </div>
  </Modal>
}
// Customizable Area End
