// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Documentation from "../../blocks/Documentation/src/Documentation";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Videos from "../../blocks/videos/src/Videos";
import FullTeamVisibility2 from "../../blocks/FullTeamVisibility2/src/FullTeamVisibility2";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TwilioIntegration2 from "../../blocks/TwilioIntegration2/src/TwilioIntegration2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Chatbot6 from "../../blocks/Chatbot6/src/Chatbot6";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import MatchAlgorithm2 from "../../blocks/MatchAlgorithm2/src/MatchAlgorithm2";
import GuestLogin3 from "../../blocks/GuestLogin3/src/GuestLogin3";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContextTagging from "../../blocks/ContextTagging/src/ContextTagging";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import GroupVideoCall2 from "../../blocks/GroupVideoCall2/src/GroupVideoCall2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PerformanceTracker from "../../blocks/PerformanceTracker/src/PerformanceTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Archive from "../../blocks/Archive/src/Archive";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import CfRolesPermission4 from "../../blocks/CfRolesPermission4/src/CfRolesPermission4";


import EmailAccountLoginWeb from "../../blocks/email-account-login/src/EmailAccountLogin.web";
import ForgetPasswordWeb from "../../blocks/forgot-password/src/ForgetPassword.web";
import { Route, Switch, Link } from "react-router-dom"
import Home from "../../blocks/landingpage/src/LandingPage.web";
import "./global.css"
import SignUpPageWeb from "../../blocks/email-account-login/src/SignUpPage.web";
import CandidateLandingPage from "../../blocks/CustomisableUserProfiles/src/CandidateLandingPage.web";
import PrivacyPolicy from "../../blocks/email-account-login/src/PrivacyPolicy.web";

const routeMap = {
CfRolesPermission4:{
 component:CfRolesPermission4,
path:"/CfRolesPermission4"},

VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
ProjecttaskTracking2:{
 component:ProjecttaskTracking2,
path:"/ProjecttaskTracking2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Videos:{
 component:Videos,
path:"/Videos"},
FullTeamVisibility2:{
 component:FullTeamVisibility2,
path:"/FullTeamVisibility2"},
Customform:{
 component:Customform,
path:"/Customform"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TwilioIntegration2:{
 component:TwilioIntegration2,
path:"/TwilioIntegration2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
Chatbot6:{
 component:Chatbot6,
path:"/Chatbot6"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
MatchAlgorithm2:{
 component:MatchAlgorithm2,
path:"/MatchAlgorithm2"},
GuestLogin3:{
 component:GuestLogin3,
path:"/GuestLogin3"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContextTagging:{
 component:ContextTagging,
path:"/ContextTagging"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
TeamBuilder:{
 component:TeamBuilder,
path:"/TeamBuilder"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
GroupVideoCall2:{
 component:GroupVideoCall2,
path:"/GroupVideoCall2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Archive:{
 component:Archive,
path:"/Archive"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }
  
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  constructor(props){
    super(props)
    this.state={
      firstName:"",
      lastName:"",
      role:""
    }
    this.updateUserData = this.updateUserData.bind(this)
  }
  updateUserData(firstName,lastName,role){
    console.error(firstName,lastName,role);
    this.setState({ ...this.state, firstName:firstName, lastName:lastName , role:role})
  }
  render() {
    console.log("APP",this.props)
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View style={{ height: '100vh' }}>
        {/* <TopNav /> */}
        {/* {WebRoutesGenerator({ routeMap })} */}
        {/* <ModalContainer /> */}
        <Switch>
        <Route exact path="/" component={EmailAccountLoginWeb} />
        <Route exact path="/login" component={EmailAccountLoginWeb} />
          {/* <Route  path="/login" render={()=>(<EmailAccountLoginWeb updateUser={this.updateUserData}/>)}  >  </Route> */}
          <Route  path="/forget-password" component={ForgetPasswordWeb} />
          <Route  path="/sign-up" component={SignUpPageWeb} />
          <Route  path="/Home" component={Home }  />
          <Route  path="/Candidate" component={ CandidateLandingPage }/>
          <Route path="/Privacy" component={PrivacyPolicy}/>
          </Switch>
        </View>
    );
  }
}


function Sidebar(params) {
  return<>
    Candidate's page
  </>
}

// function MainHead(params) {
//   return(<h3>
//     Interview<span style={{color:"yellow"}}>Panels</span>.ai
//   </h3>)
// }

// function SidebarChilds(params) {
//   return params.childs?.map( (name) =>
//     <div className="sidebar-child">
//       {name}
//     </div> )
// }

export default App;