export const DounutGraph = require("../assets/dounutGraph.png");
export const StackedBarGraph = require("../assets/stackedBarGraph.png");
export const ActiveJobs = require("../assets/dashboard/activeJobs.png");
export const Candidates = require("../assets/dashboard/candidates.png");
export const Managers = require("../assets/dashboard/managers.png");
export const Vendors = require("../assets/dashboard/vendors.png");
export const ClosedJobs = require("../assets/dashboard/closedJobs.png");
export const CompletedInterviews = require("../assets/dashboard/completedInterviews.png");
export const DashboardIcon = require("../assets/dashboard/dashboard.png");
export const DownArrowGraph = require("../assets/dashboard/downArrowGraph.png");
export const GraphIcon = require("../assets/dashboard/graphIcon.png");
export const NewJobs = require("../assets/dashboard/newJobs.png");
export const Panels = require("../assets/dashboard/panels.png");
export const ScheduledInterviews = require("../assets/dashboard/scheduledInterviews.png");
export const SelectedCandidates = require("../assets/dashboard/selectedCandidates.png");
export const PanelScheduledInterviews = require("../assets/dashboard/panelsScheduledInterviews.png");
export const PendingEvaluation = require("../assets/dashboard/pendingEvaluation.png");
export const TodaysInterview = require("../assets/dashboard/todaysInterview.png");
export const TotalEarning = require("../assets/dashboard/totalEarning.png");


export const TodayInterviews = require("../assets/dashboard/todayInterviews.png");
export const TotalCandidates = require("../assets/dashboard/totalCandidates.png");
export const DeleteIcon = require("../assets/createJobs/deleteIcon.png");
export const CoordinatorStackedBarGraph = require("../assets/coOrdinatorStackedBarGraph.PNG");
export const CoordinatorDonutGraph = require("../assets/coOrdinatorDonutGraph.PNG");
export const PaymentStatisticsGraph = require("../assets/paymentStatisticsGraph.PNG");
// export const AppliedJobAvatar1 = require("../assets/appliedJobAvatar1.png");
// export const AppliedJobAvatar2 = require("../assets/appliedJobAvatar2.png");
// export const AppliedJobAvatar3 = require("../assets/appliedJobAvatar3.png");
// export const AppliedJobAvatar4 = require("../assets/appliedJobAvatar4.png");
// export const ChatIcon = require("../assets/chatIcon.png");
export const CompanyIcon = require("../assets/dashboard/companyImage.png");
export const PositionIcon = require("../assets/dashboard/positionImage.png");
export const TimeIcon = require("../assets/dashboard/timeImage.png");


export const FilterLogo = require("../assets/FilterLogo.png");
export const CheckBoxOutline = require("../assets/checkBox.png");
export const CheckboxSelected = require("../assets/checkBoxSelected.png");
export const DeleteChipIcon = require('../assets/deleteChipIcon.png')
export const RadioSelectedIcon = require('../assets/radioSelectedIcon.png')
export const RadioIcon = require('../assets/radioIcon.png')




