export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const AppliedJobAvatar1 = require("../assets/appliedJobAvatar1.png");
export const AppliedJobAvatar2 = require("../assets/appliedJobAvatar2.png");
export const AppliedJobAvatar3 = require("../assets/appliedJobAvatar3.png");
export const AppliedJobAvatar4 = require("../assets/appliedJobAvatar4.png");
export const ChatIcon = require("../assets/chatIcon.png");
// export const SuccessIcon = require('../assets/successIcon.png')
export const RejectedIcon = require('../assets/rejectedIcon.png')
export const SuccessIcon = require('../../landingpage/assets/successIcon.png')
export const NotificationWhiteIcon = require('../../landingpage/assets/notificationWhiteLogo.png')
export const MainLogoWhite = require('../../landingpage/assets/mainLogoWhite.png')
export const Company = require('../assets/company.png')
export const Experience = require("../../documentopener/assets/experience.png");
export const JobType = require("../../documentopener/assets/jobType.png");
export const Position = require("../../documentopener/assets/positions.png");
export const Salary = require("../../documentopener/assets/salary.png");
export const CreatedOn = require('../../documentopener/assets/createdOn.png')

export const InfoIcon = require("../../documentopener/assets/infoIcon.png");
export const DownIcon = require("../assets/downArrow.png");
export const DropDownIcon = require('../assets/dropDownIcon.png')

export const CompanyIcon = require("../../documentopener/assets/companyLogo.png");
export const WarningIcon = require('../../documentopener/assets/warningIcon.png')
export const SelectedIcon = require('../../documentopener/assets/selected.png')
export const ScheduleIcon = require('../../documentopener/assets/scheduleIcon.png')
export const CalenderIcon = require('../../documentopener/assets/calender.png')
export const RadioOutlinedIcon = require('../assets/radioOutlinedIcon.png')
export const RadioCheckedIcon = require('../assets/radioCheckedIcon.png')
export const Notification = require('../assets/notification.png');
export const RatingStar = require('../assets/ratingStar.png');

export const CandidateRejected = require('../assets/candidateRejected.png');
export const CandidateSelected = require('../assets/candidateSelected.png');