import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Data: any[];
  location?: any;
  history?: any;
  updateSection?: any;
  getActiveJob?: any;
  getClosedJob?: any;
  getActiveFiltersJobs?: any;
  getClosedFiltersJobs?: any;
  activeData?: any;
  closedData?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  step: number;
  showDialog: boolean;
  active_closed_jobs: string;
  createJobsAddRounds:any[];
  allJobsList: any;
  errors: any;
  categories : any;
  job_types : any[];
  isOpenJobFilter: boolean;
  closedJobFilter:any;
  activeJobFilter:any;
  companyList:any[];
  loader:boolean;
  approvePanelCount: any;
  newRequestPanelCount: any;
  activeJobCount: any;
  closedJobCount: any;
  allCandidateList: any;
  activeJobs: any;
  interviewList: {
    todays: any,
    upcoming: any,
    completed: any,
    reviewPending: any,
  };
  recentInterviewsForCoordinator: any;
  interviewSummaryForCoordinator: {
    total_interviews: any,
    completed_interviews: any,
    cancelled_interviews: any,
    scheduled_interviews: any
  };
  jobSummaryForDashboard: any;
  jobSummaryForRecruiterDashboard: any;
  interviewSummaryForRecruiter: {
    selected: any,
    rejected: any,
    in_progress: any,
  };
  interviewSummaryForManager: {
    completed: any,
    rejected: any,
    in_progress: any,
  };
  update_data:boolean;
  openUpcomingInterviews: boolean;
  completedInterviewList: any;
  recentInterviewsForPanel: any;
  recruitingStatistics: any;
  locationList: any;
  clientAdminList: any;
  selectedClientAdmin: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  createJobApiId: string = "";
  getAllJobApiId: string = "";
  getClosedJobApiId: string = "";
  getActiveJobApiId: string = "";
  getjobCategoryId: string = "";
  jobTypeId: string = "";
  mandatorySkillId: string = "";
  optionalSkillId: string = "";
  commonSkillId: string = "";
  managerListId: string = "";
  recruiterListId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  toogleDialog = (msg: string)=> {
    this.setState( {...this.state,showDialog: !this.state.showDialog , errorMsg: msg})
  };
  getCompanyListId: string = "";
  newPanelRequestsApiCallId: string = "";
  approvedPanelRequestsApiCallId: string = "";
  getNotAppliedCandidateListId: string = "";
  getAppliedCandidateListId: string = "";
  getSelectedCandidateListId: string = "";
  todaysInterviewListApiId: string = "";
  upcomingInterviewListApiId: string = "";
  completedInterviewListApiId: string = "";
  reviewPendingInterviewListApiId: string = "";
  getRecentInterviewListForCoordinatorApiId: string = "";
  getInterviewSummaryListForCoordinatorApiId: string = "";
  getJobSummaryForDashboardApiId: string = "";
  getJobSummaryForRecruiterDashboardApiId: string = "";
  getRecruiterSummaryApiId: string = "";
  getInterviewSummaryForManagerApiId: string = "";
  getCompletedInterviewListApiId: string = "";
  getRecentInterviewsForPanelApiCallId: string = "";
  getRecruitingStatisticsApiId: string = "";
  getLocationsApiId: string = "";
  role?: string;
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      step:1,
      showDialog:false,
      active_closed_jobs:"active",
      createJobsAddRounds:[],
      allJobsList:[],
      job_types:[],
      errors : {},
      categories : { mandatory_skill:[], optional_skill:[], job_category:[], commonSkills:[{id:0,name:"loading..."}], managers:[], recruiters:[] },
      isOpenJobFilter: false,
      closedJobFilter: {},
      activeJobFilter: {},
      companyList:[],
      loader:false,
      approvePanelCount: 0,
      newRequestPanelCount: 0,
      activeJobCount: 0,
      closedJobCount: 0,
      allCandidateList: {
        notAppliedCandidates: 0,
        rejectedCandidates: 0,
        appliedCandidates: 0,
        selectedCandidates: 0,
        totalCount: 0
      },
      activeJobs: [],
      interviewList : {
        todays: [],
        upcoming: [],
        completed: [],
        reviewPending: []
      },
      recentInterviewsForCoordinator: [],
      interviewSummaryForCoordinator: {
        total_interviews: 0,
        completed_interviews: 0,
        cancelled_interviews: 0,
        scheduled_interviews: 0
      },
      jobSummaryForDashboard: [],
      jobSummaryForRecruiterDashboard: [],
      interviewSummaryForRecruiter: {
        selected: 0,
        rejected: 0,
        in_progress: 0,
      },
      interviewSummaryForManager: {
        completed: 0,
        rejected: 0,
        in_progress: 0,
      },
      update_data:false,
      openUpcomingInterviews: false,
      completedInterviewList: [],
      recentInterviewsForPanel: [],
      recruitingStatistics: [],
      locationList: [],
      clientAdminList: [],
      selectedClientAdmin: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.createJobApiId || apiRequestCallId === this.getClosedJobApiId ||
        apiRequestCallId === this.getActiveJobApiId ||
        apiRequestCallId === this.getjobCategoryId || apiRequestCallId === this.jobTypeId ||
        apiRequestCallId === this.commonSkillId ||
        apiRequestCallId === this.managerListId || apiRequestCallId === this.recruiterListId ||
        apiRequestCallId === this.getCompanyListId || apiRequestCallId === this.newPanelRequestsApiCallId ||
        apiRequestCallId === this.approvedPanelRequestsApiCallId || apiRequestCallId === this.getAppliedCandidateListId ||
        apiRequestCallId === this.getNotAppliedCandidateListId) {

        this.receiveAPI1(apiRequestCallId, responseJson, errorResponse);
      }

      if (apiRequestCallId === this.getSelectedCandidateListId || apiRequestCallId === this.todaysInterviewListApiId ||
        apiRequestCallId === this.upcomingInterviewListApiId || apiRequestCallId === this.completedInterviewListApiId ||
        apiRequestCallId === this.reviewPendingInterviewListApiId || apiRequestCallId === this.getRecentInterviewListForCoordinatorApiId ||
        apiRequestCallId === this.getInterviewSummaryListForCoordinatorApiId || apiRequestCallId === this.getJobSummaryForDashboardApiId ||
        apiRequestCallId === this.getJobSummaryForRecruiterDashboardApiId || apiRequestCallId === this.getRecruiterSummaryApiId ||
        apiRequestCallId === this.getInterviewSummaryForManagerApiId || apiRequestCallId === this.getCompletedInterviewListApiId ||
        apiRequestCallId === this.getRecentInterviewsForPanelApiCallId || apiRequestCallId === this.getRecruitingStatisticsApiId ||
        apiRequestCallId === this.getLocationsApiId) {

        this.receiveAPI2(apiRequestCallId, responseJson, errorResponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  


  createJob = async(values:any)=>{
    
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createJobApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createJobsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createJobsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ ...this.state, loader:true });
  }

  getClosedJob = async(searchParams="")=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClosedJobApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllJobsApiEndPoint}?status=closed&search=${searchParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getActiveJob = async(searchParams="")=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActiveJobApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllJobsApiEndPoint}?status=active&search=${searchParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobCategory = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getjobCategoryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobCategoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobTypes = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobTypeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobTypesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMandatorySkills = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.mandatorySkillId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mandatorySkillApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getOptionalSkills = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.optionalSkillId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.optionalSkillApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCommonSkills = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commonSkillId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commonSkillApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecruiterList = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.recruiterListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recruiterListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getManagersList = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.managerListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.managerListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompanyList = async()=>{
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCompanyListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNewPanelsRequests = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.newPanelRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelRequestsApiEndPoint}?panel_status=new_request&page=1&per_page=10&search=`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getApprovePanelsRequests = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.approvedPanelRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelRequestsApiEndPoint}?panel_status=approve&page=1&per_page=10&search=`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllCandidates = () => {
    this.getAppliedCandidates("");
    this.getNotAppliedCandidates("");
    this.getSelectedCandidates("");
  }

  getAppliedCandidates = async (prams: string = '') => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAppliedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAppliedCandidateListApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotAppliedCandidates = async (prams: string = '') => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotAppliedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotAppliedCandidateListApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSelectedCandidates = async (prams: string = '') => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSelectedCandidateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSelectedCandidateListApiEndPoint + prams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllInterviewList = (userType: any) => {
    this.getTodaysInterviewList(userType === "panel" ? true : false);
    this.getUpcomingInterviewList(userType === "panel" ? true : false);
    this.getCompletedInterviewList(userType === "panel" ? true : false);
    this.getReviewPendingInterviewList(userType === "panel" ? true : false);
  }

  getTodaysInterviewList = async (isPanelUser: boolean) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.todaysInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `today`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUpcomingInterviewList = async (isPanelUser: boolean) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upcomingInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `upcoming`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompletedInterviewList = async (isPanelUser: boolean) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completedInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `completed`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReviewPendingInterviewList = async (isPanelUser: boolean) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reviewPendingInterviewListApiId = requestMessage.messageId;
    const endPoint = isPanelUser ? configJSON.getInterviewListForPanelApiEndPoint : configJSON.getInterviewListApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + `review`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecentInterviewListForCoordinator = async () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecentInterviewListForCoordinatorApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentInterviewsForCoordinatorApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInterviewSummaryForCoordinator = async () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInterviewSummaryListForCoordinatorApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInterviewSummaryForCoordinatorApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getJobSummaryForDashboard = async (role: string) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJobSummaryForDashboardApiId = requestMessage.messageId;
    const endPoint = role === "manager" ? configJSON.getJobSummaryForManagerApiEndPoint : configJSON.getJobSummaryForClientAdminApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobSummaryForRecruiterDashboard = async () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJobSummaryForRecruiterDashboardApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getJobSummaryForRecruiterApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInterviewSummaryForRecruiterDashboard = async () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecruiterSummaryApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateSummaryForRecruiterAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInterviewSummaryForManagerDashboard = async (role: string) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInterviewSummaryForManagerApiId = requestMessage.messageId;
    const endPoint = role === "manager" ? configJSON.getCandidateSummaryForManagerAPIEndpoint : configJSON.getCandidateSummaryForClientAdminAPIEndpoint;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompletedInterviewsForCoordinator = async () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompletedInterviewListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCompletedInterviewsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecentInterviewsForPanel = async () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecentInterviewsForPanelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentInterviewsForPanelApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecruitingStatistics = async (user: string, companyId: any = "") => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecruitingStatisticsApiId = requestMessage.messageId;
    
    let endPoint = "";
    if (user === "manager") {
      if (companyId) {
        endPoint = configJSON.getRecruitingStatisticsForManagerApiEndPoint + `?client_admin_id=${companyId}`;
      } else {
        endPoint = configJSON.getRecruitingStatisticsForManagerApiEndPoint;
      }
    } else if (user === "recruiter") {
      if (companyId) {
        endPoint = configJSON.getRecruitingStatisticsForRecruiterApiEndPoint +`?client_admin_id=${companyId}`;
      } else {
        endPoint = configJSON.getRecruitingStatisticsForRecruiterApiEndPoint;
      }
    } else {
      endPoint = configJSON.getRecruitingStatisticsForClientAdminApiEndPoint;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLocationList = async()=>{
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "token":localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLocationsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveCreateJobAPI = (responseJson: any, errorResponse: any) => {
    //@ts-ignore
    this.state.loader = false
    if (responseJson && responseJson.message) {
      //Success Case
      this.toogleDialog('success')
    } else {
      //Error Case
      this.toogleDialog(`apiError^${responseJson.description}`)
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetClosedJobAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.jobs) {
      //Success Case
      this.setState({ ...this.state, closedJobCount: responseJson.jobs.length });
    } else {
      //Error Case
      console.log("error-IN_GET_CLOSED_JOBS")
      this.setState({ ...this.state, closedJobCount: 0 });
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetActiveJobAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.jobs) {
      //Success Case
      this.setState({ ...this.state, activeJobCount: responseJson.jobs.length, activeJobs: responseJson.jobs });
    } else {
      //Error Case
      this.setState({ ...this.state, activeJobCount: 0 });
      console.log("error-IN_GET_ACTIVE_JOBS")
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetJobCategoriesAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.category) {
      //Success Case
      this.setState({ ...this.state, categories: { ...this.state.categories, job_category: responseJson.category } })
    } else {
      //Error Case
      this.toogleDialog('apiError^Error Occured While Fetching Job Categories')
      console.log("error-IN_GET_JOB_CATEGORY")
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetJobTypesAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.job_types) {
      //Success Case
      this.setState({ ...this.state, job_types: responseJson.job_types })
    } else {
      //Error Case
      this.toogleDialog('apiError^Error Occured While Fetching Job Types')
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetCommonSkillsAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.skill) {
      //Success Case
      this.setState({ ...this.state, categories: { ...this.state.categories, commonSkills: responseJson.skill } })
    } else {
      //Error Case
      this.toogleDialog('apiError^Error Occured While Fetching Skills')
      console.log("error-IN_Common_SKILLS")
      this.setState({ ...this.state, categories: { ...this.state.categories, commonSkills: [] } })
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetManagersAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.managers) {
      //Success Case
      this.setState({ ...this.state, categories: { ...this.state.categories, managers: responseJson.managers } })
    } else {
      //Error Case
      this.toogleDialog("apiError^Error Occured While Fetching Manager's List")
      console.log("error-IN_Manager_List")
      this.setState({ ...this.state, categories: { ...this.state.categories, managers: [] } })
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetRecruitersAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.recruiters) {
      //Success Case
      this.setState({ ...this.state, categories: { ...this.state.categories, recruiters: responseJson.recruiters } })
    } else {
      //Error Case
      this.toogleDialog("apiError^Error Occured While Recruiter's List")
      console.log("error-IN_Recruiter_List")
      this.setState({ ...this.state, categories: { ...this.state.categories, recruiters: [] } })
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetCompaniesAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.success) {
      //Success Case
      const list = responseJson.success[0].companies || []
      this.setState({ ...this.state, companyList: list })
    } else {
      //Error Case
      console.log("error-IN_CompanyList")
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetPanelNewRequestAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.total_panel_user) {
      //Success Case
      this.setState({ ...this.state, newRequestPanelCount: responseJson.total_panel_user })
    } else {
      //Error Case
      console.log("error-IN_New_Panel")
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetPanelApproveRequestAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.total_panel_user) {
      //Success Case
      this.setState({ ...this.state, approvePanelCount: responseJson.total_panel_user })
    } else {
      //Error Case
      console.log("error-IN_Approve_Panel")
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetAppliedCandidatesAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.candidates) {
      //Success Case
      this.setState({
        ...this.state,
        allCandidateList:
        {
          ...this.state.allCandidateList,
          appliedCandidates: responseJson.applied,
          totalCount: responseJson.total_candidate_count
        }
      })
    } else {
      //Error Case
      console.log("Error Occured getAppliedCandidateList");
      this.setState({
        ...this.state, allCandidateList: {
          appliedCandidates: 0,
        }
      })
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetNotAppliedCandidatesAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.candidates) {
      //Success Case
      this.setState({
        ...this.state,
        allCandidateList:
        {
          ...this.state.allCandidateList,
          notAppliedCandidates: responseJson.not_applied,
        }
      })
    } else {
      //Error Case
      console.log("Error Occured getAppliedCandidateList");
      this.setState({
        ...this.state, allCandidateList: {
          notAppliedCandidates: 0,
        }
      })
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetSelectedAppliedCandidatesAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.candidates) {
      //Success Case
      this.setState({
        ...this.state,
        allCandidateList:
        {
          ...this.state.allCandidateList,
          selectedCandidates: responseJson.selected,
        }
      })
    } else {
      //Error Case
      console.log("Error Occured getAppliedCandidateList");
      this.setState({
        ...this.state, allCandidateList: {
          selected: 0,
        }
      })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetTodaysInterviewsAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          todays: responseJson.candidate_details
        }
      })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetUpcomingInterviewsAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          upcoming: responseJson.candidate_details
        }
      })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetCompletedInterviewsAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          completed: responseJson.candidate_details
        }
      })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetReviewPendingInterviewsAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.candidate_details) {
      this.setState({
        ...this.state, interviewList: {
          ...this.state.interviewList,
          reviewPending: responseJson.candidate_details
        }
      })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetRecentInterviewListForCoordinatorAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.data && responseJson.data.recent_interviews && responseJson.data.recent_interviews.data) {
      this.setState({ ...this.state, recentInterviewsForCoordinator: responseJson.data.recent_interviews.data })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetInterviewSummaryForCoordinatorAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, interviewSummaryForCoordinator: { ...responseJson.data } })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetJobSummaryForDashboardAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.job_summary) {
      this.setState({ ...this.state, jobSummaryForDashboard: responseJson.job_summary })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetJobSummaryForRecruiterDashboardAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.job_summary) {
      this.setState({ ...this.state, jobSummaryForRecruiterDashboard: responseJson.job_summary })
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetRecruiterSummaryAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson) {
      this.setState({ ...this.state, interviewSummaryForRecruiter: responseJson });
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetInterviewSummaryForManagerAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson) {
      this.setState({ ...this.state, interviewSummaryForManager: responseJson });
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetCompletedInterviewListForCoordinatorAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.completed_interviews) {
      this.setState({ ...this.state, completedInterviewList: responseJson.completed_interviews });
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetRecentInterviewListForPanelAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.job_summary) {
      this.setState({ ...this.state, recentInterviewsForPanel: responseJson.job_summary });
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetRecruitingStatisticsAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson?.client_admins) {
      this.setState({
        ...this.state, recruitingStatistics: responseJson.recruting_detail || [],
        selectedClientAdmin: responseJson?.current_client_admin?.data?.id || "", clientAdminList: responseJson?.client_admins || []
      });
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveGetLocationsAPI = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.Locations && responseJson.Locations.length > 0) {
      this.setState({ ...this.state, locationList: responseJson.Locations });
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  receiveAPI1 = (apiID: string, responseJson: any, errorResponse: any) => {
    if (apiID === this.createJobApiId) {
      this.receiveCreateJobAPI(responseJson, errorResponse);
    }

    if (apiID === this.getClosedJobApiId) {
      this.receiveGetClosedJobAPI(responseJson, errorResponse);
    }

    if (apiID === this.getActiveJobApiId) {
      this.receiveGetActiveJobAPI(responseJson, errorResponse);
    }

    if (apiID === this.getjobCategoryId) {
      this.receiveGetJobCategoriesAPI(responseJson, errorResponse);
    }

    if (apiID === this.jobTypeId) {
      this.receiveGetJobTypesAPI(responseJson, errorResponse);
    }

    if (apiID === this.commonSkillId) {
      this.receiveGetCommonSkillsAPI(responseJson, errorResponse);
    }

    if (apiID === this.managerListId) {
      this.receiveGetManagersAPI(responseJson, errorResponse);
    }

    if (apiID === this.recruiterListId) {
      this.receiveGetRecruitersAPI(responseJson, errorResponse);
    }

    if (apiID === this.getCompanyListId) {
      this.receiveGetCompaniesAPI(responseJson, errorResponse);
    }

    if (apiID === this.newPanelRequestsApiCallId) {
      this.receiveGetPanelNewRequestAPI(responseJson, errorResponse);
    }

    if (apiID === this.approvedPanelRequestsApiCallId) {
      this.receiveGetPanelApproveRequestAPI(responseJson, errorResponse);
    }

    if (apiID === this.getAppliedCandidateListId) {
      this.receiveGetAppliedCandidatesAPI(responseJson, errorResponse);
    }

    if (apiID === this.getNotAppliedCandidateListId) {
      this.receiveGetNotAppliedCandidatesAPI(responseJson, errorResponse);
    }
  }

  receiveAPI2 = (apiID: string, responseJson: any, errorResponse: any) => {
    if (apiID === this.getSelectedCandidateListId) {
      this.receiveGetSelectedAppliedCandidatesAPI(responseJson, errorResponse);
    }

    if (apiID === this.todaysInterviewListApiId) {
      this.receiveGetTodaysInterviewsAPI(responseJson, errorResponse);
    }

    if (apiID === this.upcomingInterviewListApiId) {
      this.receiveGetUpcomingInterviewsAPI(responseJson, errorResponse);
    }

    if (apiID === this.completedInterviewListApiId) {
      this.receiveGetCompletedInterviewsAPI(responseJson, errorResponse);
    }

    if (apiID === this.reviewPendingInterviewListApiId) {
      this.receiveGetReviewPendingInterviewsAPI(responseJson, errorResponse);
    }

    if (apiID === this.getRecentInterviewListForCoordinatorApiId) {
      this.receiveGetRecentInterviewListForCoordinatorAPI(responseJson, errorResponse);
    }

    if (apiID === this.getInterviewSummaryListForCoordinatorApiId) {
      this.receiveGetInterviewSummaryForCoordinatorAPI(responseJson, errorResponse);
    }

    if (apiID === this.getJobSummaryForDashboardApiId) {
      this.receiveGetJobSummaryForDashboardAPI(responseJson, errorResponse);
    }

    if (apiID === this.getJobSummaryForRecruiterDashboardApiId) {
      this.receiveGetJobSummaryForRecruiterDashboardAPI(responseJson, errorResponse);
    }

    if (apiID === this.getRecruiterSummaryApiId) {
      this.receiveGetRecruiterSummaryAPI(responseJson, errorResponse);
    }

    if (apiID === this.getInterviewSummaryForManagerApiId) {
      this.receiveGetInterviewSummaryForManagerAPI(responseJson, errorResponse);
    }

    if (apiID === this.getCompletedInterviewListApiId) {
      this.receiveGetCompletedInterviewListForCoordinatorAPI(responseJson, errorResponse);
    }

    if (apiID === this.getRecentInterviewsForPanelApiCallId) {
      this.receiveGetRecentInterviewListForPanelAPI(responseJson, errorResponse);
    }

    if (apiID === this.getRecruitingStatisticsApiId) {
      this.receiveGetRecruitingStatisticsAPI(responseJson, errorResponse);
    }

    if (apiID === this.getLocationsApiId) {
      this.receiveGetLocationsAPI(responseJson, errorResponse);
    }
  }
  // Customizable Area End

}
