// Customizable Area Start
import {
    Dialog, IconButton, DialogTitle,
    DialogContent, DialogActions,
    Button
  } from "@material-ui/core"
  import React from "react"
  import { Formik } from 'formik'
  import Rating from '@material-ui/lab/Rating';
  import CloseIcon from '@material-ui/icons/Close'
  import { UnSelectedStar, RatingStar } from '../../landingpage/src/assets'
  
  
  export default function RateInterviewer(props: any) {
  
    const handleSubmit = (values: any, { setSubmitting }: any) => {
      props.rateInterviewer({
        panel_candidate_slot_id: props.panel_candidate_slot_id,
        leadership_skills: values.leadership_skills,
        english_language_knowledge: values.english_language_knowledge,
        communication_skills: values.communication_skills,
        comments: values.comment,
      })
      props.handleClose()
  
    }
    return (<>
      <Dialog open={props.show} fullWidth maxWidth="sm" scroll='body' className="default-timing-dialog-wrapper">
        <DialogTitle className="dialog-title">
          <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
            <div style={{ fontSize: 22 }}>Rate Interviewer</div>
            <IconButton size="small" onClick={props.handleClose}><CloseIcon className="close-icon-btn" /></IconButton>
          </div>
        </DialogTitle>
        <Formik
          data-test-id="form"
          initialValues={{
            account_id: 0,
            issue_reason: "",
            leadership_skills: 0,
            english_language_knowledge: 0,
            communication_skills: 0,
            comment: ""
          }}
          // validate={validateStep3 }
          onSubmit={handleSubmit}
        >
          {({
            values,
            // errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            setErrors
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent className='opting-out-wrapper'>
                <RateSkill name='Leadership Skills' rating={Number(values.leadership_skills)} hChange={handleChange} varName={"leadership_skills"} />
                <RateSkill name='English Language Knowledge' rating={Number(values.english_language_knowledge)} hChange={handleChange} varName={"english_language_knowledge"} />
                <RateSkill name='Communication Skills' rating={Number(values.communication_skills)} hChange={handleChange} varName={"communication_skills"} />
  
  
  
                <div style={{ marginTop: 30 }}>
                  <h3>Comments on</h3>
                  <textarea className='reason' id="rate-interviewer-comment" name="comment" placeholder='Please Enter Your Comments' onChange={handleChange} />
                </div>
              </DialogContent>
              <DialogActions className='opting-out-actions'>
                <Button fullWidth style={{ borderRadius: 8 }} variant="contained" type="submit" className="btn-3 hy-btn" >
                  SUBMIT
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>)
  }
  
  export const RateSkill = (props: { name: string, rating: number, varName: string, hChange: any }) => {
  
  
    return (
      <div className="rate-skill">
        <span>{props.name} </span>
  
        <Rating
          name={props.varName}
          value={props.rating}
          onChange={props.hChange}
          icon={<img src={RatingStar} alt="*" height="20" style={{ marginRight: "6px" }} />}
          emptyIcon={<img src={UnSelectedStar} alt="." height="20" style={{ marginRight: "6px" }} />}
        />
      </div>
    )
  }
  
  
  // Customizable Area End