import LinkShareController from './LinkShareController'
import { Modal, Button } from '@material-ui/core'
import { WarningIcon } from '../../documentopener/src/assets'
import { SuccessIcon } from '../../landingpage/src/assets'
import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';

export default class ConfirmReviewDialog extends LinkShareController {
  constructor(props:any){
    super(props)
  }
  render() {
    //@ts-ignore
    const {show, DialogClose} = this.props
    return (
    <Modal
      open={show}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="jobs-success-model"
    >
      <div className="infromation-block">
          
        <img src={WarningIcon} alt="!" />
        <h4>Submit Reviews?</h4>
        <h6 >Are you sure want to submit reviews for the interview?</h6> 
          
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button onClick={() => {
            DialogClose()
          }} variant="outlined" fullWidth style={{ borderRadius: 8, height: 56 }}>
            CANCEL
          </Button>
          <Button 
            onClick={() => {
              DialogClose("submit")
            }} 
            variant="contained" 
            className="hy-btn" 
            fullWidth 
            style={{ 
              borderRadius: 8, 
              height: 56,
              marginLeft: 8,
            }}
          >
            CONFIRM
          </Button>
        </div>
      
      </div>
        </Modal>
    )
  }
}

export const SuccessReviewDialog = (props:any) => {
  const {show, DialogClose, type} = props
  return (
  <Modal
    open={show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="jobs-success-model"
  >
    <div className="infromation-block">
      {type === "error" ? <>
      <CloseIcon style={{ color: "red" }} />
      <h4>Error Occurred</h4>
      <h6>Something went wrong!</h6>
      </> : <>
      <img src={SuccessIcon} alt="v/" width='120' />
      <h4>Review Saved</h4>
      <h6 >Your review has been saved successfully</h6> 
      </>}
        
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Button 
          onClick={() => {
            DialogClose("")
          }} 
          variant="contained" 
          className="hy-btn" 
          fullWidth 
          style={{ 
            borderRadius: 8, 
            height: 56,
          }}
        >
          OKAY
        </Button>
      </div>
    
    </div>
      </Modal>
  )
}
