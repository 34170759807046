// Customizable Area Start
import React from 'react'
import {Card, CardContent} from "@material-ui/core"

export default function InterviewRounds({data}:{data:any}) {
  return (
    <Card className="view-jobs-job-description-wrapper interview-round-wrapper"  >
      <CardContent>
        {data && data.length ? data.map((round:any,ind:number)=> <RoundBlock index={ind} name={round.name} 
          details={`${round.description}`} />  ) : <>
        <RoundBlock index={0} name={""} 
          details={``} />
        </>
      } 
      </CardContent>
    </Card>
  )
}

function RoundBlock({index,name,details}:{index:number,name:string,details:string}) {
  return (<>
    <span className="view-job-detail-headings">Round {index+1} : {name}</span>
    <p className="view-job-description" >{details}</p>
  </>)
}

// Customizable Area End