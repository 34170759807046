export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const dummpPdf = require("../assets/dummy-pdf.pdf");
export const Img = require("../assets/companyLogo.png");
export const BackBtn = require("../assets/BackBtn.png");
export const SuccessIcon = require("../assets/successIcon.png");
export const CloseJob = require("../assets/closeJob.png");
export const DeleteJob = require("../assets/deleteJob.png");
export const Experience = require("../assets/experience.png");
export const JobType = require("../assets/jobType.png");
export const Position = require("../assets/positions.png");
export const Salary = require("../assets/salary.png");
export const SearchIcon = require("../assets/searchIcon.png");
export const InfoIcon = require("../assets/infoIcon.png");
export const DefaultProfile = require('../assets/defaultProfile.png')
export const EditIcon = require('../assets/edit.png')
export const AccountIcon = require('../assets/account.png')
export const CalenderIcon = require('../assets/calender.png')
export const CompanyIcon = require('../assets/company.png')
export const DegreeIcon = require('../assets/degree.png')
export const DeleteIcon = require('../assets/delete.png')
export const DownloadIcon = require('../assets/download.png')
export const EmailIcon = require('../assets/email.png')
export const FileIcon = require('../assets/file.png')
export const MobileIcon = require('../assets/mobile.png')
export const UploadIcon = require('../assets/upload.png')
export const CreatedOn = require('../assets/createdOn.png')
export const WarningIcon = require('../assets/warningIcon.png')
export const ScheduleIcon = require('../assets/scheduleIcon.png')
export const RadioOutlinedIcon = require('../assets/radioOutlinedIcon.png')
export const RadioCheckedIcon = require('../assets/radioCheckedIcon.png')
export const DeleteChipIcon = require('../assets/deleteChipIcon.png')
export const DropDownIcon = require('../assets/dropDownIcon.png')

export const Selected = require('../assets/selected.png')
export const StarIcon = require('../assets/star.png')
export const StarFilledIcon = require('../assets/starFilled.png')
export const RejectedIcon = require('../assets/rejectedIcon.png')
export const RejectedStatusIcon = require('../assets/rejectedStatusIcon.png')
export const SelectedStatusIcon = require('../assets/selectedStatusIcon.png')