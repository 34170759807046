Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LinkShare";
exports.labelBodyText = "LinkShare Body";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.updateStatusAPiEndPoint = "update_interview/";
exports.panelReviewsAPiEndPoint = "bx_block_reviews/panel_reviews"
exports.btnExampleTitle = "CLICK ME";
exports.cancelInterviewApiEndPoint = "cancel_interview/";
exports.getInterviewListApiEndPoint = "bx_block_coordinator/coordinator/coordinator_interviews/?tab=";
exports.getInterviewListForPanelApiEndPoint = "bx_block_panel/panel_interviews/?tab=";
exports.getInterviewDetailsApiEndPoint = "get_interview/";
exports.interviewJoiningIssueApiEndPoint = "bx_block_coordinator/coordinator/coordinator_interviews/joining_issue";
exports.notificationsApiContentType = "application/json";
exports.notificationsEndPoint= "bx_block_notifications/notifications";
// Customizable Area End