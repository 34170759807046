Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.loginApiEndPoint = "accounts";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.validateEmailApiEndPoint = "bx_block_panel/panels/check_panel_account";
exports.createDetailsApiEndPoint = "bx_block_panel/panels/create_details";
exports.dropdownDataApiEndPoint = "bx_block_panel/panels/show_skills";
exports.privacyPolicyApiEndPoint = "account_block/candidates/privacy_policy_list";
exports.TermsAndConditionsApiEndPoint = "account_block/candidates/terms_and_policy_list";

// Customizable Area End