// Customizable Area Start

import React, { Component } from "react";
import "./NotificationBlock.css";
import { Grid } from "@material-ui/core";
import { BlueClock } from "./assets";
import LinkShareController from "./LinkShareController";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
// import Button from "@material-ui/core";

export default class NotificationBar extends LinkShareController {
  constructor(props: any) {
    super(props);
  }
  async componentDidMount() {
    const a = document.querySelector("#root") as HTMLDivElement;
    a.style.overflow = "hidden";
    this.notificationsSubmit();
  }
  async componentWillUnmount() {
    const a = document.querySelector("#root") as HTMLDivElement;
    a.style.overflow = "unset";
  }
  handleNotificationsTypes(value: any) {
    return this.state.listofNotificationTypes.find((ele: any) => {
      return ele.type == value;
    });
  }
  

  customTimeConvert = (data: any) => {
    var utcValue = new Date(data);
    var dateUTC = utcValue.getTime();
    var dateIST = new Date(dateUTC);

    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);
    return dateIST;
  };
  render() {
    //@ts-ignore
    const { handleClose } = this.props;
    return (
      <div>
        <div className="fullscreen-container">
          <div className="text-head"> Notifications</div>
          
        {this.state.allnotifications.length > 0 && <button className="clear-button" onClick={()=>this.clearAllnotifications()}>Clear All</button>}  
          <CloseIcon onClick={handleClose} className="cross-point" />
          <main className="notification-content">
            <Grid container spacing={4}>
              <div style={{ marginTop: "8px",width:"100%"}}>
                {this.state.allnotifications.length > 0 ? (
                  this.state.allnotifications
                    .map((e: any, index: any) => {
                      let type = this.handleNotificationsTypes(
                        e.attributes.notification_type
                      );
                      let values;
                      if (type) {
                        values = type;
                      } else {
                        values = { type: "confirmed", key: 8, icon: BlueClock };
                      }

                      return (
                        <div style={{ marginBottom: "22px" }}>
                          <Grid item xs={12} key={"notification" + index}>
                            <NotificationBlock
                              msg={e.attributes.contents}
                              type={values}
                              time={
                                +moment(
                                  this.customTimeConvert(
                                    e.attributes?.created_at
                                  )
                                )
                                  ?.fromNow()
                                  ?.split(" ")[0] > 7
                                  ? moment(e.attributes?.created_at).format(
                                      "DD MMMM YYYY"
                                    )
                                  : moment(e.attributes?.created_at)?.fromNow()
                              }
                            />
                          </Grid>
                        </div>
                      );
                    })
                ) : (
                  <div className="data-style" style={{width:"100%"}}>No Notifications</div>
                )}
              </div>
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

enum notificationTypes {
  error = "error",
  normal = "normal",
  warn = "warn",
}

interface NotificationBlock {
  type: any;
  msg: any;
  time: any;
}

function NotificationBlock({ msg, type, time }: NotificationBlock) {
 
 
  return (
    <div className="notification-block">
      {type.type == "selected" ? (
        <img
          style={{
            height: "17px",
            width: "20px",
            marginTop: "12px",
            background: "#cddc394d",
            padding: "8px",
            borderRadius: "5px",
          }}
          src={type.icon}
          alt={type.type}
        />
      ) : (
        <img height={40} src={type.icon} alt={type.type} />
      )}
      <div className="msg-format">
        {msg}
        <span className="notification-time">{time}</span>
      </div>
    </div>
  );
}
