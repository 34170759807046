export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Loginbanner = require("../assets/loginBannerImage.png");
export const LoginBannerImage = require("../assets/loginBanner.png");
export const HiyameeBlackLogo = require("../assets/hiyameeBlack.png");
export const HiyameeLogo = require("../assets/hiyameeLogo.png");
export const MainLogo = require("../assets/mainLogo.png");
export const PersonalDetailBannerImage = require("../assets/personalDetailBanner.png");
export const ProfessionalDetailBannerImage = require("../assets/professionalDetailBanner.png");
export const OtherDetailBannerImage = require("../assets/otherDetailBanner.png");
export const UploadIcon = require("../assets/uploadIcon.png");
export const CheckIcon = require("../assets/checkIcon.png");
export const ProfileReviewIcon = require("../assets/profileReviewIcon.png");
export const MainLogoWhite = require("../assets/mainLogoWhite.png");
