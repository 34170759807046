// Customizable Area Start
import React from 'react'
import { Card, CardActionArea, CardContent } from "@material-ui/core"
import { Experience, JobType, Position, Salary, CreatedOn } from "../assets";

export default function JobDetailsSection({data, experienceList, locationList}:{data:any, experienceList:any[], locationList: any[]}) {
  const [showMore,setShowMore] = React.useState<boolean>(false);
  const jobDetails = data.description || "No description available"
  let date:any = new Date(data.created_at)?.toString()?.split(" ") || ""
  date = `${date[2]} ${date[1]} ${date[3]}` || ""

  const getExperience = (id: any) => {
    let name = "";
    const obj = experienceList.find((item:any) => Number(id) === item.id);
    if (obj) {
      name = obj.years + `${obj.years === "1" ? " Year": " Years"}`;
    } else if (id && !Number(id)) {
      name = id;
    }
    return name;
  }

  const getSelectedLocation = (ids: any) => {
    let locationArray: any[] = [];
    if (ids && ids.length > 0 && typeof(ids) !== "string") {
      ids.filter((id: any) => {
        const location = locationList.find((i: any) => i.id == id)?.name || "";
        if (location) {
          locationArray.push({name: location});
        }
      })
    }
    return locationArray;
  }

  return (
    <Card className="view-jobs-job-description-wrapper"  >
      {/* <CardActionArea> */}
        <CardContent>
          <div className="view-job-info-list">
            {/* <div className="view-job-info-items">
              <img height="40" src={Experience} alt="dsf" />
              <div className="view-job-info-item" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column" }} >
                <span className="view-job-info-head" > No. of Positions </span>
                <span className="view-job-info-value" > 25 </span>
              </div>
            </div> */}
            <InfoItem head={"No. of Positions"} image={Position} value={`${data.no_of_positions||""}`} />
            <InfoItem head={"Job Type"} image={JobType} value={`${data.get_dummy_data.job_type|| ""}`} />
            <InfoItem head={"Salary Range"} image={Salary} value={`INR ${Number(data.min_salary)+"-"+Number(data.max_salary) || ""} LPA`} />
            <InfoItem head={"Experience"} image={Experience} value={`${ getExperience(data.experience) || ""}`} />
            <InfoItem head={"Created On"} image={CreatedOn} value={`${ date || ""}`} />
          </div>
          <Heads_Tags head="Job Location" data={data.location ? typeof(data.location) === "string" ? [{name: data.location}] : getSelectedLocation(data.location) : [{name:""}] } />
          {/* <span className="view-job-detail-headings">Job Location</span>
          <div className="job-card-label" >{data.location}</div> */}
          {/* <div className="job-card-label" >{"Bangalore"}</div>
          <div className="job-card-label" >{"Delhi"}</div> */}
          <span className="view-job-detail-headings">Job Description</span>
          <p className="view-job-description" >
            {showMore || jobDetails.length<=900  ? jobDetails : `${jobDetails.substring(0, 900)}...`}
            {jobDetails.length<=900 ? null 
            :<span className="view-job-description-show-hide pointer" onClick={()=>setShowMore(!showMore)}>{showMore ? "Show Less" : "Show More"  }</span>}
          </p>
          {/* <span className="view-job-detail-headings">Mandatory Skills</span>
          <div className="job-card-label" >{"PhotoShop"}</div>
          <div className="job-card-label" >{"Sletch"}</div>
          <div className="job-card-label" >{"illustrator"}</div>
          <div className="job-card-label" >{"Figma"}</div> */}
          <Heads_Tags head="Mandatory Skills" data={  data.get_dummy_data?.skills?.filter((skill:any)=>!skill.optional) || [{name:""}]} />
          <Heads_Tags head="Optional Skills" data={  data.get_dummy_data?.skills?.filter((skill:any)=>skill.optional) || [{name:""}]} />

          {/* <span className="view-job-detail-headings">Optional Skills</span>
          <div className="job-card-label last-label" >{"XD"}</div>
          <div className="job-card-label last-label" >{"Principle"}</div> */}
        </CardContent>
      {/* </CardActionArea> */}
    </Card>
  )
}

function Heads_Tags({head,data}:{head:string,data:any[]}){
  return(<>
      <span className="view-job-detail-headings">{head || ""}</span>
      {data && data.length && data.map((tag,ind)=> <div key={tag.name+ind} className="job-card-label" >{tag.name}</div> ) || null }
    </>
  )
}

function InfoItem({head,image,value}:{head: string, image:any, value: string}){
  return ( <div className="view-job-info-items">
    <img height="40" src={image} alt="dsf" />
    <div className="view-job-info-item" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column" }} >
      <span className="view-job-info-head" > {head} </span>
      <span className="view-job-info-value" > {value} </span>
    </div>
  </div> )
}
    
// Customizable Area End