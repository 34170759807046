// Customizable Area Start
import DashboardController from "./DashboardController"
import {Grid, Card, CardContent, Avatar, Button, Divider, NativeSelect, Switch, FormControlLabel, Dialog, DialogTitle,
   DialogContent, IconButton} from '@material-ui/core';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from 'react'
import "./dashboard.web.css"
import { ActiveJobs, Candidates, Managers, Vendors, ClosedJobs, CompletedInterviews,
  NewJobs, Panels, ScheduledInterviews, SelectedCandidates, TodayInterviews, TotalCandidates, PanelScheduledInterviews, PendingEvaluation,
  TodaysInterview, TotalEarning,PositionIcon,TimeIcon,CompanyIcon} from './assets';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CloseIcon from '@material-ui/icons/Close';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import Chart from "react-apexcharts";
import moment from "moment";

let role =""

let data:{name:string,count:number,Logo:any}[] = []
let tableHeading:string = ''
let rows:{name:string, job:string, companyName:string, slotDate:string, status:JSX.Element}[] = [];
let dounutHeading:string = "";
let graphHeading:string = "";
let tableHeaders:string[] = [];

function createData(name:string, job:string, companyName:string, slotDate:string, status:JSX.Element | string) {
  return { name, job, companyName, slotDate, status };
}

export const getFullName = (name: string) => {
  let fullName = "";
  if (name && name.trim() !== "") {
    const trimName = name.trim();
    if (trimName.indexOf(' ') >= 0) {
      const firstNameLower = trimName.split(" ")[0].toLowerCase();
      const lastNameLower = trimName.slice(trimName.indexOf(" ")+1, trimName.length).toLowerCase();
      const firstName = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
      const lastName = lastNameLower[0].toUpperCase() + lastNameLower.slice(1);
      fullName = `${firstName} ${lastName}`;
    } else {
      const firstNameLower = trimName.toLowerCase();
      const firstName = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
      fullName = firstName;
    }
  }
  return fullName
}

const getDropdownFullName = (username: string) => {
  let formattedName = "";
  if (username) {
    formattedName = username.length < 16 ? username : `${username.slice(0, 16)}...`;
  }
  return getFullName(formattedName);
}

const getFormattedSlot = (date: string) => {
  let formattedSlot = "-";
  if (moment(date).isValid()) {
    const time = moment(date).format("hh:mm A");
    const newDate = moment(date).format("DD MMMM YYYY");
    formattedSlot = time + " " + newDate;
  }
  return formattedSlot;
}

function recentInterviewStatus(text: string) {
  let classNameString = "";
  let textStatus = text;
  switch (text) {
    case "Processing":
    case "ongoing":
      classNameString = "status-warning dashboard-table-status";
      break;
    case "completed":
    case "Completed":
    case "selected":
      classNameString = "status-completed dashboard-table-status";
      break;
    case "cancelled":
    case "Cancelled":
    case "round_cancelled":
    case "rejected":
    case "Closed":
      classNameString = "status-error dashboard-table-status";
      break;
    default:
      classNameString = "status-log dashboard-table-status";
      break;
  }
  if (text == "ongoing") {
    textStatus = "On Going";
  } else if (text === "opted_out") {
    textStatus = "Opted Out";
  } else if (text === "round_selected") {
    textStatus = "Completed";
  } else if (text === "round_cancelled") {
    textStatus = "Cancelled";
  }
  return (
    <div className={classNameString}>
      {textStatus}
    </div>
  );
}

const getInterviewTime = (data: any) => {
  const startTime = moment(data.start_time).format("hh:mm A");
  const endTime = moment(data.end_time).format("hh:mm A");
  return `${startTime} - ${endTime}`;
}

const interviewScheduledDetail = (data:any) => {
  return (
    <div className="interview-card">
      <div className="candidate-name">{getFullName(data.candidate_name)}</div>
      <div className="with-icon-row"><img src={PositionIcon} alt="" className="interview-row-icon" />{data.job_role}</div>
      <div className="with-icon-row"><img src={CompanyIcon} alt="" className="interview-row-icon" />{data.company}</div>
      <div className="with-icon-row"><img src={TimeIcon} alt="" className="interview-row-icon" />{getInterviewTime(data.confirm_job_slot)}</div>
    </div>
  )
}

const getAvatarColor = (index:number) =>{
  const avatarColorArray = [
    {color:"#805cbd",backgroundColor:"#e7e0f2"},
    {color:"#f45d6b",backgroundColor:"#fdebed"},
    {color:"#56c4fb",backgroundColor:"#e0f4fe"},
    {color:"#60c596",backgroundColor:"#e5f5ee"}
  ];
  const number = index < avatarColorArray.length ? index : index % avatarColorArray.length;
  return {background:avatarColorArray[number].backgroundColor, color:avatarColorArray[number].color}
}

const getNewJobsCount = (jobs: any) => {
  let filteredData: any[] = [];
  jobs.forEach((item: any) => {
    let dateDiff = new Date().getTime() - new Date(item.created_at).getTime();
    const days = Math.ceil(dateDiff / (1000 * 3600 * 24));
    if (days <= 7) {
      filteredData.push(item);
    }
  });
  return filteredData.length > 0 ? filteredData.length : 0;
}

const showClientAdminDropdown = (role: any) => {
  return ((role === "manager" || role === "recruiter") && localStorage.getItem("is_hiyamee") === "true");
}

const setFirstChartTitleClass = (role: any) => {
  return `hy-cards-title ${role === "panel" || role === "recruiter" || role === "manager" ? "upcoming-interview-header" : ""}`;
}

const setSecondChartTitleClass = (role: any) => {
  return `hy-cards-title ${role === "panel" ? "upcoming-interview-header" : ""}`;
}

const setDashboardItemCount = (role: any, name: any, count: any) => {
  return <>
    {role === "panel" && name === "Total Earning" ? <h2 style={{fontSize: '22px', color: "black", fontWeight: "bold", fontStyle: "normal" }}>₹ {count}K</h2> : <h2 data-test-id="dashboard-count">{count}</h2>}
  </>
}

const showUpcomingInterviewsDivider = (index: number, interviewList: any) => {
  return <>
    {index === 0 && interviewList.length > 1 && <Divider />}
  </>
}

const showNoDataFoundForUpcomingInterviews = (role: any, list: any) => {
  return <>
    {role === "panel" && (!list.upcoming || !list.upcoming.length) &&
      <div style={{ fontSize: 16, fontWeight: 900, padding: "16px 0px" }}>No Data Found</div>}
  </>
}

const showSlotDateColumn = (role: any, date: any) => {
  return <>
    {role === "panel" && <span key="panel-date" style={{ color: "#13bb6d", fontWeight: 500 }} >{date}</span>}
    {(role === "manager" || role === "coordinator") && <span key="manager-date" style={{ fontWeight: 500 }} >{date}</span>}
    {role !== "panel" && role !== "manager" && role !== "coordinator" && <span key="admin-date">{date}</span>}
  </>
}

const showUpcomingInterviewsForPanel = (role: any, list: any) => {
  return <>
    {role === "panel" && (list.upcoming && list.upcoming.length > 0) && list.upcoming.slice(0, 2).map((data: any, index: number) => (
      <div style={{ paddingBottom: "0px" }}>
        {interviewScheduledDetail(data)}
        {showUpcomingInterviewsDivider(index, list.upcoming)}
      </div>
    ))
    }
  </>
}

const getAvatarTwoLetters = (name: any) => {
  return name && name.includes(' ') ? name.split(" ")[0][0]+name.split(" ")[1][0] : name[0] 
}

const DonutChart = ({labels, colors, series, totalLabel, totalCount}: any) => {
  return (
    <div className="donut-chart">
      <Chart
        options={{
        chart: {
          id: "donut",
          type: "donut",
        },
        colors: colors,
        labels: labels,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  fontFamily: "Gilroy-Regular",
                  color:"rgba(33,33,33,1)",
                  formatter: function(val: any)  {
                    return totalLabel
                  }
                },
                value: {
                  show: true,
                  fontSize: "14px",
                  color:"rgba(33,33,33,1)",
                  fontWeight: 500,
                  formatter: function(val: any)  {
                    return totalLabel
                  }
                },
                name: {
                  show: true,
                  fontFamily:"Gilroy-SemiBold",
                  fontSize: "24px",
                  fontWeight: 600,
                  color:"rgba(33,33,33,1)",
                  formatter: function(val: any) {
                    return totalCount
                  }
                }
              },
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
      }}
      series= {series}
      type="donut"
      height="370"
      />
  </div>
  )
}

const CompletedInterviewsChart = ({data}: any) => {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 6;

  useEffect(() => {
    setPage(0)
  }, [data]);

  const itemLength = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length;
  return <div className="completed-interviews-charts">
    <Chart
        options={{
          chart: {
            id: "coordinator",
            type: "bar",
            toolbar: {
              show: false
            },
            parentHeightOffset: -10,
          },
          plotOptions: {
            bar: {
              horizontal: true,
                borderRadius: 8,
                barHeight: `${itemLength*5}px`,
            },
          },
          dataLabels: {
            enabled: false
          },
          colors: ["rgba(0, 166, 251, 1)"],
          xaxis: {
            categories: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => (getFullName(item.name))),
            labels : {
              style:{
                fontSize: "15px",
              }
            },
            axisBorder: {
              show: false,
            },
          },
          yaxis: {
            labels : {
              style:{
                fontSize: "15px",
              },
              offsetX: -20
            },
          },
          grid: {
            show: true,
            strokeDashArray: 0,
            xaxis: {
                lines: {
                    show: true
                },
            },   
            yaxis: {
                lines: {
                    show: false
                }
            },
            padding: {
              left: 30,
            }
          }
        }}
        series={[
          {
            name: "Completed Interviews",
            data: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => (item.total_completed_interviews))
          }
        ]}
        type="bar"
        height="335"
      />
      <div style={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
        <div> Showing {data.length > 0 ? page * 6 + 1 : 0} to {page * rowsPerPage + rowsPerPage > data.length ? data.length : page * rowsPerPage + rowsPerPage} of {data.length} entries </div>
        <div>
          <IconButton className="pagination-icon" disabled={page === 0} size="small" onClick={() => setPage(page-1)}><SkipPreviousIcon fontSize="small"/></IconButton>
          <IconButton className="pagination-icon" disabled={page+1 >= data.length/6} size="small" onClick={() => setPage(page+1)}><SkipNextIcon fontSize="small"/></IconButton>
        </div>
      </div>
  </div>
}

const RecruitingStatisticsChart = ({data}: any) => {
  const [switchArray, setSwitchArray] = useState<any[]>([true, true, true]);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 4;

  useEffect(() => {
    setPage(0)
  }, [data]);

  const itemLength = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length;
  const onChangeSwitchArray = (index: number) => {
    const list = [...switchArray];
    list[index] = !switchArray[index];
    setSwitchArray(list);
  }
  return <div className="recruiting-statistics-charts">
      <Grid container className="charts-switch-container">
        <Grid item xs={6} sm={4}>
          <FormControlLabel
            control={<Switch name="charts-switch"
              classes={{
                root: "switch-root",
                switchBase: "switch-base",
                thumb: "switch-thumb",
                track: "switch-track success",
                checked: "switch-checked",
              }}
              focusVisibleClassName="switch-focus-visible"
              disableRipple
            />}
            label="Selected"
            checked={switchArray[0]}
            onChange={()=> onChangeSwitchArray(0)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormControlLabel
            control={<Switch name="charts-switch"
              classes={{
                root: "switch-root",
                switchBase: "switch-base",
                thumb: "switch-thumb",
                track: "switch-track error",
                checked: "switch-checked",
              }}
              focusVisibleClassName="switch-focus-visible"
              disableRipple
            />}
            label="Rejected"
            checked={switchArray[1]}
            onChange={()=> onChangeSwitchArray(1)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormControlLabel
            control={<Switch name="charts-switch"
              classes={{
                root: "switch-root",
                switchBase: "switch-base",
                thumb: "switch-thumb",
                track: "switch-track warning",
                checked: "switch-checked",
              }}
              focusVisibleClassName="switch-focus-visible"
              disableRipple
            />}
            label="In Progress"
            checked={switchArray[2]}
            onChange={()=> onChangeSwitchArray(2)}
          />
        </Grid>
      </Grid>
      <Chart
        options={{
          chart: {
            id: "admin",
            type: "bar",
            toolbar: {
              show: false
            },
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 8,
              barHeight: `${itemLength*6}px`,
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => getFullName(item[0])),
            labels: {
              style: {
                fontSize: "15px"
              }
            },
            axisBorder: {
              show: false,
            },
            tickAmount: 6
          },
          yaxis: {
            labels : {
              style:{
                fontSize: "15px",
              },
              offsetX: -80
            },
          },
          legend: {
            show: false
          },
          grid: {
            show: true,
            strokeDashArray: 0,
            xaxis: {
              lines: {
                  show: true
              },
            },   
            yaxis: {
              lines: {
                  show: false
              }
            },
            padding: {
              left: 100,
            }
          }
        }}
        series={[
          {
            name: 'Selected',
            data:switchArray[0] ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => item[1].selected || 0) : [],
            color: "rgba(19, 187, 109, 1)"
          }, {
            name: 'Rejected',
            data: switchArray[1] ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => item[1].rejected || 0) : [],
            color: "rgba(255, 60, 60, 1)"
          }, {
            name: 'In Progress',
            data: switchArray[2] ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => item[1].in_progress || 0) : [],
            color: "rgba(244, 145, 19, 1)",
          }
        ]}
        type="bar"
        height="300"
      />
      <div style={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
        <div> Showing {data.length > 0 ? page * 4 + 1 : 0} to {page * rowsPerPage + rowsPerPage > data.length ? data.length : page * rowsPerPage + rowsPerPage} of {data.length} entries </div>
        <div>
          <IconButton className="pagination-icon" disabled={page === 0} size="small" onClick={() => setPage(page-1)}><SkipPreviousIcon fontSize="small"/></IconButton>
          <IconButton className="pagination-icon" disabled={page+1 >= data.length/4} size="small" onClick={() => setPage(page+1)}><SkipNextIcon fontSize="small"/></IconButton>
        </div>
      </div>
  </div>
}

const PaymentStatisticsChart = () => {
  return <div className="payment-statistics-chart">
    <Chart
        options={{
          chart: {
            id: "panel",
            type: "bar",
            toolbar: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
              columnWidth: "24px"
            }
          },
          colors: ["rgba(255, 211, 39, 1)"],
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            labels : {
              style:{
                fontSize: "16px",
              }
            },
          },
          yaxis: {
            labels : {
              style:{
                fontSize: "16px"
              },
              formatter: function (val) {
                return val + "K"
              }
            },
            tickAmount: 5
          },
          grid: {
            show: true,
            strokeDashArray: 0,
            xaxis: {
                lines: {
                    show: false
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            }, 
          }
        }}
        series={[
          {
            name: "Payment Statistics",
            data: [13, 18, 7, 14, 21, 11, 14]
          }
        ]}
        type="bar"
        height="335"
      />
  </div>
}

const UpcomingInterviewsDialog = ({ open, handleClose, interviewList }: { open: boolean, handleClose: any, interviewList: any }) => {
  return (
    <Dialog open={open} scroll="paper" fullWidth maxWidth="sm" className="default-timing-dialog-wrapper">
      <DialogTitle className="dialog-title upcoming-interview-title">
        <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
          <div>Upcoming Interviews</div>
          <IconButton size="small" onClick={handleClose}><CloseIcon className="close-icon-btn" /></IconButton>
        </div>
      </DialogTitle>
        <DialogContent>
          <div>
            {interviewList.map((data: any, index: number) => (
              <>
                <div className="interview-card">
                  <div className="candidate-name">{getFullName(data.candidate_name)}</div>
                  <div className="with-icon-row"><img src={PositionIcon} alt="" className="interview-row-icon" />{data.job_role}</div>
                  <div className="with-icon-row"><img src={CompanyIcon} alt="" className="interview-row-icon" />{data.company}</div>
                  <div className="with-icon-row"><img src={TimeIcon} alt="" className="interview-row-icon" />{getInterviewTime(data.confirm_job_slot)}</div>
                </div>
                {(index +1) < interviewList.length && <Divider />}
              </>
            ))}
          </div>
        </DialogContent>
    </Dialog>
  )
}

export default class Dashboard extends DashboardController {
  async componentDidMount(){
    role = localStorage.getItem("role")?.toLowerCase() || "";
    if (role === "coordinator") {
      this.getNewPanelsRequests();
      this.getApprovePanelsRequests();
      this.getAllInterviewList(role);
      this.getRecentInterviewListForCoordinator();
      this.getInterviewSummaryForCoordinator();
      this.getCompletedInterviewsForCoordinator();
    }
    if (role === "panel") {
      this.getAllInterviewList(role);
      this.getRecentInterviewsForPanel();
    }
    if (role === "client admin" || role === "manager" || role === "recruiter") {
      this.managerAndClientAdminAPICall();
      if (role === "recruiter") {
        this.getJobSummaryForRecruiterDashboard();
        this.getInterviewSummaryForRecruiterDashboard();
      }
      this.getActiveJob();
      this.getAllCandidates();
      this.getRecruitingStatistics(role);
    }
    this.updateDashboard(role);
    this.forceUpdate()
  }
  
  managerAndClientAdminAPICall = () => {
    if (role === "client admin" || role === "manager") {
      this.getRecruiterList();
      this.getJobSummaryForDashboard(role);
      this.getInterviewSummaryForManagerDashboard(role);
      if (role === "client admin") {
        this.getManagersList();
      }
      if (role === "manager") {
        this.getClosedJob();
      }
    }
  }
  
  async componentDidUpdate(prevProps: any, prevState: any) {
    const prevCandidates = prevState.allCandidateList;
    const allCandidate = this.state.allCandidateList;
    const prevInterviewList = prevState.interviewList;
    const newInterviewList = this.state.interviewList;
    if ((prevState.newRequestPanelCount !== this.state.newRequestPanelCount) ||
      (this.state.approvePanelCount !== prevState.approvePanelCount) || (prevState.activeJobCount !== this.state.activeJobCount) ||
      (prevState.closedJobCount !== this.state.closedJobCount)
      || (this.state.categories.managers.length !== prevState.categories.managers.length) ||
      (this.state.categories.recruiters.length !== prevState.categories.recruiters.length) ||
      ((allCandidate.appliedCandidates + allCandidate.notAppliedCandidates + allCandidate.selectedCandidates) !==
        (prevCandidates.appliedCandidates + prevCandidates.notAppliedCandidates + prevCandidates.selectedCandidates)) || 
        (prevInterviewList.todays !== newInterviewList.todays) || (prevInterviewList.upcoming !== newInterviewList.upcoming) || 
        (prevInterviewList.completed !== newInterviewList.completed) || (prevInterviewList.reviewPending !== newInterviewList.reviewPending)
        || (prevState.recentInterviewsForCoordinator !== this.state.recentInterviewsForCoordinator) || 
        (prevState.interviewSummaryForCoordinator.completed_interviews !== this.state.interviewSummaryForCoordinator.completed_interviews) ||
        (prevState.interviewSummaryForCoordinator.cancelled_interviews !== this.state.interviewSummaryForCoordinator.cancelled_interviews) ||
        (prevState.interviewSummaryForCoordinator.scheduled_interviews !== this.state.interviewSummaryForCoordinator.scheduled_interviews) ||
        (prevState.interviewSummaryForCoordinator.completed_interviews !== this.state.interviewSummaryForCoordinator.completed_interviews) ||
        (prevState.interviewSummaryForRecruiter.selected !== this.state.interviewSummaryForRecruiter.selected) ||
        (prevState.interviewSummaryForRecruiter.rejected !== this.state.interviewSummaryForRecruiter.rejected) ||
        (prevState.interviewSummaryForRecruiter.in_progress !== this.state.interviewSummaryForRecruiter.in_progress) ||
        (prevState.interviewSummaryForManager.completed !== this.state.interviewSummaryForManager.completed) ||
        (prevState.interviewSummaryForManager.rejected !== this.state.interviewSummaryForManager.rejected) ||
        (prevState.interviewSummaryForManager.in_progress !== this.state.interviewSummaryForManager.in_progress) ||
        (prevState.jobSummaryForDashboard.length !== this.state.jobSummaryForDashboard.length) ||
        (prevState.jobSummaryForRecruiterDashboard.length !== this.state.jobSummaryForRecruiterDashboard.length) || 
        (prevState.completedInterviewList.length !== this.state.completedInterviewList.length) ||
        (prevState.recentInterviewsForPanel.length !== this.state.recentInterviewsForPanel.length) ||
        (prevState.recruitingStatistics.length !== this.state.recruitingStatistics.length)) {
      this.updateDashboard(role);
    }
  }

  updateDashboard(role: string) {
    if (role === "client admin") {
      this.updateClientAdminDashboard();
    }
    else if (role === "manager") {
      this.updateManagerDashboard();
    }
    else if (role === "recruiter") {
      this.updateRecruiterDashboard();
    }
    else if (role === "coordinator") {
      this.updateCoordinatorDashboard();
    }
    if (role === "panel") {
      this.updatePanelDashboard();
    }
    this.setState({ ...this.state, dashboardData: data });
  }

  updateClientAdminDashboard = () => {
    tableHeading = "Recent Job Status"
    graphHeading = "Recruiting Statistics"
    dounutHeading = "Candidate Summary"
    tableHeaders = ["Manager Name", "Job", "Company Name", "Job Created", "Status"]
    const allCandidate = this.state.allCandidateList;
    data = [
      {
        name: "Active Jobs",
        count: this.state.activeJobCount || 0,
        Logo: ActiveJobs,
      },
      {
        name: "Managers",
        count: (this.state.categories && this.state.categories.managers && this.state.categories.managers.length > 0) ? this.state.categories.managers.length : 0,
        Logo: Managers,
      },
      {
        name: "Candidates",
        count: allCandidate.totalCount || 0,
        Logo: Candidates,
      },
      {
        name: "Recruiters / Vendors",
        count: (this.state.categories && this.state.categories.recruiters && this.state.categories.recruiters.length > 0) ? this.state.categories.recruiters.length : 0,
        Logo: Vendors,
      },
    ]
    rows = this.state.jobSummaryForDashboard.slice(0, 4).map((item: any) => (
      createData(item.manager ? getFullName(item.manager) : "-",
        item.job_role || "-",
        item.company || "-",
        item.created_at || "-",
        recentInterviewStatus(item.status))
    ));
  }

  updateManagerDashboard = () => {
    tableHeading = "Recent Job Status"
    graphHeading = "Recruiting Statistics"
    dounutHeading = "Candidate Summary"
    tableHeaders = ["Manager Name", "Job", "Company Name", "Job Created", "Status"]
    const allCandidate = this.state.allCandidateList;
    data = [
      {
        name: "Active Jobs",
        count: this.state.activeJobCount || 0,
        Logo: ActiveJobs,
      },
      {
        name: "Closed Jobs",
        count: this.state.closedJobCount,
        Logo: ClosedJobs,
      },
      {
        name: "Candidates",
        count: allCandidate.totalCount || 0,
        Logo: Candidates,
      },
      {
        name: "Recruiters / Vendors",
        count: (this.state.categories && this.state.categories.recruiters && this.state.categories.recruiters.length > 0) ? this.state.categories.recruiters.length : 0,
        Logo: Vendors,
      },
    ]

    rows = this.state.jobSummaryForDashboard.slice(0, 4).map((item: any) => (
      createData(item.manager ? getFullName(item.manager) : "-",
        item.job_role || "-",
        item.company || "-",
        item.created_at || "-",
        recentInterviewStatus(item.status))
    ));
  }

  updateRecruiterDashboard = () => {
    tableHeading = "Recent Interview Status"
    graphHeading = "Recruiting Statistics"
    dounutHeading = "Job Summary"
    tableHeaders = ["Candidates Name", "Role", "Company Name", "Interviewer", "Status"]
    const allCandidate = this.state.allCandidateList;
    data = [
      {
        name: "Active Jobs",
        count: this.state.activeJobCount || 0,
        Logo: ActiveJobs,
      },
      {
        name: "New Jobs",
        count: getNewJobsCount([...this.state.activeJobs]),
        Logo: NewJobs,
      },
      {
        name: "Total Candidates",
        count: allCandidate.totalCount || 0,
        Logo: TotalCandidates,
      },
      {
        name: "Selected Candidates",
        count: allCandidate.selectedCandidates || 0,
        Logo: SelectedCandidates,
      },
    ]

    rows = this.state.jobSummaryForRecruiterDashboard.slice(0, 5).map((item: any) => (
      createData(item.candidate_name ? getFullName(item.candidate_name) : "-",
        item.job_role || "-",
        item.company || "-",
        getFullName(item.panel_name) || "-",
        recentInterviewStatus(item.status))
    ));
  }

  updateCoordinatorDashboard = () => {
    tableHeading = "Recent Interviews Status"
    graphHeading = "Completed Interviews"
    dounutHeading = "Interviews Summary"
    tableHeaders = ["Panel Name", "Job", "Company Name", "Confirmed Slot", "Status"]
    const upcomingCount = this.state.interviewList.upcoming.length || 0;
    const todaysCount = this.state.interviewList.todays.length || 0;
    const completedCount = this.state.interviewList.completed.length || 0;
    data = [
      {
        name: "Panels",
        count: (this.state.newRequestPanelCount + this.state.approvePanelCount) || 0,
        Logo: Panels,
      },
      {
        name: "Completed Interviews",
        count: (completedCount),
        Logo: CompletedInterviews,
      },
      {
        name: "Scheduled Interviews",
        count: (upcomingCount + todaysCount) || 0,
        Logo: ScheduledInterviews,
      },
      {
        name: "Today's Interviews",
        count: (todaysCount),
        Logo: TodayInterviews,
      },
    ]
    rows = this.state.recentInterviewsForCoordinator.map((item: any) => (
      createData(item.attributes.panel ? getFullName(item.attributes.panel) : "-",
        item.attributes.job.job_name || "-",
        item.attributes.job.company_name || "-",
        item.attributes.confirmed_slot ? getFormattedSlot(item.attributes.confirmed_slot) : "-",
        recentInterviewStatus(item.attributes.status))
    ));
  }

  updatePanelDashboard = () => {
    tableHeading = "Recent Interviews"
    graphHeading = "Payment Statistics"
    dounutHeading = "Upcoming Interviews"
    tableHeaders = ["Candidates Name", "Role Name", "Company Name", "Pricing", "Payment due date"]
    const upcomingCount = this.state.interviewList.upcoming.length || 0;
    const todaysCount = this.state.interviewList.todays.length || 0;
    const reviewPendingCount = this.state.interviewList.reviewPending.length || 0;
    data = [
      {
        name: "Scheduled Interviews",
        count: (todaysCount + upcomingCount) || 0,
        Logo: PanelScheduledInterviews,
      },
      {
        name: "Today's Interviews",
        count: (todaysCount),
        Logo: TodaysInterview,
      },
      {
        name: "Pending Evaluation",
        count: (reviewPendingCount),
        Logo: PendingEvaluation,
      },
      {
        name: "Total Earning",
        count: 65.2,
        Logo: TotalEarning,
      },
    ]
    rows = this.state.recentInterviewsForPanel.slice(0, 5).map((item: any) => (
      createData(item.candidate_name ? getFullName(item.candidate_name) : "-",
        item.job_role || "-",
        item.company || "-",
        item.pricing || "-",
        item.payment_due_date ? moment(item.payment_due_date).format("DD/MM/yyyy") : "-")
    ));
  }

  handleChangeClientAdmin = (e: any) => {
    this.setState({...this.state, selectedClientAdmin: e.target.value});
    this.getRecruitingStatistics(role, e.target.value);
  } 

  render() {
    const {interviewSummaryForCoordinator, interviewSummaryForRecruiter, interviewSummaryForManager, interviewList, openUpcomingInterviews,
       completedInterviewList, recruitingStatistics, selectedClientAdmin, clientAdminList} = this.state;
    return (
        <div className='hy-dashboard-block'> 
          <div className='hy-dashboard-counters'>
            <Grid container direction="row" spacing={3} style={{minHeight:"135px"}} >
              { this.state.dashboardData?.map(({name,count,Logo}: any)=> {
                return <Grid item xs={12} sm={6} md={4} lg={3} key={name} > 
                <Card className="hy-cards hy-counters-items" style={{height: "calc(100% - 45px)"}}>
                  <CardContent className=''>
                    <span className='hy-icons purple'>
                      <img style={{height:"50px"}} src={Logo}/>
                    </span>
                    <div className='hy-info'>
                      <h4 className="hy-dashboard-info-label">{name}</h4>
                      {setDashboardItemCount(role, name, count)}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              }) }
              </Grid>
          </div>
                  
          <div className='hy-dashboard-infos'>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} lg={7} sm={12} > 
                <Card className="hy-cards hy-recruiting-items" style={{height: "calc(100% - 45px)"}}>
                  <div className={setFirstChartTitleClass(role)}>
                    <h2 className="dashboard-table-heading"> {graphHeading} </h2>  
                    {role==="panel"&& <NativeSelect className="week-selection">
                      <option value="This Week">This Week</option>
                      <option value="This Month">This Month</option>
                    </NativeSelect> } 
                    {showClientAdminDropdown(role) && (
                      <div style={{display:"flex", alignItems:"center"}}>
                        <BusinessOutlinedIcon fontSize="small"/>
                        <NativeSelect className="recruitment-dropdown" value={selectedClientAdmin} onChange={this.handleChangeClientAdmin}>
                          {clientAdminList.filter((o: any) => o.user_name !== "").map((item: any) => (
                            <option key={"client-admin"+item.id} value={item.id}>{getDropdownFullName(item.user_name)}</option>
                          ))}
                        </NativeSelect>
                    </div>) }
                  </div>
                  <CardContent className='hy-cards-body' style={{padding:0}}>
                    {role === "coordinator" && <CompletedInterviewsChart key={completedInterviewList.length + "-completed-interviews"} data={completedInterviewList}/>}
                    {role !== "coordinator" && role !== "panel" && <RecruitingStatisticsChart data={recruitingStatistics} key={recruitingStatistics.length + "-recruiting-statistics"} />}
                    {role === "panel" && <PaymentStatisticsChart />} 
                  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} sm={12} lg={5}> 
                <Card className="hy-cards hy-candidate-items" style={{height: "calc(100% - 45px)"}}>
                  <div className={setSecondChartTitleClass(role)}>
                    <h2 className="dashboard-table-heading"> {dounutHeading} </h2>
                    {role==="panel" && (interviewList.upcoming && interviewList.upcoming.length > 1) && <Button style={{fontWeight:"bold",color: 'rgba(12, 52, 70, 1)',fontSize:"20px"}} onClick={() => {this.setState({...this.state, openUpcomingInterviews: true})}}>VIEW ALL</Button>}  
                  </div>
                  <CardContent className='hy-cards-body' style={{padding:0}} >
                    {role === "coordinator" && 
                      <DonutChart
                        key={interviewSummaryForCoordinator.total_interviews}
                        labels={["Completed", "Cancelled", "Scheduled"]} 
                        colors={["rgba(19, 187, 109, 1)", "rgba(255, 60, 60, 1)", "rgba(244, 145, 19, 1)"]}
                        series={[interviewSummaryForCoordinator.completed_interviews, interviewSummaryForCoordinator.cancelled_interviews, interviewSummaryForCoordinator.scheduled_interviews]}
                        totalLabel="Total Interviews"
                        totalCount={interviewSummaryForCoordinator.total_interviews}
                      />
                    }
                    {role === "recruiter" && 
                      <DonutChart
                        key={interviewSummaryForRecruiter.selected + interviewSummaryForRecruiter.rejected + interviewSummaryForRecruiter.in_progress}
                        labels={["Selected", "In Progress", "Rejected"]} 
                        colors={["rgba(19, 187, 109, 1)", "rgba(244, 145, 19, 1)", "rgba(255, 60, 60, 1)"]}
                        series={[interviewSummaryForRecruiter.selected, interviewSummaryForRecruiter.in_progress, interviewSummaryForRecruiter.rejected]}
                        totalLabel="Total Candidates"
                        totalCount={interviewSummaryForRecruiter.selected + interviewSummaryForRecruiter.rejected + interviewSummaryForRecruiter.in_progress}
                      />}
                    {(role === "client admin" || role ==="manager") &&
                      <DonutChart
                        key={interviewSummaryForManager.completed + interviewSummaryForManager.rejected + interviewSummaryForManager.in_progress}
                        labels={["Selected", "In Progress", "Rejected"]} 
                        colors={["rgba(19, 187, 109, 1)", "rgba(244, 145, 19, 1)", "rgba(255, 60, 60, 1)"]}
                        series={[interviewSummaryForManager.completed, interviewSummaryForManager.in_progress, interviewSummaryForManager.rejected]}
                        totalLabel="Total Candidates"
                        totalCount={interviewSummaryForManager.completed + interviewSummaryForManager.rejected + interviewSummaryForManager.in_progress}
                      />}
                      {showUpcomingInterviewsForPanel(role, interviewList)}
                      {showNoDataFoundForUpcomingInterviews(role, interviewList)}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>

          <TableContainer component={Paper} style={{borderRadius: "8px", marginTop:10}}>
      <div style={{
          border: "none",
          padding: "16px 16px 0px"
        }}
        className="dashboard-table-heading">
        {tableHeading}
      </div>
      <Table stickyHeader style={{ minWidth: "650", padding:"0px 16px" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeaders.map((head)=><TableCell align="left" className="hy-dashboard-thead" key={`dashboard-t-${head}`}>{head}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? <TableRow> <div style={{ padding: "18px 0 ", fontSize: "16px", fontWeight: 900 }}> No Data Found </div> </TableRow> : null}
          {rows?.map((row,ind) => {
            return(
            <TableRow data-test-id="table-row" key={row.name+ind} className="hy-dashboard-tbody">
              <TableCell align="left">
                <span style={{display: "flex",alignItems:"center"}}>
                    {role !== "recruiter" &&
                      <Avatar style={getAvatarColor(ind)} className={"me-2 avatar-icon"}>
                        {getAvatarTwoLetters(row.name)}
                      </Avatar>}
                 {row.name}
                </span>
              </TableCell>
              <TableCell align="left">{row.job}</TableCell>
              <TableCell align="left">{row.companyName}</TableCell>
              <TableCell align="left">{showSlotDateColumn(role, row.slotDate)}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    {openUpcomingInterviews && <UpcomingInterviewsDialog open={openUpcomingInterviews} handleClose={() => {this.setState({...this.state, openUpcomingInterviews: false})}} interviewList={interviewList.upcoming}/>}
      </div>
      );
  }
}
// Customizable Area End