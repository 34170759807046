Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.jobDetailAPiEndPoint = "account_block/jobs/"
exports.candidateDetailAPiEndPoint = "account_block/candidates/"
exports.candidateReviewsAPiEndPoint = "bx_block_reviews/candidate_reviews"
exports.joinInterviewApiEndPoint = "account_block/jobs/joined_interview?id="
exports.optOutApiEndPoint = "opted_out/"
exports.InterviewStatusApiEndPoint = "get_interview_by_slots/"
exports.getApiMethod = "GET";
exports.putApiMethod = "put";
exports.postApiMethod = "POST";
exports.btnExampleTitle = "CLICK ME";
exports.getExperienceListApiEndPoint = "account_block/jobs/experience_list";
exports.interviewJoiningIssueApiEndPoint = "bx_block_coordinator/coordinator/coordinator_interviews/joining_issue"
exports.getLocationsApiEndPoint = "account_block/jobs/locations_list";
exports.getClientAdminListApiEndPoint = "account_block/candidates/";

// Customizable Area End