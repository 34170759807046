// Customizable Area Start

import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button, TextField, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, Drawer,
  TableContainer, TableHead, TableRow, Menu, MenuItem, IconButton, Avatar, Dialog, DialogTitle, Tooltip 
} from "@material-ui/core"
import Pagination from '@material-ui/lab/Pagination';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { SearchIcon, SelectedStatusIcon, RejectedStatusIcon } from "../assets"
import OptingoutComponent  from './OptingoutComponent.web';

interface Props {
  show: (id: number) => void;
  hide?: any;
  data: any[] | "";
  assignCandidateListData?: any;
  getAssignCandidateList?: any;
  assignCandidate?: any;
  optoutCandidate?: any
  getAssignCandidatesData?: any;
  getSearchAssignCandidateList?: any;
  scheduleSlots?: () => void;
  candidateInterviewDetails: any;
  getSearchAppliedJobCandidateList?: any;
  sourceList: any[];
  assignCandidateBtn: boolean;
  reAddCandidate: any;
}

const getCandidateId = (item: any) => {
  return item.candidate_id ? item.candidate_id : item.id;
}

const setAssignedCandidateBtnClass = (isSelected: boolean) => {
  return `w-80 ${isSelected ? " hy-btn" : " hy-assign-btn"}`;
}

const setPreviousBtnClass = (page: any) => {
  return page == 0 ? "next-prev-btn disable" : "next-prev-btn active";
}

const setNextBtnClass = (page: any, assignCandidateListData: any) => {
  return page + 1 < assignCandidateListData.length / 10 ? "next-prev-btn active" : "next-prev-btn disable";
}

const setAssignedCandidateBtnText = (isSelected: boolean) => {
  return isSelected ? "ADDED" : "ADD";
}

const onSetAssignCandidateSelectedIds = (list: any, candidate_id: any) => {
  const count = list.length;
  let ids = list.filter((id: any) => id !== candidate_id);
  if (count === ids.length && candidate_id) {
    ids.push(candidate_id);
  }
  
  return ids;
}

const showHiyameeUserMessage = () => {
  return localStorage.getItem("is_hiyamee") === "true" &&
  <div className='error-msg' style={{marginTop: -20}}>Displaying all candidate records, please assign respective client admin - candidates</div>;
}

export default function CandidatesSection({ show, hide, candidateInterviewDetails, getAssignCandidatesData, optoutCandidate, assignCandidate,
  getAssignCandidateList, getSearchAssignCandidateList, assignCandidateListData, data, getSearchAppliedJobCandidateList, sourceList, assignCandidateBtn, reAddCandidate }: Props) {
  
  const [openAssignCandidates, setOpenAssignCandidates] = useState(false);
  const [assignCandidateSelected, setAssignCandidateSelected] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  
  useEffect(() => {
    _getAssignCandidates()
  }, [])
  
  function back() {
    
    setOpenAssignCandidates(false);
    setAssignCandidateSelected([]);
    setPage(0);
    getAssignCandidatesData("");
  }

  async function _getAssignCandidates() {
    
    await getAssignCandidatesData("");
  }

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [assignCandidateListData]);
  
  const appliedRows = () => {
    return (
      <div className="candidate-table-wrapper view-job-candidate-table-wrapper ">
      {/* @ts-ignore */}
      <CandidateTable rows={data} show={(id) => { show(id); }} optoutCandidate={optoutCandidate} sourceList={sourceList} reApply={reAddCandidate} />
      </div>
    )
  }
  
  const appliedButton = () => {
    return (
      <div className="button assign-candidate-btn">
        <button className="btn-2 pointer"
          onClick={() => back()}>CANCEL</button>
        <button className="btn-3 pointer" onClick={() => { assignCandidate(assignCandidateSelected); back() }}>SAVE</button>
      </div>
    )
  }
  
  const setAssignCandidates = () => {
    return (
      <Button data-test-id="assign-candidate-btn" className="hy-btn" style={{ fontWeight: 600, marginRight: "16px", borderRadius: "8px", width: "187px", display: `${assignCandidateBtn ? "none" : "block"}` }}
        disabled={assignCandidateBtn}
        onClick={() => { setOpenAssignCandidates(true); getAssignCandidatesData(""); setAssignCandidateSelected([]) }}>ASSIGN CANDIDATES</Button>
    )
  }
  
  const setButton = () => {
    return (
      <div data-test-id="previous-btn" className={setPreviousBtnClass(page)}
        onClick={() => { setPage(page - 1) }} >Prev</div>
    )
  }
  
  const setNextButton = () => {
    return (
      <div data-test-id="next-btn" className={setNextBtnClass(page, assignCandidateListData)}
        onClick={page + 1 < assignCandidateListData.length / 10 ? () => { setPage(page + 1); document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', }) }
          : () => { }} >Next</div>
    )
  }
  
  return (
    <React.Fragment>
      {data === "" && <>Loading...</>}
        <div >
          <div className='view-job-candidates-wrapper'>
            <span style={{ fontSize: "20px", fontWeight: 900 }}> {data.length} Candidates</span>
            <div className="view-job-candidates-toolbar">
              {setAssignCandidates()}
              <TextField placeholder="Search Candidates" variant="outlined"
                style={{ borderRadius: "8px", width: "190px" }}
                data-test-id="assign-candidate-search-input"
                onChange={(e: any) => getSearchAppliedJobCandidateList(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchIcon} height="19" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          {appliedRows()}


          {openAssignCandidates && <Drawer
            anchor={'right'}
            open={openAssignCandidates}
            PaperProps={{ style: { width: "50%" } }}
            className="assign-candidate-wrapper"
          >
            <div className="draw-cont" >
              <div className="heading assign-heading">Assign Candidates
                <div className="closed pointer" data-test-id="close-assign-candidate"
                  onClick={() => back()}
                >X</div>
              </div><br />
              <div className="assign-candidate-search">
                <TextField data-test-id="search-assign-candidate-input" placeholder="Search Candidates" variant="outlined"
                  onChange={(e: any) => getAssignCandidatesData(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} height="19" />
                      </InputAdornment>
                    ),
                  }}
                />
                {showHiyameeUserMessage()}
              </div>
              <TableContainer className="assign-table-container">
                <Table stickyHeader aria-label="sticky table" className='assign-candidate-table-wrapper'>
                  <TableHead>
                    <TableRow className='assign-table-tr'>
                      <TableCell >First Name</TableCell>
                      <TableCell align="left">Last Name</TableCell>
                      <TableCell align="left">Phone Number</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assignCandidateListData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => {
                        
                      let candidate_id = getCandidateId(item);
                      //@ts-ignore
                      
                      const isSelected = assignCandidateSelected.includes(candidate_id)
                      return (
                        <TableRow className="pointer assign-table" key={candidate_id}>
                          <TableCell scope="row" className="block_container assign-table-th">
                            <div className="inline">
                              {getFormattedName(item.first_name)}
                            </div>
                          </TableCell>
                          <TableCell align="left">{getFormattedName(item.last_name)}</TableCell>
                          <TableCell align="left">{item.phone}</TableCell>
                          <TableCell align="left" >
                            {/* @ts-ignore */}
                            <Button data-test-id="assign-btn" variant="outlined" className={setAssignedCandidateBtnClass(isSelected)}
                              onClick={() => {
                                
                                let ids: any = onSetAssignCandidateSelectedIds(assignCandidateSelected, candidate_id);
                                
                                setAssignCandidateSelected(ids)
                              }}>
                              {/* @ts-ignore */}
                              {setAssignedCandidateBtnText(isSelected)}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='adjust-pagination-scroll' >
                <div> Showing {assignCandidateListData.length > 0 && page * 10 + 1} {!assignCandidateListData.length && 0}
                {" "}to {page * rowsPerPage + rowsPerPage > assignCandidateListData.length && assignCandidateListData.length}
                  {page * rowsPerPage + rowsPerPage < assignCandidateListData.length && page * rowsPerPage + rowsPerPage}
                  {" "}of {assignCandidateListData.length} entries </div>
                {
                  assignCandidateListData.length > 10 &&
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "8px" }}>
                    {setButton()}
                    <Pagination siblingCount={0} boundaryCount={1} className='assign-candidate-pagination' count={Math.ceil(assignCandidateListData.length / 10)} shape="rounded" page={page + 1}
                      onChange={(event, page) => setPage(page - 1)} hidePrevButton hideNextButton />
                    {setNextButton()}
                  </div>
                }
              </div>
              {appliedButton()}

            </div>
          </Drawer>}
        </div>
      
    </React.Fragment>
  )
}


////////////// Candidates Table

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: { // to get scroll bar in table 
    maxHeight: "100%",
  },
});

interface Column {
  id: 'candidate_name' | 'email' | 'source' | 'round_status' | 'status' | 'more_icon';
  label: string;
  minWidth: number;
  align?: 'left';
}


let appliedColumns: Column[] ;
appliedColumns = [
  { id: 'candidate_name', label: 'Candidates Name', minWidth: 100, align: 'left', },
  { id: 'email', label: 'Email ID', minWidth: 100, align: 'left', },
  {
    id: 'source',
    label: 'Source',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'round_status',
    label: 'Round Status',
    minWidth: 130,
    align: 'left',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'more_icon',
    label: '',
    minWidth: 50,
    align: 'left',
  },
];


const getFormattedName = (name: string) => {
  let fullName = "";
  if (name.indexOf(' ') >= 0) {
    const firstNameLower = name.split(" ")[0].toLowerCase();
    const lastNameLower = name.split(" ")[1].toLowerCase();
    const firstName = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
    const lastName = lastNameLower[0].toUpperCase() + lastNameLower.slice(1);
    fullName = `${firstName} ${lastName}`;
  } else {
    
    const firstNameLower = name.toLowerCase();
    const firstName = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
    fullName = firstName;
  }
  
  return fullName
}

function getStatus (status: string, roundList: any = []) {
  let msg = status;
  let statusClass = "status-log";
  const isUnderProcess: boolean = roundList.find((item: any) => item.round_status === "round_selected");
  switch (status) {
    case "processing":
      statusClass = "status-warning";
      break;
    case "selected":
      statusClass = "status-completed";
      break;
    case "rejected":
      statusClass = "status-error";
      break;
    case "opted_out":
      statusClass = "status-opted-out";
      msg = "Opted Out";
      break;
    case "job_closed":
      statusClass = "status-opted-out";
      msg = "Job Closed";
      break;
    case "applied":
      statusClass = isUnderProcess ? "status-warning" : "status-log";
      msg = isUnderProcess ? "Processing" : "Applied";
      break;
  }
  return <div className={`${statusClass} dashboard-table-status`}>
    {msg}
  </div>
}

const showPaginationEntryText = (rows: any, page: any, rowsPerPage: any) => {
  const rLength = rows.length;
  return `Showing ${rLength ? page * 10 + 1 : 0} to ${page * rowsPerPage + rowsPerPage > rLength ? rLength : page * rowsPerPage + rowsPerPage} of ${rLength} entries`;
}

const nextBtnClassName = (page: any) => {
  return page == 0 ? "next-prev-btn disable" : "next-prev-btn active"
}

const prevBtnClassName = (page: any, rows: any) => {
  return page + 1 < rows.length / 10 ? "next-prev-btn active" : "next-prev-btn disable";
}

const setRoundStatusIcon = (status: any, ind: number) => {
  if (status=== "round_rejected") {
    return <Tooltip title={`Round ${ind+1}`}><img src={RejectedStatusIcon} height={35} alt="rejected" style={{ margin: 5 }} /></Tooltip>
  } else if (status=== "round_selected") {
    return <Tooltip title={`Round ${ind+1}`}><img src={SelectedStatusIcon} height={35} alt="selected" style={{ margin: 5 }} /></Tooltip>
  } else {
    return <Tooltip title={`Round ${ind+1}`}><div className='round-status-empty-block' /></Tooltip>
  }
}

const getRoundStatusList = (row: any) => {
  return (row.job_interviews_rounds && row.job_interviews_rounds.length > 0) ? row.job_interviews_rounds.filter((i: any) => i.round !== null) : [];
}

const showRoundStatus = (roundStatusList: any, row: any) => {
  return <div className='round-status'>
    {roundStatusList ?
      new Array(row.total_inverview_round).fill("a")?.map((a: any, ind: number) => (setRoundStatusIcon(roundStatusList[ind]?.round_status, ind))) :
      new Array(row.total_inverview_round).fill("a")?.map((item: any, ind: number) => (<Tooltip title={`Round ${ind+1}`}><div className='round-status-empty-block' /></Tooltip>))}
  </div>
}

const getAvatarText = (row: any, column: any) => {
  return row[column.id] && row[column.id].includes(' ') ? row[column.id].split(" ")[0][0] + row[column.id].split(" ")[1][0] : row[column.id][0];
}

export function CandidateTable({ rows, show, optoutCandidate, sourceList, reApply }: { optoutCandidate: any, rows: any[], show: (id: number) => void, sourceList: any[], reApply: any }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openOptingOut, setOpenOptingOut] = React.useState(false);
  const [err_msg, setErrorMsg] = useState("")
  let [candidateId, setCandidateId] = React.useState<number>(0);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const appliedMenu = () => {
    return (
      <MenuItem id="opt-out" onClick={() => { setOpenOptingOut(true); handleClose(); }}>Opting out</MenuItem>
    )
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10
  useEffect(() => {
    const pageNo = page;
    setPage(pageNo);
  }, [rows])


  const getAvatarColor = (index: number) => {
    const avatarColorArray = [
      { color: "rgba(57, 0, 153, 1)", backgroundColor: "#e7e0f2" },
      { color: "rgba(244, 91, 105, 1)", backgroundColor: "#fdebed" },
      { color: "rgba(0, 166, 251, 1)", backgroundColor: "#e0f4fe" },
      { color: "rgba(38, 169, 108, 1)", backgroundColor: "#e5f5ee" }
    ];
    const number = index < avatarColorArray.length ? index : index % avatarColorArray.length;
    return { background: avatarColorArray[number].backgroundColor, color: avatarColorArray[number].color }
  }
  const getSourceValue = (id: any) => {
    let name = "";
    const obj = sourceList.find((item: any) => item.id === Number(id));
    if (obj) {
      name = obj.name;
    } else if (id && !Number(id)) {
      name = id;
    }
    return name;
  }
  return (
    <Paper className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="opt-out"
        style={{ borderRadius: 10 }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {appliedMenu()}
      </Menu>
      <Grid className='candidate-head-custom'>
        <TableContainer className={classes.container}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {appliedColumns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, paddingLeft: '10px !important' }}
                    className="candidate-table-header-cell"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='candidate-body-custom'>
              {rows.length === 0 ? <TableRow> <div style={{ padding: "18px", fontSize: "16px", fontWeight: 900 }}> No Data Found </div> </TableRow> : 
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, ind: number) => {
                 
                const roundStatusList = getRoundStatusList(row);
                return (
                  <>
                    <TableRow data-test-id="candidate-section-table-row" hover role="checkbox" tabIndex={-1} key={ind} onClick={() => show(row.candidate_id)}>
                      {appliedColumns.map((column: any) => {
                        
                        let value
                        if ("more_icon" === column.id)
                          value = row.status !== "opted_out" && <IconButton style={{ padding: 6 }} onClick={(e) => { e.stopPropagation(); handleMenu(e); setCandidateId(row.candidate_id);}} > <MoreVertIcon style={{ verticalAlign: "middle" }} /> </IconButton>
                        else if ("round_status" === column.id)
                          value = showRoundStatus(roundStatusList, row)
                        else if ("status" === column.id)
                          value = getStatus(row[column.id], roundStatusList)
                        else if ("email" === column.id)
                          value = row[column.id].toLowerCase()
                        else if ("candidate_name" === column.id)
                          value = <div style={{ display: "inline-flex", alignItems: "center" }}> <Avatar style={getAvatarColor(ind)} className={"me-2 avatar-icon"}>
                            {getAvatarText(row, column)}
                          </Avatar> {getFormattedName(row[column.id])}  </div>
                        else
                          value = getSourceValue(row[column.id])
                        return (
                          //@ts-ignore
                          <TableCell key={column.id} align={column.align} style={{textTransform:"email" === column.id && "lowercase"}}>
                            {value}
                          </TableCell >
                        );
                      })}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <div className='assigned-candidates-responsive-footer' style={{ display: 'flex', justifyContent: 'space-between', padding: "18px", alignItems: "center" }}>
        <div> {showPaginationEntryText(rows, page, rowsPerPage)}  </div>
        {
          rows.length > 0 &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className={nextBtnClassName(page)} onClick={() => { setPage(page - 1) }} >Prev</div>
              <Pagination style={{ margin: "0px 10px" }} count={Math.ceil(rows.length / 10)} shape="rounded" page={page + 1} onChange={(event, page) => setPage(page - 1)} hidePrevButton hideNextButton />
              <div className={prevBtnClassName(page, rows)} onClick={page + 1 < rows.length / 10 ? () => { setPage(page + 1); document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', }) } : () => { }} >Next</div>
            </div>
        }
      </div>

      <Dialog open={openOptingOut} fullWidth scroll='body' maxWidth="sm" className="default-timing-dialog-wrapper">
        <DialogTitle className="dialog-title">
          <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
            <div style={{ fontSize: 22 }}>Opting Out?</div>
            <IconButton size="small" onClick={() => setOpenOptingOut(false)}><CloseIcon className="close-icon-btn" /></IconButton>
          </div>
        </DialogTitle>
        <OptingoutComponent setErrorMsg={setErrorMsg} optoutCandidate={optoutCandidate} setOpenOptingOut={setOpenOptingOut} candidateId={candidateId} err_msg={err_msg} />

      </Dialog>
    </Paper>
  );
}

// Customizable Area End
