// Customizable Area Start
import React, { PureComponent, useEffect } from 'react'
import {  createStyles, withStyles,Theme, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import CloseIcon from '@material-ui/icons/Close'; 
import { Formik, FieldArray } from 'formik';
import {Grid, TextField, Button, Divider, CircularProgress, Input, Radio} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {DeleteChipIcon, DeleteIcon, RadioCheckedIcon, RadioOutlinedIcon} from "../assets"
import { getFullName } from '../../../dashboard/src/Dashboard.web';


export const useStyles = (theme:Theme) => createStyles({
    mainDiv:{
        [theme.breakpoints.down("sm")]:{
        height:"100vh",
        width:"100vw"}
    },
    hidden:{
      display:"none"
    },
    cancelBtn:{
      position: "absolute",
      right: "16px",
      top: "16px",
    }
  }
)

const scrollToErrorField = (errorList: any) => {
  const errVal = Object.keys(errorList);
  if (errVal.length) {
    const inputOb = document.querySelector(
      `input[name=${errVal[0]}]`
    );
    const dropdownOb = document.querySelector(
      `select[name=${errVal[0]}]`
    );
    if (inputOb) {
      inputOb.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (dropdownOb) {
      dropdownOb.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }
}

const validateStep1 = (values: any) => {
  let errors:any = {};

  if (!values.job_role) {
    errors.job_role = 'Job Role is Required';
  }
  else if (!values.category) {
    errors.category = 'Category is Required';
  }
  else if (!values.company_name) {
    errors.company = 'Company is Required';
  }
  else if (!values.salaryRange  ) {
    errors.salaryRange = 'Salary Range is Required';
  }
  else if ( !values.salaryRange.includes("-") ) {
    errors.salaryRange = 'Enter Correct Format i.e. 100-1000 ';
  }
  else if (  !( Number(values.salaryRange.split("-")[0]) && Number(values.salaryRange.split("-")[1]) )  ) 
  {
      errors.salaryRange = 'Please Enter in Number format i.e. 100-1000 ';         
  }
  else if (  ( Number(values.salaryRange.split("-")[0]) >= Number(values.salaryRange.split("-")[1]) )  ) 
  {
      errors.salaryRange = 'Number Format should be Val 1(Min)< Val 2(Max ) i.e.100-1000'; 
  }
  else if (!values.location.length ) {
    errors.location = 'Location is Required';
  }
  else if ( ! values.job_type ) {
    errors.job_type = 'Job Type is Required';
  }
  else if ( !values.experience ) {
    errors.experience = 'Experience is Required';
  }
  else if ( !values.description ) {
    errors.description = 'Description is Required';
  }

  else if (values.mandatory_skills.length ==  0 ) {
    errors.mandatory_skills = 'Select atleast one Mandatory Skill';
  }
  else if (values.optional_skills.length ==  0 ) {
    errors.optional_skills = 'Select atleast one Optional Skill';
  }
 
   let err= Validations(values,errors);
   const objectData = {values, errors,err};
  return objectData;

}


const Validations= (values:any,errors:any) =>{

  if (!values.manager_data) {
     errors.manager_data='Manager is Required';
  }
  else if (!values.recruiter_data) {
     errors.recruiter_data='Recruiter is Required';
  } else {
      let roundNameErrorList = values.interview_rounds.filter((ob: any) => ob._destroy !== 1).map((i: any) => "");
      let roundDescriptionErrorList = values.interview_rounds.filter((ob: any) => ob._destroy !== 1).map((i: any) => "");
      values.interview_rounds.filter((ob: any) => ob._destroy !== 1).forEach((item: any, ind: number) => {
        if (!item.name) {
          roundNameErrorList[ind] = "Round Name is Required";
        } 
        if (!item.description) {
          roundDescriptionErrorList[ind] = "Round Details are Required";
        }
      });
      errors.round_name = roundNameErrorList;
      errors.round_description = roundDescriptionErrorList;

      const isRoundNameErrorPresent: any = errors.round_name.find((i: any) => (i !== ""));
      const isRoundDescriptionErrorPresent: any = errors.round_description.find((i: any) => (i !== ""));
    
      if (!isRoundNameErrorPresent) {
        delete errors.round_name;
      }
      if (!isRoundDescriptionErrorPresent) {
        delete errors.round_description;
      }
  }
  return errors;
}

const getSelectedLocation = (ids: any, locationList: any) => {
  let locationString = "";
  let locationArray: any[] = [];
  if (ids && ids.length > 0 && typeof(ids) !== "string") {
    ids.filter((id: any) => {
      const location = locationList.find((i: any) => i.id == id)?.name || "";
      if (location) {
        locationArray.push(location);
      }
    })
    locationString = locationArray.join(", ");
  }
  return locationString;
}

const setInitialManagerValue = (mValue: any, managers: any) => {
  let managerId = "";
  if (mValue) {
    const manager = managers.find((i: any) => i.id == mValue);
    managerId = manager ? manager.id : "";
  }
  return managerId;
}

const setInitialRecruiterValue = (rValue: any, recruiters: any) => {
  let recruiterId = "";
  if (rValue) {
    const recruiter = recruiters.find((i: any) => i.id == rValue);
    recruiterId =  recruiter ? recruiter.id : "";
  }
  return recruiterId;
}

const getInitialData = (data: any, managerList: any, recruiterList: any) => {
  const m_skills = data.get_dummy_data.skills.filter((skill: any) => !skill.optional).map((skill: any) => skill.name);
  const o_skills = data.get_dummy_data.skills.filter((skill: any) => skill.optional).map((skill: any) => skill.name);
  let illustrator_ques: { id: any, questions: string } = { id: "", questions: "" };
  let photoshop_ques: { id: any, questions: string } = { id: "", questions: "" };
  let adobe_ques: { id: any, questions: string } = { id: "", questions: "" };
  let illustrator_question: string = "";
  let photoshop_question: string = "";
  let adobe_question: string = "";

  if (data.get_dummy_data.interview_type) {
    illustrator_ques = data.get_dummy_data.interview_type.find((type: any) => type.name == "illustrator");
    photoshop_ques = data.get_dummy_data.interview_type.find((type: any) => type.name == "photoshop");
    adobe_ques = data.get_dummy_data.interview_type.find((type: any) => type.name == "adobe");

    illustrator_question = illustrator_ques.questions;
    photoshop_question = photoshop_ques.questions;
    adobe_question = adobe_ques.questions;
  }
  const initValues = {
    id: data.id, job_role: data.job_role || "NA", job_id: data.id, category: data.job_category_id || "", company_name: data.get_dummy_data.company_name || "NA", salaryRange: data.get_dummy_data.al_range || "NA", location: data.location || [], job_type: data.job_type_id || "", experience: data.experience || "", description: data.description || "No description found :(",
    mandatory_skills: m_skills, mandatory_skills_id: [],
    optional_skills: o_skills, optional_skills_id: [],
    no_of_positions: data.no_of_positions, manager_data: setInitialManagerValue(data.manager_id, managerList), recruiter_data: setInitialRecruiterValue(data.recruiter_id, recruiterList), round: "", round_details: "", interview_rounds: data.get_dummy_data.interview_rounds && data.get_dummy_data.interview_rounds.length && data.get_dummy_data.interview_rounds || [{ name: "", description: "" }],
    interviewer_type: data.interviewer || '', interviewer_media_mode: data.interview_mode || '', interviewer_video: data.show_interview_video || '', min_schedules_slot: data.min_shedule_slots || '', photoshop_ques: photoshop_question, adobe_ques: adobe_question, illustrator_ques: illustrator_question,
  }
  return initValues;
}

const getEditJobClass = (show: boolean, hidden: any) => {
  return show ? 'wrapper-dialog fullscreen-dialog' : hidden
}

const showLoader = (loader: boolean, mainDiv: any) => {
  return <>
    {loader &&
      <div className={"circular-loader " + mainDiv}>
        <CircularProgress style={{ width: "30px", height: "30px", color: '#083247' }} />
      </div>
    }
  </>
}

const showErrorMessage = (error: any) => {
  return <span className={error ? 'error-msg' : ""}>{error}</span>
}

const getExperienceDropdownOptions = (list: any) => {
  return <>
    {list.length > 0 ? list.map((item: any) => (
      <option key={item.id + "experience"} value={item.id}>{item.years} {`${item.years === "1" ? " Year" : " Years"}`}</option>
    )) : null}
  </>
}

const getDropdownClass = (value: any) => {
  return `textbox edit-job-select-icon ${value ==="" ? "defaultOption":""}`
}

const getRoundNameErrorList = (error: any, index: number) => {
  if (error && error.round_name && error.round_name.length > 0) {
    return error.round_name[index];
  } else {
    return ""
  }
}

const getRoundDescriptionErrorList = (error: any, index: number) => {
  if (error && error.round_description && error.round_description.length > 0) {
    return error.round_description[index];
  } else {
    return ""
  }
}

interface Props{
  getOptions:any;
  categories:any;
  data:any;
  updateJob:any;
  jobTypes:any;
  experienceList: any[];
  loader: boolean;
  locationList: any[]
}

interface State{
    dashboardData:any[];
    errorMsg: string;
    token: string;
    loading: boolean;
    step: number;
    showDialog:boolean;
    active_closed_jobs:string;
    createJobsAddRounds:any[];
    allJobsList:string;
    errors:any;
    categories: any;
    editJobSection : string;
}
  
export class EditJob extends PureComponent<Props,State> {
  constructor(props: any) {
    super(props)
    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      step: 1,
      showDialog:false,
      active_closed_jobs:"active",
      createJobsAddRounds:[1],
      allJobsList:"",
      errors:{},
      categories: {mandatory_skill:[],optional_skill:[],job_category:[]},
      editJobSection:"jobDetails",
    }
      //@ts-ignore
      this.getData = this.getData.bind(this)
    }

    data: any = {jobs:{}}

  getData() {
    //@ts-ignore
    console.log("Form DATA", document.getElementById("jobCategory")?.options[document.getElementById("jobCategory")?.selectedIndex].value)
  }

  updateJobSubmit = (values: any) => {
    const { data } = this.props;
    const illustrator_questions = data.get_dummy_data.interview_type && data.get_dummy_data.interview_type.filter((type: any) => type.name == "illustrator") || [{ id: null, questions: "" }]
    const photoshop_questions = data.get_dummy_data.interview_type && data.get_dummy_data.interview_type.filter((type: any) => type.name == "photoshop") || [{ id: null, questions: "" }]
    const adobe_questions = data.get_dummy_data.interview_type && data.get_dummy_data.interview_type.filter((type: any) => type.name == "adobe") || [{ id: null, questions: "" }]
    const illustrator_ques_id = illustrator_questions[0]?.id
    const photoshop_ques_id = photoshop_questions[0]?.id
    const adobe_ques_id = adobe_questions[0]?.id

    const [min_salary, max_salary] = values.salaryRange.split("-")
    const interview_types_attributes = [{ name: "photoshop", id: photoshop_ques_id, questions: values.photoshop_ques },
    { name: "adobe", id: adobe_ques_id, questions: values.adobe_ques },
    { name: "illustrator", id: illustrator_ques_id, questions: values.illustrator_ques }
    ]
    let job_skill_attribute = [...values.mandatory_skills_id.map((skill: any) => ({ skill_id: skill.id ? skill.id : skill.name, optional: false })), ...values.optional_skills_id.map((skill: any) => ({ skill_id: skill.id ? skill.id : skill.name, optional: true }))]
    let reqPayload = {
      job: {
        job_role: values.job_role,
        job_id: values.job_id,
        job_type_id: values.job_type,
        company_name: values.company_name,
        max_salary: max_salary.trim(),
        min_salary: min_salary.trim(),
        location: values.location.map((id: any) => id.toString()),
        description: values.description.trim(),
        no_of_positions: (values.no_of_positions && values.no_of_positions !== "NA") ? values.no_of_positions : "",
        job_category_id: values.category,
        interviewer: values.interviewer_type,
        interview_mode: values.interviewer_media_mode,
        show_interview_video: values.interviewer_video,
        min_shedule_slots: values.min_schedules_slot,
        job_skills_attributes: job_skill_attribute,
        interview_rounds_attributes: values.interview_rounds,
        interview_types_attributes: interview_types_attributes,
        experience: `${values.experience}`,
        manager_id: values.manager_data,
        recruiter_id: values.recruiter_data,
      }
    }
    this.props.updateJob(values.id, reqPayload)
  }

  validateProfile = (values: any, handleSubmit: any) => {
    const ob = validateStep1(values);

    if (!Object.keys(ob.errors).length) {
      this.setState({...this.state, errors: {}})
      return this.updateJobSubmit(values)
    }
    else {
      this.setState({ ...this.state, errors: { ...ob.errors } })
      scrollToErrorField(ob.errors);
    }
  }
    
  render() {
   
    //@ts-ignore
    const { classes, show, hide, loader } = this.props
    const { errors } = this.state
    const fontColor = {
      style: { color: 'black' }
    }
    return (
      <div style={{ top: 0,right: 0 }} className={getEditJobClass(show, classes.hidden)}>
        {showLoader(loader, classes.mainDiv)}
        <Formik
          initialValues={ getInitialData(this.props.data, this.props?.categories?.managers, this.props?.categories?.recruiters) }
          onSubmit={(values, { setSubmitting }) => {
            this.data.jobs = {...values}
            console.log("values",values)
            
          }}
        >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        isSubmitting,
        setErrors
      }) => (
        <form onSubmit={handleSubmit}>
            <div className={'inner-space-jobscreation '+ classes.mainDiv}>
              <div className="jobs-header-block">
                  <div className="header-item" style={{ marginBottom: "-6px" }} >
                    <span className='fw-bold' >Edit Job Details</span>
                    <span className="pointer" onClick={hide}>  
                      <CloseIcon/>
                    </span>
                  </div>
                  <div style={{margin: '0px 0px 0px -6px'}} >
                    <span className="admin-jobs-heads" >
                      <input type="radio" name="edit-jobs-sections" id="jobDetails" defaultChecked={this.state.editJobSection==="jobDetails"} />
                      <label htmlFor="jobDetails" className="" onClick={()=>{this.setState({ ...this.state , editJobSection:"jobDetails"}) }} >
                      Job Details
                      </label>
                    </span>
                    <span className="admin-jobs-heads"  >
                      <input type="radio" name="edit-jobs-sections" id="interview-details" defaultChecked={this.state.editJobSection==="interviewDetails"} />
                      <label htmlFor="interview-details" className="" onClick={()=>{this.setState({ ...this.state , editJobSection:"interviewDetails"}); }} >
                          Interview Details
                      </label>
                    </span>
                    <span className="admin-jobs-heads"  >
                      <input type="radio" name="edit-jobs-sections" id="interview-panels" defaultChecked={this.state.editJobSection==="interviewPanels"} />
                      <label htmlFor="interview-panels" className="" onClick={()=>{this.setState({ ...this.state , editJobSection:"interviewPanels"}); }} >
                        Configure Interview Panels
                      </label>
                    </span>
                  </div>
                </div>
            <div >
            
            </div>
              { this.state.editJobSection=== "jobDetails" && 
            <div className="jobs-forms-body" style={{paddingTop:"100px"}} ><div className='hy-forms-block' > 
              <Grid container direction="row" spacing={3} >
                <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job Role<span className="require-field">*</span></label>
                    <TextField className='textbox' placeholder="Enter Job Role" name='job_role' value={values.job_role} onChange={handleChange}/>
                </div>
                {showErrorMessage(errors.job_role)}
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job ID</label>
                    <TextField disabled inputProps={fontColor} className='textbox' placeholder="Enter Job ID" name='job_id' value={values.job_id} onChange={handleChange} />
                    {showErrorMessage(errors.job_id)}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable ej'>Category<span className="require-field">*</span></label> 
                    <Select IconComponent={(ctgProps) => (<KeyboardArrowDownIcon {...ctgProps}/>)} className={getDropdownClass(values.category)} native name='category' id='category-id' defaultValue={values.category} onChange={handleChange} >
                        <option aria-label="" className='ej-category' disabled value="">Select Category</option>
                        { this.props?.categories?.job_category?.map((item:any,ind:number)=>
                            <option className='ej-category' value={item.id} key={ind} >{getFullName(item.name)}</option>
                          )
                        }
                    </Select>
                    {showErrorMessage(errors.category)}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Company</label>
                    <TextField className='textbox' disabled inputProps={fontColor} placeholder="Enter Company Name" name='company' value={values.company_name} onChange={handleChange}/>
                    {showErrorMessage(errors.company)}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Salary Range <span>(LPA)</span><span className="require-field">*</span></label>
                    <TextField className='textbox' placeholder="Enter Salary Range" name='salaryRange' value={values.salaryRange} onChange={handleChange}/>
                    {showErrorMessage(errors.salaryRange)}
                </div>
            </Grid>
            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Location<span className="require-field">*</span></label>
                    <Select
                      renderValue={(selected: any) => {
                        if ((selected as string[]).length === 0) {
                          return "Select Location";
                        }
                        return getSelectedLocation(selected, this.props.locationList);
                      }}
                      displayEmpty
                      input={<Input />} multiple IconComponent={(locProps) => (<KeyboardArrowDownIcon {...locProps} />)} className={`textbox location edit-job-select-icon ${(values.location && values.location.length > 0) ? "":"defaultOption"}`} name='location' value={(values.location && typeof(values.location) !=="string" && values.location.length > 0) ? values.location : []} onChange={handleChange} >
                      <MenuItem disabled value="">
                        Select Location
                      </MenuItem>
                        {(this.props.locationList && this.props.locationList.length > 0) && this.props.locationList.map((item: any) => (
                          <MenuItem className='ej-location' key={"location"+item.id} value={item.id.toString()}>{getFullName(item.name)}
                        </MenuItem>
                        ))}
                    </Select>
                    {showErrorMessage(errors.location)}
                </div>
            </Grid>

            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job Type<span className="require-field">*</span></label>
                    <Select IconComponent={(jtProps) => (<KeyboardArrowDownIcon {...jtProps} />)} className={getDropdownClass(values.job_type)} native name='job_type' defaultValue={values.job_type} onChange={handleChange}>
                        <option aria-label="None" value="" disabled >Select Job Type</option>
                        { this.props?.jobTypes?.map((item:any,ind:number)=>
                            <option className='ej-job-type' value={item.id} key={ind} >{getFullName(item.name)}</option>
                          )
                        }
                    </Select>
                    {showErrorMessage(errors.job_type)}
                </div>
            </Grid>

            <Grid item xs={12} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Experience<span className="require-field">*</span></label>
                    <Select IconComponent={(exProps) => (<KeyboardArrowDownIcon {...exProps} />)} className={getDropdownClass(values.experience)} native defaultValue={values.experience} name='experience' onChange={handleChange} >
                    <option aria-label="None" value="" disabled >Select Experience</option>
                    {getExperienceDropdownOptions(this.props.experienceList)}
                    </Select>
                    {showErrorMessage(errors.experience)}
                </div>
            </Grid>

            <Grid item xs={12} sm={12} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Job Description<span className="require-field">*</span></label> 
                    <TextField className='textbox textbox1' rows="4" placeholder="Enter Job Description" multiline name='description' value={values.description} onChange={handleChange} /> 
                    {showErrorMessage(errors.description)}
                </div>
            </Grid>

            <Grid item xs={12} sm={12} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Mandatory Skills<span className="require-field">*</span></label> 
                    <MultipleSelect  setFieldValue={setFieldValue} placeholder="Select Mandatory Skills" data={this.props?.categories?.commonSkills.filter((i: any) => (i.name !== "" && i.name))} tagName="mandatory_skills" hChange={handleChange} vals={values.mandatory_skills} />
                    {showErrorMessage(errors.mandatory_skills)}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Optional Skills <span className="require-field">*</span></label> 
                    <MultipleSelect setFieldValue={setFieldValue} placeholder="Select Optional Skills" data={this.props?.categories?.commonSkills.filter((i: any) => (i.name !== "" && i.name))} tagName="optional_skills" hChange={handleChange} vals={values.optional_skills} />
                    {showErrorMessage(errors.optional_skills)}
                </div>
              </Grid>
        
          </Grid>
        </div>

        </div>}
         {this.state.editJobSection=== "interviewDetails" && <div className="jobs-forms-body">
        <div className='hy-forms-block' >  
        <Grid container direction="row" spacing={3} style={{margin:"0 -12"}}  >
            <Grid item xs={12} sm={4} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>No of Positions</label>
                    <TextField className='textbox' placeholder="Enter No of Positions" name='no_of_positions' value={values.no_of_positions} onChange={handleChange}/>
                   {showErrorMessage(errors.no_of_positions)}
                </div>
            </Grid>

            <Grid item xs={12} sm={4} spacing={4}>  
            <div className='form-group'>
                    <label className='hy-forms-lable'>Manager<span className="require-field">*</span></label> 
                    <Select IconComponent={(mngProps) => (<KeyboardArrowDownIcon {...mngProps} />)} className={getDropdownClass(values.manager_data)} native name='manager_data' defaultValue={values.manager_data} onChange={handleChange}>
                    <option aria-label="None" disabled value="">Select Manager</option>
                    { this.props?.categories?.managers?.map((item:any,ind:number)=>
                        <option className='ej-manager' value={item.id} key={ind} >{getFullName(`${item.first_name} ${item.last_name}`)}</option>
                      )
                    }
                </Select>
                {showErrorMessage(errors.manager_data)}
                </div>
                </Grid>

            <Grid item xs={12} sm={4} spacing={4}>              
              <div className='form-group'>
                  <label className='hy-forms-lable'>Recruiter<span className="require-field">*</span></label>
                  <Select IconComponent={(recProps) => (<KeyboardArrowDownIcon {...recProps} />)} className={`textbox edit-job-select-icon ${values.recruiter_data==="" ? "defaultOption":""}`} native name='recruiter_data' defaultValue={values.recruiter_data} onChange={handleChange}>
                  <option aria-label="" disabled value="">Select Recruiter</option>
                  { this.props?.categories?.recruiters?.map((item:any,ind:number)=>
                      <option className='ej-recruiter' key={ind} value={item.id}>{getFullName(`${item.first_name} ${item.last_name}`)}</option>
                    )
                  }
              </Select>
              {showErrorMessage(errors.recruiter_data)}
              </div>
            </Grid>
            <FieldArray name="interview_rounds">
                {({ insert, remove, push, replace }) => (
            <Grid item xs={12} sm={12} md={12} lg={12} spacing={4} >
            <span style={{fontSize:"16px", fontWeight:"bold"}}>Interview Rounds</span>
                  {values.interview_rounds.sort((a: any, b: any) => a.id - b.id).filter((round:any)=> round._destroy!==1).map(( item:any, index: number, array:any[] ) => { 
                    return <AddRounds roundNo={index+1} id={item.id} hChange={handleChange} delBtn={ array.length != 1 } delF={(ind:number)=>{
                      replace(ind,{id:item.id,_destroy:1})
                    }} 
                    insert={replace}
                    value1={item.name } 
                    value2={item.description }
                    error1={getRoundNameErrorList(errors, index)}
                    error2={getRoundDescriptionErrorList(errors, index)}
                    />
                    })
                  }
                  <div style={{fontWeight:"bold", color: "#083247", fontSize: 14,marginTop:8}} className="pointer"
                onClick={()=>{ 
                  push({name:"",description:""})
                }} 
                >                 
                + ADD ROUND
                </div>
        
            </Grid>
            )
          }
        </FieldArray>
          </Grid>
        </div></div> }
        {this.state.editJobSection=== "interviewPanels" &&  
        <div className="jobs-forms-body"> <div className='hy-forms-block' >  
        <Grid container direction="row" spacing={3} style={{margin:"0 -12"}}>
        
            <Grid item xs={12} sm={12} spacing={4} style={{paddingBottom: 8}}>              
                <div className='form-group'>
                  <div className='fw-bold'>Interviewer<span className="require-field">*</span></div>
                  <div className='hy-radio-group'>
                    <span>
                      <Radio disableRipple id="hiyameeExpert" name="interviewer_type" checked={values.interviewer_type === "Hiyamee Expert"} value="Hiyamee Expert" icon={<img src={RadioOutlinedIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                      <label className='icon-target pointer' htmlFor="hiyameeExpert">Hiyamee Expert</label>
                    </span>
                    <span style={{ marginLeft: 29 }}>
                      <Radio disableRipple id="InHouseExpert" name="interviewer_type" checked={values.interviewer_type === "In-House Expert"} value="In-House Expert" icon={<img src={RadioOutlinedIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                      <label className='icon-target pointer' htmlFor="InHouseExpert">In-House Expert</label>
                    </span>
                  </div>
                {showErrorMessage(errors.interviewer_type)}
                </div>
            </Grid>
        
            <Grid item xs={12} sm={12} spacing={4} style={{paddingBottom: 8}}>              
                <div className='form-group'>
                <div className='fw-bold'>Interview Media Mode<span className="require-field">*</span></div>
                <div className="hy-radio-group">
                  <span>
                    <Radio disableRipple id="AudioOnly" name="interviewer_media_mode" checked={values.interviewer_media_mode === "Audio Only"} value="Audio Only" icon={<img src={RadioOutlinedIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                    <label className='icon-target pointer' htmlFor="AudioOnly">Audio Only</label>
                  </span>
                  <span style={{marginLeft: 68}}>
                    <Radio disableRipple id="AudioVideo" name="interviewer_media_mode" checked={values.interviewer_media_mode === "Audio-Video"} value="Audio-Video" icon={<img src={RadioOutlinedIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                    <label className='icon-target pointer' htmlFor="AudioVideo">Audio-Video</label>
                  </span>
                </div>
                  {showErrorMessage(errors.interviewer_media_mode)}
                </div>
            </Grid>
        
            <Grid item xs={12} sm={12} spacing={4} style={{paddingBottom: 8}}>              
                <div className='form-group'>
                <div className='fw-bold'>Show Interviewer Video<span className="require-field">*</span></div>
                <div className="hy-radio-group">
                  <span>
                    <Radio disableRipple id="ShowSelfVideo" name="interviewer_video" checked={values.interviewer_video === "Yes"} value="Yes" icon={<img src={RadioOutlinedIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                    <label className='icon-target pointer' htmlFor="ShowSelfVideo">Yes</label>
                  </span>
                  <span style={{ marginLeft: 124 }}>
                    <Radio disableRipple id="HideSelfVideo" name="interviewer_video" checked={values.interviewer_video === "No"} value="No" icon={<img src={RadioOutlinedIcon} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                    <label className='icon-target pointer' htmlFor="HideSelfVideo">No</label>
                  </span>
                </div>
                {showErrorMessage(errors.interviewer_video)}
                </div>
            </Grid>
                                  
            <Grid item xs={12} sm={12} spacing={4}>              
                <div className='form-group'>
                    <label className='hy-forms-lable'>Minimum Schedules Slots<span className="require-field">*</span></label> 
                    <Select IconComponent={(slProps) => (<KeyboardArrowDownIcon {...slProps} />)} className={getDropdownClass(values.min_schedules_slot)} native name='min_schedules_slot' defaultValue={values.min_schedules_slot}  onChange={handleChange}>
                        <option value="" disabled >Select Minimum Schedules Slots</option>
                        <option value={'1 slot'}> 1 Slot </option>
                        <option value={'2 slots'}> 2 Slot </option>
                        <option value={'3 slots'}> 3 Slot </option>
                    </Select>
                    {showErrorMessage(errors.min_schedules_slot)}
                </div>
            </Grid>
            
            <Grid item xs={12} sm={12} spacing={4}>
                <div className='form-group'> <Divider/> </div>
            </Grid>
        
            <Grid item xs={12} sm={12} md={12} lg={12} spacing={4}>              
              <div className='form-group'>
                <label className='hy-forms-lable'>Interview Type</label> 
                <AddQuestions text="Photoshop" tagName="photoshop_ques"  hChange={handleChange} data={values.photoshop_ques} /> 
                <AddQuestions text="Illustrator" tagName="illustrator_ques" hChange={handleChange} data={values.illustrator_ques} /> 
                <AddQuestions text="Adobe XD" tagName="adobe_ques" hChange={handleChange} data={values.adobe_ques} /> 
              </div>
            </Grid>
            
          </Grid>
        </div>
        </div>
  }
          
        <div className="jobs-footer-block">
          <Button variant='outlined' className='btn-100px fw-bold' style={{paddingTop:"8px"}} color='inherit' onClick={()=>{ hide(); }} >
            CANCEL
          </Button>

          <div className='hy-btn MuiButton-root btn-100px fw-bold' style={{textAlign: "center",paddingTop:"12px"}}  onClick={()=>{ this.validateProfile(values,handleSubmit); }}  >
            SAVE
          </div> 
        </div>
        </div>
      </form>
      )}
    </Formik>
    </div>
    )
  }
}

export default withStyles(useStyles, { withTheme: true })(EditJob)

function AddRounds({roundNo, hChange, delBtn, delF, insert, id, value1, value2, error1, error2}:{id:any,roundNo:number, hChange:any, delBtn:boolean, delF:any,insert:any , value1:string, value2:string, error1?: string, error2?: string }) {
  return <>
    { roundNo > 1 ? <Divider/> : null }
    <Grid item xs={12} sm={12} md={12} lg={12} spacing={4}>              
      <div className='form-group'>
        <label className='hy-forms-lable' style={{position: 'relative'}}>
          {"Round "+roundNo}
          <span className="require-field">*</span>
          { roundNo > 1 ?
            <span style={{color:"#cf1d1de0",position:"absolute",right:8,fontSize:"12px"}} className="pointer" onClick={()=>{delF(roundNo-1); }}> 
              <img src={DeleteIcon} alt="DeleteIcon" style={{height:"13px",marginRight:"4px"}}  />
              DELETE ROUND
            </span>
            : null
          }
        </label> 
        <TextField name={`interview_rounds.${roundNo-1}.name`} className='textbox' placeholder={`Enter Round ${roundNo} Name`} multiline value={value1} 
        onChange={
          (e)=>{
            insert(roundNo-1,{id:id,name:e.target.value,description:value2})
            }} /> 
      </div>
      {showErrorMessage(error1)}
    </Grid>

    <Grid item xs={12} sm={12} spacing={4}>              
      <div className='form-group'>
        <label className='hy-forms-lable'> Details<span className="require-field">*</span></label> 
        <TextField className='textbox textbox1' rows="4" value={value2} placeholder="Enter Details" multiline defaultValue={value2} name={`interview_rounds.${roundNo-1}.description`} 
          onChange={(e)=>{
            insert(roundNo-1,{id:id,name:value1,description:e.target.value})
          }}/> 
      </div>
      {showErrorMessage(error2)}
    </Grid>
  </>
}

function AddQuestions({text,tagName,hChange,data}:{text: string, tagName: string, hChange:any,data:string}){
  const [state,setState] = React.useState<boolean>(!!data)
  return     <Grid item xs={12} sm={12} spacing={4} style={{marginTop:10}} >              
            <div style={{position: 'relative',height:"33px",padding:"8 10"}} className="add-content-input">
              {text}
              <span className='add-btn pointer' onClick={()=> setState(!state) } >{state ? "-" : "+" }</span>
            </div>
            {state ? <TextField className='textbox textbox1 mt-3' rows="4" defaultValue={data} placeholder="Add Questions" name={tagName} onChange={hChange} multiline/> : null }
          </Grid>
}

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0
    },
    formControl: {
      margin : "0px",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop:10
    },
    chip: {
      margin: theme.spacing(0.5),
      color: "#083247",
      fontWeight:"bold",
      marginLeft:0,
      borderRadius: 4
    },
    btn:{
        color : "#083247",
    },
    fullWidth:{
        minWidth:"100%",
        width:"100%",
    },
    itemList:{
      maxHeight:"110px ",
    }
  })
);

function MultipleSelect({setFieldValue,placeholder,data,tagName,hChange,vals}:{setFieldValue:any,placeholder:string,data:any[],tagName:string,hChange:any,vals:any[]}) {
    const handleDeleteMy = (chipToDelete: string) => async() => { 
      const restChips = skillName.filter((chip) => chip !== chipToDelete)
      setSkillName(restChips);
      setFieldValue(`${tagName}`,restChips);
    };

    const handleChange = (event: any) => {
      if (!skillName.includes(event.target.value[0])) {
        setFieldValue(`${tagName}`,[...vals, event.target.value[0]]);
        setSkillName([...skillName, event.target.value[0]]);
        setTempValue([]);
      }
    };
    const handleBlur = (val: any) => {
      if (!skillName.includes(val)) {
        setFieldValue(`${tagName}`,[...vals, val]);
        setSkillName([...skillName, val]);
      }
    };
  const classes = Styles();
  const [skillName, setSkillName] = React.useState<string[]>( [ ...vals ]);
  const [tempValue, setTempValue] = React.useState<string[]>([]);

  useEffect(()=>{
    console.log("aaaa1",skillName)
    let ids = skillName.map((name)=>{
      let filteredData = data.filter((d)=> d.name === name);
      if (!filteredData.length && name) {
        filteredData.push({id: "", name: name});
      }
      console.log("filteredData",filteredData)
      return filteredData[0] ? filteredData[0] : skillName.slice(-1)[0]
    })
    console.log("aaaa",ids)
    setFieldValue(`${tagName}_id`,ids)
  },[skillName])

  return (
    <> 
        <FormControl
          variant="outlined"
          className={`${classes.formControl} ${classes.fullWidth}`}
        >
        <Autocomplete
          fullWidth 
          forcePopupIcon={true} popupIcon={<KeyboardArrowDownIcon style={{color: "#083247"}}/>}
          multiple
          value={tempValue}
          selectOnFocus
          handleHomeEndKeys
          options={data.map((value, key) => getFullName(value.name))}
          onChange={(e,value) => { handleChange({target:{value}}) }}
          onBlur={(e:any) => {setTempValue([]);
            if (e.target.value) {
              handleBlur(e.target.value)
            }
          }}
          renderOption={(option) => option}
          freeSolo
          getOptionDisabled={(option) => skillName.includes(option)}
          renderInput={(params) => (
            <TextField {...params} InputProps={{
              ...params.InputProps
            }} name={tagName} placeholder={!tempValue.length ? placeholder : ""} onChange={()=>console.log("options",params)} variant="outlined" className="text-input multi-select-input"/>
          )}
        />
        <div className={classes.chips}>
          {skillName.map((value, key) => (
            <Chip
              key={value + key}
              label={value}
              onDelete={ handleDeleteMy(value) }
              className={`skills-chip ${classes.chip}`}
              deleteIcon={<img src={DeleteChipIcon} alt="delete"/>}
            />
          ))}
        </div>
      </FormControl>
    </>
  );
}

// Customizable Area End
