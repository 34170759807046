  // Customizable Area Start

import React from 'react'
import { Button } from '@material-ui/core'
import VideosController from './VideosController'
import './meetingPage.css'

export default class MeetingPage extends VideosController {
  constructor(props:any){
    super(props)
  }
  render() {
    //@ts-ignore
    const {reviewPopup} = this.props
    return (
      <div className='fullscreen-dialog zoom-page' style={{zIndex: 1}}>
        <Button onClick={reviewPopup} >Send Review</Button>
      </div>
    )
  }
}



  // Customizable Area End