// Customizable Area Start
import React, { useEffect } from 'react'
import DocumentOpenerCommonController from "../DocumentOpenerCommonController"
import { BackBtn, SuccessIcon, CloseJob,CompanyIcon, DeleteJob, ScheduleIcon,DropDownIcon, CalenderIcon, StarFilledIcon, StarIcon, RadioCheckedIcon, RadioOutlinedIcon } from "../assets"
import {Button, Avatar, MenuItem, IconButton, Modal, Popover, Grid, Select, CircularProgress, Dialog, createTheme} from "@material-ui/core"
import MoreIcon from "@material-ui/icons/MoreVert";
import CloseIcon from '@material-ui/icons/Close'
import {Link} from "react-router-dom"
import EditJobPage from "./EditJob.web"
import InterviewRoundsSection from "./InterviewRoundsSection.web"
import JobDetailsSection from "./JobDetailsSection.web"
import CandidatesSection from './CandidatesSection.web';
import CandidateDetails from './CandidateDetails.web';
import { DatePicker, MuiPickersUtilsProvider,TimePicker} from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles"
import DateFnsUtils from '@date-io/date-fns';
import "./style.css"
import moment from 'moment';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
const mainConfigJson = require("../../../../framework/src/config");

const getJobRole = (roleName: any) => {
  return roleName ? roleName : "";
}

const getCompanyName = (company: any) => {
  return company ? company : "";
}

const getManagerName = (manager: any) => {
  return manager ? manager : "";
}

const checkForDisabledField = (status: string) => {
  return (status === "cancelled" || status === "missed_out")
}

const getFormattedDate = (dateInput:string) => {
  let formattedDate = "";
  if (dateInput) {
    const day = dateInput.substring(0,dateInput.indexOf("-"));
    const month = dateInput.substring(
      dateInput.indexOf("-") + 1, 
      dateInput.lastIndexOf("-")
    );
    const year = dateInput.substring(
      dateInput.lastIndexOf("-")+1,
      dateInput.length
    );
    formattedDate = `${day}-${Number(month)+1}-${year}`;
  }
  return formattedDate;
}

const datePlaceHolder = (date: any) => {
  return getFormattedDate(date) === "" ? "Select Date" : "";
}

const getTimeDropDownOption = (startTime: any, index: any) => {
  return `${startTime+index<=12 ? startTime+index : startTime+index-12 }:30` + `${ startTime+index <= 11 ? "AM" : "PM"}`
}

export default class ViewJobsPage extends DocumentOpenerCommonController {
  // id: number = 0;
  async componentDidMount() {
    //@ts-ignore 
    const historyState = this.props.history;
    //@ts-ignore 
    const locationState = this.props.location.state;
    if (historyState.action === "REPLACE") {
      this.setState({...this.state, view_job_section:"candidates", showCandidateDetails:true, candidateId: locationState.candidateId})
    }
    if(!locationState || !locationState.id)
    {
      //@ts-ignore
      this.props.history.push("/Home/Jobs")
    }
    if (locationState) {
      this.id = locationState.id;
      this.activeJobFilter = locationState.activeJobFilter;
      this.closedJobFilter = locationState.closedJobFilter;
      this.currentTabName = locationState.currentTabName;
      this.type = locationState.type;
    }
    // this.id = this.props.location.state
    this.getJobDetails(this.id)
    this.getAppliedCandidateList(this.id)
    this.getJobCategory();
    this.getJobTypes();
    this.getCommonSkills()
    this.getManagersList()
    this.getRecruiterList();
    this.getLocationList();
    //@ts-ignore 
    this.props.screen("View_Jobs")
  }

  showJobSectionComponent = () => {
    return <>
      {this.state.view_job_section === "jobDetails" &&
        // @ts-ignore
        <JobDetailsSection data={this.state.jobDetails} experienceList={this.props.experienceList} locationList={this.state.locationList} />}
    </>
  }

  showCandidateSectionComponent = () => {
    return <>
      {this.state.view_job_section === "candidates" &&
        <CandidatesSection
          // @ts-ignore
          sourceList={this.props.sourceList} assignCandidateBtn={this.state.jobDetails.status === "closed"} candidateInterviewDetails={this.state.candidateInterviewDetails} getAssignCandidatesData={this.getAssignCandidatesData} assignCandidate={this.assignCandidate} optoutCandidate={this.optoutCandidate} getSearchAssignCandidateList={this.getSearchAssignCandidateList} assignCandidateListData={this.state.assignCandidateListData} getAssignCandidateList={this.getAssignCandidateList} show={(id: number) => { this.setState({ ...this.state, showCandidateDetails: true, candidateId: id }) }} data={this.state.appliedCandidateList} getSearchAppliedJobCandidateList={(searchKey: string) => this.getSearchAppliedCandidateList(this.id, searchKey)} reAddCandidate={(candidateId: any) => this.reAddCandidate(candidateId)} />}
    </>
  }

  showInterviewRoundSectionComponent = () => {
    return <>
      {this.state.view_job_section === "interviewRounds" &&
        <InterviewRoundsSection data={this.state.jobDetails.get_dummy_data.interview_rounds} />}
    </>
  }

  showJobCompanyLogo = () => {
    const logo: string = this.state.jobDetails?.get_dummy_data.company_logo;
    if (logo) {
      return <img style={{ height: "55px", borderRadius: "7px", marginRight: "8px", width: "70px" }} src={mainConfigJson.baseURL + logo} />
    } else {
      return <img style={{ height: "35px", borderRadius: "7px", marginRight: "8px", width: "35px" }} src={CompanyIcon} />
    }
  }

  showEditJobButton = () => {
    const role = localStorage.getItem("role")?.toLowerCase();
    return <>
      {role !== "recruiter" &&
        <div>
          <Button className="hy-btn edit-job-details" onClick={() => this.setState({ ...this.state, showDialog: { show: false, msg: "jobDetails" } })} >EDIT JOB DETAILS</Button> <IconButton onClick={(e: any) => this.handleMenu(e)} > <MoreIcon className='custmore' /> </IconButton>
        </div>
      }
    </>
  }

  showSuccessDialogBox = () => {
    return <>
      {this.state.showDialog.msg !== "candidateDetails" && this.state.showDialog.show && <SuccessDialogBox show={this.state.showDialog.show} msg={this.state.showDialog.msg} handleClose={() => this.setState({ ...this.state, showDialog: { show: false, msg: "" } })}
        handleDialogClose={(isReset: any) => this.setState({ ...this.state, showDialog: { show: false, msg: "" }, scheduleNextRoundParams: {} })}
        closeJob={() => { this.closeJob(this.id); }}
        deleteJob={() => { this.deleteJob(this.id); }}
        activateJob={() => { this.activateJob(this.id); }}
        loader={this.state.loader}
        getCandidateInterviewDetails={() => {
          (this.state.scheduleNextRoundParams && this.state.scheduleNextRoundParams.slotId && this.state.scheduleNextRoundParams.roundId)
          ? this.scheduleNextRound(this.state.scheduleNextRoundParams.slotId, this.state.scheduleNextRoundParams.roundId) : this.getCandidateInterviewDetails(this.state.candidateId)
        }}
      />}
    </>
  }

  showChangeSlotsDialog = () => {
    return <>
      {this.state.showDialog.msg === "changeInterviewSlot" &&
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <ChangeSlots show={this.state.showDialog.msg === "changeInterviewSlot"} msg={this.state.showDialog.msg} changeSlots={(vals: any) => vals.forEach(async (val: any) => val.id && val.start_time && val.end_time && this.changeSlots(val))} handleClose={() => this.setState({ ...this.state, showDialog: { show: false, msg: "" }, scheduleNextRoundParams: {} })} slots={this.state.candidateSlotsTiming.filter((item: any) => item.round_id === this.state.scheduleNextRoundParams?.roundId)} />
        </MuiPickersUtilsProvider>
      }
    </>
  }

  showEditJobComponent = () => {
    return <>
      {this.state.showDialog.msg === "jobDetails" &&
        //@ts-ignore
        <EditJobPage loader={this.state.loader} locationList={this.props.locationList} experienceList={this.props.experienceList} getOptions={() => { this.getJobCategory(); this.getJobTypes(); this.getCommonSkills() }} categories={this.state.categories} jobTypes={this.state.job_types} data={this.state.jobDetails} show={this.state.showDialog.msg === "jobDetails"} hide={() => { this.setState({ ...this.state, showDialog: { show: false, msg: "" } }) }} updateJob={(id, values) => this.updateJobDetails(id, values)} />}
    </>
  }

  showCandidateDetailsComponent = () => {
    return <>
      {this.state.showCandidateDetails &&
        <CandidateDetails
          id={this.state.candidateId || 0}
          show={this.state.showCandidateDetails}
          parentComponent={{ path: "/Home/View_Jobs", tab: "candidates" }}
          getCandidateRoundDetails={() => { this.getCandidateInterviewDetails(this.state.candidateId) }}
          candidateInterviewDetails={this.state.candidateInterviewDetails}
          candidateSlotsTiming={this.state.candidateSlotsTiming}
          getScheduleSlots={() => { this.scheduleSlots(this.state.candidateId) }}
          hide={() => { this.setState({ ...this.state, showDialog: { show: false, msg: "" }, showCandidateDetails: false }); this.getAppliedCandidateList(this.id); }}
          scheduleInterview={(bool: boolean, roundId?: any, slotId?: any) => this.setState({ ...this.state, showDialog: { show: true, msg: bool ? "scheduleInterview" : "changeInterviewSlot" }, scheduleNextRoundParams: { slotId: slotId, roundId: roundId } })}
          jobId={this.id}
          openPanelReview={(id: any) => this.getPanelReviews(id)}
        />}
    </>
  }

  showPanelReviewDialog = () => {
    return <>
      {this.state.showPanelReview && <PanelReviewDialog open={this.state.showPanelReview} handleClose={() => this.setState({ ...this.state, showPanelReview: false, panelReviewsObj: {} })} panelReviewsObj={this.state.panelReviewsObj} />}
    </>
  }

  handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({...this.state, anchorEl:event.currentTarget});
  }

  render() { 

    const handleClose = () => {
      this.setState({...this.state, anchorEl:null });
    };
    const backToJobs = async () => {
      await new Promise((resolve, reject) => {
        setTimeout(() =>{
          //@ts-ignore
          this.props.screen("Jobs")},300)
      })
    }
    
    return ( this.state.jobDetails !== "" ? 
    <div style={{fontWeight: 500}}>
      <div style={{display: 'flex',justifyContent: 'space-between',color: '#083247'}}> <Link to={{pathname:'/Home/Jobs', state: {activeJobFilter: this.activeJobFilter, closedJobFilter: this.closedJobFilter, parent:"Jobs", currentTabName: this.currentTabName}}} onClick={backToJobs} style={{fontWeight: 600,cursor: 'pointer',display: "inline-flex",alignItems: "center"}} > <img height={13} style={{marginRight:"8px"}} src={BackBtn} alt="back-btn" /> Back to Jobs </Link> <span style={{color: "black"}}> JOB ID : #{`${this.state.jobDetails.id || "675238152"}`} </span> </div>

      <div className='view-job-head' > 
        <div style={{display: 'flex'}}>
          {this.showJobCompanyLogo()}
          <div style={{display: 'flex',flexDirection: "column", justifyContent: "space-evenly"}}> 
            <span style={{fontSize:"19px", fontWeight:600, textTransform: "capitalize" }} > {getJobRole(this.state.jobDetails.job_role)} </span>
            <span style={{fontSize:"15px"}} > {getCompanyName(this.state.jobDetails.get_dummy_data.company_name)} </span>
          </div>
        </div>
        <div >
          {this.showEditJobButton()}
          {this.showSuccessDialogBox()}
          {this.showChangeSlotsDialog()}
          {this.showEditJobComponent()}
          {this.showCandidateDetailsComponent()}
          {this.showPanelReviewDialog()}
          <Popover
          id="add-candidate-more-options"
          className='edit-job-menu'
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}

          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!this.state.anchorEl}
          onClose={handleClose}
        >
          <MenuItem style={{borderBottom: '1px solid #ccc',minHeight:"48px", paddingRight:40}} disabled={this.state.jobDetails.status === "unassigned_by_manager"||this.state.jobDetails.status ==="unassigned_by_recruiter"||this.state.jobDetails.status === "active"} onClick={()=>{ this.setState({ ...this.state, showDialog:{ show:true,msg:"activateJob" }, anchorEl:null }); }}>Activate Job</MenuItem>
          <MenuItem style={{borderBottom: '1px solid #ccc',minHeight:"48px", paddingRight:40}} onClick={()=>{ this.setState({ ...this.state, showDialog:{ show:true,msg:"deleteJob" }, anchorEl:null }); }}>Delete Job</MenuItem>
          <MenuItem style={{ minHeight:"48px", paddingRight:40}} disabled={this.state.jobDetails.status === "closed"} onClick={()=>{ this.setState({ ...this.state, showDialog:{ show:true,msg:"closeJob" }, anchorEl:null }); }}>Close Job</MenuItem>
        </Popover>
        </div>
      </div>
      <div style={{display: 'flex',alignItems: 'center',fontSize:'14px', textTransform: "capitalize"}} >
        <Avatar className="view-job-avatar view-job-manager-avatar"> { this.state?.jobDetails?.get_dummy_data?.manager && this.state.jobDetails.get_dummy_data.manager.includes(' ') ? this.state.jobDetails.get_dummy_data.manager.split(" ")[0][0]+this.state.jobDetails.get_dummy_data.manager.split(" ")[1][0] : this.state.jobDetails.get_dummy_data.manager[0] || "M" } </Avatar>
          <div>{getManagerName(this.state?.jobDetails?.get_dummy_data?.manager)} (Manager)</div> 
        <Avatar className="view-job-avatar view-job-recruiter-avatar"> {this.state?.jobDetails?.get_dummy_data?.recruiter?.includes(' ') ? this.state.jobDetails.get_dummy_data.recruiter.split(" ")[0][0]+this.state.jobDetails.get_dummy_data.recruiter.split(" ")[1][0] : this.state.jobDetails.get_dummy_data.recruiter[0] || "R" } </Avatar> {getManagerName(this.state.jobDetails.get_dummy_data.recruiter)} (Recruiter) 
      </div>
      <div style={{margin: '0px 0 0px -6px'}}>
        <span className="admin-jobs-heads" >
          <input type="radio" name="view-jobs-sections" id="job-details" defaultChecked={this.state.view_job_section==="jobDetails"} />
          <label htmlFor="job-details" className="" onClick={()=>{this.setState({ ...this.state , view_job_section:"jobDetails"}) }} >
            Job Details
          </label>
        </span>
        <span className="admin-jobs-heads"  >
          <input type="radio" name="view-jobs-sections" id="candidates" defaultChecked={this.state.view_job_section==="candidates"} />
          <label htmlFor="candidates" className="" onClick={()=>{this.setState({ ...this.state , view_job_section:"candidates"}); }} >
            Candidates
          </label>
        </span> 
        <span className="admin-jobs-heads"  >
          <input type="radio" name="view-jobs-sections" id="interview-rounds" defaultChecked={this.state.view_job_section==="interviewRounds"} />
          <label htmlFor="interview-rounds" className="" onClick={()=>{this.setState({ ...this.state , view_job_section:"interviewRounds"}); }} >
            Interview Rounds
          </label>
        </span>
      </div>
      {this.showJobSectionComponent()}
      {this.showCandidateSectionComponent()}
      {this.showInterviewRoundSectionComponent()}
    </div> 
    : <h6>Loading...</h6>
    )
  }
}


function  DeleteActiveJob(params:any){

 return <> {params.msg === "deleteJob" ? "Delete Job" : JobsData(params)} </>
}


function ActiveDeleteJobs(params:any){

  return  <>  {params.msg !== "activateJob" &&  <h6 >Are you sure want to {params.msg === "deleteJob" ? "delete" : "close" } the job ? <br/>
  All the {params.msg === "deleteJob" ? "candidates details" : "running interview process" } will be 
  {params.msg === "deleteJob" ? " removed" : " closed."}</h6> }  </>

}

function JobsData(params:any){
  return params.msg === "activateJob" ? "Activate Job" : "Close Job"
}

function ErrorMsg (params:any){

  return  <>   {params.msg.split(" ") && params.msg.split("^")[1] ?
          <h6> {params.msg.split("^")[1]} </h6>
          : <h6 >Some error is Occured !<br />
            Please try Again
          </h6>} </>
}




function SuccessDialogBox(params: any) {

  return <Modal
    open={params.show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="jobs-success-model"
  >
    <div className="infromation-block">
      {params.msg === "" ? <><h1 > Some error occured </h1> <button className="hy-btn">Close</button> </> : null}
      {params.msg == "deleteJob" || params.msg == "closeJob" || params.msg == "activateJob" ? <>
      { params.loader ?
        <div className={"full-page-loader "}>
          <CircularProgress style={{ width: "30px", height: "30px",color:'#083247' }} />
        </div>
        : null
      }
       < img src={params.msg == "deleteJob"? DeleteJob : params.msg == "closeJob"? CloseJob:SuccessIcon} alt="relative image" style={{ height: "65px", margin: "35px 0" }} /> 
        <h4>
          {DeleteActiveJob(params)} ?
          
        </h4>
         {ActiveDeleteJobs(params)}

        {params.msg === "activateJob" &&
           <h6 >Are you sure want to activate the job ?</h6>
        }
        <div style={{ display: "flex",height: "56px", marginTop: "24px"}}>
        <Button onClick={() => {
          params.handleClose();
        }}
          variant='outlined'
          style={{fontWeight: 600,marginRight:"5px", flex:1, borderRadius:"8px" }}
        >
          CANCEL
        </Button>
        <Button onClick={() => { params.msg === "closeJob" ? params.closeJob() : params.msg === "deleteJob" ? params.deleteJob() : params.msg === "activateJob" ? params.activateJob() : params.handleClose();
        }}
          className="hy-btn"
          style={{fontWeight: 600,marginLeft:"5px",flex:1, borderRadius:"8px" }}
        >
          CONFIRM
        </Button>
        </div>
      </>
        : params.msg === "scheduleInterview" ? 
        <>
          <img src={ScheduleIcon} alt="relative image" style={{ height: "65px", margin: "35px 0" }} /> 
          <h4>
            Schedule Interview
          </h4>
          <h6 >Are you sure want to schedule the interview ? <br/>
          Slots will be auto generated</h6>
          <div style={{ display: "flex",height: "56px", marginTop: "24px"}}>
            <Button onClick={() => {
              params.handleClose();
            }}
              variant='outlined'
              style={{fontWeight: 600,marginRight:"5px", flex:1, borderRadius:"8px" }}
            >
              CANCEL
            </Button>
            <Button onClick={() => { params.getCandidateInterviewDetails(); params.handleClose()}}
              className="hy-btn"
              style={{fontWeight: 600,marginLeft:"5px",flex:1, borderRadius:"8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </>
        : 
      null}
      {params.msg.split("^") && params.msg.split("^")[0] && params.msg.split("^")[0] === "apiError" ? <>
        <CloseIcon style={{ color: "red" }} />
        <h4>Error Occured</h4>
        {ErrorMsg}
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button onClick={() => {
            params.handleClose();
          }} variant="outlined" className="hy-btn" fullWidth style={{ borderRadius: 8, height: 56 }}>
            OKAY
          </Button>
        </div>
      </>
        : null}
    </div>
  </Modal>
}
  
export const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    } ,
  },
});

const dateComparator = (startDate: any, endDate: any) => {
  const start_date = moment(moment(updateSelectedDate(startDate), "DD-MM-YYYY"));
  const end_date = moment(moment(updateSelectedDate(endDate), "DD-MM-YYYY"));
  const diffCount = start_date.diff(end_date, "day");
  return diffCount;
}

const timeComparator = (startTime: any, endTime: any) => {
  const start_time = moment(moment(startTime, "hh:mm A"));
  const end_time = moment(moment(endTime, "hh:mm A"));
  const diffMinCount = start_time.diff(end_time, "minute");
  return diffMinCount;
}

const setErrorMessage = (data: any, index: any, errList: any, hasValidObj: any) => {
  let isValidOb = hasValidObj;
  if (!data[index].start_time) {
    errList[index] = "Start Time is Required";
  } else if (!data[index].start_date) {
    errList[index] = "Start Date is Required";
  } else if (!data[index].end_time) {
    errList[index] = "End Time is Required";
  } else if (!data[index].end_date) {
    errList[index] = "End Date is Required";
  } else if (dateComparator(data[index].start_date, data[index].end_date) > 0) {
    errList[index] = "Start Date should not be greater than End Date";
  } else if (dateComparator(data[index].start_date, data[index].end_date) === 0 &&
    timeComparator(data[index].start_time, data[index].end_time) > -1) {
    errList[index] = "Start Time should not be greater than or equal to End Time";
  } else {
    errList[index] = "";
    isValidOb = true;
  }
  return { errList, isValidOb };
}

const checkValidation = (data: any) => {
  let errList = data.map((item: any) => "");
  let hasValidObj = false;
  const idList: any[] = data.map((item: any, index: number) => (item.start_time || item.start_date || item.end_time || item.end_date) &&
    (item.status !== "cancelled" && item.status !== "missed_out") && index);
  const indexList = idList.filter((i: any) => i !== "");
  if (indexList.length > 0) {
    indexList.forEach((index: any) => {
      if (typeof (index) === "number") {
        const eResponse = setErrorMessage(data, index, errList, hasValidObj);
        errList = eResponse.errList;
        hasValidObj = eResponse.isValidOb;
      }

    })
  } else {
    const emptyObIndex = data.findIndex((item: any) => (!item.start_time && !item.start_date && !item.end_time && !item.end_date) &&
      (item.status !== "cancelled" && item.status !== "missed_out") && !hasValidObj);
    if (emptyObIndex > -1) {
      const eResponseForEmptyOb = setErrorMessage(data, emptyObIndex, errList, hasValidObj);
      errList = eResponseForEmptyOb.errList;
      hasValidObj = eResponseForEmptyOb.isValidOb;
    }
  }
  return errList;
}

const updateSelectedDate = (date: any) => {
  const dateValue = date.substring(0, date.indexOf("-"));
  const monthValue = date.substring(date.indexOf("-") + 1, date.lastIndexOf("-"));
  const yearValue = date.substring(date.lastIndexOf("-") + 1, date.length);
  const newDate = `${dateValue}-${Number(monthValue) + 1}-${yearValue}`;
  return newDate;
}

const createChangeSlotData = (data: any) => {
  let newData: any[] = [];
  data.forEach((item: any) => {
    if (item.start_date && item.start_time && item.end_date && item.end_time) {
      newData.push(
        {
          id: item.id,
          start_time: moment(updateSelectedDate(item.start_date) + " " + item.start_time, "DD-MM-YYYY hh:mm A"),
          end_time: moment(updateSelectedDate(item.end_date) + " " + item.end_time, "DD-MM-YYYY hh:mm A")
        }
      );
    }
  })
  return newData;
}

const setChangeSlotInputClassName = (status: any) => {
  return status === "missed_out" || status === "cancelled" ? "select_date disabled" : "select_date";
}

function ChangeSlots(params: any) {
  const [data, setData] = React.useState(params?.slots?.map((slot: any) => ({ id: slot.id, start_time: "", start_date: "", end_time: "", end_date: "", status: slot.status})));
  const [errors, setErrors] = React.useState<any[]>([]);

  const confirmSlots = () => {
    const errList: any = checkValidation(data);

    setErrors(errList);
    const isValid = errList.find((item: any) => item !== "") ? false : true;
    if (isValid) {
      const newData = createChangeSlotData(data);
      params.handleClose();
      if (newData.length > 0) {
        params.changeSlots(newData);
      }
    }
  }

  const getFormattedTime = (value: any) => {
    let hours = value.getHours();
    let minutes = value.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    if (hours) {
      hours = hours >= 10 ? hours: '0'+hours;
    } else {
      hours = 12;
    }
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  return <Dialog
    open={params.show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="change-slots-wrapper"
    maxWidth="md"
    scroll='body'
  >
    <div style={{ padding:"20px", background:"white", borderRadius:"7px", margin:"0 auto", width:"450px" }}>
    {params.msg === "changeInterviewSlot" &&
        <>
          <div style={{ display: "flex", justifyContent: "space-between",alignItems: "center"}}>
          <div style={{fontSize:"20px", fontWeight: 900}}>
            Change Slots
          </div>
          <CloseIcon className="pointer" onClick={params.handleClose} />
          </div>
          {data.map((slot:any,ind:number)=><>
              <h3 key={slot.id} style={{ fontSize:"16px", fontWeight: 400}} >Slot {ind+1}</h3>
              <Grid key={slot.id+"a"} container direction="row" spacing={3} >
                <Grid item xs={6} sm={6} spacing={4}>              
                <div className='form-group'>
                    <label style={{fontWeight: 500}} className="field-label" >Start Time</label>
                    <label onClick={()=>document.getElementById(`start_time_${ind+1}`)?.click()}>
                      <div   style={{display:'flex'}} className={setChangeSlotInputClassName(slot.status)}>
                     <div style={{width:'70%'}}>  {slot.start_time} <div style={{opacity:'0.6'}}> {slot?.start_time===''?'Select Time':''} </div> </div>
                     <div style={{width:'30%'}} ><img src={DropDownIcon} height={6} style={{opacity:checkForDisabledField(slot.status) ? 0.2:1}} className='dropIcon' /> </div>
                    </div>
                    </label>
                  </div>
                  <ThemeProvider theme={defaultMaterialTheme}>
                <TimePicker  className="hidden-date" label="Start Time" disabled={checkForDisabledField(slot.status)} value={new Date()} id={`start_time_${ind+1}`}  onChange={(e: any) =>{
                  const dataToSet = [...data]
                  dataToSet[ind] = {...dataToSet[ind] , start_time:getFormattedTime(e)}
                  setData(dataToSet) }} />
               </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6} spacing={4}   >               
                  <div className='form-group' style={{ position: 'relative'}}>
                    <label style={{ fontWeight: 500 }} className="field-label" >Start Date</label>
                    <label onClick={()=>document.getElementById(`start_date_${ind+1}`)?.click()}>
                    <div className={setChangeSlotInputClassName(slot.status)} onClick={()=>document.getElementById(`start_date_${ind+1}`)?.click()}>
                      {getFormattedDate(slot.start_date)} <span className='date_select'> {datePlaceHolder(slot.start_date)} </span>
                      <img src={CalenderIcon} height={22} style={{opacity:checkForDisabledField(slot.status) ? 0.2:0.5,marginLeft:5,marginBottom:-4,float:'right'}} alt="calender" />
                    </div>
                    </label>
                    < ThemeProvider theme={defaultMaterialTheme}>
                      <DatePicker 
                        className="hidden-date" 
                        style={{position: "absolute"}} 
                        openTo="year" 
                        format="dd/MM/yyyy" 
                        views={["year", "month", "date"]} 
                        minDate={moment()} 
                        maxDate={moment().add(30,"d")}  
                        value={moment()} 
                        id={`start_date_${ind+1}`} 
                        onChange={(e)=>{  
                          const dataToSet = [...data]
                          dataToSet[ind] = {...dataToSet[ind] , start_date:`${e?.getDate()}-${e && e?.getMonth()}-${e?.getFullYear()}` }
                          setData(dataToSet)
                          }}
                        disabled={checkForDisabledField(slot.status)}
                        />
                    </ ThemeProvider>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} spacing={4}>              
                    <div className='form-group'>
                    <label style={{fontWeight: 500}} className="field-label" >End Time</label>
                    <label onClick={()=>document.getElementById(`end_time_${ind+1}`)?.click()}>
            
                    <div   style={{display:'flex'}} className={setChangeSlotInputClassName(slot.status)}>
                     <div style={{width:'70%'}}>  {slot.end_time} <div style={{opacity:'0.6'}}> {slot?.end_time===''?'Select Time':''} </div> </div>
                     <div style={{width:'30%'}} ><img src={DropDownIcon} height={6}  style={{opacity:checkForDisabledField(slot.status) ? 0.2:1}} className='dropIcon' /> </div>
                    </div>
                    </label>
                  </div>
                  <ThemeProvider theme={defaultMaterialTheme}>
                <TimePicker  className="hidden-date" disabled={checkForDisabledField(slot.status)} label="End Time" value={new Date()} id={`end_time_${ind+1}`}  onChange={(e: any) =>{
                  const dataToSet = [...data]
                  dataToSet[ind] = {...dataToSet[ind] , end_time:getFormattedTime(e)}
                  setData(dataToSet) }}
                   />
               </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6} spacing={4}   >               
                  <div className='form-group' style={{ position: 'relative' }}>
                    <label style={{ fontWeight: 500 }} className="field-label" >End Date</label>
                    <label onClick={()=>document.getElementById(`end_date_${ind+1}`)?.click()}>
                    <div className={setChangeSlotInputClassName(slot.status)} onClick={()=>document.getElementById(`end_date_${ind+1}`)?.click()}>
                      {getFormattedDate(slot.end_date)} <span className='date_select'> {datePlaceHolder(slot.end_date)} </span>
                      <img src={CalenderIcon} height={22} style={{opacity:checkForDisabledField(slot.status) ? 0.2:0.5, marginLeft:5,marginBottom:-4,float:'right'}} alt="calender" />
                    </div>
                    </label>
                    < ThemeProvider theme={defaultMaterialTheme}>
                      <DatePicker 
                        className="hidden-date" 
                        style={{position: "absolute"}} 
                        openTo="year" 
                        format="dd/MM/yyyy" 
                        views={["year", "month", "date"]} 
                        minDate={moment()} 
                        maxDate={moment().add(30,"d")}  
                        value={moment()} 
                        id={`end_date_${ind+1}`} 
                        onChange={(e)=>{  
                          const dataToSet = [...data]
                          dataToSet[ind] = {...dataToSet[ind] , end_date:`${e?.getDate()}-${e && e?.getMonth()}-${e?.getFullYear()}` }
                          setData(dataToSet)
                          }}
                        disabled={checkForDisabledField(slot.status)}
                        />
                    </ ThemeProvider>
                  </div>
                </Grid>
                {errors && errors[ind] && <div style={{paddingLeft:12, paddingBottom:6}} className='error-msg'>{errors[ind]}</div>}
              </Grid>
        </>
        )}
          <div style={{ display: "flex",height: "56px", marginTop: "24px"}}>
          <Button onClick={confirmSlots}
            variant='outlined'
            className='hy-btn'
            style={{fontWeight: 600, flex:1, borderRadius:"8px" }}
          >
            CONFIRM
          </Button>
          </div>
        </> 
    }
    </div>
  </Dialog>
}

export const PanelReviewDialog = ({open, handleClose, panelReviewsObj}:any) => {
  const ratingList = [
    {category:"Leadership skills", rating: panelReviewsObj?.leadership_skills || 0},
    {category:"English language knowledge", rating: panelReviewsObj?.english_language_knowledge || 0},
    {category:"Communication skills", rating: panelReviewsObj?.communication_skills || 0},
    {category:"Problem solving", rating: panelReviewsObj?.problem_solving || 0},
    {category:"Programming skills", rating: panelReviewsObj?.programming_skills || 0},
    {category:"Overall rating", rating: panelReviewsObj?.overall_rating || 0},
  ];

  const showRating = (count:number) => {
    return (
      <span>
        {[1,2,3,4,5].map((item:any, index:number) => (
          <span className='rating' key={index+"star-rating"}><img src={count < index+1 ? StarIcon : StarFilledIcon} height={20} width={20} alt="star" /></span>
        ))}
      </span>
    )
  }
  return <Dialog open={open} scroll="body" className="panel-review-modal" maxWidth="sm" fullWidth>
    <div className='modal-content'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <div className='dialog-header' style={{justifyContent:"space-between",display:"flex"}}>
                <div>Panel Reviews</div>
                <IconButton size='small' onClick={handleClose}><CloseIcon className="close-icon" /></IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
              {ratingList.map((data:any, index:number) => (
                <Grid key={index+"rating"} container style={{justifyContent:"space-between", display:"flex", marginBottom:16}}>
                  <div className='skill-label'>{data.category}</div>
                  <div>
                    {showRating(data.rating)}
                  </div>
                </Grid>
              ))}
          </Grid>
          <Grid item xs={12}>
            <div className='label-bold'>Comments</div>
            <div className='comment-box'>
              {panelReviewsObj?.comments || ""}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='label-bold' style={{marginBottom:15}}>Recommendations</div>
            <div style={{display:"flex", alignItems:"center", paddingBottom:15}}>
              <div><img src={panelReviewsObj?.eligibile_for_next_round ? RadioCheckedIcon : RadioOutlinedIcon} alt="" height={22} width={22}/></div>
              <div className='recommendation-comment'>Eligible for next round</div>
            </div>
            <div style={{display:"flex", alignItems:"center"}}>
              <div><img src={panelReviewsObj?.recommended_an_offer_to_be_made ? RadioCheckedIcon: RadioOutlinedIcon} alt="" height={22} width={22}/></div>
              <div className='recommendation-comment'>Recommended an offer to be made</div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='final-result-block'>
              <div className='label-bold'>Final Result:</div>
              <div className='final-rating-block'>
                <StarRoundedIcon className='white-star-icon'/>
                <span className='final-rating'>{(
                5 - (
                  (
                    30 - (
                      Number(panelReviewsObj?.leadership_skills) +
                      Number(panelReviewsObj?.english_language_knowledge) +
                      Number(panelReviewsObj?.communication_skills) +
                      Number(panelReviewsObj?.problem_solving) +
                      Number(panelReviewsObj?.programming_skills) +
                      Number(panelReviewsObj?.overall_rating)
                    )
                  ) / 6
                )
              ).toFixed(1)
              }</span>
              </div>
            </div>
          </Grid>
      </Grid>
    </div>
  </Dialog>
}
// Customizable Area End