// Customizable Area Start

import React from 'react'
import CustomisableUserProfilesController from "./CustomisableUserProfilesController";
import {
  Grid, Card, CardContent,
  Dialog, IconButton, DialogTitle,
  DialogContent, Radio, DialogActions,
  Button, Select
} from '@material-ui/core';
import { Formik } from 'formik'
import {
  AppliedJobAvatar1, AppliedJobAvatar2, AppliedJobAvatar3, AppliedJobAvatar4, ChatIcon, RadioOutlinedIcon,
  RadioCheckedIcon
} from './assets';
import CloseIcon from '@material-ui/icons/Close'
import Avatar from '@material-ui/core/Avatar';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import GifIcon from '@material-ui/icons/Gif';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import "./candidate.web.css"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getFullName } from '../../dashboard/src/Dashboard.web';




const getJobLocation = (location: any) => {
  return location ? `, ${location}` : "";
}

export function JobCard(job: any, num: number, showDetails: () => void, locationList: any) {
  function randomIcon(params: number) {
    if (params % 4 == 0)
      return AppliedJobAvatar4
    if (params % 3 == 0)
      return AppliedJobAvatar3
    if (params % 2 == 0)
      return AppliedJobAvatar2
    return AppliedJobAvatar1
  }

  return <Card style={{ borderRadius: "6px", height: "100%" }} data-testid='txtInput'elevation={1} className="pointer" onClick={() => {
    showDetails()
  }} >
    <CardContent style={{ paddingBottom: "0px", padding: "20px" }}>
      <div className='applied-job-top-row'>
        <img dayta-testid='applyJob' src={randomIcon(num)} alt="" height={60} />
        <div style={{ textTransform: "capitalize" }} >{jobStatus(job.job_applied_status, job.job_applied_status)}</div>
      </div>
      <div className='applied-job-role' style={{ textTransform: "capitalize" }}>
        {job.job_role}
      </div>
      <div style={{ display: "flex", padding: "15px 0px" }}>
        <div className='job-status-box'>{job.job_type}</div>
        {job?.total_interview_rounds === 0
          ?
          null
          :
          <div className='job-status-box'>
            {job?.total_interview_rounds} Round
            {
              job?.total_interview_rounds === 1 ? null : "s"
            }
          </div>
        }
      </div>
      <div className='applied-job-company'>{job.company_name}{getJobLocation(job.client_admin_location)}</div>
      <div className='applied-job-summary'>{job.job_descriptions}</div>
    </CardContent>
  </Card>
}

export function jobStatus(text: string, status: string) {
  let className = "";
  let statusText = text;
  switch (status) {
    case "opted_out":
      className = "status-warning";
      break;
    case "success":
    case "selected":
      className = "status-completed";
      break;
    case "rejected":
      className = "status-error";
      break;
    case "job_closed":
      className = "status-opted-out";
      break;
    case "applied":
    case "active":
      className = "status-info";
      break;
  }
  if (text === "opted_out") {
    statusText = "Opted Out"
  } else if (text === "job_closed") {
    statusText = "Job Closed";
  }
  return (
    <div
      className={`${className} applied-job-status`}
    >
      {statusText}
    </div>
  );
}

export default class CandidatePage extends CustomisableUserProfilesController {
 
    
    
   
    
    findWhere: any;  
    jobStatus: any;
  constructor(props: any) {
    super(props)
  }

  async componentDidMount() {
    console.log(this.props, "--");
    super.componentDidMount()
    this.getClientAdminList();
    this.getLocationList();
    //@ts-ignore 
    const historyState = this.props.history;
    //@ts-ignore 
    const locationState = this.props.location.state;
    if (historyState.action === "REPLACE") {
      setTimeout(() => {
        if (locationState?.companyId) {
          this.setState({...this.state, selectedClientAdmin: locationState.companyId});
          this.handleSearchJobs();
        }
      }, 100);
    }
    if (historyState.action === "POP") {
      setTimeout(() => {
        //@ts-ignore
        // this.props.havingIssue(false);
        this.setState({...this.state, havingAnIssue: false});
        if (locationState?.companyId) {
          this.setState({...this.state, selectedClientAdmin: locationState.companyId});
          this.handleSearchJobs();
        }
      }, 100);
    }
  }

  handleDialog = (show: string = "", msg: string = "") => {
    this.setState({ ...this.state, showDialog: { show: show, msg: msg } });
  }

  handleSearchJobs = () => {
    this.setState({...this.state, isGoButtonClicked: true});
    this.updateClientAdmin(this.state.selectedClientAdmin);
  }

  render() {
    const { selectedClientAdmin, isGoButtonClicked, clientAdminList } = this.state;
    return (
      <div>
        <div className='company-dropdown-section'>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{fontWeight: 600, fontSize: 18}}>
              Please select the company from the dropdown to view the assigned jobs
            </Grid>
            <Grid item xs={12} className='company-label'>
              <label>Company</label>
            </Grid>
            <Grid item sm={6} md={4} lg={3} xs={10}>              
              <div className='form-group'>
                <Select data-test-id="adminSelect" IconComponent={(adminListProps) => (<KeyboardArrowDownIcon {...adminListProps} style={{paddingRight: 10, color: "#083247"}}/>)} className={`textbox`} native name='company' value={selectedClientAdmin} onChange={(e: any)=> this.setState({...this.state, selectedClientAdmin: e.target.value})}>
                    <option aria-label="None" value="" disabled>Please select the company</option>
                    {clientAdminList && clientAdminList.length > 0 ? clientAdminList.map((item:any, ind:number)=>
                        <option value={item.id} key={ind+"client-admin-for-candidate"} >{getFullName(item.user_name)}</option>
                      )
                    :""}                       
                </Select>
              </div>
          </Grid>
          <Grid item md={1} sm={1} xs={1} lg={1}>
            <Button 
              data-testid='button-item-click'
              variant="contained" 
              fullWidth 
              style={{backgroundColor: "#083247", color: "#fff", height: 48, borderRadius: 8}}
              disabled={!selectedClientAdmin}
              onClick={this.handleSearchJobs}>Go
            </Button>
          </Grid>
        </Grid>
        </div>
        {((clientAdminList !== "" && !clientAdminList.length) || (clientAdminList.length > 0 && isGoButtonClicked)) && <div className='applied-jobs-header'>Applied Jobs</div>}
        {clientAdminList !== "" && !clientAdminList.length && 
          <Grid container className='applied-jobs-container' spacing={4}>
            <h2 style={{ margin: "auto", marginTop: "200px" }}>No jobs assigned yet</h2>
          </Grid>
        }
        {clientAdminList.length > 0 && isGoButtonClicked && <Grid container className='applied-jobs-container' spacing={4} >
          {this.state.jobsList === "" && <h3 style={{ marginLeft: "16px" }} >Loading...</h3>}
          {this.state.jobsList !== "" && this.state.jobsList.length === 0 && <h2 style={{ margin: "auto", marginTop: "200px" }}>No jobs assigned yet</h2>}
          {this.state.jobsList !== "" && this.state.jobsList.length > 0 && <>
          {this.state.jobsList.map((jobDetail: any, index: number) => (
            <Grid data-testid='gridt'item xs={12} sm={6} md={4} lg={3} key={index} style={{ width: '100%' }} >
              {/* @ts-ignore */}
              {JobCard(jobDetail, index + 1, () => { this.props?.history?.push({ pathname: "/Candidate/Job_Details", state: { JobId: jobDetail?.job_id, companyId: selectedClientAdmin } }) }, this.state.locationList)}
            </Grid>
          ))}
          </>}
        </Grid>}
        <img src={ChatIcon} style={{ position: "fixed", bottom: 20, right: 20 }} alt="" height={80}/>
        <>
          <div style={{
            position: "fixed", bottom: 100, right: 20,
            display:this.state.toggleBot ? 'block' : 'none',
            borderRadius: "12px",
            background: "#ffffff",
            boxShadow: "6px 6px 12px #c1c1c1 -6px -6px 12px #ffffff",
        
            marginTop: "10px",
            width: "350px",
          }}>

            <div style={{
              width: "350px",
              textAlign: "center",

              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              backgroundColor: "#fdc817"
            }}>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: '0 10px' }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <h2 style={{ fontSize: "15px", textAlign: "start", padding: "10px",fontWeight:"bold" }}>Hiyamee <br/> <span style={{color:"#454545",fontWeight:"normal"}}>Bot</span></h2>
              </div>
            </div>
            <div style={{ padding: '1rem' }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center",width:"300px" }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <p style={{
                  color: "black",
                  backgroundColor: "#fee389",
                  fontWeight: "normal",
                  textAlign: "right",
                  padding: "10px",
                  marginRight: "40px",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  marginLeft: "10px",
                  borderTopRightRadius: "10px"
                }}>Hi, Want to see what can i do for you?</p>
              </div>

              <p style={{
                color: "black",
                backgroundColor: "lightgray",
                fontWeight: "normal",
                textAlign: "right",
                padding: "10px",
                right: "0px",
                marginLeft: "40px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                borderTopRightRadius: "10px"
              }}>Let's see what you got!</p>

              <p style={{
                color: "black",
                backgroundColor: "lightgray",
                fontWeight: "normal",
                textAlign: "right",
                padding: "10px",
                right: "0px",
                marginLeft: "90px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px"
              }}>Not right now, thanks</p>
            </div>
            <hr/>
            
            <div style={{display:"flex",flexDirection:"row",alignContent:"spaceEvenly",padding:"10px 20px"}}>
             <InputLabel/>
             <TextField placeholder='Send a Message'></TextField>
              <GifIcon style={{color:"#ABABAB",padding:"5px"}}/>
              <SentimentSatisfiedIcon style={{color:"#ABABAB",padding:"5px"}}/>
              <AttachFileIcon style={{color:"#ABABAB",padding:"5px"}}/>
            </div>

          </div>

        </>
      </div>
    )
  }
}




// Customizable Area End