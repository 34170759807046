import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, Loginbanner, LoginBannerImage } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  open:any;
  isMessagePop:any;
  msg:string;
  type:string;
  isSubmitionCompleted:any;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  step:number;
  isOpenProfileReview:boolean;
  errors:{};
  panel_document_proof:any;
  isUserExists:boolean;
  dropdownData: any;
  bannerImage: string;
  openPrivacyPolicy: boolean;
  openTermsConditions: boolean;
  privacyPolicyData: string;
  termsConditionsData: string;
  userNotFoundMsg: string;
  userDeletedMsg: string;
  loader: boolean;
  isDocValid: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  loginInformation: string = "" ;
  emailReg: any = "";
  labelTitle: string = "";
  userEmailInformation = "";
  createUserData = "";
  getDropdownDataId = "";
  getTermsAndConditionsApiId ="";
  getPrivacyPolicyApiId = "";

  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    
    this.state = {
      email: "",
      password: "",
      open:false,
      isSubmitionCompleted:false,
      isMessagePop:false,
      type:"info",
      msg:"Default Text",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.plaopen,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      step: 1,
      isOpenProfileReview:false,
      errors:{},
      panel_document_proof:"",
      isUserExists:false,
      dropdownData:{},
      bannerImage: "",
      openPrivacyPolicy: false,
      openTermsConditions:false,
      privacyPolicyData: "",
      termsConditionsData:'',
      userNotFoundMsg: "",
      userDeletedMsg: "",
      loader: false,
      isDocValid: true
    };

    this.emailReg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // this.getPrivacyPolicy()
    // this.getTermsAndConditions()
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin()
  };

  btnEmailLogInProps = {
    color: "#6200EE",
    onPress: () => this.doEmailLogIn()
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword()
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    }
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    }
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;


    validateLogin = async(values:any)=>{
    
      const headers = {
        "Content-Type": configJSON.validationApiContentType
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.loginInformation = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(values)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    validateUserEmail = async(values:any)=>{
      localStorage.setItem("new-user-email", values.email);
      const headers = {
        "Content-Type": configJSON.validationApiContentType
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userEmailInformation = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.validateEmailApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(values)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    savePanelUser = async(values:any) => {
      const headers = {
        "Content-Type": undefined,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createUserData = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createDetailsApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        values
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDropdownData = async() => {
      const headers = {
        "Content-Type": configJSON.validationApiContentType
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getDropdownDataId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.dropdownDataApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPrivacyPolicy = async() => {
      const headers = {
        "Content-Type": configJSON.validationApiContentType
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getPrivacyPolicyApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.privacyPolicyApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getTermsAndConditions = async() => {
      const headers = {
        "Content-Type": configJSON.validationApiContentType
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTermsAndConditionsApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.TermsAndConditionsApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  setLoginInformationResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.data) {
      localStorage.setItem('token', responseJson?.meta?.token)
      let { first_name: firstName, last_name: lastName, role, show_role, user_name, company_type, company_logo_url, image_url, is_hiyamee } = responseJson?.data?.attributes;
      localStorage.setItem("fname", firstName)
      localStorage.setItem("lname", lastName)
      localStorage.setItem("user_name", user_name)
      localStorage.setItem("company_type", company_type)
      localStorage.setItem("role", role)
      localStorage.setItem("show_role", show_role)
      localStorage.setItem("userId", responseJson.data.id)
      localStorage.setItem("company_logo", company_logo_url)
      localStorage.setItem("image", image_url)
      localStorage.setItem("is_hiyamee", is_hiyamee);
      if (role !== "candidate") {
        this.props?.history?.push("/Home/Dashboard");
      }
      else {
        this.props?.history?.push("/Candidate/Home");
      }
    }
    else if (responseJson && responseJson.errors) {
      if (responseJson.errors === "Email/Password not valid") {
        this.setState({ ...this.state, msg: "Invalid Password *" })
      }
      else if (responseJson.errors === "User not found") {
        this.setState({ ...this.state, msg: "Invalid Email Address *" })
      } else if (responseJson.errors === "User not found or deleted") {
        this.setState({ ...this.state, userDeletedMsg: "User Not Found" });
        setTimeout(() => {
          this.setState({ ...this.state, userDeletedMsg: "" });
        }, [5000]);
      }
    }
    else {
      //Check Error Response
      this.setState({ ...this.state, msg: "Something Went Wrong *" })
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  setTermsAndConditionsResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.terms_and_policies_list) {
      this.setState({...this.state, termsConditionsData: responseJson.terms_and_policies_list.description, openTermsConditions: true})
    } else {
      //Check Error Response
      console.log("error");
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  setPrivacyPolicyResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.list_of_privacy_policies) {
      this.setState({...this.state, privacyPolicyData: responseJson.list_of_privacy_policies.description, openPrivacyPolicy: true})
    } else {
      //Check Error Response
      console.log("error");
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  setDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.skill && responseJson.timezone) {
      this.setState({...this.state, dropdownData: responseJson})
    } else {
      //Check Error Response
      console.log("error");
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  setSignUpResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.message) {
      this.setState({isOpenProfileReview:true, loader: false})
    } else {
      //Check Error Response
      console.log("error");
      this.setState({loader: false});
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  setUserEmailResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.message) {
      if(this.props.history.location.pathname === "/sign-up") {
        this.setState({...this.state,step:1, 
          errors:{...this.state.errors, email:"Email id already exists"}})
      } else {
        switch(responseJson.user_type) {
          case 'candidate':
            this.setState({bannerImage: Loginbanner})
            break;
          case 'recruiter':
          case 'panel':
            break;
          case 'Client Admin':
          case 'manager':
          case 'coordinator':
            this.setState({bannerImage: LoginBannerImage})
            break;
        }
        this.setState({isUserExists:true});
      }
    } else {
      if(this.props.history.location.pathname !== "/sign-up") {
        this.setState({ ...this.state, userNotFoundMsg: "User Not Found" });
        setTimeout(() => {
          this.setState({ ...this.state, userNotFoundMsg: "" });
        }, [5000]);
      } else {
         this.setState({...this.state, step:2, errors: {}})
      }
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  setEmailLoginCallResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();
      this.openInfoPage();
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  setValidationCallResponse = (responseJson: any) => {
    if (responseJson) {
      let arrayHolder = responseJson.data;
  
      if (arrayHolder && arrayHolder.length !== 0) {
        let regexData = arrayHolder[0];
  
        if (regexData && regexData.email_validation_regexp) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
      }
    }
  }

  setAPIResponseData = (apiId: any, responseJson: any, errorResponse: any) => {
    if (apiId === this.validationApiCallId) {
      this.setValidationCallResponse(responseJson);
    }
    if (apiId === this.apiEmailLoginCallId) {
      this.setEmailLoginCallResponse(responseJson, errorResponse);
    }
    if (apiId === this.userEmailInformation) {
      this.setUserEmailResponse(responseJson, errorResponse);
    }
    if (apiId === this.createUserData) {
      this.setSignUpResponse(responseJson, errorResponse);
    }
    if (apiId === this.getDropdownDataId) {
      this.setDropdownResponse(responseJson, errorResponse);
    }
    if (apiId === this.getPrivacyPolicyApiId) {
      this.setPrivacyPolicyResponse(responseJson, errorResponse);
    }
    if (apiId === this.getTermsAndConditionsApiId) {
      this.setTermsAndConditionsResponse(responseJson, errorResponse);
    }
    if (apiId === this.loginInformation) {
      this.setLoginInformationResponse(responseJson, errorResponse);
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.validationApiCallId || apiRequestCallId === this.apiEmailLoginCallId ||
        apiRequestCallId === this.userEmailInformation || apiRequestCallId === this.createUserData ||
        apiRequestCallId === this.getDropdownDataId || apiRequestCallId === this.getPrivacyPolicyApiId ||
        apiRequestCallId === this.getTermsAndConditionsApiId || apiRequestCallId === this.loginInformation) {

        this.setAPIResponseData(apiRequestCallId, responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

}
