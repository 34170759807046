// Customizable Area Start
import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Chip from '@material-ui/core/Chip';
import Pagination from '@material-ui/lab/Pagination';
import { Menu, MenuItem, IconButton, Divider} from "@material-ui/core"
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import "./landing_page.css";
export const configJSON = require("../../../framework/src/config");
interface Column {
  id: 'full_name' | 'last_name' | 'phone_number' | 'user_type' |  'email' | 'manager_jobs' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right';
  format?: (value: number) => string;
}


const managersColumns: Column[] = [
  { id: 'full_name', label: 'Full Name', minWidth: 100, align: 'left',},
  {
    id: 'phone_number',
    label: 'Phone Number',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'user_type',
    label: 'User Type',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email ID',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'manager_jobs',
    label: 'Assigned Jobs',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'actions',
    label: '',
    minWidth: 100,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: { // to get scroll bar in table 
    maxHeight: "100%",
  },
  managerJobChip: {
    borderRadius: "8px",
    marginLeft: "6px",
    background: "#08324719",
    color: "#083247"
  },
  managerJobCountChip: {
    background: "#083247",
    color: "white",
    borderRadius: "8px",
    height: "25px",
    marginLeft: "6px"
  }
});

const getUserType = (isHiyamee: boolean) => {
  return isHiyamee ? "Hiyamee" : "Company";
}

const getColumnClassName = (id: any) => {
  return id === "email" ? "email-column" : "";
}

function ManagerTable({rows, editManager, deleteManager,viewManager}:{rows:any[], editManager:any, deleteManager: any,viewManager:any}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  let [managerId,setManagerId] = React.useState<number>(0);
  const [extend_data,SetExtendData ] = React.useState([]);
  let [managerData,setManagerData] = React.useState<any>({});
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    console.log('event.currentTarget',event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const Check = (event: React.MouseEvent<HTMLElement>,jobs:any) =>{
   console.log('test it',jobs)

    setAnchorE2(event.currentTarget);
    SetExtendData(jobs)
  }
  
  const classes = useStyles();
  let col:any =[]
    col = managersColumns

  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10

  useEffect(()=>{
    console.log("rows changed")
    setPage(0)
  },[rows])

  const isHiyameeUser = () => {
    const loginAsHiyameeUser = localStorage.getItem("is_hiyamee") === "true" ? true : false;
    let isHiyameeUser: boolean = false;
    if (!loginAsHiyameeUser) {
      isHiyameeUser = rows.find((item: any) => item.id === managerId)?.is_hiyamee;
    }
    return isHiyameeUser;
  }

  const getFullName = (fName: string, lName: string) => {
    const firstNameLower = fName.toLowerCase();
    const lastNameLower = lName.toLowerCase();
    const firstName = firstNameLower[0].toUpperCase() + firstNameLower.slice(1);
    const lastName = lastNameLower[0].toUpperCase() + lastNameLower.slice(1);
    return `${firstName} ${lastName}`;
  }

  return (
    <Paper className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {/* edite and delete manager */}
        <MenuItem id="edit-manager" onClick={() => { viewManager(managerData); handleClose(); }}>View Profile</MenuItem>
        {!isHiyameeUser() && <>
        <Divider />
        <MenuItem id="edit-manager" onClick={() => { editManager(managerData); handleClose(); }}>Edit Profile</MenuItem>
        <Divider />
        <MenuItem id="delete-manager" onClick={() => { deleteManager(managerId); handleClose(); }}>Delete</MenuItem>
        </>}
      </Menu>
      <Menu
        anchorEl={anchorE2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{marginLeft:-40,height:350}}
        open={!!anchorE2}
        onClose={handleClose2}
      >
        {/* edite and delete manager */}

        {extend_data.slice(2,extend_data.length).map((item: any,index)=>(
            <div>   
           <Chip  style={{margin:5}} className={classes.managerJobChip} label={item.job_role} />
           </div>
           
        )
        
        )
        
        }
      </Menu>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {col.map((column:any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="candidate-table-header-cell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          { rows.length === 0 ? <TableRow> <div style={{padding:"18px 0 ",fontSize:"16px",fontWeight:900}}> No Data Found </div> </TableRow>  : null }
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any,ind:number) => {
              return (
                <>
                  <TableRow hover role="checkbox" tabIndex={-1} key={ind} >
                    {col.map((column:any) => { 
                      let value
                      if("actions" === column.id)
                       value =  <div className='' > <IconButton onClick={(e: any) => { e.stopPropagation();handleMenu(e); setManagerId(row.id); setManagerData(row) }} > <MoreVertIcon style={{ verticalAlign: "middle" }} /> </IconButton> </div>
                      else if ("manager_jobs" == column.id) {
                        value = <div>
                          {
                            row.manager_jobs.map((jobData: any, index: number) => { 
                              return (
                                index < 2 ? <Chip className={classes.managerJobChip} label={jobData.job_role} /> : null) 
                            })
                          }
                          {
                            row.manager_jobs.length > 2 ? <Chip onClick={(e: any) => Check(e,row.manager_jobs)} style={{backgroundColor:'#083247',color: '#fff',borderRadius:8, marginLeft:6}} label={"+" + (row.manager_jobs.length - 2).toString()} /> : null
                          }
                        </div>
                      }
                       else if("job_role" === column.id)
                        value = row.candidate_job.job_role
                       else if("full_name" === column.id)
                        value = getFullName(row.first_name, row.last_name)
                       else if("user_type" === column.id)
                        value = getUserType(row.is_hiyamee)
                       else
                      value = row[column.id] 
                      return (
                        <TableCell key={column.id} align={column.align} className={getColumnClassName(column.id)}>
                           { value } 
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{display: 'flex', justifyContent: 'space-between',padding:"18px",alignItems: "center" }}>
        <div> Showing {rows.length>0 ? page*10 +1 : 0} to {page * rowsPerPage + rowsPerPage > rows.length ? rows.length : page * rowsPerPage + rowsPerPage } of {rows.length} entries </div>
        {
          rows.length > 0 ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className={page == 0 ? "next-prev-btn disable" : "next-prev-btn active"}
                onClick={() => { setPage(page - 1) }} >Prev</div>
              <Pagination style={{ margin: "0px 10px" }} count={Math.ceil(rows.length / 10)} shape="rounded" page={page + 1} onChange={(event, page) => setPage(page - 1)} hidePrevButton hideNextButton />
              <div className={page + 1 < rows.length / 10 ? "next-prev-btn active" : "next-prev-btn disable"}
                onClick={page + 1 <= rows.length / 10 ? () => { setPage(page + 1); document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', }) } : () => { }} >Next</div>
            </div>
            : null
        }
      </div>
    </Paper>
  );
}
export default class Managers extends React.Component < any ,any >  {
    constructor(props:any){
        super(props);
      }
      //@ts-ignore
    componentDidMount(){
        this.props.getAllManagers("");
      }
    render(){
        const deleteManageById = (managerId:any) => {
          this.props.deleteManager(managerId);
        }
        const editManageById = (managerData:any) => {
          this.props.editManager(managerData);
        }

        const viewManageById = (managerData:any) => {
          console.log(managerData)
          this.props.viewManager(managerData);
        }
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center", marginTop:-20}}>
            <div> {console.log("this.props.getAllManagers",this.props.getAllManagers)}
            </div>
            </div>
            <div className="manager-table-wrapper">
            <ManagerTable rows={ this.props?.allManagerList || [] } editManager={editManageById}  viewManager={viewManageById}   deleteManager={deleteManageById} />
            </div>
          </>
        )
      }
}

// Customizable Area End