// Customizable Area Start
import React from "react";
import { Modal, 
  Divider } from "@material-ui/core";
import '../src/panel.web.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CatalogueController, { Props } from "./CatalogueController";
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment";
import { DatePicker,
   MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getFullName } from '../../dashboard/src/Dashboard.web';

const defaultThemeForCoordinator = createTheme({
  palette: {
    primary: {
      main: '#083247',
    } ,
  },
});

const calendarIconForCoordinator = require('../assets/calender.png')

moment.locale("en-GB");
// @ts-ignore
const localizer: any = momentLocalizer(moment);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const formatsForCoordinator = {
  timeGutterFormat: 'hh A',
  dayFormat: (dateOb: any, cultureVal: any, localizer: any) =>
    localizer.format(dateOb, 'ddd D', cultureVal),
}
export default class Scheduled_CoordinateCalendar extends CatalogueController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.setState({ ...this.state, selectedDate: new Date() });
    this.props.getApprovePanelsList(this.state.currentPage + 1, this.state.rowsPerPage, '')
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(this.props.coordinatorInterview) !== JSON.stringify(prevProps.coordinatorInterview)) {
      this.setEventsData(this.props.coordinatorInterview);
    }
    const calenderViewType = this.state.calenderViewType;
    const currentDate = this.state.selectedDate;
    if (this.props.selectedPanel && ((moment(prevState.selectedDate).format("DD-MM-YYYY") !== moment(currentDate).format("DD-MM-YYYY")) ||
      (prevState.calenderViewType !== calenderViewType) ||
      (prevProps.selectedPanel !== this.props.selectedPanel))) {

      if (calenderViewType === "day") {
        this.props.getInterviewCoordinator(moment(currentDate).format("DD-MM-YYYY"), moment(currentDate).format("DD-MM-YYYY"), this.props.selectedPanel);
      } else if (calenderViewType === "week") {
        this.props.getInterviewCoordinator(moment(currentDate).startOf("week").format("DD-MM-YYYY"), moment(currentDate).endOf("week").format("DD-MM-YYYY"), this.props.selectedPanel);
      } else if (calenderViewType === "month") {
        this.props.getInterviewCoordinator(moment(currentDate).startOf("month").format("DD-MM-YYYY"), moment(currentDate).endOf("month").format("DD-MM-YYYY"), this.props.selectedPanel);
      }
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
      <div className="scheduled_interviews_wrapper">
        <div className="head">
          <div className="month">
            <div onClick={() => { document.getElementById("coordinator-calendar-date")?.click() }}>
              <img src={calendarIconForCoordinator} width={40} alt="calender" style={{ cursor: 'pointer', marginLeft: 30 }} />
            </div>
            <span className="month"> {moment(this.state.selectedDate).format("MMM-YYYY").slice(0, 3)} {moment(this.state.selectedDate).format("MMM-YYYY").slice(4, 10)} &#160;    <span className="pointer" style={{ marginLeft: 5 }} onClick={() => this.calendarPreviousEvent()} >&lt;</span> &#160;&#160;&#160; <span className="pointer" onClick={() => this.calendarNextEvent()}>&gt;</span></span>
          </div>
          <div className="calender-type">
            <span className={this.state.calenderViewType === "day" ? "hy-active" : ""} onClick={() => this.setState({ calenderViewType: "day" })}>Day</span>
            <span className={this.state.calenderViewType === "week" ? "hy-active" : ""} onClick={() => this.setState({ calenderViewType: "week" })}>Week</span>
            <span className={this.state.calenderViewType === "month" ? "hy-active" : ""} onClick={() => this.setState({ calenderViewType: "month" })}>Month</span>
          </div>
        </div>
        <Calendar
          className="calendarMain-wrapper"
          toolbar={false}
          localizer={localizer}
          events={this.state.recruiterEventsFormattedData}
          formats={formatsForCoordinator}
          view={this.state.calenderViewType}
          views={[this.state.calenderViewType]}
          date={this.state.selectedDate}
          onNavigate={(date: any) => this.setRecruiterMoreData(date)}
          components={{
            timeSlotWrapper: this.timeSlotWrapper, // Coordinator Time Slot Wrapper
            day: {
              event: this.eventTile,
            },
            week: {
              event: this.eventTile,
            },
            month: {
              event: this.eventTile,
            },
          }}
          showAllEvents={false}
          showMultiDayTimes={true}
          onView={(viewOb: any) => viewOb}
        />
      </div>
      <ThemeProvider theme={defaultThemeForCoordinator}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker className="hidden-date" format="dd/MM/yyyy" label="Date" value={new Date(this.state.selectedDate)} id="coordinator-calendar-date" onChange={(e: any) => { this.handleDateChange(e) }} />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      {this.state.isOpenRecruiterSelectedEvent && <Modal
        open={this.state.isOpenRecruiterSelectedEvent}
        onClose={() => this.setState({ isOpenRecruiterSelectedEvent: false, recruiterSelectedEvent: "" })}
        className="jobs-success-model view-interview-modal-wrapper"
      >
        <div className="infromation-block coordinator-panel-block">
          <div className="heading">{moment(this.state.recruiterSelectedEvent?.start).format("DD MMM yyyy")}
            <div className="closed pointer" onClick={() => this.setState({ isOpenRecruiterSelectedEvent: false, recruiterSelectedEvent: "" })}>X</div>
          </div>
          <div >
            {this.state.recruiterSelectedEvent !== '' &&
              <div className="interview mt-15">
                <label className="panel_title coordinator-cal">{getFullName(this.state.recruiterSelectedEvent?.title)}</label>
                <div style={{ fontSize: 13 }}>{this.formatAMPM(this.state.recruiterSelectedEvent?.actualStartTime)} - {this.formatAMPM(this.state.recruiterSelectedEvent?.actualEndTime)}</div>
                <div className="event_interviwer" style={{ fontSize: 13, marginTop: 10 }}>Interviewer</div>
                <div className="event_title" style={{ fontSize: 14, fontWeight: 'bold', marginTop: 0 }}>{getFullName(this.state.recruiterSelectedEvent?.panel_name)} </div>
              </div>
            }
          </div>
        </div>
      </Modal>}
      {this.state.isOpenRecruiterShowMore && 
        <Modal
          open={this.state.isOpenRecruiterShowMore}
          onClose={() => this.setState({ isOpenRecruiterShowMore: false, recruiterMoreEventData: [] })}
          className="jobs-success-model view-interview-modal-wrapper"
        >
          <div className="infromation-block coordinator-panel-block coordinator-cal">
            <div className="heading coordinator-cal">{moment(this.state.recruiterMoreEventData[0].start).format("DD MMMM yyyy")}
              <div className="closed pointer" onClick={() => this.setState({ isOpenRecruiterShowMore: false, recruiterMoreEventData: [] })}>X</div>
            </div>
            {this.state.recruiterMoreEventData.map((item: any, index: number) =>
            (
              <div>
                <div className="interview mt-15">
                  <label className="panel_title coordinator-cal">{getFullName(item?.title)}</label>
                  <div style={{ fontSize: 13 }} className="coordinator-cal">{this.formatAMPM(item.start)} - {this.formatAMPM(item.end)}</div>
                  <div style={{ fontSize: 13, marginTop: 10 }} className="coordinator-cal">Interviewer</div>
                  <div className="coordinator-cal" style={{ fontSize: 14, fontWeight: 'bold' }}>{getFullName(item?.panel_name)}</div>
                </div>
                {this.state.recruiterMoreEventData.length !== index+1 && <Divider className="coordinator-cal"/>}
              </div>
            )
            )
            }
          </div>
        </Modal>}
    </ThemeProvider>
    );
  }
}

// Customizable Area End
