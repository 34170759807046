// Customizable Area Start

import React from "react";
import { Drawer, IconButton, Grid, Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { EditIcon, DefaultProfile } from "./assets";

export const mainConfigJson = require("../../../framework/src/config");
export default class AddEditVendor extends React.Component<any, any> {
  initialValue = {
    first_name: "",
    last_name: "",
    email: "",
    image: "",
    country_code: "+91",
    phone_number: "",
  };
  constructor(props: any) {
    super(props);
    this.state = {
      vendorDetails: this.initialValue,
      profileImage: DefaultProfile,
      errorObj: {},
    };
    if (this.props.vendorObject && this.props.vendorObject.id) {
      this.state = {
        ...this.state,
        vendorDetails: {...this.props.vendorObject, country_code: `+${this.props.vendorObject.country_code}`},
        profileImage: this.props.vendorObject.image_url ? mainConfigJson.baseURL + this.props.vendorObject.image_url : DefaultProfile,
      };
    }
  }
  
  render() {
    const { show, hide, vendorOpeningMode } = this.props;
    const { errorObj, profileImage, vendorDetails } = this.state;
    
    const onClose = () => {
      this.setState({
        ...this.state,
        vendorDetails: this.initialValue,
        profileImage: DefaultProfile,
        errorObj: {},
      });
      hide();
    };
    
    const validate = (values: any) => {
      const regexForEmail = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const regexForPhoneNumber = new RegExp(/^\d{10}$/);
      const regexAlphabetNumber= new RegExp(/[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/);
      const regexNumber =new RegExp(/^[0-9]*$/);
      const countryCodeRegex = new RegExp(/^\+[0-9]{1,3}$/);
      const errors: any = {};
      if (!values.first_name || (values.first_name && values.first_name.trim() === "")) {
        errors.first_name = "First Name is Required";
      } else if (values.first_name && regexAlphabetNumber.test(values.first_name) || regexNumber.test(values.first_name)) {
        errors.first_name = "Please enter valid First Name";
      } else if (!values.last_name || (values.last_name && values.last_name.trim() === "")) {
        errors.last_name = "Last Name is Required";
      } else if (values.last_name && regexAlphabetNumber.test(values.last_name) || regexNumber.test(values.last_name)) {
        errors.last_name = "Please enter valid Last Name";
      } else if (!values.email) {
        errors.email = "Email is Required";
      } else if (!regexForEmail.test(values.email)) {
        errors.email = "Enter Valid Email";
      } else if (!values.country_code) {
        errors.phone_number = "Country code is Required";
      } else if (values.country_code && !countryCodeRegex.test(values.country_code)) {
        errors.phone_number = "Please enter valid Country code i.e. +91";
      } else if (!values.phone_number) {
        errors.phone_number = "Phone Number is Required";
      } else if (!regexForPhoneNumber.test(values.phone_number)) {
        errors.phone_number = "Phone Number should be 10 digit number";
      }
      if (!Object.keys(errors).length) {
        const data = {...values};
        data.first_name = data.first_name.trim();
        data.last_name = data.last_name.trim();
        data.country_code = "91";
        this.setState({ ...this.state, errorObj: {}});
        this.props.saveVendor(data, vendorOpeningMode === "edit" ? "edit": "");
      } else {
        const err = Object.keys(errors);
        const input = document.querySelector(`input[name=${err[0]}]`);
        if (input) {
          input.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        this.setState({ ...this.state, errorObj: errors });
      }
    };

    return (
      <div>
        <Drawer anchor={"right"} open={show}>
          <Formik
            initialValues={vendorDetails}
            enableReinitialize
            onSubmit={(values: any) => {
              console.log(values);
            }}
          >
            {({ values, handleSubmit, handleChange, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className="availability-container">
                  <div className="availability-header">
                    <div>{`${vendorOpeningMode === "view" ? "View" : vendorOpeningMode === "edit" ? "Edit" : "Add New"} Vendor`}</div>
                    <IconButton onClick={onClose} size="small">
                      <CloseIcon className="close-icon" />
                    </IconButton>
                  </div>
                  <div className="availability-content">
                    <div
                      className="add-new-candidate-upload-image"
                      id="image"
                      style={{ top: 0, marginBottom: 25 }}
                    >
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={(e) => {
                          const objectUrl = URL.createObjectURL(
                            e.target.files ? e.target.files[0] : profileImage
                          );
                          setFieldValue(
                            "image",
                            e.target.files ? e.target.files[0] : ""
                          );
                          this.setState({
                            ...this.state,
                            profileImage: objectUrl,
                          });
                        }}
                        id="vendor-profile"
                        disabled={vendorOpeningMode === "view"}
                      />
                      <label htmlFor="vendor-profile" className={vendorOpeningMode ==="view" ? "" :"pointer"}>
                        <img src={profileImage} alt="user-image" />
                        <div className="add-new-candidate-edit-img add-panel-edit-msg">
                          <img src={EditIcon} alt="edit-icon" />
                        </div>
                      </label>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          First Name
                          <TextField
                            value={values.first_name}
                            fullWidth
                            className="text-input"
                            variant="outlined"
                            name="first_name"
                            onChange={handleChange}
                            placeholder="Enter First Name"
                            disabled={vendorOpeningMode ==="view"}
                          />
                        </div>
                        {errorObj.first_name ? (
                          <span className="error-msg">
                            {errorObj.first_name}
                          </span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          Last Name
                          <TextField
                            value={values.last_name}
                            fullWidth
                            className="text-input"
                            variant="outlined"
                            name="last_name"
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                            disabled={vendorOpeningMode ==="view"}
                          />
                        </div>
                        {errorObj.last_name ? (
                          <span className="error-msg">
                            {errorObj.last_name}
                          </span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          Email ID
                          <TextField
                            value={values.email}
                            fullWidth
                            className="text-input"
                            variant="outlined"
                            name="email"
                            onChange={handleChange}
                            placeholder="Enter Email ID"
                            disabled={vendorOpeningMode ==="view"}
                          />
                        </div>
                        {errorObj.email ? (
                          <span className="error-msg">{errorObj.email}</span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          Phone Number
                          <div className="add-vendor-phone-number">
                            <TextField
                              value="+91"
                              className="text-input country-code"
                              style={{ flex: "1", minWidth: "60px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                              variant="outlined"
                              name="country_code"
                              onChange={handleChange}
                              placeholder="Code"
                              disabled={vendorOpeningMode ==="view"}
                            />
                            <TextField
                              value={values.phone_number}
                              fullWidth
                              className="text-input phone-no"
                              variant="outlined"
                              name="phone_number"
                              onChange={handleChange}
                              placeholder="Enter Phone Number"
                              // maxLength=
                              inputProps={{ maxLength: 10 }}
                              disabled={vendorOpeningMode ==="view"}
                            />
                          </div>
                        </div>
                        {errorObj.phone_number ? (
                          <span className="error-msg">
                            {errorObj.phone_number}
                          </span>
                        ) : null}
                      </Grid>
                    </Grid>
                  </div>
                  {vendorOpeningMode !=="view" && <Grid container spacing={2} style={{ padding: "20px 26px" }}>
                    <Grid item xs={6} className="availability-action">
                      <Button
                        className="cancel-btn"
                        fullWidth
                        variant="outlined"
                        onClick={onClose}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                    <Grid item xs={6} className="availability-action">
                      <Button
                        className="save-btn"
                        fullWidth
                        onClick={() => validate(values)}
                      >
                        SAVE
                      </Button>
                    </Grid>
                  </Grid>}
                </div>
              </form>
            )}
          </Formik>
        </Drawer>
      </div>
    );
  }
}

// Customizable Area End
