export const RejectIcon = require('../assets/close.png')
export const MobileIcon = require('../assets/mobileIcon.png')
export const DocIcon = require('../assets/docIcon.png')
export const AccountIcon = require('../assets/accountIcon.png')
export const EmailIcon = require('../assets/emailIcon.png')
export const CompanyIcon = require('../assets/companyIcon.png')
export const DesignationIcon = require('../assets/designationIcon.png')
export const LocationIcon = require('../assets/locationIcon.png')
export const TimerIcon = require('../assets/timerIcon.png')
export const BrowseIcon = require('../assets/browseIcon.png')
export const notransactions = require("../assets/notransactions.png");
export const account_holder = require("../assets/Account_Holder_Name.png");
export const ifsc_code = require("../assets/IFSC_Code.png");
export const Account_Number = require("../assets/Account_Number.png");
export const verified = require("../assets/verified.png");
export const PanelScheduledInterviews = require("../assets/dashboard/totalinterviews.png");
// export const PendingEvaluation = require("../assets/dashboard/pendingEvaluation.png");
// export const TodaysInterview = require("../assets/dashboard/todaysInterview.png");
// export const TotalEarning = require("../assets/dashboard/totalEarning.png");

