// Customizable Area Start
import React from "react";
import "./style.css"
import { Button } from '@material-ui/core';
import {Link} from "react-router-dom"
import AlertBlock from "../../alert/src/AlertBlock.web"
import { createStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EmailAccountLoginController , {Props,configJSON}from './EmailAccountLoginController'
import { imgPasswordInVisible, LoginBannerImage, MainLogo } from "./assets";  
import HtmlParser from "html-react-parser";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
const styles = (theme:any) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '121ch',
  },
});

export const PrivacyPolicyDialog = (props:any) => {
  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogContent className="profile-review-dialog">
        <div className="profile-review-header policy-header">{props.heading}</div>
        <div className="profile-review-summary">{HtmlParser(props.data)}</div>
        <div className="profile-review-action">
          <Button fullWidth className="back-btn" onClick={props.onClose}>CLOSE</Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const getButtonText = (isUserExists: boolean) => {
  return isUserExists? "LOGIN": "CONTINUE";
}

export const setMargin = (isUserExists: boolean) => {
  return isUserExists ? 30 : 60;
}

export const getInputType = (open: boolean) => {
  return open ? "text" : "password";
}

export const showVisibilityIcon = (open: boolean) => {
  return <>
    {open ? <VisibilityOffOutlinedIcon color="action" className={"visible"} /> : < VisibilityOutlinedIcon color="action" className={"visible"} />}
  </>
}

export const getErrorFieldClass = (isError: any, isTouched: any) => {
  console.log("item",getErrorFieldClass)
  return isError && isTouched ? "text-input error" : "text-input";
}

export class EmailAccountLoginWeb extends EmailAccountLoginController {
  
  constructor(props:Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      open:false,
      msg:"",
      type:"info",
      isSubmitionCompleted:false,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      isMessagePop:false,
      step:1,
      isOpenProfileReview:false,
      errors:{},
      panel_document_proof:"",
      isUserExists:false,
      dropdownData:{},
      bannerImage: LoginBannerImage,
      openPrivacyPolicy: false,
      openTermsConditions:false,
      privacyPolicyData: "",
      termsConditionsData:'',
      userNotFoundMsg: "",
      userDeletedMsg: "",
      loader: false,
      isDocValid: true
    }
  }
  
  onFormSubmit = (values:any)=>{
    if(this.state.isUserExists) {
      console.log("OnSubmit of login",values,this.props);
      
      let requestPayload = {
        account: {
          email: values.email,
          password:values.password
        }
      }
      this.validateLogin(requestPayload)
    } else {
      this.validateUserEmail({email:values.email});
    }
  }
  
  handleClose = (event:any, reason:any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({...this.state , isMessagePop:false})
  }
  
  redirectToSignUp = () => {
    if (this.props.history.location.pathname !== "/sign-up") {
      this.props.history.push("/sign-up");
    }
  }
  onCloseConditions() {
    this.setState({ ...this.state, openTermsConditions: false });
  }
  onClosePrivacyPolicy() {
    console.log("item",this.onClosePrivacyPolicy)
    this.setState({ ...this.state, openPrivacyPolicy: false });
  }
  showTermsAndConditions = () => {
    return <>
      {this.state.openTermsConditions && this.state.termsConditionsData &&
        <PrivacyPolicyDialog
        data={this.state.termsConditionsData}
        heading='Terms and Conditions'
        open={this.state.openTermsConditions}
        onClose={this.onCloseConditions.bind(this)} />
      }
    </>
  }
  
  showPrivacyPolicy = () => {
    return <>
      {this.state.openPrivacyPolicy && this.state.privacyPolicyData &&
        <PrivacyPolicyDialog
        data={this.state.privacyPolicyData}
        heading='Privacy Policy'
        open={this.state.openPrivacyPolicy}
        onClose={this.onClosePrivacyPolicy.bind(this)} />
      }
    </>
  }
  
  onSubmit = (errors: any) => {
    if (errors && errors.email) {
      document.getElementById("email")?.focus();
    } else if (errors && errors.password) {
      document.getElementById("password")?.focus();
    }
  }
  
  setInitialData = () => {
    return this.state.isUserExists ? {email: this.state.email, password:""}:{email: this.state.email}
  }
  handleButtonClick = () => {
    this.setState({ ...this.state, open: !this.state.open });
    const passwordInput = document.getElementById("password");
    if (passwordInput) {
      passwordInput.focus();
    }
  };

  render() {

    const { open, isMessagePop, isUserExists, userNotFoundMsg, userDeletedMsg } = this.state;
    
     return (
      <div>
        {this.showPrivacyPolicy()}
        {this.showTermsAndConditions()}
          <div className="login-block">
            <Grid container className="inner-block">
              <Grid item xs={6} className="information-block">
                <div className="imgbox">
                  <img src={this.state.bannerImage} alt="login banner" />
                </div>
              </Grid>
              <Grid item xs={6} className="login-item">
                <div className="items-inner">
                { //@ts-ignore
                <AlertBlock data={{show:isMessagePop ,close: this.handleClose ,msg:this.state.msg, type:this.state.type, vertical:"top", horizontal:"center" }} />
                }
                <div className="logintitle">
                  <img src={MainLogo} style={{height:"38px"}} alt="Hiyamee logo" />
                </div>

                <h2 className="loginsubtitle">
                  Login to your account 
                </h2>
                  <p className="loginsubtitle1">
                    Enter your given email address and password 
                  </p>
                  <Formik
                    data-test-id="form"
                    initialValues={this.setInitialData()}
                    onSubmit={async values => { this.onFormSubmit(values)}}
                    validationSchema={isUserExists ? Yup.object().shape({
                      email: Yup.string()
                        .email("Enter Valid Email")
                        .required("Email Required *"),
                        password:Yup.string()
                        .required("Password Required *").min(8),
                      }) : Yup.object().shape({
                        email: Yup.string()
                          .email("Enter Valid Email")
                          .required("Email Required *")
                      })}
                  >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    } = props;
                    return (
                    <form onSubmit={handleSubmit}>
                    <div className="form-group"> 
                      <div className="form-item">
                        <label className="form-label fw-bold">Email</label>
                        <TextField
                        fullWidth
                        variant="outlined"
                        id="email"
                        placeholder="Enter Email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getErrorFieldClass(errors.email, touched.email)}
                        />
                        {errors.email && touched.email && ( 
                          <div className="input-feedback">{errors.email}</div>
                        )}
                        {!errors.email && userNotFoundMsg && ( 
                          <div className="input-feedback">{userNotFoundMsg}</div>
                        )}
                        <div className="input-feedback" style={{textAlign:"center"}}>{this.state.msg === "Invalid Email Address *" ? this.state.msg : "" }</div>
                      </div>
                    </div>

                    {isUserExists && <div className="form-group">
                      <div className="form-item">
                        <label className="form-label fw-bold">Password</label>
                        <div className="input-group">
                          <TextField 
                            variant="outlined"
                            fullWidth
                            id="password"
                            placeholder="Enter Password"
                            type={getInputType(open)}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={getErrorFieldClass(errors.password, touched.password)}
                          />
                          
                          <span className="input-group-addon pointer"onClick={this.handleButtonClick}>
                            {showVisibilityIcon(open)}
                          </span>
                        </div>
                        {errors.password && touched.password && (
                          <div className="input-feedback">{errors.password}</div>
                        ) }
                        {!errors.password && userDeletedMsg && (
                          <div className="input-feedback">{userDeletedMsg}</div>
                        )}
                        <div className="input-feedback" style={{textAlign:"center"}}>{this.state.msg === "Invalid Password *" ? this.state.msg : "" }</div>
                      </div>
                    </div>}
                    <div className="form-group">
                      <Button data-test-id="data" className="btn btn-submit" type="submit" style={{fontWeight: 900}} onClick={(e)=>{this.onSubmit(errors)}}>{getButtonText(isUserExists)}</Button>
                    </div>
                    {!isUserExists && <div className="form-group">
                      <Button fullWidth variant="outlined" className="sign-up-btn" onClick={this.redirectToSignUp}>PANEL SIGN UP</Button>
                    </div>}
                    {isUserExists && <div className="form-group forget-group">
                        <label className="form-label">
                          <Link to="/forget-password" > <span style={{fontFamily:"Quicksand !important",fontSize:'14px',fontWeight:'bold',color: "#083247 !important"}}>  Forgot password? </span> </Link>
                          
                        </label>
                    </div>}
                    </form>
                    );
                   }}
                  </Formik>
                <ul className="navbar" style={{marginTop: setMargin(isUserExists)}}>
                  <li className="list-item" >
                    <a  className="nav-link pointer" onClick={this.getPrivacyPolicy}>Privacy Policy</a>
                  </li>
                  <li className="list-item" >
                    <label className="nav-text">and</label>
                  </li>
                  <li className="list-item">
                    <a  className="nav-link pointer" onClick={this.getTermsAndConditions} >Terms of services </a>
                  </li>
                </ul>
                </div>
                </Grid>
            </Grid>
          </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EmailAccountLoginWeb);
// Customizable Area End