// Customizable Area Start

import React from "react";
import {
  Button,
  Radio,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import {
  RadioCheckedIcon,
  RadioOutlinedIcon,
} from "../assets";
import { Formik } from "formik";

export default function OptingoutComponent(props: any) {
  return (
    <div>
      <Formik
        initialValues={{
          account_id: 0,
          opting_out_reason: "",
          not_interested: false,
          get_offer_from_other_company: false,
          not_looking_for_opprtunities: false,
          other_reason: "",
        }}
        // validate={validateStep3 }
        onSubmit={(values, { setSubmitting }) => {
          //NOSONAR
          if (values.opting_out_reason == "" && values.other_reason == "") {
            console.log("select anyone ");
            return props.setErrorMsg(
              "Please select any option or enter your reason"
            );
          }
          //NOSONAR
          let payload = {
            job: {
              account_id: props.candidateId,
              not_interested: values.opting_out_reason === "not_interested",
              get_offer_from_other_company:
                values.opting_out_reason === "get_offer_from_other_company",
              not_looking_for_opprtunities:
                values.opting_out_reason === "not_looking_for_opprtunities",
              other_reason: values.other_reason,
            },
          };
          //NOSONAR
          if (props.optoutCandidate) {
            props.optoutCandidate(payload);
            props.setOpenOptingOut(false);
          } else {
            props.optout(payload, props.jobId)
            props.handleClose()
          }
          props.setErrorMsg("");
        }}
      >
        {({
          values,
          // errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          setErrors,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent className="opting-out-wrapper">
              <div
                className={
                  values.opting_out_reason === "not_interested"
                    ? "checkdiv checked"
                    : "checkdiv"
                }
              >
                <Radio
                  disableRipple
                  id="OptingOut1"
                  name="opting_out_reason"
                  checked={values.opting_out_reason === "not_interested"}
                  value="not_interested"
                  icon={
                    <img
                      src={RadioOutlinedIcon}
                      style={{ marginBottom: 0 }}
                      alt=""
                      width={24}
                      height={24}
                    />
                  }
                  checkedIcon={
                    <img
                      src={RadioCheckedIcon}
                      style={{ marginBottom: 0 }}
                      width={24}
                      height={24}
                      alt=""
                    />
                  }
                  onChange={handleChange}
                />
                <label className="icon-target pointer" htmlFor="OptingOut1">
                  Not interested in job/role/company
                </label>
              </div>
              <div
                className={
                  values.opting_out_reason === "get_offer_from_other_company"
                    ? "checkdiv checked"
                    : "checkdiv"
                }
              >
                <Radio
                  disableRipple
                  id="OptingOut2"
                  name="opting_out_reason"
                  checked={
                    values.opting_out_reason === "get_offer_from_other_company"
                  }
                  value="get_offer_from_other_company"
                  icon={
                    <img
                      src={RadioOutlinedIcon}
                      style={{ marginBottom: 0 }}
                      alt=""
                      width={24}
                      height={24}
                    />
                  }
                  checkedIcon={
                    <img
                      src={RadioCheckedIcon}
                      style={{ marginBottom: 0 }}
                      width={24}
                      height={24}
                      alt=""
                    />
                  }
                  onChange={handleChange}
                />
                <label className="icon-target pointer" htmlFor="OptingOut2">
                  Got offer from other company
                </label>
              </div>
              <div
                className={
                  values.opting_out_reason === "not_looking_for_opprtunities"
                    ? "checkdiv checked"
                    : "checkdiv"
                }
              >
                <Radio
                  disableRipple
                  id="OptingOut3"
                  name="opting_out_reason"
                  checked={
                    values.opting_out_reason === "not_looking_for_opprtunities"
                  }
                  value="not_looking_for_opprtunities"
                  icon={
                    <img
                      src={RadioOutlinedIcon}
                      style={{ marginBottom: 0 }}
                      alt=""
                      width={24}
                      height={24}
                    />
                  }
                  checkedIcon={
                    <img
                      src={RadioCheckedIcon}
                      style={{ marginBottom: 0 }}
                      width={24}
                      height={24}
                      alt=""
                    />
                  }
                  onChange={handleChange}
                />
                <label className="icon-target pointer" htmlFor="OptingOut3">
                  Not looking for opportunities anymore
                </label>
              </div>

              <div style={{ marginTop: 30 }}>
                <h3>Other Reason</h3>
                <textarea
                  className="reason"
                  id="OptingOut4"
                  name="other_reason"
                  placeholder="Please Enter Your Reason"
                  onChange={handleChange}
                />
              </div>
              {props.err_msg != "" && (
                <p className="err_msg"> {props.err_msg} </p>
              )}
            </DialogContent>

            <DialogActions className="opting-out-actions">
              <Button
                fullWidth
                style={{ borderRadius: 8 }}
                variant="contained"
                type="submit"
                className="btn-3 hy-btn"
              >
                OPT OUT
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </div>
  );
}

// Customizable Area End
