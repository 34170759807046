// Customizable Area Start
//@ts-nocheck 
//@ts-ignore
import React, {useState} from 'react'
import DashboardController from "./DashboardController"
import {Grid, Card, CardActionArea, CardContent, Typography, Avatar, Button, IconButton, AccordionDetails, AccordionSummary, Accordion, Divider,
   FormControl, FormControlLabel, Checkbox, FormGroup} from "@material-ui/core"
import {TotalCandidates, SelectedCandidates, FilterLogo, CheckBoxOutline, CheckboxSelected} from './assets';
import {Link} from "react-router-dom"
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface cardData {
  jobRole: string,
  category: string,
  company: string,
  manager: string,
  recruiter: string,
  appliedCandidate: string,
  selectedCandidate:string,
  id:number,
  filterObj: {activeJobFilter: any, closedJobFilter: any},
  type: string,
  activeClosedJobs: string;
}

const getFilterString = (jobTypeFilter:any, postedTimeFilter:any) => {
  const jobTypeFilterData = jobTypeFilter && jobTypeFilter.length > 0 ? `key[]=job_type&job_type_ids=[${jobTypeFilter}]` : "";
  const postedTimeFilterData = postedTimeFilter && postedTimeFilter.length > 0 ? `key[]=posted_on&posted_value=${postedTimeFilter}` : "";

  let filter = "";
  if (jobTypeFilterData && postedTimeFilterData) {
    filter = `${jobTypeFilterData}&${postedTimeFilterData}`;
  }
  if (!jobTypeFilterData && postedTimeFilterData) {
    filter = `${postedTimeFilterData}`
  }
  if (jobTypeFilterData && !postedTimeFilterData) {
    filter = `${jobTypeFilterData}`
  }
  return filter;
}

const showJobsScreen = (role: any) => {
  return (role === "client admin" || role === "manager" || role === "recruiter") ? true : false;
}

function JobCard({id=0,jobRole="",category="",company="",type="",manager="",recruiter="",appliedCandidate="0",selectedCandidate="0", filterObj, activeClosedJobs}:cardData){
  return (
      <Card className="job-card"  >
        <Link to={{ pathname: '/Home/View_Jobs', state:{id:id, activeJobFilter: filterObj.activeJobFilter, closedJobFilter: filterObj.closedJobFilter, currentTabName: activeClosedJobs,type:type} }} >
        <CardActionArea>
          <CardContent>
          <div className="job-card-label" >{category}</div>
            <p style={{fontSize: "18px", fontWeight: "bold", marginBottom: "0px"}} >
              {jobRole}
            </p>
            <p style={{fontSize:"15px" }} >
              {company}
            </p>
            <p style={{display:"flex", alignItems:'center'}} >
              <Avatar style={{background:"#e557a425" ,color:"#e557a4",display: "inline-flex"}} className="me-2 avatar-icon">
            { manager.includes(' ') ? manager.split(" ")[0][0]+manager.split(" ")[1][0] : manager[0]}
            </Avatar> 
            {manager} (Manager)
            </p>
            
            <p style={{display:"flex", alignItems:'center'}} >
            <Avatar style={{background:"#46004625" ,color:"#460046", display: "inline-flex"}} className="me-2 avatar-icon">
            { recruiter.includes(' ') ? recruiter.split(" ")[0][0]+recruiter.split(" ")[1][0] : recruiter[0] }  
            </Avatar> 
            {recruiter} (Recruiter)
            </p>
            <div style={{display:"flex", justifyContent:"space-between", marginTop:"8px", paddingRight:"30px"}}>
            <div>
              <img src={TotalCandidates} alt='Applied_Candidate' style={{ height: "40px" }} />
            <span className="jobcard-label" >
              Applied Candidate
            </span>
            <p className='fw-bold'>
              {appliedCandidate}
            </p>
            </div>
            <div>
            <img src={SelectedCandidates} alt='Selected_Candidate' style={{ height: "40px" }} />
            <span className="jobcard-label" >
              Selected Candidate
            </span>
            <p className='fw-bold'>
            {selectedCandidate}
            </p>
            </div>
            </div>
          </CardContent>
        </CardActionArea>
        </Link>
      </Card>
  )
}

const JobFilter = ({jobTypes, onApplyFilter, appliedFilter, onCloseFilter}:any) => {
  const jobTypeList = jobTypes || [];
  const postedTypes = [{name:"Today", value:"today"}, {name:"This Week", value:"week"}, {name:"This Month", value:"month"}, {name:"This Year", value:"year"}];

  const [expanded, setExpanded] = useState<string[]>(['panel1', 'panel2']);
  const [jobTypeFilter, setJobTypeFilter] = useState<any[]>(appliedFilter?.jobTypeFilter || []);
  const [postedTimeFilter, setPostedTimeFilter] = useState<any[]>(appliedFilter?.postedTimeFilter || []);

  const toggleAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    const list = [...expanded];
    if (isExpanded) {
      list.push(panel);
    } else {
      const index = list.findIndex((value) => value === panel);
      if (index > -1) {
        list.splice(index, 1);
      }
    }
    setExpanded(list);
  };

  const handleFilterChange = (e:any, type:string) => {
    let data = type === "jobTypeFilter" ? [...jobTypeFilter] : postedTimeFilter;
      if (e.target.checked) {
        if (type === "postedTimeFilter") {
          data = [];
          data.push(e.target.name);
        } else {
          data.push(Number(e.target.name))
        }
      } else {
        if (type === "postedTimeFilter") {
          data = [];
        } else {
          const index = data.findIndex((value) => value.toString() === e.target.name.toString());
          if (index > -1) {
            data.splice(index, 1);
          }
        }
      }
      type === "jobTypeFilter" ? setJobTypeFilter(data) : setPostedTimeFilter(data);
  }

  const resetFilter = () => {
    setJobTypeFilter([])
    setPostedTimeFilter([])
  }

  const applyFilter = () => {
    let filter = "";
    let filterObj = {};
    filter = getFilterString(jobTypeFilter,postedTimeFilter);

    filterObj = {
      jobTypeFilter:jobTypeFilter,
      postedTimeFilter:postedTimeFilter,
    }
    onApplyFilter(filter, filterObj);
  }

  return (
    <div className='job-filter-container'>
      <div className='job-filter-header'>
        <div>Filter</div>
        <IconButton onClick={onCloseFilter} size='small'><CloseIcon className="close-icon"/></IconButton>
      </div>
      <div className='job-filter-content'>
        <div className='accordion-container'>
          <Accordion expanded={expanded.includes("panel1")} onChange={toggleAccordion("panel1")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='accordion-icon' />}
            >
              <Typography className='accordion-title'>Job Type</Typography>
            </AccordionSummary>
            <Divider style={{margin:"0px 16px"}}/>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormGroup>
                  {jobTypeList.map((item: any) => (
                    <FormControlLabel
                      control={<Checkbox className='checkbox'
                      icon={<img src={CheckBoxOutline} height={20} width={20} />}
                      checkedIcon={<img src={CheckboxSelected} height={20} width={20} />}
                      checked={jobTypeFilter.includes(item.id)} 
                      name={item.id.toString()} 
                      onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, "jobTypeFilter")}/>}
                      label={item.name}
                      className="label"
                      key={item.id+"job-type"}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className='accordion-container'>
          <Accordion expanded={expanded.includes("panel2")} onChange={toggleAccordion("panel2")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='accordion-icon' />}
            >
              <Typography className='accordion-title'>Posted on</Typography>
            </AccordionSummary>
            <Divider style={{margin:"0px 16px"}}/>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormGroup>
                  {postedTypes.map((item: any) => (
                    <FormControlLabel
                      control={<Checkbox className='checkbox' 
                      checked={postedTimeFilter.includes(item.value)}
                      icon={<img src={CheckBoxOutline} height={20} width={20} />}
                      checkedIcon={<img src={CheckboxSelected} height={20} width={20} />} 
                      name={item.value} 
                      onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, "postedTimeFilter")}/>}
                      label={item.name}
                      className="label"
                      key={item.value+"posted-type"}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Grid container spacing={2} style={{padding:26}}>
        <Grid item xs={6} className='job-filter-action'>
          <Button className='reset-btn' fullWidth variant='outlined' onClick={resetFilter}>RESET</Button>
        </Grid>
        <Grid item xs={6} className='job-filter-action'>
          <Button className='apply-btn' fullWidth onClick={applyFilter}>APPLY</Button>
        </Grid>
      </Grid>
    </div>
  )
}
export default class JobsPage extends DashboardController {
  constructor(props: any) {
    super(props)
  }

  async componentDidMount(){
    this.role = localStorage.getItem('role')?.toLowerCase();
    this.notAuthorizeToViewJobs(this.role);
    this.getJobTypes();
    this.getCompanyList();
    const locationState = this.props.location.state;
    if (locationState && locationState.currentTabName && locationState.currentTabName !== "") {
      this.props.updateSection(locationState.currentTabName)
      this.setState({active_closed_jobs: locationState.currentTabName})
    } else {
      this.props.updateSection("active")
    }
    if (locationState && locationState.parent === "Jobs" && locationState.activeJobFilter) {
        const activeFilter = locationState.activeJobFilter;
        const filterString = getFilterString(activeFilter.jobTypeFilter, activeFilter.postedTimeFilter);
        if (filterString) {
          setTimeout(() => {
            this.setState({...this.state, activeJobFilter: activeFilter});
          }, 0);
          this.props.getActiveFiltersJobs(filterString+`&status=active`);
        } else {
          this.props.getActiveJob();
        }
    } else {
      this.props.getActiveJob();
    }
    if (locationState && locationState.parent === "Jobs" && locationState.closedJobFilter) {
      const closedFilter = locationState.closedJobFilter;
      const filterString = getFilterString(closedFilter.jobTypeFilter, closedFilter.postedTimeFilter);
      if (filterString) {
        setTimeout(() => {
          this.setState({...this.state, closedJobFilter: closedFilter});
        }, 0);
        this.props.getClosedFiltersJobs(filterString+`&status=closed`);
      } else {
        this.props.getClosedJob()
      }
    } else {
      this.props.getClosedJob()
    }
    this.props.history.replace("/Home/Jobs", undefined);
  }

  notAuthorizeToViewJobs = (role: any) => {
    if (!showJobsScreen(role)) {
      this.props.history.go(-1);
    }
  }

  FilterCall = (filterString: any, filterObj: any, activeJobsFilter: any, closedJobsFilter: any, tab: any) => {

    if (filterString) {
      if (this.state.active_closed_jobs === "active") {
        this.props.getActiveFiltersJobs(filterString + `&status=active`)
      }

      if (this.state.active_closed_jobs === "closed") {
        this.props.getClosedFiltersJobs(filterString + `&status=closed`);
      }
    } else {
      this.state.active_closed_jobs === "active" ?
        this.props.getActiveJob() : this.props.getClosedJob();
    }
    this.setState({
      ...this.state, activeJobFilter: tab === "active" ? filterObj : activeJobsFilter,
      closedJobFilter: tab === "closed" ? filterObj : closedJobsFilter, isOpenJobFilter: false})

  }

  render() {
    const saveFilter = (filterString: any, filterObj: any) => {
      const activeJobsFilter = { ...this.state.activeJobFilter };
      const closedJobsFilter = { ...this.state.closedJobFilter };
      const tab = this.state.active_closed_jobs;

      this.FilterCall(filterString, filterObj, activeJobsFilter, closedJobsFilter, tab)
    }

  return (
  <>
  <div className="jobs-list-container" >
    <div style={{marginLeft:"10px"}}>
      <span className="admin-jobs-heads"  >
        <input type="radio" name="job-head" id="active-jobs" checked={this.state.active_closed_jobs==="active"} />
        <label htmlFor="active-jobs" className="" onClick={()=>{this.setState({ ...this.state , active_closed_jobs:"active"}); this.props.updateSection("active")}} >
          Active Jobs
          <div className="jobs-status-counter">{this.props?.activeData?.length}</div>
        </label>
        
      </span>
      <span className="admin-jobs-heads"  >
        <input type="radio" name="job-head" id="closed-jobs" checked={this.state.active_closed_jobs==="closed"}/>
        <label htmlFor="closed-jobs" className="" onClick={()=>{this.setState({ ...this.state , active_closed_jobs:"closed"}); this.props.updateSection("closed")}} >
          Closed Jobs  
          <div className="jobs-status-counter">{this.props?.closedData?.length}</div>
        </label>
      </span>
    </div>
    <div style={{paddingTop:24}}>
      <Button variant='outlined'
        className='filter-btn'
        onClick={() => this.setState({...this.state, isOpenJobFilter: true})}
        startIcon={ <img src={FilterLogo} alt="Filter" style={{ width: "14px", marginTop: "3px" }} />
      }>
        Filters
      </Button>
    </div>
  </div>
  {this.state.isOpenJobFilter && <div className='job-filter-shadow'> <JobFilter jobTypes={this.state.job_types} 
     appliedFilter={this.state.active_closed_jobs === "active" ? this.state.activeJobFilter : this.state.closedJobFilter} onApplyFilter={saveFilter} onCloseFilter={() => this.setState({...this.state, isOpenJobFilter: false})}/></div>}
    <div className="job-card-wrapper">
      {this.state.active_closed_jobs === "active" && this.props.activeData === "" && <h4>Loading...</h4>}
      {this.state.active_closed_jobs === "active" && typeof (this.props.activeData) !== "string" && this.props.activeData.length === 0 && <h4>No Data Found</h4>}
      {this.state.active_closed_jobs === "active" && typeof (this.props.activeData) !== "string" && this.props.activeData.length > 0 && this.props.activeData.map((item: any) =>
        <JobCard
          key={item.id + "active"}
          id={item.id}
          jobRole={item.job_role}
          type={'1'}
          category={item.get_dummy_data.category}
          company={item.get_dummy_data.company_name}
          manager={item.get_dummy_data.manager}
          recruiter={item.get_dummy_data.recruiter}
          appliedCandidate={item.get_dummy_data.applied_candidate}
          selectedCandidate={item.get_dummy_data.selected_candidate}
          filterObj={{ activeJobFilter: this.state.activeJobFilter, closedJobFilter: this.state.closedJobFilter }}
          activeClosedJobs={this.state.active_closed_jobs}
        />)}
      {this.state.active_closed_jobs === "closed" && this.props.closedData === "" && <h4>Loading...</h4>}
      {this.state.active_closed_jobs === "closed" && typeof (this.props.closedData) !== "string" && this.props.closedData.length === 0 && <h4>No Data Found</h4>}
      {this.state.active_closed_jobs === "closed" && typeof (this.props.closedData) !== "string" && this.props.closedData.length > 0 && this.props.closedData.map((item: any) =>
        <JobCard
          key={item.id + "closed"}
          id={item.id}
          jobRole={item.job_role}
          category={item.get_dummy_data.category}
          company={item.get_dummy_data.company_name}
          type={'2'}
          manager={item.get_dummy_data.manager}
          recruiter={item.get_dummy_data.recruiter}
          appliedCandidate={item.get_dummy_data.applied_candidate}
          selectedCandidate={item.get_dummy_data.selected_candidate}
          filterObj={{ activeJobFilter: this.state.activeJobFilter, closedJobFilter: this.state.closedJobFilter }}
          activeClosedJobs={this.state.active_closed_jobs}
        />)}
    </div>
    </>
  )
  }
}

// Customizable Area End