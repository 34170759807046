// Customizable Area Start
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import {Button,
  } from "@material-ui/core"

export default function CustomViewJobRow(props:any) {
  return (
    <>
          {props.params.msg.split("^") && props.params.msg.split("^")[0] && props.params.msg.split("^")[0] === "apiError" ? <>
        <CloseIcon style={{ color: "red" }} />
        <h4>Error Occurred</h4>
        {props.params.msg.split(" ") && props.params.msg.split("^")[1] ?
          <h6> {props.params.msg.split("^")[1]} </h6>
          : <h6 >Some error is Occurred !<br />
            Please try Again
          </h6>}
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button onClick={() => {
            //NOSONAR
            props.params.handleClose();
          }} variant="outlined" className="hy-btn" fullWidth style={{ borderRadius: 8, height: 56 }}>
            OKAY
          </Button>
        </div>
      </>
        : null}
    </>
  )
}
// Customizable Area End