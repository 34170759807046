Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DocumentOpener";
exports.labelBodyText = "DocumentOpener Body";
exports.jobDetailAPiEndPoint = "account_block/jobs/"
exports.jobTypesApiEndPoint = "account_block/jobs/job_types";
exports.btnExampleTitle = "CLICK ME";
exports.commonSkillApiEndPoint = "bx_block_panel/panels/show_skills"
exports.jobCategoryApiEndPoint = "jobs_category";
exports.recruiterListApiEndPoint = "get_recruiters";
exports.managerListApiEndPoint = "get_managers";
exports.getApiMethod = "GET";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.openFromDevice = "Open from device";
exports.openWithUrl = "Open with URL";
exports.getCandidateDetailsApiEndPoint = "account_block/candidates/";
exports.deleteApiMethod = "DELETE";
exports.assignCandidateListEndPoint = "/account_block/jobs/2/assign_candidate_list";
exports.searchAssignCandidateListEndPoint = "account_block/jobs/";
exports.assignCandidateEndPoint = "/account_block/jobs/2/add_assign_candidate";
exports.getPanelReviewsEndPoint = "bx_block_reviews/panel_reviews/";
exports.scheduleNextRoundEndPoint = "create_round_job_slot/";
exports.postApiMethod = "POST";
exports.getLocationsApiEndPoint = "account_block/jobs/locations_list";

// Customizable Area End