Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.panelRequestsApiEndPoint = 'bx_block_panel/panels'
exports.apiMethodTypePut = "PUT";
exports.dropdownDataApiEndPoint = "bx_block_panel/panels/show_skills";
exports.panelApproveAcceptApiEndPoint = "bx_block_coordinator/coordinator/panels";
exports.getInterviewListApiEndPoint = "bx_block_coordinator/coordinator/panels/panel_interview_list?tab=";
// Customizable Area End