import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";


//          //
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

interface Props {
  navigation?: any;
  data: any;
}

interface S {}

interface SS { }

export default class AlertBlock extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    
  }



  render() {
    return (
      <Snackbar id="alert-box"  open={this.props?.data?.show} autoHideDuration={5000} onClose={this.props?.data?.close} anchorOrigin={{ vertical:this.props?.data?.vertical, horizontal:this.props?.data?.horizontal }}>
        <MuiAlert elevation={6} variant="filled" onClose={this.props?.data?.close} severity={this.props?.data?.type} > 
                                                                               {/* severity = "success" || "warning" || "error" || "info" */}
          {this.props?.data?.msg}
        </MuiAlert>
      </Snackbar>
    );
}
}


