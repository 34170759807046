// Customizable Area Start

import React from "react";
import { Drawer, IconButton, Grid, Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { EditIcon, DefaultProfile, UploadIcon } from "./assets";

const showFileValidation = (showMessage: boolean) => {
  return (<>
  {showMessage && <div data-test-id="profile-img-validation-msg" style={{ whiteSpace: "nowrap" }} className="error-msg">The file size should be less than 2 MB</div>}
  </>)
}

const getDocumentPlaceHolder = (value: any) => {
  return <>
  <span className='selected_date doc-placeholder' style={{display: value ? "none": "inline"}}>{value ? "" : "Aadhar card / PAN Card / Passport"}</span></>
}

export default class AddPanel extends React.Component<any, any> {
  initialValue = {
    first_name: "",
    last_name: "",
    email: "",
    image: "",
    country_code: "+91",
    phone_number: "",
    panel_document_proof: "",
  };
  constructor(props: any) {
    super(props);
    this.state = {
      profileImage: DefaultProfile,
      panelDetails: this.initialValue,
      panel_document_proof_name: "",
      errorObj: {},
      isDocumentValid: true,
      isProfileImageValid: true
    };
  }

  savePanelDetails = (formValues: any, formErrors: any) => {
    if (!Object.keys(formErrors).length) {
      const formData = { ...formValues };
      formData.first_name = formData.first_name.trim();
      formData.last_name = formData.last_name.trim();
      formData.email = formData.email.toLowerCase();
      formData.country_code = "+91";
      this.setState({ ...this.state, errorObj: {} });
      this.props.savePanel(formData);
    } else {
      const err = Object.keys(formErrors);
      const inputPara = document.querySelector(`input[name=${err[0]}]`);
      if (inputPara) {
        inputPara.scrollIntoView({ behavior: "smooth", block: "center", });
      }
      this.setState({ ...this.state, errorObj: formErrors });
    }
  }

  render() {
    const { show, hide } = this.props;
    const { errorObj, panelDetails, profileImage } = this.state;

    const onAddPanelClose = () => {
      this.setState({
        ...this.state,
        profileImage: DefaultProfile,
        panelDetails: this.initialValue,
        isDocumentValid: true,
        errorObj: {},
        isProfileImageValid: true
      });
      hide();
    };

    const validate = (values: any) => {
      const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
      const regexForPhoneNumber = new RegExp(/^\d{10}$/);
      const regexForAlphabets = new RegExp(/^[A-Za-z0-9 ]+$/);
      const errors: any = {};
      if (
        !values.first_name ||
        (values.first_name && values.first_name.trim() === "")
      ) {
        errors.first_name = "First Name is Required";
      } else if (
        values.first_name &&
        !regexForAlphabets.test(values.first_name)
      ) {
        errors.first_name = "Please enter valid First Name";
      } else if (
        !values.last_name ||
        (values.last_name && values.last_name.trim() === "")
      ) {
        errors.last_name = "Last Name is Required";
      } else if (
        values.last_name &&
        !regexForAlphabets.test(values.last_name)
      ) {
        errors.last_name = "Please enter valid Last Name";
      } else if (!values.email) {
        errors.email = "Email is Required";
      } else if (!emailRegex.test(values.email)) {
        errors.email = "Enter Valid Email";
      } else if (!values.phone_number) {
        errors.phone_number = "Phone Number is Required";
      } else if (!regexForPhoneNumber.test(values.phone_number)) {
        errors.phone_number = "Phone Number should be 10 digit number";
      }
      this.savePanelDetails(values, errors);
    };

    return (
      <div>
        <Drawer anchor={"right"} open={show}>
          <Formik
            initialValues={panelDetails}
            enableReinitialize
            onSubmit={(values: any) => {
              console.log(values);
            }}
          >
            {({ values, handleSubmit, handleChange, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className="availability-container">
                  <div className="availability-header">
                    <div>Add New Panel</div>
                    <IconButton data-test-id="panel-close-btn" onClick={onAddPanelClose} size="small">
                      <CloseIcon className="close-icon" />
                    </IconButton>
                  </div>
                  <div className="availability-content">
                    <div
                      className="add-new-candidate-upload-image"
                      id="image"
                      style={{ top: 0, marginBottom: 25 }}
                    >
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        data-test-id="panel-profile-image"
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0].size < 2097152) {
                            const objectUrl = URL.createObjectURL(e.target.files[0]);
                            setFieldValue("image", e.target.files[0]);
                            this.setState({...this.state, profileImage: objectUrl, isProfileImageValid: true});
                          } else {
                            setFieldValue("image", "");
                            this.setState({...this.state, profileImage: DefaultProfile, isProfileImageValid: false});
                          }
                        }}
                        id="panel-profile"
                      />
                      <label htmlFor="panel-profile" className="pointer">
                        <img src={profileImage} alt="user-image" />
                        <div className="add-new-candidate-edit-img add-panel-edit-msg">
                          <img src={EditIcon} alt="edit-icon" />
                        </div>
                      </label>
                      {showFileValidation(!this.state.isProfileImageValid)}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          First Name
                          <TextField
                            value={values.first_name}
                            fullWidth
                            className="text-input"
                            variant="outlined"
                            name="first_name"
                            onChange={handleChange}
                            placeholder="Enter First Name"
                          />
                        </div>
                        {errorObj.first_name ? (
                          <span className="error-msg">
                            {errorObj.first_name}
                          </span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          Last Name
                          <TextField
                            value={values.last_name}
                            fullWidth
                            className="text-input"
                            variant="outlined"
                            name="last_name"
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                          />
                        </div>
                        {errorObj.last_name ? (
                          <span className="error-msg">
                            {errorObj.last_name}
                          </span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          Email ID
                          <TextField
                            value={values.email}
                            fullWidth
                            className="text-input"
                            variant="outlined"
                            name="email"
                            onChange={handleChange}
                            placeholder="Enter Email ID"
                          />
                        </div>
                        {errorObj.email ? (
                          <span className="error-msg">{errorObj.email}</span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="add-new-vendor-form-block">
                          Phone Number
                          <div className="add-vendor-phone-number">
                            <TextField value="+91"
                              className="text-input country-code"
                              style={{ flex: "1", minWidth: "60px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                              variant="outlined"
                              name="country_code"
                              onChange={handleChange}
                              placeholder="Code"
                            />
                            <TextField
                              value={values.phone_number}
                              fullWidth
                              className="text-input phone-no"
                              variant="outlined"
                              name="phone_number"
                              onChange={handleChange}
                              placeholder="Enter Phone Number"
                            />
                          </div>
                        </div>
                        {errorObj.phone_number ? (
                          <span className="error-msg">
                            {errorObj.phone_number}
                          </span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                      <div className="add-new-vendor-form-block add-panel-block">
                        <input type="file" data-test-id="panel-document" hidden id="panel-document" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, image/jpeg, image/png" onChange={(e)=>{
                            if (e.target.files && e.target.files[0].size < 2097152) {
                              setFieldValue("panel_document_proof", e.target.files[0]);
                              this.setState({ ...this.state, panel_document_proof_name: e.target.files[0].name, isDocumentValid: true });
                            } else {
                              setFieldValue("panel_document_proof", "");
                              this.setState({ ...this.state, panel_document_proof_name: "", isDocumentValid: false });
                            }
                          }} />
                          <div style={{ position: "relative", cursor: 'pointer' }} >
                            <label htmlFor="panel-document">
                              <div style={{ cursor: 'pointer' }} className="birth_date doc-container">
                                {getDocumentPlaceHolder(values.panel_document_proof)}
                                <div className="doc-name">{this.state.panel_document_proof_name}</div>
                                <Button
                                  component="span"
                                  className="input-group-addon-upload panel-upload-btn pointer"
                                >
                                  <img
                                    width={20}
                                    src={UploadIcon}
                                    alt="Upload"
                                    className="upload-icon"
                                    style={{ paddingRight: 5 }}
                                  />
                                  UPLOAD
                                </Button>
                              </div>
                            </label>
                          </div>
                        </div>
                        {showFileValidation(!this.state.isDocumentValid)}
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={2} style={{ padding: "20px 26px" }}>
                    <Grid item xs={6} className="availability-action">
                      <Button
                        className="cancel-btn"
                        fullWidth
                        variant="outlined"
                        data-test-id="close-panel-button"
                        onClick={onAddPanelClose}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                    <Grid item xs={6} className="availability-action">
                      <Button
                        className="save-btn"
                        fullWidth
                        onClick={() => validate(values)}
                      >
                        SAVE
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          </Formik>
        </Drawer>
      </div>
    );
  }
}

// Customizable Area End
