Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PutApiMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.PostAPiMethod = "POST";
exports.DeleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
// exports.FileApiContentType = "multipart/form-data;boundary='boundary'";
// exports.FileApiContentType = "multipart/form-data";
exports.FileApiContentType = undefined
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getNotAppliedCandidateListApiEndPoint = "account_block/candidates?type=not_applied&search="
exports.getAppliedCandidateListApiEndPoint = "account_block/candidates?type=applied&search="
exports.getSelectedCandidateListApiEndPoint = "account_block/candidates?type=selected&search="
exports.getRejectedCandidateListApiEndPoint = "account_block/candidates?type=rejected&search="
exports.uploadCandidateApiEndPoint = "account_block/candidates"
exports.getAllJobsApiEndPoint = "account_block/jobs";
exports.getApiMethod = "GET";
exports.getRolesApiEndPoint = "get_roles"
exports.bulkUploadApiEndPoint = "bulk_upload"
exports.btnExampleTitle = "CLICK ME";
exports.addPanelUserApiEndPoint = 'bx_block_coordinator/coordinator/panels'
exports.recruiterList="account_block/schedule_interviews/job_list";
exports.profileDetailAPIEndPoint = 'account_block/user_profile/';
exports.panelRequestsApiEndPoint = 'bx_block_coordinator/coordinator/panels';
exports.getFilteredJobsApiEndPoint = "filter_job";
exports.getAllManagersApiEndPoint = "account_block/managers?search=";
exports.saveNewManagerApiEndPoint = "account_block/managers";
exports.deleteManagerApiEndPoint = "account_block/managers";
exports.saveDisableSlotApiEndPoint = "bx_block_panel/schedules/create_disable_slot";
exports.saveDefaultSlotApiEndPoint = "bx_block_panel/schedules/create_default_slot";
exports.getDefaultSlotApiEndPoint = "bx_block_panel/schedules/show_default_slot";
exports.getDefaultManualSlotApiEndPoint = "bx_block_panel/schedules/show_user_slot";
exports.saveAvailabilityApiEndPoint = "bx_block_panel/schedules/create_manual_slot";
exports.getAvailabilityApiEndPoint = "bx_block_panel/schedules/show_manual_slot";
exports.getSourceListApiEndPoint = "account_block/candidates/source_list";
exports.getExperienceListApiEndPoint = "account_block/jobs/experience_list";
exports.saveRecruiterApiEndPoint = "account_block/recruiters";
exports.getAllRecruitersApiEndPoint = "account_block/recruiters?search=";
exports.deleteVendorApiEndPoint = "account_block/recruiters";
exports.panelList="panel_list";
exports.panelDisableList="bx_block_panel/schedules";
exports.panelInterview="get_job_interview_by_panel";
exports.recruiterInterview="get_job_interview_by_recruiter";
exports.coordinatorInterview="get_job_interview_by_coordinator";
exports.addbankAccount = "bx_block_payments/bank_accounts";
exports.getbankAccount = "bx_block_payments/bank_accounts";
exports.updatebankAccount ="bx_block_payments/bank_accounts";
exports.getInterviews = "bx_block_payments/payment_details";
exports.selectedCandidateListApiEndPoint="bx_block_reviews/panel_reviews?selected=1&search=";
exports.rejectedCandidateListApiEndPoint="bx_block_reviews/panel_reviews?rejected=1&search=";
exports.getRecruiterClosed = "account_block/jobs";
exports.panelProfileApiEndPoint = "bx_block_panel/panels/";
exports.dropdownApiEndPoint = "bx_block_panel/panels/show_skills";
exports.deletePanelSkillApiEndPoint = "bx_block_panel/panels/remove_skills?primary_skill=";
exports.jobRollList ="account_block/jobs/get_job_roles";
exports.unassignPanel="get_unassigned_panels";
exports.assignPanel="assign_panel";
exports.unassignData="unassigned_interviews";
exports.getLocationsApiEndPoint = "account_block/jobs/locations_list";
exports.getUpdateSlot="account_block/jobs/";
exports.UpdateSlotTime="account_block/jobs/";
exports.getRecruiterJobListApiEndPoint = "account_block/schedule_interviews/get_job_list_by_recruiter";

// Customizable Area End