// Customizable Area Start
import React from "react";
import {
 Modal, Divider
} from "@material-ui/core";
import '../src/panel.web.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CatalogueController, { Props } from "./CatalogueController";
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment";
import 'moment/locale/en-gb';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { getFullName } from '../../dashboard/src/Dashboard.web';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    } ,
  },
});

const calenderIcon = require('../assets/calender.png')
moment.locale("en-GB");

const localizer: any = momentLocalizer(moment);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

let formats = {
  timeGutterFormat: 'h A',
   dayFormat: (date:any,culture:any, localizer:any) =>
  localizer.format(date, 'ddd D', culture),
}

export default class Scheduled_PanelInterviews extends CatalogueController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.setState({ ...this.state, selectedDate: new Date() });
    this.props.getInterviewPanel(moment(new Date()).startOf("week").format("DD-MM-YYYY"), moment(new Date()).endOf("week").format("DD-MM-YYYY"));
    this.props.getPanelDisableList();
    this.props.getDefaultManualSlotTiming();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(this.props.panelInterview) !== JSON.stringify(prevProps.panelInterview)) {
      this.setEventsData(this.props.panelInterview, this.state.disableSlotList);
    }
    if (JSON.stringify(this.props.disable_slot) !== JSON.stringify(prevProps.disable_slot)) {
      this.setDisableSlots();
    }
    if (JSON.stringify(this.props.default_slot) !== JSON.stringify(prevProps.default_slot)) {
      this.setBackgroundEvents();
    }

    const viewType = this.state.calenderViewType;
    if ((prevState.calenderViewType !== viewType) ||
      (moment(prevState.selectedDate).format("DD-MM-YYYY") !== moment(this.state.selectedDate).format("DD-MM-YYYY"))) {
      let inputDate = this.getStartAndEndDate(this.state.selectedDate, viewType)
      this.props.getInterviewPanel(inputDate.inputStartDate, inputDate.inputEndDate);
      this.setBackgroundEvents();
    }
  }

  setDisableSlots = () => {
    const disableEvents = [...this.props.disable_slot];
    const eventList = [...this.state.recruiterEventsFormattedData];
    let eList: any[] = [];
    disableEvents.forEach((item: any) => {
      let eObj: any = {};
      if (item.slot_from && item.slot_to) {
        let fromDateValue = new Date(item.slot_from);
        let start_date_val = item.slot_from.slice(8, 10);
        let start_month = fromDateValue.getMonth();
        let start_year = fromDateValue.getFullYear();
        let start_hour = fromDateValue.getUTCHours();
        let start_minute = fromDateValue.getUTCMinutes();
        
        let toDateValue = new Date(item.slot_to);
        let end_date_val = item.slot_to.slice(8, 10);
        let end_month = toDateValue.getMonth();
        let end_year = toDateValue.getFullYear();
        let end_hour = toDateValue.getUTCHours();
        let end_minute = toDateValue.getUTCMinutes();
        
        const startTime = new Date(start_year, start_month, start_date_val, start_hour, start_minute, 0);
        const endTime = new Date(end_year, end_month, end_date_val, end_hour, end_minute, 0);

        eObj = {
          start: new Date(startTime), end: new Date(endTime), actualStartTime: new Date(startTime),
          actualEndTime: new Date(endTime), title: "",
          job_role: "", panel_name: "", panel_slot_id: item.id,
        }
        eList.push(eObj);
      }
    });
    this.setState({ ...this.state, recruiterEventsFormattedData: [...eventList, ...eList], disableSlotList: eList });
  }

  setBackgroundEvents = () => {
    const availability = {...this.props.default_slot};
    if (availability && availability.manual_slot && availability.manual_slot.working_availability) {
      if (availability.manual_slot.default_timing) {
        if (availability.default_slot && availability.default_slot.slots && availability.default_slot.slots[0]) {
          const dayList = availability.default_slot.slots[0]?.default_day;
          const slotList = availability.default_slot.slots[0]?.slot_timings;
          this.setPanelAvailability(slotList, dayList);
        }
      } else if (availability.manual_slot.slots && availability.manual_slot.slots.length > 0) {
        const slotList = availability.manual_slot.slots;
        this.setPanelAvailability(slotList);
      }
    }
  }

  setPanelAvailability = (availableSlots: any, defaultDays: any = []) => {
    const start = this.getStartAndEndDate(this.state.selectedDate, this.state.calenderViewType).inputStartDate;
    const end = this.getStartAndEndDate(this.state.selectedDate, this.state.calenderViewType).inputEndDate;
    let startDate = new Date(moment(start, "DD-MM-YYYY").toDate());
    let endDate = new Date(moment(end, "DD-MM-YYYY").toDate());
    const dayStringArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const formatString = 'DD-MM-YYYY h:mm A';
    let backEvents: any = [];

    while (startDate <= endDate) {
      const dateString = moment(startDate).format("DD-MM-YYYY");
      if (defaultDays && defaultDays.length > 0) {
        if (defaultDays.includes(dayStringArray[new Date(startDate).getDay()])) {
          availableSlots.forEach((item: any) => {
            const startSlot = moment(`${dateString} ${item.start_time}`, formatString).toDate();
            const endSlot = moment(`${dateString} ${item.end_time}`, formatString).toDate();

            const backEventObj = {
              title: "available slot", start: startSlot, end: endSlot,
              actualStartTime: startSlot, actualEndTime: endSlot,
              job_role: "", panel_name: "", panel_slot_id: "",
            };
            backEvents.push(backEventObj);
          })
        }
      } else {
        availableSlots.forEach((item: any) => {
          if (item.availability_day && item.availability_day[0].includes(dayStringArray[new Date(startDate).getDay()]) && item.slot_timings) {
            item.slot_timings.forEach((manualSlotOb: any) => {
              const manualStartSlot = moment(`${dateString} ${manualSlotOb.start_time}`, formatString).toDate();
              const manualEndSlot = moment(`${dateString} ${manualSlotOb.end_time}`, formatString).toDate();

              const eventObj = {
                title: "available slot", start: manualStartSlot, end: manualEndSlot,
                actualStartTime: manualStartSlot,
                actualEndTime: manualEndSlot,
                panel_name: "", panel_slot_id: "", job_role: ""
              };
              backEvents.push(eventObj);
            })
          }
        })
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    this.setState({ ...this.state, backGroundEvents: backEvents });
  }
    
   DisableSlotData =() => {

       this.setState({disable_new_data:[],arrayHolder:[]})
             
      //  this.DisableSlot()
      
   this.props.panelInterview?.forEach((item:any)=>{
    
    if((item?.confirm_job_slot?.start_time!=null&&item?.confirm_job_slot?.end_time!=null)){
    
      let start_date = new Date(item?.confirm_job_slot?.start_time);
      let end_data=new Date(item?.confirm_job_slot?.end_time)

      if(moment(start_date).format('MM/DD/YYYY')==moment(end_data).format('MM/DD/YYYY')){

    //new Date(end_year,end_month,end_date,end_hour,end_minute, 0)
    //  console.log('end_date',end_date,end_month,end_year,end_minute,end_hour);
    if(this.state.disable_new_data.length<parseInt(this.props.panelInterview.length)){
  
      console.log('panel_data ',item)

    this.state.disable_new_data.push({title: item.candidate_name, allDay: false, 'start': new Date(item?.confirm_job_slot?.start_time),
        'end': new Date(item?.confirm_job_slot?.end_time),'panel_name':item.panel_name,'interview':'Interviewer','panel_slot_id':item.panel_slot_id,'start_time':'','end_time':''})
        }
      }  
        else{

          console.log('interview_data',item?.confirm_job_slot?.end_time.slice(0,10)+"T19:30:00.000Z")

          let date1 =item?.confirm_job_slot?.start_time.slice(0,10)+"T18:29:00.000Z";
          let date2 =item?.confirm_job_slot?.start_time.slice(0,10)+"T18:30:00.000Z";
          
          console.log('new Date(date1)', new Date().setHours(0,0,0,0))
    this.state.disable_new_data.push({title: item.candidate_name, allDay: false, 'start': new Date(item?.confirm_job_slot?.start_time),
    'end': new Date(date1),'panel_name':item.panel_name,'interview':'Interviewer','panel_slot_id':item.panel_slot_id,'start_time':new Date(item?.confirm_job_slot?.start_time),'end_time':new Date(item?.confirm_job_slot?.end_time)})

    this.state.disable_new_data.push({title: item.candidate_name, allDay: false, 'start': new Date(date2),
    'end': new Date(item?.confirm_job_slot?.end_time),'panel_name':item.panel_name,'interview':'Interviewer','panel_slot_id':item.panel_slot_id,'start_time':new Date(item?.confirm_job_slot?.start_time),'end_time': new Date(item?.confirm_job_slot?.end_time)})
    
        }
      }
      })

            // 👇️ [{id: 1, name: 'Tom'}, {id: 2, name: 'Nick'}]
      
   if(this.props?.panelInterview!=''){
   this.setState({panel_disable_data:this.state.disable_new_data.concat(this.state.arrayHolder),interviewData:true});
   this.setState({disable_user_data:this.state.disable_new_data.concat(this.state.arrayHolder)})
   if(this.state.calenderViewType=='month'){
   this.setState({panel_disable_data:this.state.disable_new_data})
   }
   this.setState({interview_data:this.state.disable_new_data})
  }
   if(this.props?.panelInterview==''){

    this.setState({panel_disable_data:this.state.arrayHolder,disable_data:false});
    this.setState({disable_user_data:this.state.arrayHolder})
    }

    }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="scheduled_interviews_wrapper panel-cal">
          <div className="head panel-cal">
            <div className="month panel-cal">
              <div onClick={() => { document.getElementById("panel-calendar-date")?.click() }}>
                <img src={calenderIcon} width={40} alt="panel-calendar" style={{ cursor: 'pointer', marginLeft: 30 }} />
              </div>
              <span className="month panel-cal"> {moment(this.state.selectedDate).format("MMM-YYYY").slice(0, 3)} {moment(this.state.selectedDate).format("MMM-YYYY").slice(4, 10)} &#160;    <span className="pointer" style={{ marginLeft: 5 }} onClick={() => this.calendarPreviousEvent()} >&lt;</span> &#160;&#160;&#160; <span className="pointer" onClick={() => this.calendarNextEvent()}>&gt;</span></span>
            </div>
            <div className='main_div'> <span className="disable_box"></span> Disable Slots</div>
            <div className='main_div'> <span className="disable_box2"></span>  Available Slots</div>
            <div className='main_div'> <span className="disable_box3"></span> Non-working Day</div>
            <div className="calender-type panel-cal">
              <span className={this.state.calenderViewType === "day" ? "hy-active panel-cal" : ""} onClick={() => this.setState({ calenderViewType: "day" })}>Day</span>
              <span className={this.state.calenderViewType === "week" ? "hy-active panel-cal" : ""} onClick={() => this.setState({ calenderViewType: "week" })}>Week</span>
              <span className={this.state.calenderViewType === "month" ? "hy-active panel-cal" : ""} onClick={() => this.setState({ calenderViewType: "month" })}>Month</span>
            </div>
          </div>
          <Calendar
            className="calendarMain-wrapper panel-cal"
            toolbar={false} localizer={localizer}
            events={this.state.recruiterEventsFormattedData}
            formats={formats} view={this.state.calenderViewType}
            views={[this.state.calenderViewType]} date={this.state.selectedDate}
            onNavigate={(dateOb: any) => this.setRecruiterMoreData(dateOb)}
            components={{
              timeSlotWrapper: this.timeSlotWrapper, // Panel Time Slot Wrapper
              day: {event: this.eventTile},
              week: {event: this.eventTile},
              month: {event: this.eventTile},
            }}
            showAllEvents={false} showMultiDayTimes={true}
            backgroundEvents={this.state.backGroundEvents}
            onView={(viewObj: any) => viewObj}
          />
        </div>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker className="hidden-date" format="dd/MM/yyyy" label="Date" value={new Date(this.state.selectedDate)} id="panel-calendar-date" onChange={(e: any) => { this.handleDateChange(e) }} />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
        {this.state.isOpenRecruiterSelectedEvent && <Modal
          open={this.state.isOpenRecruiterSelectedEvent}
          onClose={() => this.setState({ isOpenRecruiterSelectedEvent: false, recruiterSelectedEvent: "" })}
          className="jobs-success-model view-interview-modal-wrapper panel-cal"
        >
          <div className="infromation-block coordinator-panel-block panel-cal">
            <div className="heading panel-cal">{moment(this.state.recruiterSelectedEvent?.start).format("DD MMM yyyy")}
              <div className="closed pointer panel-cal" onClick={() => this.setState({ isOpenRecruiterSelectedEvent: false, recruiterSelectedEvent: "" })}>X</div>
            </div>
            <div >
              {this.state.recruiterSelectedEvent !== '' &&
                <div className="interview mt-15 panel-cal">
                  <label className="panel_title panel-cal">{getFullName(this.state.recruiterSelectedEvent?.title)}</label>
                  <div style={{ fontSize: 13 }}>{this.formatAMPM(this.state.recruiterSelectedEvent?.actualStartTime)} - {this.formatAMPM(this.state.recruiterSelectedEvent?.actualEndTime)}</div>
                  <div className="event_interviwer panel-cal" style={{ fontSize: 13, marginTop: 10 }}>Interviewer</div>
                  <div className="event_title panel-cal" style={{ fontSize: 14, fontWeight: 'bold', marginTop: 0 }}>{getFullName(this.state.recruiterSelectedEvent?.panel_name)} </div>
                </div>
              }
            </div>
          </div>
        </Modal>}
        {this.state.isOpenRecruiterShowMore &&
          <Modal
            open={this.state.isOpenRecruiterShowMore}
            onClose={() => this.setState({ isOpenRecruiterShowMore: false, recruiterMoreEventData: [] })}
            className="jobs-success-model view-interview-modal-wrapper panel-cal"
          >
            <div className="infromation-block coordinator-panel-block panel-cal">
              <div className="heading panel-cal">{moment(this.state.recruiterMoreEventData[0].start).format("DD MMMM yyyy")}
                <div className="closed pointer panel-cal" onClick={() => this.setState({ isOpenRecruiterShowMore: false, recruiterMoreEventData: [] })}>X</div>
              </div>
              {this.state.recruiterMoreEventData.map((panelItem: any, index: number) =>
              (
                <div>
                  <div className="interview mt-15 panel-cal">
                    <label className="panel_title panel-cal">{getFullName(panelItem?.title)}</label>
                    <div style={{ fontSize: 13 }} className="panel-cal">{this.formatAMPM(panelItem.start)} - {this.formatAMPM(panelItem.end)}</div>
                    <div style={{ fontSize: 13, marginTop: 10 }} className="panel-cal">Interviewer</div>
                    <div className="panel-cal" style={{ fontSize: 14, fontWeight: 'bold' }}>{getFullName(panelItem?.panel_name)}</div>
                  </div>
                  {this.state.recruiterMoreEventData.length !== index + 1 && <Divider className="panel-cal"/>}
                </div>
              )
              )
              }
            </div>
          </Modal>}
      </ThemeProvider>
    );
  }
}

// Customizable Area End
