// Customizable Area Start
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Toolbar from '@material-ui/core/Toolbar';
import { alpha, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Link, Switch, Route } from 'react-router-dom';
import { MainLogoWhite, NotificationWhiteIcon, Notification } from "./assets"

// import Userprofile from './UserImg.png' 
import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const mainConfigJson = require("../../../framework/src/config");

/////////  Candidate Page  /////////

import CandidatePage from './CandidatePage.web';

import ViewCandidateProfile from '../../documentopener/src/JobsSection/ViewCandidateProfile.web';

import ViewJobDetails from './ViewJobDetails.web'

// Notification Bar
import NotificationBar from "../../LinkShare/src/NotificationBar.web"


const drawerWidth = 250;

export const useStyles = (theme: Theme) => createStyles({
  grow: {
    flexGrow: 1
  },
  root: {
    display: 'flex',
  },
  title: {
    minWidth: "fit-content",
    marginRight: "16px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "0px",
      marginRight: "0px"
    },
    fontFamily: "'Poppins-SemiBold', sans-serif !important"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: 12,
    maxWidth: "500px",
    minWidth: "120px",
    border: "solid 1px #e7e7e7",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    height: "100%"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      height: "100%"
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up(650)]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up(650)]: {
      display: "none"
    }
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#083247",
    fontSize: "18px",
    color: "white",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#0832470a",
    minHeight: "100vh",
    boxSizing: "border-box",
  },
  candidateAppBar: {
    background: "#083247",
  }
}
)

export class LandingPage extends React.PureComponent<any, any, any> {
  role: any
  id: any
  constructor(props: any) {
    super(props);
    this.state = {
      profileKey: '',
      mobileOpen: false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      screen: "",
      defaultAppBar: true,
      name: "",
      role: "",
      show: false,
      havingIssue: false,
      havingIssuePopup: false,
      companyId: "",
      openNotification: false
    }
  }

  //@ts-ignore
  componentDidMount() {
    console.log('item',this.componentDidMount)
    this.role = localStorage.getItem("role")?.toLowerCase()
    this.id = localStorage.getItem("userId")
    if (this.role !== "candidate") {
      localStorage.clear()
      //@ts-ignore
      this.props.history.push("/");
    }

    //@ts-ignore
    let path = this.props.location.pathname.split("/")

    path = path[path.length - 1]
    path = path[0].toUpperCase() + path.slice(1)
    this.setState({ ...this.state, screen: path, role: this.role })
  }

  getAppendString = (url: any) => {
    const newUrl = url.charAt(0) === "/" ? mainConfigJson.baseURL + url : url;
    return newUrl;
  }

  componentDidUpdate(prevProps: any) {
    if (!localStorage.getItem("role")?.toLowerCase()) {
      localStorage.clear()
      //@ts-ignore
      this.props.history.push("/");
    }
  }

  handleLogout = () => {
    localStorage.clear();
    //@ts-ignore
    this.props.history.push("/")
  }

  handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    console.log('item',this.handleMobileMenuOpen)
    this.setState({ ...this.state, mobileMoreAnchorEl: event.currentTarget });
  };


  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ ...this.state, mobileMoreAnchorEl: null });
  };

  updateUserProfile = () => {
    setTimeout(() => {
      this.setState({ ...this.state, profileKey: new Date().toISOString() })
    }, 1000);
    setTimeout(() => {
      this.setState({ ...this.state, profileKey: "" })
    }, 2000);
  }

  onClickProfile = () => {
    this.setState({ ...this.state, anchorEl: null });
    this.props?.history?.push("/Candidate/Profile", { id: this.id, path: "/Candidate/Home", tab: "Candidates", companyId: this.state.companyId })
  }

  Accountcircle = () => {
    return (
    
      localStorage.getItem("image") ?
        <img src={localStorage.getItem("image") ? this.getAppendString(localStorage.getItem("image")) : ""} alt="user" style={{ height: "35px", width: "35px", marginRight: "6px", borderRadius: "50%" }} />
        :
        <AccountCircle style={{ fontSize: "36px", marginRight: "5px", color: this.role === "candidate" || this.state.screen === "CandidateProfile" ? "#fff" : "" }} />
    )
  }
  Capitalization=()=>{
    return (
     <span style={{ color: "grey", textTransform: "capitalize" }}> {localStorage.getItem("show_role") !== null && localStorage.getItem("show_role") !== undefined
                      ? localStorage.getItem("show_role")
                      : this.state.role} </span>
    )
  }
  
  onCloseRenderMenu = () => this.setState({ ...this.state, anchorEl: null })
  handleClick() {
    this.setState({
      mobileMoreAnchorEl: null,
      openNotification: true,
    });
  }
  handleRedirect = () => {
    this.props.history.push('/Candidate/Home');
  }
  handleHavingIssuePopup = () => {
    this.setState({ havingIssuePopup: true });
  }
  handleOpenNotification = () => {
    this.setState({ openNotification: true });
  }
  render() {
    //@ts-ignore
   
   
    const { classes } = this.props
    
    let isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);
    const menuId = "primary-search-account-menu";
    const mobileMenuId = "primary-search-account-menu-mobile";

    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.onCloseRenderMenu}
        className="nav-menu"
      >
        <MenuItem style={{ padding: "8px 30px" }} onClick={this.onClickProfile}>Profile</MenuItem>
        <MenuItem style={{ padding: "8px 30px" }} onClick={this.handleLogout}>Logout</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem onClick={this.handleClick}>
          <IconButton aria-label="show new notifications" color="inherit"
          >
            <img src={Notification} alt="notification" style={{ height: "25px" }} />
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div style={{ display: "flex" }}>
  
         {
          //@ts-ignore
          <AppBar position="fixed" className={classes.candidateAppBar} color={"#000"} >
            <Toolbar>
              <img src={MainLogoWhite} className='pointer' alt="" style={{ height: 20 }} onClick={this.handleRedirect} />
              <div className={classes.grow} />
              <div className={classes.sectionDesktop} style={{ alignItems: "center" }}>
                {this.state.havingIssue &&
                    <Button
                      variant="outlined"
                      style={{ color: "#fff", borderColor: "#fff", borderRadius: "8px", marginRight: "12px" }}
                      onClick={this.handleHavingIssuePopup}
                    >
                      Have an Issue?
                    </Button>
                  
                }

                <IconButton aria-label="show 17 new notifications" color="inherit" onClick={this.handleOpenNotification}>

                  <img src={NotificationWhiteIcon} alt="notification" style={{ height: "25px" }} />
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={this.handleProfileMenuOpen}
                  color="inherit"
                  key={this.state.profileKey + "account-candidate"}

                >
                  {this.Accountcircle()}
                  <div
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span style={{ fontWeight: "bold", marginBottom: "3px", color: 'white', textTransform: "capitalize" }}>
                      {localStorage.getItem("fname") + " " + localStorage.getItem("lname")}
                    </span>
         
                    {this.Capitalization()}
                  </div>
                </IconButton>
              </div>

              <div className={classes.sectionMobile}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={this.handleProfileMenuOpen}
                  color="inherit"
                  key={this.state.profileKey + "can-account"}
                >



                  {
                    localStorage.getItem("image") ?
                      <img src={localStorage.getItem("image") ? this.getAppendString(localStorage.getItem("image")) : ""} alt="user" style={{ height: "30px", width: "30px", borderRadius: "50%" }} />
                      : <AccountCircle style={{ color: this.role === "candidate" || this.state.screen === "CandidateProfile" ? "#fff" : "" }} />
                  }
                </IconButton>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={this.handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon style={{ color: this.role === "candidate" || this.state.screen === "CandidateProfile" ? "#fff" : "" }} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        }
        {renderMenu}
        {renderMobileMenu}
        { //@ts-ignore
          this.state.openNotification && <NotificationBar handleClose={ ()=>{this.setState({ openNotification: false })}}/>
        }
        <main className={classes.content + " main-content-wrapper"}>
          <div className={classes.toolbar} />
          {/* @ts-ignore */}
          <Switch>
            {/* @ts-ignore */}
            <Route path={"/Candidate/Home"} render={() => <CandidatePage history={this.props.history} location={this.props.location} havingIssue={(set: boolean) => { this.setState({ havingIssue: set }) }} companyId={(set: any) => { this.setState({ companyId: set }) }} />} />
            {/* @ts-ignore */}
            <Route path={"/Candidate/Profile"} render={() => <MuiPickersUtilsProvider utils={DateFnsUtils} ><ViewCandidateProfile location={this.props.location} history={this.props.history} updateProfile={this.updateUserProfile} /></MuiPickersUtilsProvider>} />
            { /* @ts-ignore */}
            <Route path={"/Candidate/Job_Details"} render={() => <ViewJobDetails havingIssue={(set: boolean) => { this.setState({ havingIssue: set }) }} location={this.props.location} history={this.props.history} showHavingIssuePopup={this.state.havingIssuePopup} havingIssuePopup={(set: boolean) => { this.setState({ havingIssuePopup: set }) }} />} />
            {/* @ts-ignore */}
            <Route path={"/Candidate/*"} component={NoPage} />
          </Switch>
        </main>

      </div>
    )
  }
}

export function NoPage() {
  return <div style={{ color: '#083247', textAlign: 'center' }}>
    <h1 style={{ color: 'red' }}> 404 No Page Found :(</h1>
    <Link style={{ fontWeight: 600, fontSize: "16px" }} to="/Candidate/Home">Go To Home :-)</Link>
  </div>
}

export default withStyles(useStyles, { withTheme: true })(LandingPage)




// Customizable Area End